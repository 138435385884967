import { authjourneyNavConfig, unauthjourneyNavConfig } from '../../components/JourneyNavProvider'
import { config, CwaEnvironmentNames, EnvironmentConfigInterface, JourneyNames } from '../../config'

export type LogSeverity = 'error' | 'warn' | 'info'

export enum ErrorSource {
  MS_ERROR = 'MS_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
  UI_ERROR = 'UI_ERROR',
}

export const MS_ERROR_PRETEXT = 'Protection Error: '
export interface LogInfo {
  content: string
  journey: JourneyNames
  source: ErrorSource
  activePage?: string
  timestamp?: Date
  severity?: LogSeverity
}

export const buildDynatraceLogBody = (log: LogInfo): LogInfo => {
  const logInfo = { ...log }
  const { severity, timestamp, activePage } = logInfo

  if (!timestamp) {
    logInfo.timestamp = new Date()
  }

  if (!severity) {
    logInfo.severity = 'info'
  }

  if (!activePage) {
    logInfo.activePage = window.location.href
  }

  return logInfo
}

export const getErrorType = (errorMessage: string): ErrorSource => {
  return errorMessage.includes(MS_ERROR_PRETEXT) ? ErrorSource.MS_ERROR : ErrorSource.NETWORK_ERROR
}

export const getEnv = () => {
  return process.env.NODE_ENV ?? ''
}

export const sendLogToAdobeAnalytics = (body: LogInfo[], cwaEnvironmentName: CwaEnvironmentNames) => {
  const joinedMessage = body.map((log) => log.content).join(' | ')

  if (['development', 'test'].includes(getEnv())) {
    console.error(`Error Info: ${JSON.stringify(joinedMessage)}`)
    return
  }
  const navConfig = cwaEnvironmentName === 'unauth' ? unauthjourneyNavConfig : authjourneyNavConfig
  const currentPagePathName = body[0].activePage?.substring(body[0].activePage?.lastIndexOf('#') + 1)
  const journeyNavState = navConfig.find((journeyConfig) => currentPagePathName === journeyConfig.path)?.state
  try {
    window.LBGAnalytics.data
      .setJourney('Life Insurance - Digital', '2')
      .setJourneyStep(
        journeyNavState?.journeyStep?.JourneyStep ?? 'ERROR_INFO',
        journeyNavState?.journeyStep?.JourneyStepName ?? '',
      )
      .done()
      .events.pageView()
      .send({ EventAction: 'Error Report', EventNarrative: joinedMessage })
  } catch {
    /* tslint:disable:no-empty */
  }
}

export const sendLogToExternal = (body: LogInfo[], cwaEnvironmentName: CwaEnvironmentNames) => {
  const formattedBody = body.map((log) => buildDynatraceLogBody(log))
  // sending error info to adobe analytics, body can be used to send to dynatrace as well in future updates
  sendLogToAdobeAnalytics(formattedBody, cwaEnvironmentName)
}

export const handleError = (journeyConfig: EnvironmentConfigInterface, errorMessages: string[]) => {
  const { errorReporterConfig, journeyName, cwaEnvironmentName } = journeyConfig

  if (!errorReporterConfig || !Object.keys(errorReporterConfig).length) {
    return
  }
  const body: LogInfo[] = []
  errorMessages.forEach((errMsg) => {
    const source: ErrorSource = getErrorType(errMsg)

    const log = {
      content: errMsg,
      journey: journeyName,
      severity: 'error',
      source,
    } as LogInfo

    if (source === ErrorSource.MS_ERROR && errorReporterConfig.microServiceErrorLog === 'true') {
      body.push(log)
    }
    if (source === ErrorSource.NETWORK_ERROR && errorReporterConfig.networkErrorLog === 'true') {
      body.push(log)
    }
  })

  if (body.length) {
    sendLogToExternal(body, cwaEnvironmentName)
  }
}

export const isErrorReportingEnabled = (uiError = false): boolean => {
  return uiError
    ? process.env.REACT_APP_ERROR_REPORTER_RUNTIME_ERROR_LOG === 'true'
    : process.env.REACT_APP_ERROR_REPORTER_MICRO_SERVICES_LOG === 'true' ||
        process.env.REACT_APP_ERROR_REPORTER_NETWORK_ERROR_LOG === 'true'
}

export const getMessagesFromArray = (errors: any[]): string[] => {
  const errorMessages: string[] = []
  const errorsExists = !!errors?.length

  if (errorsExists && typeof errors[0] === 'string') {
    errorMessages.push(...errors)
  }

  if (typeof errors[0] === 'object') {
    const errorProps = Object.keys(errors[0])
    const isMoreErrors = ['code', 'message'].every((key) => errorProps.includes(key))
    if (isMoreErrors) {
      errorMessages.push(...errors.map((err) => err.message))
    }
  }
  return errorMessages
}

export const getErrorMessages = (payload: any): string[] => {
  const errorMessages: string[] = []
  if (!payload) {
    return []
  }

  if (typeof payload === 'string') {
    return [payload]
  }

  if (typeof payload === 'object') {
    const { errors, validationErrors, message, httpMessage } = payload

    if (message) {
      errorMessages.push(message)
    }

    if (httpMessage) {
      errorMessages.push(httpMessage)
    }

    if (validationErrors && Array.isArray(validationErrors)) {
      errorMessages.push(...getMessagesFromArray(validationErrors))
    }

    if (errors && Array.isArray(errors)) {
      errorMessages.push(...getMessagesFromArray(errors))
    }
  }

  return [...new Set(errorMessages)]
}

export const onErrorThirdPartyLogger = async (errors: any) => {
  const thirdPartyloggerEnabled = isErrorReportingEnabled()
  if (!thirdPartyloggerEnabled) {
    return
  }
  const errorMessages = getErrorMessages(errors)

  if (errorMessages && !!errorMessages.length) {
    const envConfig = await config.get()
    handleError(envConfig, errorMessages)
  }
}
