import React from 'react'
import { Box, makeStyles, Typography } from '@lbg-protection/lib-ui-monoceros'
import { PhoneNumberLink } from '../../../Shared/PhoneNumberLink/PhoneNumberLink'
import { tagButtonClick } from '../../../../utils/tagUtils/tagsUtils'

const withStyles = makeStyles((theme) => {
  return {
    phoneLinkWrapper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  }
})

export const CavendishOnlineContent = () => {
  const classes = withStyles({})

  return (
    <>
      <Typography gutterBottom>
        If you&apos;d like advice, then contact Cavendish Online. They can help you find cover that&apos;s right for you
        and your loved ones.
      </Typography>
      <Typography gutterBottom>
        Cavendish Online can advise you on Scottish Widows protection policies. They won&apos;t charge you for their
        advice, and you&apos;re under no obligation when you speak to them.
      </Typography>
      <Typography>Call Cavendish Online on:</Typography>
      <Box className={classes.phoneLinkWrapper}>
        <PhoneNumberLink
          department="callback"
          data-testid="ineligble-phone-link1"
          onClick={() => tagButtonClick('Cavendish Online number')}
        />
      </Box>
      <Typography gutterBottom>Lines are open Monday to Thursday 9am to 7pm, Friday 9am to 6pm.</Typography>
      <Typography>Or talk to us about your application. Call us on:</Typography>
      <Box className={classes.phoneLinkWrapper}>
        <PhoneNumberLink department="modelOffice" data-testid="ineligble-phone-link2" />
      </Box>
      <Typography>Lines are open Monday to Friday 9am to 5pm.</Typography>
    </>
  )
}
