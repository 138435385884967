import React from 'react'
import { CoverRightForYou } from './CoverRightForYou'

const rightlifeCover = [
  'You have children or loved ones who rely on your income',
  'You have other dependents who might struggle to get by without you',
  "You're the main earner in your family",
  'You have long-term debts, like a mortgage.',
]

export const LifeCoverRightForYou = () => {
  return <CoverRightForYou title="Life Cover might be right for you if:" content={rightlifeCover} />
}
