import { makeStyles, MonocerosTheme, Brand } from '@lbg-protection/lib-ui-monoceros'
import { isBOS } from '../../../utils/brandSpecificFormatting'

export const withStyles = makeStyles((theme: MonocerosTheme) => {
  return {
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingTop: isBOS(theme.name as Brand) ? theme.spacing(5) : undefined,
    },
    quoteHelp: {
      marginTop: '1.5rem',
      width: 'auto',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#333333',
    },
    validationErrorBox: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(5),
    },
    quoteHelpText: {
      width: 'auto',
      color: '#505050',
    },
    validationErrorBoxWider: {
      backgroundColor: theme.palette.secondary.light,
      marginTop: `-${theme.spacing(5)}`,
      marginLeft: `-${theme.spacing(3)}`,
      marginRight: `-${theme.spacing(3)}`,
      padding: `${theme.spacing(7)} ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(3)}`,
    },
    quoteHelpTextWider: {
      width: 'auto',
      color: theme.palette.namedColours?.bosSlateGrey,
      backgroundColor: theme.palette.secondary.light,
      marginLeft: `-${theme.spacing(3)}`,
      marginRight: `-${theme.spacing(3)}`,
      padding: `${theme.spacing(4)} ${theme.spacing(3)} 0px ${theme.spacing(3)}`,
    },
  }
})
