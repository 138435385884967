import React from 'react'
import clsx from 'clsx'
import { Box, Brand, MonocerosTheme, Typography, useTheme } from '@lbg-protection/lib-ui-monoceros'
import formatCurrency, { formatCurrencyWithZeroesAfterDecimal } from '../../../../utils/formatCurrency'
import { useStyles } from '../styles'
import { CoverSummaryProps } from './types'
import { isSW } from '../../../../utils/brandSpecificFormatting'

export const CoverSummary = (props: CoverSummaryProps) => {
  const {
    coverSummary: { title, amount = 0, term, monthlyCost = 0 },
  } = props
  const formattedAmount = formatCurrency('en-GB', 'GBP', Number(amount))
  const payment = formatCurrencyWithZeroesAfterDecimal('en-GB', 'GBP', Number(monthlyCost))
  const classes = useStyles()
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)

  return (
    <Box className={classes.summaryRow}>
      <Box className={classes.summaryCol}>
        <Typography variant="caption" color="inherit" data-testid={`${title}-cover-name`}>
          {title}
        </Typography>
        <Typography
          variant={isSWBrand ? 'body1' : 'subtitle1'}
          component="p"
          color="inherit"
          data-testid={`${title}-cover-amount`}
        >
          {formattedAmount} for {term} years
        </Typography>
      </Box>
      <Box className={clsx(classes.summaryCol, classes.alightRight)}>
        <Typography color="inherit" variant="body1" data-testid={`${title}-monthly-cost`}>
          Monthly cost
        </Typography>
        <Typography variant="caption" color="inherit" data-testid={`${title}-monthly-payment`}>
          {payment}
        </Typography>
      </Box>
    </Box>
  )
}
