import { ReactNode } from 'react'

export type BindContentData = Record<string, string | number | boolean>

export const bindContent = (value: string, data?: BindContentData): ReactNode => {
  const pattern = /##(.+?)##/im
  let newValue = value
  let match
  // eslint-disable-next-line no-cond-assign
  while ((match = pattern.exec(newValue))) {
    const matchToken = match[0]
    const matchPath = match[1]

    const replacement = data ? data[matchPath] ?? '' : ''
    newValue = `${newValue.slice(0, match.index)}${replacement}${newValue.slice(match.index + matchToken.length)}`
  }
  return newValue
}
