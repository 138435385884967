import { Box, CurrencyInput, FormField, NumberInput, Typography } from '@lbg-protection/lib-ui-monoceros'
import React, { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { DialogControl } from '../../../DialogControl/DialogControl'
import { IconTitle } from '../../../IconTitle/IconTitle'
import { SubmitAnswer } from '../../types'
import { styles } from './styles'
import { HomePaymentAnswer, HomePaymentQ, HomePaymentValues } from './types'

interface HomePaymentQuestionProps {
  question: HomePaymentQ
  answer: HomePaymentAnswer | null
  submitAnswer: SubmitAnswer
  goToNextQuestion: () => void
}

export const Question = (props: HomePaymentQuestionProps) => {
  const classes = styles()
  const { errors, handleSubmit, control, watch } = useForm<HomePaymentValues>({
    mode: 'onChange',
    defaultValues: props.answer ? props.answer.values : { amount: '', term: '' },
  })
  const amountInputRef = useRef<HTMLInputElement>(null)
  const termInputRef = useRef<HTMLInputElement>(null)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (errors.amount) {
      return amountInputRef?.current?.focus()
    }
    if (errors.term) {
      return termInputRef?.current?.focus()
    }
  }, [errors])

  const values = watch()

  useDeepCompareEffect(() => {
    if (values && !errors.amount && !errors.term) {
      props.submitAnswer({
        questionId: props.question.id,
        question: props.question.question,
        category: props.question.category,
        values,
      })
    }
  }, [values])

  const onSubmit = () => {
    props.goToNextQuestion()
  }

  return (
    <>
      <IconTitle dataTestId={`${props.question.question}-title`} title={props.question.title} />
      <Typography
        gutterBottom
        className={classes.helpText}
        variant="body1"
        data-testid={`${props.question.id}-help-text`}
      >
        {props.question.helpText}
      </Typography>
      <form
        data-testid={`${props.question.id}-question-form`}
        id={`${props.question.id}-question-form`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          key={`${props.question.id}-amount`}
          id={`${props.question.id}-amount`}
          name="amount"
          control={control}
          rules={{
            required: {
              value: props.question.amount.fieldConfig.required,
              message: props.question.amount.fieldConfig.requiredErrorMessage ?? '',
            },
            min: {
              value: props.question.amount.fieldConfig.min,
              message: props.question.amount.fieldConfig.minErrorMessage ?? '',
            },
            max: {
              value: props.question.amount.fieldConfig.max,
              message: props.question.amount.fieldConfig.maxErrorMessage ?? '',
            },
          }}
          render={({ onChange, onBlur, value }) => (
            <FormField
              key={props.question.id}
              id={`${props.question.id}-amount`}
              helperText={props.question.amount.helperText}
              label={props.question.amount.label}
              errorMessage={errors?.amount?.message}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              inputWidth="284px"
              inputRef={amountInputRef}
            >
              <CurrencyInput
                min={props.question.amount.fieldConfig.min}
                max={props.question.amount.fieldConfig.max}
                data-testid={`${props.question.id}-amount-field`}
                decimalScale={props.question.amount.fieldConfig.decimals}
                inputProps={{ 'aria-required': true }}
              />
            </FormField>
          )}
        />
        <Controller
          key={`${props.question.id}-term`}
          id={`${props.question.id}-term`}
          name="term"
          control={control}
          rules={{
            required: {
              value: props.question.term.fieldConfig.required,
              message: props.question.term.fieldConfig.requiredErrorMessage ?? '',
            },
            min: {
              value: props.question.term.fieldConfig.min,
              message: props.question.term.fieldConfig.minErrorMessage ?? '',
            },
            max: {
              value: props.question.term.fieldConfig.max,
              message: props.question.term.fieldConfig.maxErrorMessage ?? '',
            },
          }}
          render={({ onChange, onBlur, value }) => (
            <FormField
              key={props.question.id}
              id={`${props.question.id}-term`}
              label={props.question.term.label}
              helperText={props.question.term.helperText}
              errorMessage={errors?.term?.message}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              endAdornment="years"
              inputWidth="100px"
              inputRef={termInputRef}
            >
              <NumberInput
                min={props.question.term.fieldConfig.min}
                max={props.question.term.fieldConfig.max}
                data-testid={`${props.question.id}-term-field`}
                decimalScale={props.question.term.fieldConfig.decimals}
                inputProps={{ 'aria-required': props.question.id === 'rent' }}
              />
            </FormField>
          )}
        />
      </form>
      {props.question.overlay && (
        <Box className={classes.overlayLink}>
          <DialogControl
            id={props.question.id}
            data-testid={props.question.id}
            title={props.question.overlay.link}
            controlText={props.question.overlay.link}
            transition="fade"
          >
            {props.question.overlay.content}
          </DialogControl>
        </Box>
      )}
    </>
  )
}
