import React, { SyntheticEvent, useState } from 'react'
import clsx from 'clsx'
import {
  Box,
  ButtonV2 as Button,
  Brand,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  LinkV2 as Link,
  makeStyles,
  MonocerosTheme,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'

import { PageFocus } from '../PageFocus'
import { isSW } from '../../../utils/brandSpecificFormatting'

const styles = makeStyles((theme: MonocerosTheme) => ({
  bottomActions: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid #d7d7d7`,
    '& > button:not(:first-child)': {
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
}))

interface DialogControlProps extends Pick<DialogProps, 'transition'> {
  id: string
  controlText: string
  title: React.ReactNode
  children: React.ReactNode
  closeButtonText?: React.ReactNode
  customOnClick?: () => void
  customOnClose?: () => void
  customActions?: React.ReactNode
  'data-testid'?: string
  linkClass?: string
  variant?: 'link' | 'button'
  buttonDataTestId?: string
  buttonStyle?: React.CSSProperties
}

export const DialogControl = (props: DialogControlProps) => {
  const classes = styles({})
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)
  const [open, setOpen] = useState(false)
  const dataTestId = `${props['data-testid']}-dialog`

  const handleOnClose = () => {
    if (props.customOnClose !== undefined) props.customOnClose()
    setOpen(false)
  }

  return (
    <>
      {props.variant === 'button' && (
        <Button
          data-testid={props.buttonDataTestId || `${dataTestId}-button`}
          onClick={() => {
            setOpen(true)
            if (props.customOnClick) {
              props.customOnClick()
            }
          }}
          style={props.buttonStyle}
          variant="outlined"
          color="primary"
        >
          {props.controlText}
        </Button>
      )}
      {props.variant === 'link' && (
        <Link
          data-testid={`${dataTestId}-link`}
          onClick={(event: SyntheticEvent) => {
            event.preventDefault()
            setOpen(true)
            if (props.customOnClick) {
              props.customOnClick()
            }
          }}
          className={props.linkClass}
          href={`#${props.id}-dialog-link`}
          variant={isSWBrand ? 'body1' : 'body2'}
        >
          {props.controlText}
        </Link>
      )}

      <Dialog data-testid={dataTestId} open={open} onClose={handleOnClose} transition={props.transition}>
        <PageFocus>
          <Box>{props.title && <DialogTitle data-testid={`${dataTestId}-title`}>{props.title}</DialogTitle>}</Box>
        </PageFocus>
        <DialogContent data-testid={`${dataTestId}-content`}>{props.children}</DialogContent>
        <DialogActions className={clsx(classes.bottomActions)}>
          {props.customActions}
          <Button
            fullWidth
            data-testid={`${props.id}-dialog-close-button`}
            variant="contained"
            color="primary"
            onClick={handleOnClose}
          >
            {props.closeButtonText ?? 'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DialogControl.defaultProps = {
  variant: 'link',
}
