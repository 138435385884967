import { makeStyles } from '@lbg-protection/lib-ui-monoceros'

export const styles = makeStyles((theme) => {
  return {
    addButton: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    overlayLink: {
      marginTop: theme.spacing(4),
    },
  }
})
