import { CoverSelectedEnum } from '../../../../journeys/purchase'
import { calculateMaxTerm } from '../../../../utils/calculateMaxTerm'
import { calculateAge } from '../../../../utils/calculateAge'
import { ChildrenAgeAnswer } from '../../../Shared/Questionaire/questions/ChildrenAgeQuestion/types'
import { HomePaymentAnswer } from '../../../Shared/Questionaire/questions/HomePaymentQuestion/types'
import { Milestone, TermQ } from '../../../Shared/Questionaire/questions/TermQuestion/types'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'
import { Answer } from '../../../Shared/Questionaire/types'
import { getMilestones, getTermRange } from './utils/helpers'

export const term = (
  answers: Record<string, Answer>,
  dateOfBirth: Date,
  selectedProducts: CoverSelectedEnum,
): TermQ => {
  const customerAge = calculateAge(dateOfBirth)
  const children = answers.children as ChildrenAgeAnswer
  const mortgage = answers.mortgage as HomePaymentAnswer
  const minTerm = 5
  const maxTerm = calculateMaxTerm(dateOfBirth)
  const termRange: number[] = getTermRange(minTerm, maxTerm)
  const milestones: Milestone[] = getMilestones(children, mortgage, customerAge, termRange)
  const showLifeTerm = selectedProducts === CoverSelectedEnum.Life || selectedProducts === CoverSelectedEnum.All
  const showBodyTerm = selectedProducts === CoverSelectedEnum.Body || selectedProducts === CoverSelectedEnum.All

  return {
    id: 'term',
    category: 'term',
    sectionHeader: 'Your term',
    type: QuestionTypes.Term,
    title: {
      text: 'How long would you like your cover to last?',
      icon: 'clock',
    },
    question: 'Term',
    helpText: `Think about how long you'd like to have this cover. Don't worry if you're not sure, you can change this on your cover report.`,
    terms: [
      ...(showLifeTerm
        ? [
            {
              id: 'lifeTerm',
              label: 'How long would you like Life Cover?',
              helpText: `We can offer you cover from ${minTerm} to ${maxTerm} years.`,
              errorMessage: `Please select how many years you'd like to have Life Cover`,
            },
          ]
        : []),
      ...(showBodyTerm
        ? [
            {
              id: 'bodyTerm',
              label: 'How long would you like Body Cover?',
              helpText: `We can offer you cover from ${minTerm} to ${maxTerm} years.`,
              errorMessage: `Please select how many years you'd like to have Body Cover`,
            },
          ]
        : []),
    ],
    milestones,
    termRange,
    JourneyStepName: 'Term',
    JourneyStep: '19',
  }
}
