import { JourneyExitPoint, PolicyApplicationPricingRequestDetails } from '../types'

export const getJourneyExitPoint = (
  policies: Array<PolicyApplicationPricingRequestDetails>,
  lastQuoteDate?: string,
): JourneyExitPoint => {
  if (policies.length > 0 || lastQuoteDate) {
    return JourneyExitPoint.Quote
  }
  return JourneyExitPoint.PreQuote
}
