import { Typography } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { PhoneNumberLink } from '../../../Shared/PhoneNumberLink/PhoneNumberLink'
import { HomePaymentQ } from '../../../Shared/Questionaire/questions/HomePaymentQuestion/types'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'

export const mortgage = (): HomePaymentQ => {
  return {
    id: 'mortgage',
    category: 'homePayment',
    type: QuestionTypes.HomePayment,
    sectionHeader: 'Your home',
    question: 'Mortgage details',
    title: {
      text: 'Your home',
      icon: 'home',
    },
    amount: {
      id: 'amount',
      label: 'How much is left to pay on your mortgage?',
      fieldConfig: {
        required: true,
        requiredErrorMessage: 'Enter the amount left to pay on your mortgage',
        min: 0,
        max: 9999999.99,
        maxErrorMessage: 'Please enter a value of less than £10 million',
        decimals: 2,
      },
    },
    term: {
      id: 'term',
      label: "How many years until it's paid off?",
      helperText: '(Optional)',
      fieldConfig: {
        required: false,
        min: 0,
        max: 100,
        maxErrorMessage: 'Please enter a number up to 100 years',
        decimals: 0,
      },
    },
    helpText: 'We’ll help you decide how much you’d need to help pay off your mortgage if you were to die.',
    overlay: {
      id: 'only mortgage',
      link: 'Do you want cover for your mortgage?',
      content: (
        <>
          <Typography gutterBottom>
            If you need cover for your repayment mortgage, then this might not be the best kind of cover for you. You
            might end up paying for cover that you don&apos;t need and there may be cheaper options better suited to you
            and your mortgage.
          </Typography>
          <Typography gutterBottom>
            You can still apply for a quote for this cover, but if you need advice then you should contact Cavendish
            Online. They can help find cover that&apos;s right for you and your mortgage.
          </Typography>
          <Typography gutterBottom>
            Cavendish Online won&apos;t charge you for their advice, and you&apos;re under no obligation when you speak
            with them.
          </Typography>
          <Typography gutterBottom>Call Cavendish Online on:</Typography>
          <PhoneNumberLink gutterBottom department="cavendishOnline" />
          <Typography gutterBottom>Lines are open Monday to Thursday 9am to 7pm, Friday 9am to 6pm.</Typography>
          <Typography gutterBottom>Cavendish Online is a part of Lloyds Banking Group.</Typography>
        </>
      ),
    },
    JourneyStepName: 'Home payment - mortgage',
    JourneyStep: '11',
  }
}
