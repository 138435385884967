import { Box, Typography, Divider, PromoBox, useTheme, MonocerosTheme, Brand } from '@lbg-protection/lib-ui-monoceros'
import React, { useMemo } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import { isHalifax } from '../../../utils/brandSpecificFormatting'
import { ExitSurveyPropsInterface } from './types'
import { ExitSurveyGiveFeedBackBtn } from './ExitSurveyGiveFeedbackBtn/ExitSurveyGiveFeedbackBtn'
import { useStyles } from './styles'
import { useFeatureFlags } from '../../../featureFlags/useFeatureFlags'
import { RootState } from '../../../app/types'
import { useJourneyChecker } from '../../../journeyCwaConfig'

const exitSurveyTitle = 'Tell us what you think'

const ExitSurveyCard = (props: ExitSurveyPropsInterface) => {
  const { isFeatureEnabled } = useFeatureFlags()
  const exitSurveyEnabled = isFeatureEnabled('enableExitSurvey')
  const { isJourneyUnAuth, isJourneyWeb } = useJourneyChecker()

  const classes = useStyles(props)
  const { name: themeName = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const {
    showGiveFeedbackBtn,
    showPromoBoxPadding,
    showTopMarigin,
    showTopDivider,
    showContainedButton,
    showSideMargin,
    showPromoBox,
    ocisId,
    showBottomMargin,
    showBottomDivider,
  } = props

  const halifaxBrand = useMemo(() => isHalifax(themeName), [themeName])
  const ExitSurveyBody = (
    <div>
      <Typography variant="body1" className={classes.feedbackBodyMargin} align="left">
        Give your feedback to help us make applying better for you (opens in your browser).
      </Typography>
      {showGiveFeedbackBtn && (
        <ExitSurveyGiveFeedBackBtn brand={themeName} ocisId={ocisId} showContainedButton={showContainedButton} />
      )}
    </div>
  )
  if (exitSurveyEnabled && !isJourneyWeb && !isJourneyUnAuth)
    return showPromoBox ? (
      <Box
        className={clsx(
          showSideMargin && !halifaxBrand && classes.feedbackContentSideMargin,
          showTopMarigin && classes.feedbackContentTopMargin,
          showBottomMargin && classes.feedbackContentBottomMargin,
        )}
        data-testid="exit-survey-container"
      >
        {halifaxBrand && showTopDivider && <Divider className={classes.dividerTopMargin} />}
        <PromoBox
          className={clsx(
            classes.squarePromoBox,
            classes.promoBoxBackground,
            !showPromoBoxPadding && classes.noPadding,
          )}
          variant="large"
          titleComponent="h4"
          title={exitSurveyTitle}
        >
          {ExitSurveyBody}
          {showBottomDivider && halifaxBrand && <Divider className={classes.dividerBottomMargin} />}
        </PromoBox>
      </Box>
    ) : (
      <Box
        className={clsx(
          showSideMargin ? classes.feedbackContentSideMargin : '',
          classes.feedbackContentTopMargin,
          classes.feedbackContentBottomMargin,
        )}
        data-testid="exit-survey-container"
      >
        <Typography className={classes.feedbackHeadingMargin} variant="h4">
          {exitSurveyTitle}
        </Typography>
        {ExitSurveyBody}
      </Box>
    )
  return null
}

ExitSurveyCard.defaultProps = {
  showContainedButton: false,
  showSideMargin: true,
  showPromoBox: true,
  showBottomDivider: true,
  showTopDivider: true,
  showGiveFeedbackBtn: true,
  showTopMarigin: true,
  showPromoBoxPadding: true,
  showBottomMargin: true,
}

const mapStateToProps = (state: RootState) => ({
  ocisId: state.app.ocisId,
})

export const ExitSurvey = connect(mapStateToProps)(ExitSurveyCard)
