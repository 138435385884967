import { makeStyles } from '@lbg-protection/lib-ui-monoceros'

export const styles = (errors: boolean) =>
  makeStyles((theme) => ({
    contentContainer: {
      width: '100%',
      '& .MuiFormControl-root': {
        '& > label': {
          marginBottom: theme.spacing(2),
        },
      },
    },
    checkboxGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      '& > label': {
        width: '6rem',
        '& .MuiFormControlLabel-label': {
          fontSize: '18px',
        },
      },
    },
    checkbox: {
      width: 'auto',
      height: 'auto',
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-checked': {
        width: 'auto',
        height: 'auto',
        padding: 0,
      },
      '& input[type="checkbox"]:focus + div': {
        boxShadow: `${theme.palette.secondary.dark} 0px 0px 0px 0.25rem`,
        borderColor: errors ? theme.palette.error.main : theme.palette.primary.dark,
      },
      '& input[type="checkbox"]:hover + div': {
        borderColor: theme.palette.primary.dark,
        '& > svg': {
          fill: theme.palette.primary.dark,
          color: theme.palette.primary.dark,
        },
      },
    },
    overlayLinks: {
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
    },
    overlayLink: {
      marginTop: theme.spacing(3),
    },
  }))
