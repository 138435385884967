export const eligibilityBulletList1 = {
  text1: `You make three payments.`,
  text2: `If you have taken out more than one policy, the voucher amount which will be given will be that of the highest premium.`,
  text3: `The voucher you will receive will be specific to the amount of your monthly premium:`,
}

export const premiumForVoucher = [
  { premium: `£5.00 - £9.99`, voucher: `£20` },
  { premium: `£10.00 - £19.99`, voucher: `£40` },
  { premium: `£20.00 - £29.99`, voucher: `£80` },
  { premium: `£30.00 - £39.99`, voucher: `£120` },
  { premium: `£40.00 - £49.99`, voucher: `£160` },
  { premium: `£50+`, voucher: `£200` },
]

export const eligibilityBulletList2 = {
  text1: `This offer is limited to one ‘Onecode’ reward code per customer.`,
  text2: `This offer is limited to new customers who have not received or are due to receive a voucher when purchasing a Scottish Widows Plan & Protect policy via Lloyds Bank, Halifax or Bank of Scotland within the last 12 months.`,
}

export const howToClaimBulletList = {
  text1: `After you have made at least three successful monthly premium payments, an email or letter will be sent to you within 30 days containing a 'Onecode' reward code for the value of the voucher you are entitled to. This email or letter will contain details on how to redeem it for a gift card using the online portal.`,
  text2: `If you do not use the 'Onecode' reward code and select a gift card from the online portal within six months of the reward code being sent, you will no longer be able to claim the gift card.`,
}

export const generalConditionsBulletList = {
  text1: `The promoter of this offer is Scottish Widows, 25 Gresham Street, London, EC2V 7HN, part of Lloyds Banking Group. The administrator is Simply Thank You, Richmond Court, Morton Road, Darlington, DL1 4PT.`,
  text2: `Your Onecode will be emailed/ supplied to you by ‘Simply Thank You’ who also host the online portal where you make your gift card selection.`,
  text3: `‘Simply Thank You’ are separate from Scottish Widows and not part of Lloyds Banking Group.`,
  text4: `We will need to share some of your details with Simply Thank You but this will be limited to details needed for them to supply the ‘Onecode’ reward code to you. Simply Thank You will not use or share your details with other parties for marketing purposes.`,
  text5: `There is no cash alternative.`,
  text6: `In the event of circumstances outside our control where a ‘Onecode’ reward code is not available, a similar alternative of equal value will be provided.`,
  text7: `After you have made three payments, you will receive your ‘Onecode’ reward code to redeem for the relevant gift card amount, based on your monthly premium. We will send the code within 30 days of paying your third month of premium via email or letter. The code is then valid for 6 months.`,
  text8: `Once the code is activated, it will follow the Simply Thank You Terms & Conditions.`,
  text9: `We reserve the right to withdraw or amend this offer at any time.`,
  text10: `Scottish Widows reserves the right to withhold this offer should we discover or have a reasonable suspicion that the terms and conditions of this offer are being abused.`,
}
