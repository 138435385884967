import { createAsyncThunk, createSlice, Selector, Reducer, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { aboutYouSlice, selectConfirmedAddress } from '../../journeys/purchase/aboutYou.slice'
import { AppDispatch, RootState } from '../../app/types'
import { AddressInterface } from '../../journeys/purchase'
import { uwMeSlice } from '../UnderwriteMe/underwriteMe.slice'
import { quotesSlice } from '../QuotePage/quotes.slice'
import { saveAndRetrieveApi } from './saveAndRetrieveApi'
import { buildPolicy, isSavedJourneyValid } from './utils'
import {
  JourneyExitPoint,
  ProcessEmailArgs,
  RetrieveData,
  RetrieveJourneyArgs,
  SaveAndRetrieveLoadingStatus,
  SaveAndRetrieveState,
  SaveJourneyArgs,
} from './types'

const initialState: SaveAndRetrieveState = {
  saveStatus: SaveAndRetrieveLoadingStatus.Idle,
  callbackRequestStatus: SaveAndRetrieveLoadingStatus.Idle,
  isValidSavedJourney: false,
  isRetrievedJourney: null,
  indicativeQuoteEmailSent: false,
  savedQuoteEmailSent: false,
}

export const processEmail = createAsyncThunk<void, ProcessEmailArgs, { dispatch: AppDispatch; state: RootState }>(
  'saveAndRetrieve/processEmail',
  async (_: ProcessEmailArgs, { getState }): Promise<void> => {
    const {
      quotes: { lifeCover, bodyCover },
      saveAndRetrieve: { lastQuoteDate },
    } = getState()
    const quoteValidFrom = lastQuoteDate ?? dayjs().format('YYYY-MM-DD')

    await saveAndRetrieveApi.processSavedQuoteEmail({
      lifeCover,
      bodyCover,
      quoteValidUntil: dayjs(quoteValidFrom)
        .add(30, 'day')
        .format('YYYY-MM-DD'),
    })
  },
)

export const saveJourney = createAsyncThunk<void, SaveJourneyArgs, { dispatch: AppDispatch; state: RootState }>(
  'saveAndRetrieve/saveJourney',
  async (args, { dispatch, getState }): Promise<void> => {
    const { journeyExitPoint } = args
    const {
      aboutYou,
      quotes: { lifeCover, bodyCover },
      underwriteMe,
      saveAndRetrieve: { cavendishOnlineConsent, lastQuoteDate, indicativeQuoteEmailSent, savedQuoteEmailSent },
    } = getState()

    await saveAndRetrieveApi.saveJourney({
      journeyExitPoint,
      confirmedAddress: selectConfirmedAddress(aboutYou),
      lifeCover,
      bodyCover,
      enquiryId: underwriteMe.uwMeData?.enquiryId,
      lastQuoteDate,
      cavendishOnlineConsent,
    })
    // Here we want to ensure that an email is sent to the user, once on the indicative quote exit point and once for all stages after
    if (!indicativeQuoteEmailSent && journeyExitPoint === JourneyExitPoint.IndicativeQuote) {
      dispatch(processEmail({ isIndicativeQuoteEmail: true }))
    } else if (!savedQuoteEmailSent && journeyExitPoint !== JourneyExitPoint.IndicativeQuote) {
      dispatch(processEmail({}))
    }
  },
)

export const retrieveJourney = createAsyncThunk<RetrieveData | null, RetrieveJourneyArgs, { dispatch: AppDispatch }>(
  'saveAndRetrieve/retrieveJourney',
  async (args, { dispatch }): Promise<RetrieveData | null> => {
    const { shouldUseSavedAddress } = args
    const response = await saveAndRetrieveApi.retrieveJourney()
    const { aboutYouState, aboutYouAddressState, policiesState, underwritingEnquiryState, ...retrieveData } = response
    const { journeyExitPoint, cavendishOnlineConsent } = retrieveData
    const hasPurchasedPolicy = journeyExitPoint === JourneyExitPoint.Purchase

    if (hasPurchasedPolicy) {
      return { isRetrievedJourney: false }
    }
    if (aboutYouState && policiesState && underwritingEnquiryState) {
      if (
        isSavedJourneyValid(
          aboutYouAddressState?.postcode,
          aboutYouState.postCode,
          underwritingEnquiryState.decisions,
          retrieveData.lastQuoteDate,
          shouldUseSavedAddress,
        )
      ) {
        if (aboutYouAddressState && shouldUseSavedAddress) {
          const { postcode, ...restOfAddress } = aboutYouAddressState
          dispatch(
            aboutYouSlice.actions.submitConfirmedAddress({ ...restOfAddress, postCode: postcode } as AddressInterface),
          )
        }
        policiesState.forEach((policy) => {
          dispatch(quotesSlice.actions.update(buildPolicy(policy)))
        })
        dispatch(uwMeSlice.actions.setEnquiry(underwritingEnquiryState))

        return { ...retrieveData, isRetrievedJourney: true, isValidSavedJourney: true } as RetrieveData
      }
      const cavendishConsent = cavendishOnlineConsent !== undefined ? { cavendishOnlineConsent } : {}
      return { isRetrievedJourney: true, isValidSavedJourney: false, ...cavendishConsent }
    }
    if (cavendishOnlineConsent !== undefined) {
      return { cavendishOnlineConsent, isRetrievedJourney: false }
    }

    return null
  },
)

export const markSavedJourneyAsPurchased = createAsyncThunk(
  'saveAndRetrieve/markSavedJourneyAsPurchased',
  saveAndRetrieveApi.markSavedJourneyAsPurchased,
)

export const saveCavendishOnlineConsent = createAsyncThunk(
  'saveAndRetrieve/saveCavendishOnlineConsent',
  async (consent: boolean): Promise<void> => {
    await saveAndRetrieveApi.saveCavendishOnlineConsent(consent)
  },
)

export const requestCallback = createAsyncThunk(
  'saveAndRetrieve/requestCallback',
  async (page: string): Promise<void> => {
    await saveAndRetrieveApi.requestCallback(page)
  },
)

type Reducers = {
  resetSaveStatus: Reducer<SaveAndRetrieveState, PayloadAction<undefined>>
}

const reducers: Reducers = {
  resetSaveStatus: (state = initialState, _action) => {
    return { ...state, saveStatus: SaveAndRetrieveLoadingStatus.Idle }
  },
}

export const saveAndRetrieveSlice = createSlice({
  name: 'saveAndRetrieve',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addCase(saveJourney.pending, (state, action) => {
      if (action.meta.arg.isAutoSave) {
        return state
      }

      return { ...state, saveStatus: SaveAndRetrieveLoadingStatus.Loading }
    })
    builder.addCase(saveJourney.fulfilled, (state, action) => {
      if (action.meta.arg.isAutoSave) {
        return state
      }

      return { ...state, saveStatus: SaveAndRetrieveLoadingStatus.Success }
    })
    builder.addCase(saveJourney.rejected, (state, action) => {
      if (action.meta.arg.isAutoSave) {
        return state
      }

      return { ...state, saveStatus: SaveAndRetrieveLoadingStatus.Error }
    })
    builder.addCase(retrieveJourney.fulfilled, (state, action) => {
      return { ...state, ...action.payload }
    })
    builder.addCase(retrieveJourney.rejected, (state, _action) => {
      return { ...state, isRetrievedJourney: false }
    })
    builder.addCase(processEmail.fulfilled, (state, action) => {
      if (action.meta.arg?.isIndicativeQuoteEmail) {
        return { ...state, indicativeQuoteEmailSent: true }
      }
      return { ...state, savedQuoteEmailSent: true }
    })
    builder.addCase(saveCavendishOnlineConsent.fulfilled, (state, action) => {
      return { ...state, cavendishOnlineConsent: action.meta.arg }
    })
    builder.addCase(saveCavendishOnlineConsent.rejected, (state, action) => {
      return { ...state, cavendishOnlineConsent: action.meta.arg }
    })
    builder.addCase(requestCallback.pending, (state, _action) => {
      return { ...state, callbackRequestStatus: SaveAndRetrieveLoadingStatus.Loading }
    })
    builder.addCase(requestCallback.fulfilled, (state, _action) => {
      return { ...state, callbackRequestStatus: SaveAndRetrieveLoadingStatus.Success }
    })
    builder.addCase(requestCallback.rejected, (state, _action) => {
      return { ...state, callbackRequestStatus: SaveAndRetrieveLoadingStatus.Error }
    })
  },
})

export const selectSaveStatus: Selector<RootState, SaveAndRetrieveState['saveStatus']> = (state) =>
  state.saveAndRetrieve.saveStatus

export const selectCallbackRequestStatus: Selector<RootState, SaveAndRetrieveState['callbackRequestStatus']> = (
  state,
) => state.saveAndRetrieve.callbackRequestStatus

export const selectIsValidSavedJourney: Selector<RootState, SaveAndRetrieveState['isValidSavedJourney']> = (state) =>
  state.saveAndRetrieve.isValidSavedJourney || false
export const selectIsRetrievedJourney: Selector<SaveAndRetrieveState, SaveAndRetrieveState['isRetrievedJourney']> = (
  state,
) => state.isRetrievedJourney
export const selectJourneyExitPoint: Selector<RootState, SaveAndRetrieveState['journeyExitPoint']> = (state) =>
  state.saveAndRetrieve.journeyExitPoint
export const selectRecordCreatedOn: Selector<RootState, SaveAndRetrieveState['recordCreatedOn']> = (state) =>
  state.saveAndRetrieve.recordCreatedOn
export const selectLastQuoteDate: Selector<RootState, SaveAndRetrieveState['lastQuoteDate']> = (state) =>
  state.saveAndRetrieve.lastQuoteDate
export const selectCavendishOnlineConsent: Selector<RootState, SaveAndRetrieveState['cavendishOnlineConsent']> = (
  state,
) => state.saveAndRetrieve.cavendishOnlineConsent
