import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import { createAsyncThunk, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { RootState } from '../../app/types'
import { CoverSummaryInterface } from '../Shared/Basket/CoverSummary/types'
import { pricingApi } from './pricingApi'
import {
  AddToBasketActionProps,
  PricingCallStatus,
  PricingFulfilledActionProps,
  PricingRequestParams,
  QuotesState,
  RemoveFromBasketActionProps,
  UpdateActionProps,
} from './types'

export const initialState = {
  tagging: { numberOfLifeCoverPricingCalls: 0, numberOfBodyCoverPricingCalls: 0 },
  lifeCoverPricingCallStatus: PricingCallStatus.Idle,
  bodyCoverPricingCallStatus: PricingCallStatus.Idle,
  lifeCover: {
    coverType: PricingRequestBody.CoverTypeEnum.LIFE,
    title: 'Life Cover',
    amount: 0,
    term: 0,
    monthlyCost: 0,
    inBasket: false,
  },
  bodyCover: {
    coverType: PricingRequestBody.CoverTypeEnum.BODY,
    title: 'Body Cover',
    amount: 0,
    term: 0,
    monthlyCost: 0,
    inBasket: false,
  },
}

// Selectors
type SelectCover = Selector<QuotesState, CoverSummaryInterface>
type SelectPricingCallStatus = Selector<QuotesState, PricingCallStatus>
type SelectNumberOfPricingCalls = Selector<QuotesState, number>
type SelectValidationError = Selector<QuotesState, string | undefined>

export const selectLifeCover: SelectCover = (state) => state.lifeCover

export const selectBodyCover: SelectCover = (state) => state.bodyCover

export const selectLifeCoverPricingCallStatus: SelectPricingCallStatus = (state) => state.lifeCoverPricingCallStatus

export const selectBodyCoverPricingCallStatus: SelectPricingCallStatus = (state) => state.bodyCoverPricingCallStatus

export const selectNumberOfLifeCoverPricingCalls: SelectNumberOfPricingCalls = (state) =>
  state.tagging.numberOfLifeCoverPricingCalls

export const selectNumberOfBodyCoverPricingCalls: SelectNumberOfPricingCalls = (state) =>
  state.tagging.numberOfBodyCoverPricingCalls

export const selectValidationError: SelectValidationError = (state) => state.validationError

export const getPricingAnonymousQuote = createAsyncThunk(
  'quotes/pricing',
  async (args: PricingRequestParams, { getState }): Promise<PricingFulfilledActionProps> => {
    const {
      saveAndRetrieve: { lastQuoteDate },
    } = getState() as RootState
    const pricingResponse = await pricingApi.getPricingAnonymousQuote({ ...args, lastQuoteDate })

    return pricingResponse
  },
)

export const quotesSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    addToBasket(state: QuotesState, action: PayloadAction<AddToBasketActionProps>): QuotesState {
      const { coverType } = action.payload
      if (coverType === PricingRequestBody.CoverTypeEnum.LIFE)
        return { ...state, lifeCover: { ...state.lifeCover, inBasket: true }, validationError: '' }
      if (coverType === PricingRequestBody.CoverTypeEnum.BODY)
        return { ...state, bodyCover: { ...state.bodyCover, inBasket: true }, validationError: '' }
      return { ...state }
    },
    removeFromBasket(state: QuotesState, action: PayloadAction<RemoveFromBasketActionProps>): QuotesState {
      const { coverType } = action.payload
      if (coverType === PricingRequestBody.CoverTypeEnum.LIFE)
        return { ...state, lifeCover: { ...state.lifeCover, inBasket: false } }
      if (coverType === PricingRequestBody.CoverTypeEnum.BODY)
        return { ...state, bodyCover: { ...state.bodyCover, inBasket: false } }
      return { ...state }
    },
    update(state: QuotesState, action: PayloadAction<UpdateActionProps>): QuotesState {
      const { coverType, title, amount, term, monthlyCost } = action.payload
      const updatedCover = { coverType, title, amount, term, monthlyCost, inBasket: false }
      if (coverType === PricingRequestBody.CoverTypeEnum.LIFE)
        return { ...state, lifeCover: updatedCover, validationError: '' }
      if (coverType === PricingRequestBody.CoverTypeEnum.BODY)
        return { ...state, bodyCover: updatedCover, validationError: '' }
      return { ...state }
    },
    setValidationError(state: QuotesState, action: PayloadAction<string>): QuotesState {
      const validationError = action.payload
      return { ...state, validationError }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getPricingAnonymousQuote.pending,
      (state: QuotesState, action): QuotesState => {
        const { coverType } = action.meta.arg
        if (coverType === PricingRequestBody.CoverTypeEnum.LIFE)
          return {
            ...state,
            lifeCoverPricingCallStatus: PricingCallStatus.Pending,
          }
        if (coverType === PricingRequestBody.CoverTypeEnum.BODY)
          return {
            ...state,
            bodyCoverPricingCallStatus: PricingCallStatus.Pending,
          }
        return { ...state }
      },
    )
    builder.addCase(
      getPricingAnonymousQuote.fulfilled,
      (state: QuotesState, action: PayloadAction<PricingFulfilledActionProps>): QuotesState => {
        const { coverType } = action.payload
        if (coverType === PricingRequestBody.CoverTypeEnum.LIFE)
          return {
            ...state,
            lifeCover: action.payload,
            lifeCoverPricingCallStatus: PricingCallStatus.Success,
            tagging: {
              ...state.tagging,
              numberOfLifeCoverPricingCalls: state.tagging.numberOfLifeCoverPricingCalls + 1,
            },
          }
        if (coverType === PricingRequestBody.CoverTypeEnum.BODY)
          return {
            ...state,
            bodyCover: action.payload,
            bodyCoverPricingCallStatus: PricingCallStatus.Success,
            tagging: {
              ...state.tagging,
              numberOfBodyCoverPricingCalls: state.tagging.numberOfBodyCoverPricingCalls + 1,
            },
          }
        return { ...state }
      },
    )
    builder.addCase(
      getPricingAnonymousQuote.rejected,
      (state: QuotesState, action): QuotesState => {
        const { coverType } = action.meta.arg
        if (coverType === PricingRequestBody.CoverTypeEnum.LIFE)
          return { ...state, lifeCoverPricingCallStatus: PricingCallStatus.Error }
        if (coverType === PricingRequestBody.CoverTypeEnum.BODY)
          return { ...state, bodyCoverPricingCallStatus: PricingCallStatus.Error }
        return { ...state }
      },
    )
  },
})

export const { addToBasket, removeFromBasket, update, setValidationError } = quotesSlice.actions
