import React from 'react'
import { Typography } from '@lbg-protection/lib-ui-monoceros'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'
import { LeaveSomethingExtraQ } from '../../../Shared/Questionaire/questions/LeaveSomethingExtraQuestion/types'

export const leaveSomethingExtra = (): LeaveSomethingExtraQ => {
  return {
    id: 'somethingExtra',
    category: 'somethingExtra',
    type: QuestionTypes.SomethingExtra,
    sectionHeader: 'Leave something extra',
    title: {
      icon: 'apply',
      text: 'Leave something extra',
    },
    question: 'Leave something extra?',
    description: (
      <>
        <Typography data-testid="somethingExtra-help-text-p1" variant="body1" gutterBottom>
          You might want to leave some money to help your loved ones with their future plans.
        </Typography>
        <Typography data-testid="somethingExtra-help-text-p2" variant="body1" gutterBottom>
          Just enter a description and an amount, to help keep track of it on your cover report.
        </Typography>
      </>
    ),
    overlayLink: 'Can you give me some examples?',
    JourneyStepName: 'Leave something extra',
    JourneyStep: '18',
  }
}
