import { makeStyles, Brand, MonocerosTheme } from '@lbg-protection/lib-ui-monoceros'
import { isBOS, isSW } from '../../../../utils/brandSpecificFormatting'

export const withStyles = makeStyles((theme: MonocerosTheme) => {
  return {
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      height: theme.spacing(12),
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      alignItems: 'center',
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: !isSW(theme.name as Brand) ? 'center' : 'initial',
    },
    coverIcon: {
      display: 'flex',
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    emphasised: {
      fontWeight: 'bold',
    },
    boxBackgroundLife: {
      backgroundColor: isBOS(theme.name as Brand)
        ? theme.palette.namedColours?.bosAliceBlue
        : theme.palette.common.white,
      height: 'auto',
      marginTop: '24px',
      marginBottom: '18px',
    },
    boxBackgroundBody: {
      backgroundColor: isBOS(theme.name as Brand)
        ? theme.palette.namedColours?.bosAliceBlue
        : theme.palette.common.white,
      height: 'auto',
      marginTop: '12px',
      marginBottom: '9px',
    },
    coverDescription: {
      fontSize: '19px',
    },
  }
})
