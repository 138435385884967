import { AnalyticsService } from '../../analytics/analytics.service'
import { JourneyExitPoint } from './types'

const standardTagInfo = {
  JourneyStep: '89',
}

type TaggingSaveStatus = 'SUCCESS' | 'ERROR'

export const tagSaveResultPageLoad = (saveStatus: TaggingSaveStatus): void => {
  const JourneyStepName = saveStatus === 'SUCCESS' ? 'Saved Progress Confirmation' : 'Progress not saved error'
  AnalyticsService.initialPageLoad({
    ...standardTagInfo,
    JourneyStepName,
    ApplicationState: 'Offered',
  })
}

export const tagWelcomeBackPageLoad = (progress?: JourneyExitPoint) => {
  AnalyticsService.initialPageLoad({
    ...standardTagInfo,
    JourneyStepName: `Welcome Back - ${progress}`,
    ApplicationState: 'Product Information',
  })
}

export const tagWelcomeBackInvalidPageLoad = () => {
  AnalyticsService.initialPageLoad({
    ...standardTagInfo,
    JourneyStepName: 'Welcome Back - cannot continue',
    ApplicationState: 'Product Information',
  })
}
