import { Box, Typography } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { BodyCoverAmountRightForMe, BodyCoverRightForYou, HowLongShouldIGetCoverFor } from '../Shared'
import { styles } from './styles'
import { PageFocus } from '../../Shared/PageFocus'

export const BodyCoverInfo = () => {
  const classes = styles()
  return (
    <>
      <Box data-testid="body-cover-question-container">
        <PageFocus>
          <Typography variant="h4" component="h1" className={classes.heading} data-testid="body-cover-question">
            What is Body Cover?
          </Typography>
        </PageFocus>
        <Typography variant="body1" data-testid="body-cover-answer">
          Body Cover is a critical illness insurance policy. It pays out a cash lump sum if you are diagnosed with an
          illness covered by your policy before it ends.
        </Typography>
      </Box>
      <Box className={classes.container} data-testid="body-cover-amount-question-container">
        <Typography variant="h4" component="h1" className={classes.heading} data-testid="body-cover-amount-question">
          What amount is right for me?
        </Typography>
        <BodyCoverAmountRightForMe />
      </Box>
      <Box className={classes.container} data-testid="body-cover-for-question-container">
        <Typography variant="h4" component="h1" className={classes.heading} data-testid="body-cover-for-question">
          How long should I get cover for?
        </Typography>
        <HowLongShouldIGetCoverFor />
      </Box>
      <Box className={classes.container} data-testid="need-body-cover-question-container">
        <Typography variant="h4" component="h1" className={classes.heading} data-testid="need-body-cover-question">
          Do I need Body Cover?
        </Typography>
        <BodyCoverRightForYou />
      </Box>
    </>
  )
}
