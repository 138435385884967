import React from 'react'
import {
  Box,
  ListWithBullets,
  ListItemWithBullet,
  makeStyles,
  MonocerosTheme,
  Typography,
  Brand,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import clsx from 'clsx'
import { DialogControl } from '../../Shared/DialogControl/DialogControl'
import { tagOverlayClick } from '../tags'
import { isSW } from '../../../utils/brandSpecificFormatting'

const styles = makeStyles((theme: MonocerosTheme) => {
  const isSWBrand = isSW(theme.name as Brand)
  return {
    link: {
      marginTop: theme.spacing(isSWBrand ? 2 : 3),
    },
    bulletList: {
      marginBottom: theme.spacing(5),
      ...(isSWBrand && { marginTop: theme.spacing(5), padding: 0 }),
    },
    linkTitle: {
      fontFamily: theme.typography.body1.fontFamily,
    },
  }
})

export const HowDoIChooseBodyOverlay = () => {
  const classes = styles({})
  const overlaySummary = `How do I choose what's right for me?`
  const { name: themeName = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(themeName)
  return (
    <Box className={classes.link}>
      <DialogControl
        id="how-do-i-choose-body"
        data-testid="how-do-i-choose-body"
        title={overlaySummary}
        controlText={overlaySummary}
        customOnClick={() => tagOverlayClick(`${overlaySummary} - Body`)}
        linkClass={clsx(isSWBrand && classes.linkTitle)}
      >
        <Typography
          gutterBottom
          variant={isSWBrand ? 'h5' : 'subtitle2'}
          data-testid="indicative-Body-choose-right-answer"
        >
          Body Cover amount
        </Typography>
        <Typography>
          Think about how much you&apos;d need to help you and your loved ones cope if you were too ill to work for an
          extended period. Consider the help you might need to keep up with:
        </Typography>
        <ListWithBullets className={classes.bulletList}>
          <ListItemWithBullet>Day-to-day costs and bills</ListItemWithBullet>
          <ListItemWithBullet>Rent or mortgage payments</ListItemWithBullet>
          <ListItemWithBullet>Costs to make health-related changes to your home.</ListItemWithBullet>
        </ListWithBullets>
        <Typography gutterBottom variant={isSWBrand ? 'h5' : 'subtitle2'}>
          Body Cover duration
        </Typography>
        <Typography>It might help to think about life&apos;s milestones, like the number of years until:</Typography>
        <Typography>
          <ListWithBullets className={clsx(isSWBrand && classes.bulletList)}>
            <ListItemWithBullet>Your children turn 18</ListItemWithBullet>
            <ListItemWithBullet>Your mortgage is paid off</ListItemWithBullet>
            <ListItemWithBullet>You reach state pension age.</ListItemWithBullet>
          </ListWithBullets>
        </Typography>
      </DialogControl>
    </Box>
  )
}
