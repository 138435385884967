import {
  Box,
  ButtonV2 as Button,
  FormField,
  Icon,
  NumberInputWithStepper,
  Typography,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { Controller, DeepPartial, useFieldArray, useForm, useWatch } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { DialogControl } from '../../../DialogControl/DialogControl'
import { IconTitle } from '../../../IconTitle'
import { SubmitAnswer } from '../../types'
import { styles } from './styles'
import { Child, ChildrenAgeAnswer, ChildrenAgeQ } from './types'

interface QuestionProps {
  question: ChildrenAgeQ
  answer: ChildrenAgeAnswer | null
  submitAnswer: SubmitAnswer
  goToNextQuestion: () => void
}

export const Question = (props: QuestionProps) => {
  const classes = styles({})

  const { errors, control, trigger, formState, handleSubmit } = useForm<Partial<ChildrenAgeAnswer>>({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      values: props.answer ? props.answer.values : [],
    },
  })

  const focusError = (): null | void => {
    const firstError = errors.values?.find((error) => error?.age)?.age
    if (firstError?.ref?.focus) {
      return firstError.ref.focus()
    }
    return null
  }

  const childrenFields = useFieldArray<Child>({
    control,
    name: 'values',
  })

  const formValues = useWatch<ChildrenAgeAnswer>({ control })

  const isValid = () =>
    !!formValues.values?.every(
      (child) => child.age !== undefined && child.age !== '' && !Number.isNaN(Number(child.age)),
    )

  /* submitValues takes an optional index so when it's called when an input is removed we can submit values
     as [] if it's the only input which fixes the issue of the first value not being removed from the store.
  */
  const submitValues = (index?: number) => {
    if (isValid()) {
      props.submitAnswer({
        questionId: props.question.id,
        question: props.question.question,
        category: props.question.category,
        values:
          index === 0
            ? []
            : (formValues.values?.map(({ age }: DeepPartial<Child>) => {
                return { age }
              }) as ChildrenAgeAnswer['values']),
      })
    }
  }

  useDeepCompareEffect(() => {
    submitValues()
  }, [formValues.values, formState.isValid])

  const onSubmit = () => {
    props.goToNextQuestion()
  }

  return (
    <>
      <IconTitle dataTestId={`${props.question.id}-title`} title={props.question.title} />
      {props.question.helpText?.map((paragraph: string, index: number) => (
        <Typography
          data-testid={`${props.question.id}-help-text-p${index + 1}`}
          key={`${props.question.id}-help-text-p${index + 1}`}
          variant="body1"
          gutterBottom
        >
          {paragraph}
        </Typography>
      ))}
      <form
        onSubmit={handleSubmit(onSubmit)}
        id={`${props.question.id}-question-form`}
        data-testid={`${props.question.id}-age-form`}
      >
        {childrenFields.fields.map((child, index) => (
          <Controller
            key={`values-${index}-${child.id}-container`}
            name={`values[${index}].age`}
            defaultValue={child.age}
            control={control}
            rules={{
              required: {
                message: "Please enter your child's age",
                value: true,
              },
            }}
            render={({ name, onBlur, onChange, ref, value }) => (
              <FormField
                id={`child-${index}`}
                label={props.question.question}
                errorMessage={errors.values && errors.values[index]?.age?.message}
                inputWidth="50%"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                endAdornment={
                  <Button
                    data-testid={`remove-values-${index}-button`}
                    aria-label="Remove child"
                    onClick={() => {
                      childrenFields.remove(index)
                      trigger(name).then(() => submitValues(index))
                    }}
                  >
                    <Icon
                      name="bin"
                      color={errors.values && errors.values[index] ? 'error' : 'primary'}
                      size="1.75rem"
                    />
                  </Button>
                }
              >
                <NumberInputWithStepper
                  data-testid={`child-${index}-age-input`}
                  step={1}
                  min={0}
                  max={99}
                  inputProps={{ maxLength: 2, 'aria-required': true }}
                  decimalScale={0}
                  allowLeadingZeros={false}
                  spellCheck={false}
                />{' '}
              </FormField>
            )}
          />
        ))}
      </form>
      <Button
        className={classes.addButton}
        data-testid="add-child-button"
        type="button"
        variant="outlined"
        color="primary"
        onClick={() => {
          trigger(`values[${childrenFields.fields.length - 1}].age`).then(() => {
            focusError()
            submitValues()
            if (isValid()) {
              childrenFields.append({ age: '' }, true)
            }
          })
        }}
      >
        + Add a child
      </Button>
      {props.question.overlay && (
        <Box className={classes.overlayLink}>
          <DialogControl
            controlText={props.question.overlay.link}
            id={props.question.id}
            data-testid={props.question.id}
            title={props.question.overlay.link}
            transition="fade"
          >
            {props.question.overlay.content}
          </DialogControl>
        </Box>
      )}
    </>
  )
}
