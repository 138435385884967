import React, { useEffect, useState, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import debounce from 'lodash.debounce'
import {
  Box,
  Brand,
  ButtonV2 as Button,
  CurrencyInput,
  CurrencyInputWithStepper,
  FormField,
  LinkV2 as Link,
  MonocerosTheme,
  NumberInputWithStepper,
  Typography,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import formatCurrency, { formatCurrencyWithZeroesAfterDecimal } from '../../../../utils/formatCurrency'
import { calculateMaxTerm } from '../../../../utils/calculateMaxTerm'
import { InfoDialog } from '../../../Shared/InfoDialog/InfoDialog'
import { PdfViewerLink } from '../../../Shared/PdfViewer'
import { BodyCoverInfo } from '../../Info/BodyCoverInfo'
import { LifeCoverInfo } from '../../Info/LifeCoverInfo'
import { PricingRequestParams } from '../../types'
import { AddedToBasket } from '../AddedToBasket'
import { AdjustedValueConfirmation } from '../AdjustedValueConfirmation'
import { EditableNumberField } from '../EditableNumberField'
import { QuoteCardProps } from '../types'
import * as c from './constants'
import { withStyles } from './styles'
import { QuoteCardOnSubmitInterface } from './types'
import { shouldShowMonthlyCost } from './utils'
import { tagPricing } from './utils-tagging'
import {
  maxMonthlyCost,
  minMonthlyCost,
  minTerm,
  monthlyCostValidator,
  termValidator,
  amountValidation,
  minAmount,
  maxAmount,
} from './validators'
import { useJourneyChecker } from '../../../../journeyCwaConfig'
import { tagExternalLink, tagButtonClick } from '../../../../utils/tagUtils/tagsUtils'
import { isSW } from '../../../../utils/brandSpecificFormatting'

export const Eligible = React.memo((props: QuoteCardProps) => {
  const classes = withStyles({})
  const { isJourneyUnAuth } = useJourneyChecker()
  const [valueFixed, setValueFixed] = useState<boolean>(false)
  const [adjustedDismissed, setAdjustedDismissed] = useState<boolean>(false)
  const [toggleInfo, setToggleInfo] = useState<boolean>(false)
  const maxTerm = calculateMaxTerm(props.dob)
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)

  const isUnderwriteMe = true

  const bodyCoverSummaryPdf = './assets/pdf/body-cover-policy-summary-500K.pdf'
  const lifeCoverSummaryPdf = './assets/pdf/life-cover-policy-summary-500K.pdf'

  const { errors, handleSubmit, getValues, control, setValue, trigger } = useForm<QuoteCardOnSubmitInterface>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      amount: props.coverSummary.amount,
      term: props.coverSummary.term,
      monthlyCost: props.coverSummary.monthlyCost,
    },
  })

  const onSubmit = handleSubmit(() => {
    const coverToAdd = { coverType: props.coverSummary.coverType }
    if (props.onSubmit) {
      props.onSubmit(coverToAdd)
    }
  })

  const toggleFixed = () => {
    if (!errors.amount && !errors.monthlyCost) setValueFixed(!valueFixed)
  }

  const buildPricingRequestParamsByCalculationType = (
    calculationType: PricingRequestBody.CalculationTypeEnum,
  ): PricingRequestParams => {
    const enquiryId = props.enquiryId || ''
    const { term, monthlyCost, amount } = getValues()

    const requestParams: PricingRequestParams = {
      dob: props.dob,
      coverTerm: term,
      coverType: props.coverSummary.coverType,
      smokerStatus: props.smokerStatus,
      calculationType: PricingRequestBody.CalculationTypeEnum.PREMIUM,
      uwOutcome: props.uwOutcome.toString(),
      underwriteMe: { isUnderwriteMe, enquiryId },
      isJourneyUnAuth,
    }

    if (calculationType === PricingRequestBody.CalculationTypeEnum.SUMASSURED) {
      requestParams.calculationType = PricingRequestBody.CalculationTypeEnum.SUMASSURED
      requestParams.sumAssured = amount
    } else {
      requestParams.monthlyPremium = monthlyCost
    }

    return requestParams
  }

  const makePricingCallForSumAssured = async () => {
    if (props.callPricingAPI) {
      await props.callPricingAPI(
        buildPricingRequestParamsByCalculationType(PricingRequestBody.CalculationTypeEnum.SUMASSURED),
      )
    }
  }

  const makePricingCallForPremium = async () => {
    if (props.callPricingAPI) {
      await props.callPricingAPI(
        buildPricingRequestParamsByCalculationType(PricingRequestBody.CalculationTypeEnum.PREMIUM),
      )
    }
  }

  const makePricingCall = () => {
    if (props.callPricingAPI) {
      const { amount, term, monthlyCost } = getValues()
      const { amount: amountError, term: termError, monthlyCost: costError } = errors
      if (term && !amountError && !termError && !costError) {
        setAdjustedDismissed(false)
        if (amount && !valueFixed) {
          makePricingCallForSumAssured()
        } else if (monthlyCost && valueFixed) {
          makePricingCallForPremium()
        }
      }
    }
  }

  const acceptAdjusted = () => {
    if (props.onUpdate) {
      const adjustedCover = {
        ...props.coverSummary,
        amount: props.coverSummary.adjustedAmount ?? 0,
        monthlyCost: props.coverSummary.adjustedMonthlyCost ?? 0,
      }
      props.onUpdate(adjustedCover)
    }
  }

  const updateCover = async (field: keyof QuoteCardOnSubmitInterface) => {
    if (props.onUpdate) {
      const valid = await trigger(field)
      if (getValues()[field] !== props.coverSummary[field]) {
        if (valid) {
          const { amount, term, monthlyCost } = getValues()
          const updatedCover = { ...props.coverSummary, amount, term, monthlyCost }
          props.onUpdate(updatedCover)
          makePricingCall()
        }
      }
    }
  }

  const debouncedUpdateCover = useCallback(debounce(updateCover, 2000), [])

  useEffect(() => {
    if (props.callPricingAPI) {
      const { amount, term, inBasket } = props.coverSummary
      const { amount: amountError, term: termError } = errors
      if (term && !amountError && !termError && !inBasket) {
        setAdjustedDismissed(false)
        if (amount && !valueFixed) {
          props.callPricingAPI(
            buildPricingRequestParamsByCalculationType(PricingRequestBody.CalculationTypeEnum.SUMASSURED),
          )
        }
      }
    }
  }, [])

  useEffect(() => {
    tagPricing(props)
  }, [props.numberOfPricingCalls])

  useEffect(() => {
    setValue('amount', props.coverSummary.amount)
    setValue('term', props.coverSummary.term)
    setValue('monthlyCost', props.coverSummary.monthlyCost)
  }, [props.coverSummary])

  const upCurrencyLabelProvider = (coverType: PricingRequestBody.CoverTypeEnum) => (
    stepperValue: string | undefined,
  ) => {
    if (stepperValue !== undefined) {
      return `Increase ${coverType} Cover amount by ${stepperValue} pound${stepperValue === '1' ? '' : 's'}`
    }
    return `for Increase ${coverType} cover amount`
  }

  const downCurrencyLabelProvider = (coverType: PricingRequestBody.CoverTypeEnum) => (
    stepperValue: string | undefined,
  ) => {
    if (stepperValue !== undefined) {
      return `Decrease ${coverType} Cover amount by ${stepperValue} pound${stepperValue === '1' ? '' : 's'}`
    }
    return `for Decrease ${coverType} cover amount`
  }

  const upDurationLabelProvider = (coverType: PricingRequestBody.CoverTypeEnum) => (
    stepperValue: string | undefined,
  ) => {
    if (stepperValue !== undefined) {
      return `Increase ${coverType} Cover duration by ${stepperValue} year${stepperValue === '1' ? '' : 's'}`
    }
    return `for Increase ${coverType} cover duration`
  }

  const downDurationLabelProvider = (coverType: PricingRequestBody.CoverTypeEnum) => (
    stepperValue: string | undefined,
  ) => {
    if (stepperValue !== undefined) {
      return `Decrease ${coverType} Cover duration by ${stepperValue} year${stepperValue === '1' ? '' : 's'}`
    }
    return `for Decrease ${coverType} cover duration`
  }

  return (
    <Box className={classes.wrapper}>
      {props.coverSummary?.inBasket ? (
        <Box className={classes.addedToBasketWrapper}>
          <AddedToBasket coverType={props.coverSummary.coverType} removeFromBasket={props.removeFromBasket} />
        </Box>
      ) : (
        <form onSubmit={onSubmit} className={classes.formWrapper}>
          {valueFixed && props.coverSummary.adjustedAmount && !adjustedDismissed ? (
            <AdjustedValueConfirmation
              message={c.adjustedAmountMsg(props.coverSummary.adjustedMonthlyCost ?? props.coverSummary.monthlyCost)}
              onSubmit={acceptAdjusted}
            />
          ) : (
            <EditableNumberField
              value={props.coverSummary?.amount}
              formattedValue={formatCurrency('en-GB', 'GBP', props.coverSummary?.amount)}
              label={c.amountLabel}
              onEdit={toggleFixed}
              showFixed={valueFixed && !errors.amount}
              fieldType={props.coverSummary.coverType}
              component={
                <Controller
                  name={c.amountName}
                  control={control}
                  rules={amountValidation}
                  render={({ onChange, onBlur, value, ref }) => (
                    <FormField
                      inputRef={ref}
                      fullWidth
                      id={`${props.coverSummary.coverType}-amount`}
                      label={c.amountLabel}
                      helperText={`Up to ${formatCurrency('en-GB', 'GBP', maxAmount)}`}
                      className={classes.input}
                      errorMessage={errors?.amount?.message}
                      value={value ? value : ''} // eslint-disable-line no-unneeded-ternary
                      onChange={onChange}
                      onBlur={() => {
                        onBlur()
                        updateCover('amount')
                      }}
                    >
                      <CurrencyInputWithStepper
                        decimalScale={0}
                        min={minAmount}
                        max={maxAmount}
                        firstStep={{
                          up: 5000,
                          down: 0,
                        }}
                        data-testid={`${props.coverSummary.coverType}-amount`}
                        inputProps={{ 'aria-required': true }}
                        step={5000}
                        snapToStep={false}
                        onStepEnd={() => {
                          debouncedUpdateCover('amount')
                        }}
                        placeholder="Enter amount"
                        className={classes.inputPlaceholder}
                        spellCheck={false}
                        stepLabelProvider={{
                          upLabelProvider: upCurrencyLabelProvider(props.coverSummary.coverType),
                          downLabelProvider: downCurrencyLabelProvider(props.coverSummary.coverType),
                        }}
                        stepperButtonProps={{ highlightOnError: !!isSWBrand }}
                      />
                    </FormField>
                  )}
                />
              }
            />
          )}
          <Box width="100%">
            <Controller
              name={c.termName}
              control={control}
              rules={termValidator(maxTerm)}
              render={({ onChange, onBlur, value, ref }) => (
                <FormField
                  inputRef={ref}
                  fullWidth
                  id={`${props.coverSummary.coverType}-term`}
                  label={c.termLabel}
                  helperText={`From 5 to  ${maxTerm} years`}
                  className={classes.input}
                  errorMessage={errors?.term?.message}
                  value={value ? value : ''} // eslint-disable-line no-unneeded-ternary
                  onChange={onChange}
                  onBlur={() => {
                    onBlur()
                    updateCover('term')
                  }}
                >
                  <NumberInputWithStepper
                    decimalScale={0}
                    min={minTerm}
                    max={maxTerm}
                    data-testid={`${props.coverSummary.coverType}-term`}
                    inputProps={{ 'aria-required': true }}
                    step={1}
                    onStepEnd={() => {
                      debouncedUpdateCover('term')
                    }}
                    placeholder="Enter duration"
                    className={classes.inputPlaceholder}
                    spellCheck={false}
                    stepLabelProvider={{
                      upLabelProvider: upDurationLabelProvider(props.coverSummary.coverType),
                      downLabelProvider: downDurationLabelProvider(props.coverSummary.coverType),
                    }}
                    stepperButtonProps={{ highlightOnError: !!isSWBrand }}
                  />
                </FormField>
              )}
            />
          </Box>
          {shouldShowMonthlyCost(props.pricingCallStatus, getValues().monthlyCost) && (
            <>
              {!valueFixed && props.coverSummary.adjustedMonthlyCost && !adjustedDismissed ? (
                <AdjustedValueConfirmation message={c.adjustedMonthlyCostMsg} onSubmit={acceptAdjusted} />
              ) : (
                <EditableNumberField
                  value={props.coverSummary?.monthlyCost}
                  formattedValue={formatCurrencyWithZeroesAfterDecimal('en-GB', 'GBP', props.coverSummary?.monthlyCost)}
                  label={c.costLabel}
                  onEdit={toggleFixed}
                  showFixed={!valueFixed && !errors.monthlyCost}
                  fieldType={props.coverSummary.coverType}
                  component={
                    <Controller
                      name={c.costName}
                      control={control}
                      rules={monthlyCostValidator}
                      render={({ onChange, onBlur, value, ref }) => (
                        <FormField
                          inputRef={ref}
                          fullWidth
                          id={`${props.coverSummary.coverType}-cost`}
                          label={c.costLabel}
                          helperText="Minimum £5"
                          className={classes.input}
                          errorMessage={errors?.monthlyCost?.message}
                          value={value ? formatCurrencyWithZeroesAfterDecimal('en-GB', 'GBP', value) : ''} // eslint-disable-line no-unneeded-ternary
                          onChange={onChange}
                          onBlur={() => {
                            onBlur()
                            updateCover('monthlyCost')
                          }}
                        >
                          <CurrencyInput
                            min={minMonthlyCost}
                            max={maxMonthlyCost}
                            data-testid={`${props.coverSummary.coverType}-cost`}
                            inputProps={{ 'aria-required': true }}
                          />
                        </FormField>
                      )}
                    />
                  }
                />
              )}
              {(adjustedDismissed || !props.coverSummary.adjustedMonthlyCost) && (
                <Button
                  className={classes.button}
                  data-testid={`${props.coverSummary.coverType}-submit-button`}
                  type="submit"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    tagButtonClick(`Add this cover ${props.coverSummary.coverType}`)
                  }}
                >
                  Add this cover
                </Button>
              )}
            </>
          )}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            href={`#more-about-${props.coverSummary.coverType}-link`}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault()
              tagExternalLink(`More about ${props.coverSummary.coverType} cover`)
              setToggleInfo(!toggleInfo)
            }}
            data-testid={`more-about-${props.coverSummary.coverType}-link`}
            variant="body2"
          >
            More about {props.coverSummary.title}
          </Link>
          <InfoDialog open={toggleInfo} toggleOpen={() => setToggleInfo(!toggleInfo)} data-testid="more-about-cover">
            {props.coverSummary.coverType === PricingRequestBody.CoverTypeEnum.LIFE ? (
              <LifeCoverInfo />
            ) : (
              <BodyCoverInfo />
            )}
          </InfoDialog>
          <Typography variant="body2" className={classes.text}>
            <PdfViewerLink
              href={props.coverSummary.coverType === 'BODY' ? bodyCoverSummaryPdf : lifeCoverSummaryPdf}
              data-testid={`${props.coverSummary.coverType}-view-policy-summary`}
              customOnClick={() => tagExternalLink(`${props.coverSummary.coverType} Cover Policy Summary`)}
            >
              {props.coverSummary.title} policy summary
            </PdfViewerLink>
          </Typography>
        </form>
      )}
    </Box>
  )
})
