import React from 'react'
import { Box, Brand, MonocerosTheme, Typography, useTheme } from '@lbg-protection/lib-ui-monoceros'
import { PhoneNumberLink } from '../../Shared/PhoneNumberLink/PhoneNumberLink'
import { tagButtonClick } from '../../../utils/tagUtils/tagsUtils'
import { isSW } from '../../../utils/brandSpecificFormatting'

export const NeedAdviceCavendishOnline = () => {
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)

  return (
    <Box>
      <Typography gutterBottom>
        If you&apos;d like advice, then contact Cavendish Online. They can help find cover that&apos;s right for you and
        your loved ones.
      </Typography>
      <Typography gutterBottom>
        Cavendish Online can advise you on Scottish Widows protection policies. They won&apos;t charge you for their
        advice, and you&apos;re under no obligation when you speak to them.
      </Typography>
      <Typography gutterBottom>Call Cavendish Online on:</Typography>
      <PhoneNumberLink
        gutterBottom
        department="callback"
        data-testid="cavendish-online-telephone"
        onClick={() => tagButtonClick('Cavendish Online number')}
      />
      <Typography gutterBottom>
        Lines are open Monday to Thursday 9am to 7pm, Friday {isSWBrand ? '10am' : '9am'} to 6pm.
      </Typography>
      <Typography>Cavendish Online is a part of Lloyds Banking Group.</Typography>
    </Box>
  )
}
