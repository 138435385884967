import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { IconProps } from '@lbg-protection/lib-ui-monoceros'
import { useNavigateTo } from '../../../utils/useNavigateTo'
import { useJourneyNavConfig } from '../../JourneyNavProvider/useJourneyNavConfig'
import { UnderWritingTemplate } from '../UnderWritingTemplate'
import { FooterNav } from './FooterNav'
import { ChildrenAgeQuestion } from './questions/ChildrenAgeQuestion'
import { ChildrenAgeAnswer, ChildrenAgeQ } from './questions/ChildrenAgeQuestion/types'
import { FinancesQuestion } from './questions/FinancesQuestion'
import { FinancesAnswer, FinancesQ } from './questions/FinancesQuestion/types'
import { HomePaymentQuestion } from './questions/HomePaymentQuestion'
import { HomePaymentAnswer, HomePaymentQ } from './questions/HomePaymentQuestion/types'
import { IconCheckboxQuestion } from './questions/IconCheckboxQuestion'
import { IconCheckboxAnswer, IconCheckboxQ } from './questions/IconCheckboxQuestion/types'
import { LeaveSomethingExtraQuestion } from './questions/LeaveSomethingExtraQuestion'
import { LeaveSomethingExtraAnswer, LeaveSomethingExtraQ } from './questions/LeaveSomethingExtraQuestion/types'
import { MultipleChoiceQuestion } from './questions/MultipleChoiceQuestion'
import { MultipleChoiceAnswer, MultipleChoiceQ } from './questions/MultipleChoiceQuestion/types'
import { TermQuestion } from './questions/TermQuestion'
import { TermAnswer, TermQ } from './questions/TermQuestion/types'
import { TreatmentQuestion } from './questions/TreatmentQuestion'
import { TreatmentAnswer, TreatmentQ } from './questions/TreatmentQuestion/types'
import { QuestionTypes } from './questions/types'
import { Answer, GotoQuestion, Question, SubmitAnswer } from './types'

const noop = () => null

export const getCurrentProgress = (questionSequence: string[], index: number) => {
  if (questionSequence.length > 0 && questionSequence.length >= index) {
    const step = 100 / questionSequence.length
    return step * index
  }
  return 0
}

export interface QuestionaireStateProps {
  question: Question | null
  answer: Answer
  questionSequence: Array<string>
  currentQuestion: string
  showProgressBar?: boolean
  progressBarColor?: 'primary' | 'secondary'
  progressBarIconName?: IconProps['name']
  progressBarText?: string
}

export interface QuestionaireDispatchProps {
  submitAnswer?: SubmitAnswer
  goto?: GotoQuestion
  finish: (navigateTo: Function) => void
}

interface Props extends QuestionaireStateProps, QuestionaireDispatchProps {}
export const Questionaire = ({
  progressBarColor,
  progressBarIconName,
  showProgressBar,
  progressBarText,
  question = null,
  answer = null,
  questionSequence,
  currentQuestion,
  submitAnswer = noop,
  goto = noop,
  finish = noop,
}: Props) => {
  const index = questionSequence.indexOf(currentQuestion)
  const nextQuestion = questionSequence[index + 1] || '__none'
  const prevQuestion = questionSequence[index - 1] || '__none'

  const footerProps = {
    gotoQuestion: goto,
    prevQuestion,
    nextQuestion,
    question,
  }
  const navigateTo = useNavigateTo()
  const { pathname } = useLocation()
  const { journeyNavState, setJourneyNavState } = useJourneyNavConfig()

  useEffect(() => {
    if (question) {
      const { JourneyStep, JourneyStepName } = question
      setJourneyNavState(pathname, { ...journeyNavState, journeyStep: { JourneyStep, JourneyStepName } })
    }
  }, [currentQuestion])

  const goToNextQuestion = () => {
    if (nextQuestion !== '__none') {
      goto(nextQuestion)
    } else {
      finish(navigateTo)
    }
  }

  return (
    <UnderWritingTemplate
      progress={getCurrentProgress(questionSequence, index)}
      FooterNav={<FooterNav {...footerProps} />}
      title={question?.sectionHeader}
      progressBarColor={progressBarColor}
      progressBarIconName={progressBarIconName}
      showProgressBar={showProgressBar}
      progressBarText={progressBarText}
      key={`${question?.id}-current-question}`}
    >
      {question?.type === QuestionTypes.Treatment && (
        <TreatmentQuestion
          question={question as TreatmentQ}
          answer={answer as TreatmentAnswer}
          submitAnswer={submitAnswer}
          goToNextQuestion={goToNextQuestion}
        />
      )}
      {question?.type === QuestionTypes.MultipleChoice && (
        <MultipleChoiceQuestion
          key={`${question.id}-multiple-choice`}
          question={question as MultipleChoiceQ}
          answer={answer as MultipleChoiceAnswer}
          submitAnswer={submitAnswer}
          goToNextQuestion={goToNextQuestion}
        />
      )}
      {question?.type === QuestionTypes.HomePayment && (
        <HomePaymentQuestion
          key={question.id}
          question={question as HomePaymentQ}
          answer={answer as HomePaymentAnswer}
          submitAnswer={submitAnswer}
          goToNextQuestion={goToNextQuestion}
        />
      )}
      {question?.type === QuestionTypes.Finances && (
        <FinancesQuestion
          key={`${question.id}-finances-question`}
          question={question as FinancesQ}
          answer={answer as FinancesAnswer}
          submitAnswer={submitAnswer}
          goToNextQuestion={goToNextQuestion}
        />
      )}
      {question?.type === QuestionTypes.IconCheckbox && (
        <IconCheckboxQuestion
          key={`${question.id}-question`}
          question={question as IconCheckboxQ}
          answer={answer as IconCheckboxAnswer}
          submitAnswer={submitAnswer}
          goToNextQuestion={goToNextQuestion}
        />
      )}
      {question?.type === QuestionTypes.ChildrenAge && (
        <ChildrenAgeQuestion
          key={`${question.id}-question`}
          question={question as ChildrenAgeQ}
          answer={answer as ChildrenAgeAnswer}
          submitAnswer={submitAnswer}
          goToNextQuestion={goToNextQuestion}
        />
      )}

      {question?.type === QuestionTypes.SomethingExtra && (
        <LeaveSomethingExtraQuestion
          key={`${question.id}-question`}
          question={question as LeaveSomethingExtraQ}
          answer={answer as LeaveSomethingExtraAnswer}
          submitAnswer={submitAnswer}
          goToNextQuestion={goToNextQuestion}
        />
      )}
      {question?.type === QuestionTypes.Term && (
        <TermQuestion
          key={`${question.id}-question`}
          question={question as TermQ}
          answer={answer as TermAnswer}
          submitAnswer={submitAnswer}
          goToNextQuestion={goToNextQuestion}
        />
      )}
    </UnderWritingTemplate>
  )
}
