import { makeStyles } from '@lbg-protection/lib-ui-monoceros'

export const styles = makeStyles((theme) => {
  return {
    displayItemDivider: {
      margin: `${theme.spacing(2)} 0`,
    },
    addButton: {
      margin: `${theme.spacing(2.5)} 0 ${theme.spacing(4)} 0`,
      width: '100%',
    },
  }
})
