import { Typography, ListWithBullets, ListItemWithBullet } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'
import { IconCheckboxQ } from '../../../Shared/Questionaire/questions/IconCheckboxQuestion/types'

export const reasons = [
  ` You'd like your debts to be paid, so they don't reduce the inheritance your loved ones might
  receive.`,
  `You'd like to make sure you're able to leave money to a loved one.`,
  `You'd like your mortgage to be paid off so someone can inherit your home.`,
]

export const protectWho = (): IconCheckboxQ => {
  return {
    category: 'lovedOnes',
    id: 'protectWho',
    type: QuestionTypes.IconCheckbox,
    sectionHeader: 'Your loved ones',
    title: {
      text: 'Your loved ones',
      icon: 'family-protector',
    },
    helpText: `We'll help you decide how much you might need to protect your loved ones financially after your death.`,
    question: `Choose who you'd like to protect`,
    options: [
      {
        icon: '2-people',
        label: 'My partner',
        value: 'partner',
      },
      {
        icon: 'family-protector',
        label: 'My children',
        value: 'children',
      },
      {
        icon: 'customer-new',
        label: 'Someone else',
        value: 'other',
      },
    ],
    errorMessage: `Please select the people you'd like to protect`,
    overlays: [
      {
        id: 'dependants',
        link: `What if I don't have dependants?`,
        content: (
          <>
            <Typography gutterBottom>
              If you don&apos;t have anyone who relies on your income, then Life Cover might not be right for you. But
              you might want to think about it if:
            </Typography>
            <ListWithBullets>
              {reasons.map((item, index) => (
                <ListItemWithBullet key={`protect-who-reason-${index}`}>{item}</ListItemWithBullet>
              ))}
            </ListWithBullets>
          </>
        ),
      },
      {
        id: 'recipient',
        link: 'Can I choose who the money goes to?',
        content: (
          <>
            <Typography gutterBottom>
              If you want to make sure that the money your policy pays out goes to a specific person, you&apos;ll need
              to make a Will or put your policy in a trust.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Your Will
            </Typography>
            <Typography gutterBottom>
              In your Will, you can choose who receives the money your policy pays out. You just need to update your
              Will, if you already have one. If you don’t have a Will yet, then a solicitor can help you set one up.
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Trusts
            </Typography>
            <Typography gutterBottom>
              Putting your policy in trust means that when it pays out, the money will quickly go to the people you want
              it to reach. It could also help reduce inheritance tax on the money paid out. A solicitor can help you set
              up a trust.
            </Typography>
          </>
        ),
      },
    ],
    JourneyStepName: 'Your loved ones',
    JourneyStep: '7',
  }
}
