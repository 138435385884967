import {
  Typography,
  Box,
  Icon,
  IconProps,
  makeStyles,
  MonocerosTheme,
  useTheme,
  Brand,
} from '@lbg-protection/lib-ui-monoceros'
import React, { useMemo } from 'react'
import { PageFocus } from '../PageFocus'
import { isSW } from '../../../utils/brandSpecificFormatting'

interface PageHeaderProps {
  headerText: string
  dataTestId: string
  icon?: {
    name: string
    size: string
    color: string
  }
  isTitleFocus?: boolean
}

const iconContainerStyles = makeStyles({
  iconContainer: {
    marginBottom: '0.75rem',
  },
})

export const PageHeader = (props: PageHeaderProps) => {
  const { headerText, dataTestId, icon, isTitleFocus = true } = props
  const { name: themeName } = useTheme<MonocerosTheme>()
  const isOrionTheme = isSW(themeName as Brand)

  const styles = makeStyles((theme: MonocerosTheme) => {
    const paddingValue = isOrionTheme
      ? 0
      : `${theme.spacing(5)} ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(3)}`
    const marginValue = isOrionTheme
      ? `${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)}`
      : 0
    return {
      pageHeader: {
        padding: paddingValue,
        margin: marginValue,
      },
    }
  })

  const classes = styles()
  const iconContainerClasses = iconContainerStyles()
  const title = useMemo(
    () => (
      <>
        {icon && (
          <Icon
            className={iconContainerClasses.iconContainer}
            name={icon.name as IconProps['name']}
            size={icon.size}
            htmlColor={icon.color}
          />
        )}
        <Typography variant={isOrionTheme ? 'h1' : 'h4'} component="h1" data-testid={dataTestId}>
          {headerText}
        </Typography>
      </>
    ),
    [isOrionTheme, headerText],
  )
  return <Box className={classes.pageHeader}>{isTitleFocus ? <PageFocus> {title} </PageFocus> : title}</Box>
}
