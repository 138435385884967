import {
  Box,
  Brand,
  ButtonV2 as Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MonocerosTheme,
  makeStyles,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { InfoDialogProps } from './types'
import { useJourneyChecker } from '../../../journeyCwaConfig'
import { PageFocus } from '../PageFocus'
import { isSW } from '../../../utils/brandSpecificFormatting'

const withStyles = makeStyles((theme: MonocerosTheme) => {
  const isOrionTheme = isSW(theme.name as Brand)
  return {
    dialogActions: {
      borderTop: '1px solid #d7d7d7',
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(5),
    },
    button: {
      margin: `${theme.spacing(isOrionTheme ? 1 : 4)} ${isOrionTheme ? 0 : theme.spacing(3)}`,
    },
    dialogContent: {
      ...(!isOrionTheme && {
        paddingTop: theme.spacing(3),
      }),
    },
  }
})

export const InfoDialog = (props: InfoDialogProps) => {
  const classes = withStyles()
  const { isJourneyWeb, isJourneyUnAuth } = useJourneyChecker()
  const dataTestId = `${props['data-testid']}-dialog`

  return (
    <Dialog
      open={props.open}
      fullScreen={isJourneyWeb || isJourneyUnAuth ? undefined : true}
      TransitionProps={{ role: 'presentation' } as any}
      onClose={props.toggleOpen}
      data-testid={dataTestId}
    >
      <PageFocus>
        <Box>{props.title && <DialogTitle data-testid={`${dataTestId}-title`}>{props.title}</DialogTitle>}</Box>
      </PageFocus>

      <DialogContent data-testid={`${dataTestId}-content`} className={classes.dialogContent} tabIndex={0}>
        {props.children}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={() => props.toggleOpen()}
          color="primary"
          variant="contained"
          fullWidth
          className={classes.button}
          data-testid={`${dataTestId}-close-button`}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
