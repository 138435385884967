import React, { FC, useEffect, useMemo, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Brand,
  Card,
  FormField,
  MonocerosTheme,
  SelectItem,
  SelectItemGroup,
  Typography,
  makeStyles,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import { useJourneyNavMap } from '../../../journeyNavMap'
import { isHalifax, isSW } from '../../../utils/brandSpecificFormatting'
import { useNavigateTo } from '../../../utils/useNavigateTo'
import { Info } from './Info'
import { LegalEntities } from './LegalEntities'
import { MarketingPreferencesProps } from './types'
import { useFeatureFlags } from '../../../featureFlags'
import { useJourneyChecker } from '../../../journeyCwaConfig'
import { tagFieldUpdate } from '../../../utils/tagUtils/tagsUtils'

const swMarketingPreferenceName = 'scottishWidowsWebsites'
const styles = makeStyles((theme: MonocerosTheme) => {
  const isSWTheme = isSW(theme.name as Brand)
  const cardPaddingTop = isHalifax(theme.name as Brand) ? 0 : theme.spacing(4)
  const cardPaddingBottom = isSWTheme ? theme.spacing(4) : 0
  const cardMarginLeftRight = isSWTheme ? theme.spacing(2) : 0
  return {
    card: {
      padding: `${cardPaddingTop} ${theme.spacing(3)} ${cardPaddingBottom} ${theme.spacing(3)}`,
      margin: `0  ${cardMarginLeftRight} 0 ${cardMarginLeftRight}`,
    },
  }
})

export const MarketingPreferences: FC<MarketingPreferencesProps> = (props) => {
  const classes = styles({})
  const { marketingPreferences, setMarketingPreference } = props
  const { control, errors, handleSubmit } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  const navigateTo = useNavigateTo()
  const targetNav = useJourneyNavMap().getTargetNav('marketing-preference', 'review-summary')
  const { isFeatureEnabled } = useFeatureFlags()
  const { isJourneyUnAuth } = useJourneyChecker()
  const showSWMarketingPreference: boolean = isFeatureEnabled('showSWMarketingPreference')

  const refs = useRef<{ [key: string]: React.MutableRefObject<HTMLElement> }>({})
  const theme = useTheme<MonocerosTheme>()
  const submit = handleSubmit(() => navigateTo(targetNav))

  const showSWOption = useMemo(() => (isJourneyUnAuth ? showSWMarketingPreference : true), [
    isJourneyUnAuth,
    showSWMarketingPreference,
  ])

  const evaluatedMarketingPreferences = useMemo(
    () =>
      showSWOption
        ? marketingPreferences
        : marketingPreferences.filter((pref) => pref.name !== swMarketingPreferenceName),
    [marketingPreferences],
  )

  useEffect(() => {
    // set false scottishWidowsWebsites marketing preference when flag is disabled on unauth journey
    if (!showSWOption && isJourneyUnAuth) {
      const swMP = marketingPreferences.find((pref) => pref.name === swMarketingPreferenceName)
      if (swMP) {
        setMarketingPreference({
          id: swMP.id,
          preference: false,
        })
      }
    }
  }, [])

  return (
    <Card className={classes.card}>
      <Info />
      <br />
      <form id="marketing-prefs-form" data-testid="marketing-prefs-form" onSubmit={submit}>
        {evaluatedMarketingPreferences.map((setting) => (
          <Controller
            key={setting.id}
            name={`marketing-prefs-${setting.id}`}
            defaultValue={setting.preference}
            control={control}
            rules={{
              validate: (value) => {
                return value !== undefined && value !== null
              },
            }}
            onFocus={() => {
              const selectItemGroup = refs.current[setting.id]?.current

              if (selectItemGroup) {
                const firstOption = selectItemGroup.querySelector('button')
                if (firstOption) {
                  firstOption.focus()
                  const formField = document.querySelector(`div[data-formfield-id="${setting.id}"]`)
                  if (formField) {
                    formField.scrollIntoView(true)
                  }
                }
              }
            }}
            render={({ name, onChange, value, ref }) => {
              refs.current[setting.id] = ref

              return (
                <FormField
                  label={setting.title}
                  id={name}
                  name={name}
                  inputWidth={theme.spacing(34)}
                  helperText={setting.subtext && <Typography variant="body1">{setting.subtext}</Typography>}
                  errorMessage={errors[name] ? "Please select 'Yes' or 'No'." : undefined}
                  inputRef={ref}
                  data-formfield-id={name}
                  value={value}
                  onChange={(_event: unknown, values: boolean[]) => {
                    const prefValue = Array.isArray(values) ? values[0] : values
                    onChange({ target: { name, value: prefValue }, type: 'change' })
                    setMarketingPreference({
                      id: setting.id,
                      preference: prefValue,
                    })
                    tagFieldUpdate(setting.title, 'yes/no', prefValue ? 'Yes' : 'No')
                  }}
                >
                  <SelectItemGroup exclusive showTickOnSelect={isSW(theme.name as Brand)} deSelectable={false}>
                    <SelectItem value data-testid={`${setting.name}-preference-select-yes`}>
                      Yes
                    </SelectItem>
                    <SelectItem value={false} data-testid={`${setting.name}-preference-select-no`}>
                      No
                    </SelectItem>
                  </SelectItemGroup>
                </FormField>
              )
            }}
          />
        ))}
      </form>
      <LegalEntities />
    </Card>
  )
}
