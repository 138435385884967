import { makeStyles, Brand, MonocerosTheme } from '@lbg-protection/lib-ui-monoceros'
import { isSW } from '../../../utils/brandSpecificFormatting'

export const withStyles = makeStyles((theme: MonocerosTheme) => {
  const isSWBrand = isSW(theme.name as Brand)
  return {
    stickyBasketWrapper: {
      width: '100%',
      position: 'sticky',
      top: 0,
      zIndex: 1100,
      borderBottom: isSWBrand ? '1px solid #ADADAD' : '1px solid #d8d8d8',
      borderTop: isSWBrand ? '1px solid #ADADAD' : 'none',
      marginBottom: isSWBrand ? theme.spacing(5) : 0,
    },
    expansionPanelSummary: {
      color: 'inherit',
      padding: 0,
      margin: `0 ${theme.spacing(3)}`,
    },
    expansionPanelDetails: {
      padding: 0,
    },
    quotesInBasket: {
      zIndex: 1100,
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      height: theme.spacing(10),
      paddingLeft: theme.spacing(3),
      borderBottom: isSWBrand ? 'none' : '1px solid #d8d8d8',
    },
    panelContainer: {
      '&.MuiExpansionPanel-root:before': {
        display: 'none !important',
      },
      '&.MuiExpansionPanel-root:after': {
        display: 'none',
      },
      '&:before': {
        display: 'none !important',
      },
      '&:after': {
        display: 'none !important',
      },
      '&$expanded': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  }
})
