import React, { useState } from 'react'
import {
  ButtonV2 as Button,
  PromoBox,
  Typography,
  makeStyles,
  MonocerosTheme,
  Card,
  IconProps,
  Brand,
  useTheme,
  Box,
} from '@lbg-protection/lib-ui-monoceros'
import clsx from 'clsx'
import { isLloyds, isBOS, isSW } from '../../../utils/brandSpecificFormatting'
import { VoucherImageTiered, VoucherImageTieredProps } from './VoucherImageTiered'
import { VoucherTermsAndConditionsOverlayTiered } from './VoucherTermsAndConditionsOverlayTiered'
import { VoucherDescriptionTiered } from './types'
import { useJourneyChecker } from '../../../journeyCwaConfig'
import { tagButtonClick } from '../../../utils/tagUtils/tagsUtils'

const styles = makeStyles((theme: MonocerosTheme) => {
  const isOrionTheme = isSW(theme.name as Brand)
  const orionDarkRed = theme.palette.namedColours?.orionDarkBackgroundRed
  const { white } = theme.palette.common
  return {
    findOutButton: {
      width: '100%',
      marginTop: theme.spacing(4),
      color: theme.palette.primary.main,
      '&:hover:not(:disabled)': {
        backgroundColor: white,
        textDecoration: 'underline',
        ...(isOrionTheme && { color: orionDarkRed }),
      },
      '&:focus': {
        backgroundColor: isOrionTheme ? orionDarkRed : '#FFC764',
        textDecoration: 'underline',
        border: `1px solid ${white}`,
        outline: `2px solid ${isOrionTheme ? orionDarkRed : '#05286A'}`,
        color: isOrionTheme ? white : '#05286A',
      },
    },
    cardWithDefaultBackgroundAndSpacing: {
      padding: isOrionTheme
        ? `${theme.spacing(6)} ${theme.spacing(2)} 0px ${theme.spacing(2)}`
        : `${theme.spacing(4)} ${theme.spacing(3)} 0px ${theme.spacing(3)}`,
      backgroundColor: 'transparent',
      marginBottom: isOrionTheme ? 0 : theme.spacing(4),
    },
    cardBorder: {
      borderColor: '#BFBFBF',
      borderWidth: 1,
      borderStyle: 'solid',
      position: 'relative',
    },
    titleContainer: {
      display: 'block',
    },
    cardExtraPadding: {
      padding: `0px ${theme.spacing(3)} ${theme.spacing(isOrionTheme ? 5 : 4)} ${theme.spacing(3)}`,
      backgroundColor: 'transparent',
    },
    boxPadding: {
      padding: `0 ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    withinAlert: {
      padding: 0,
    },
    cardWithNoBackGroundAndSpacing: {
      backgroundColor: 'transparent',
      padding: `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(3)}`,
    },
    cardText: {
      fontSize: theme.spacing(3),
      lineHeight: theme.spacing(4),
    },
  }
})

export interface VoucherIncentiveTieredProps {
  withBorder?: boolean
  withPadding?: boolean
  policyDurationBullet: string
  voucherImageProps: VoucherImageTieredProps
  voucherDescription: VoucherDescriptionTiered
  withinAlert?: boolean
  voucherAmount?: number
  onTsAndCsClick?: () => void
}

export const VoucherIncentiveCardTiered = ({
  withBorder,
  withPadding,
  policyDurationBullet,
  voucherImageProps,
  voucherDescription,
  withinAlert,
  voucherAmount = 0,
  onTsAndCsClick,
}: VoucherIncentiveTieredProps) => {
  const classes = styles({})
  const theme = useTheme<MonocerosTheme>()
  const { isJourneyUnAuth } = useJourneyChecker()
  const isLloydsBrand = isLloyds(theme.name as Brand)
  const isBOSBrand = isBOS(theme.name as Brand)
  const isSWBrand = isSW(theme.name as Brand)
  const promoBoxVariant = isLloydsBrand || isBOSBrand ? 'compact' : 'large'
  const voucherIcon = isLloydsBrand || isBOSBrand ? 'present' : 'Present-illustration'
  const [showTermsAndConditionsOverlay, setShowTermsAndConditionsOverlay] = useState(false)
  const boxPositioning = !withinAlert && withBorder ? classes.boxPadding : ''

  let cardPositioning = withBorder ? classes.cardBorder : ''

  const cardWithBackgroundAndSpacing =
    isBOS(theme.name as Brand) && !isJourneyUnAuth
      ? classes.cardWithNoBackGroundAndSpacing
      : classes.cardWithDefaultBackgroundAndSpacing

  const promoBoxBorder = withinAlert && isLloydsBrand ? classes.cardBorder : ''

  if (withinAlert) {
    cardPositioning = classes.withinAlert
  } else {
    cardPositioning = `${cardPositioning} ${withPadding ? classes.cardExtraPadding : cardWithBackgroundAndSpacing}`
  }

  const titleContent = (
    <Typography variant="h4" data-testid="incentive-tiered-title" className={classes.titleContainer}>
      {voucherDescription.title.replace('{value}', voucherAmount && voucherAmount > 0 ? `£${voucherAmount}` : '')}
    </Typography>
  )

  return (
    <Box className={boxPositioning}>
      <Card className={cardPositioning} data-testid="voucher-card">
        <PromoBox
          variant={promoBoxVariant}
          iconName={voucherIcon as IconProps['name']}
          title={titleContent}
          titleComponent="h2"
          className={promoBoxBorder}
        >
          {!voucherAmount ? (
            <>
              <Typography variant="body1" gutterBottom className={clsx(isSWBrand && classes.cardText)}>
                To receive your gift card, you just need to make
                <Typography display="inline" variant="body2">
                  {' '}
                  at least 3 monthly payments.
                </Typography>{' '}
                Then you’ll be able to select a gift card from over 140 retailers.
              </Typography>

              <Typography variant="body1" gutterBottom className={clsx(isSWBrand && classes.cardText)}>
                {voucherDescription.bodyText}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body1" gutterBottom className={clsx(isSWBrand && classes.cardText)}>
                {voucherDescription.bodyText?.replace('{value}', `£${voucherAmount}`)}
              </Typography>

              {voucherDescription.bodyTextWithVoucherAmount && (
                <Typography variant="body1" gutterBottom className={clsx(isSWBrand && classes.cardText)}>
                  {voucherDescription.bodyTextWithVoucherAmount?.replace('{value}', `£${voucherAmount}`)}
                </Typography>
              )}
            </>
          )}

          <Typography variant="body1" className={clsx(isSWBrand && classes.cardText)}>
            Terms and conditions apply.
          </Typography>
          <VoucherImageTiered {...voucherImageProps} />
          <Button
            className={classes.findOutButton}
            variant="outlined"
            data-testid="view-terms-and-conditions-voucher"
            onClick={() => {
              setShowTermsAndConditionsOverlay(true)
              tagButtonClick('View terms and conditions')
              if (onTsAndCsClick) {
                onTsAndCsClick()
              }
            }}
          >
            View terms and conditions
          </Button>
          <VoucherTermsAndConditionsOverlayTiered
            open={showTermsAndConditionsOverlay}
            toggleOpen={() => setShowTermsAndConditionsOverlay(!showTermsAndConditionsOverlay)}
            policyDurationBullet={policyDurationBullet}
          />
        </PromoBox>
      </Card>
    </Box>
  )
}
