import { makeStyles } from '@lbg-protection/lib-ui-monoceros'

export const useStyles = makeStyles((theme) => ({
  feedbackHeadingMargin: {
    marginBottom: theme.spacing(4),
  },
  feedbackBodyMargin: {
    margin: theme.spacing(0, 0, 4, 0),
  },
  feedbackContentSideMargin: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  feedbackContentTopMargin: {
    marginTop: theme.spacing(3),
  },
  feedbackContentBottomMargin: {
    marginBottom: theme.spacing(3),
  },
  dividerTopMargin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  dividerBottomMargin: {
    marginBottom: theme.spacing(-3),
  },
  promoBoxBackground: {
    backgroundColor: 'white',
  },
  squarePromoBox: {
    borderRadius: 0,
    '& .MuiBox-root': {
      justifyContent: 'start',
      alignItems: 'start',
    },
  },
  noPadding: {
    padding: 0,
  },
}))
