import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ScreenTemplate } from '../Shared/ScreenTemplate'
import { PageHeader } from '../Shared/PageHeader'
import { ConnectedMarketingPreferences } from './MarketingPreferences'
import { MarketingPreferencesFooter } from './MarketingPreferencesFooter'
import { useTagging } from '../../analytics/useTagging'

export const MarketingPreferencesPage: FC = () => {
  const { tagRoutePageLoad } = useTagging()

  useEffect(() => {
    tagRoutePageLoad()
  }, [])

  const history = useHistory()
  return (
    <ScreenTemplate
      dataTestId="marketingPreferencesPage"
      bottomNav={<MarketingPreferencesFooter goToSpecialRequirements={() => history.go(-1)} />}
      pageHeader={<PageHeader headerText="Marketing preferences" dataTestId="marketing-preferences-heading" />}
    >
      <ConnectedMarketingPreferences data-testid="marketing-preferences-page-container" />
    </ScreenTemplate>
  )
}

export { MarketingPreferencesPage as ConnectedMarketingPreferencesPage }
