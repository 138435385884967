import {
  PricingRequestBody,
  PricingResponseBodyData,
  PricingRequestBodyV2,
} from '@lbg-protection/api-client-nmp-journey'
import {
  PricingQuickQuoteRequestBody,
  PricingRequestBody as UnAuthPricingRequestBody,
} from '@lbg-protection/api-client-public-site-journey'
import { authJourneyApi } from '../../apis/authJourneyApi'
import { unAuthJourneyApi } from '../../apis/unAuthJourneyApi'
import {
  PricingFulfilledActionProps,
  PricingRequestParams,
  BasePricingRequestParams,
  BasePricingRequestData,
  PricingIndicativeQuoteFulfilledActionProps,
} from './types'
import { transformDobForPricing, transformSmokerStatusForPricing, transformUwOutcomeForPricing } from './utils'

export const pricingApi = {
  buildQuoteResponse: (
    args: PricingRequestParams | BasePricingRequestParams,
    pricingResponse: PricingResponseBodyData,
  ): PricingFulfilledActionProps => {
    const argsCalculationType = args.calculationType
    const argsSumAssured = args.sumAssured ?? 0
    const argsMonthlyPremium = args.monthlyPremium ?? 0
    const quoteSumAssured = pricingResponse?.sumAssured ?? 0
    const quoteMonthlyPremium = pricingResponse?.totalMonthlyPremium ?? 0
    const isAdjusted = pricingResponse?.calculationType === PricingResponseBodyData.CalculationTypeEnum?.ADJUSTED
    const response = {
      coverType:
        pricingResponse?.type === PricingRequestBody.CoverTypeEnum.LIFE
          ? PricingRequestBody.CoverTypeEnum.LIFE
          : PricingRequestBody.CoverTypeEnum.BODY,
      title: pricingResponse?.type === PricingRequestBody.CoverTypeEnum.LIFE ? 'Life Cover' : 'Body Cover',
      amount: isAdjusted ? argsSumAssured : quoteSumAssured,
      term: args.coverTerm,
      monthlyCost: isAdjusted ? argsMonthlyPremium : quoteMonthlyPremium,
      inBasket: false,
      calculationType: argsCalculationType,
    }
    if (isAdjusted) {
      Object.assign(response, { adjustedAmount: quoteSumAssured })
      Object.assign(response, { adjustedMonthlyCost: quoteMonthlyPremium })
    }
    return response
  },
  buildPricingRequestBody: (
    args: PricingRequestParams,
  ): PricingRequestBody | PricingRequestBodyV2 | UnAuthPricingRequestBody => {
    const {
      coverType,
      coverTerm,
      calculationType,
      monthlyPremium,
      sumAssured,
      smokerStatus,
      uwOutcome,
      dob,
      underwriteMe,
      lastQuoteDate,
      isJourneyUnAuth,
    } = args
    const { isUnderwriteMe, enquiryId } = underwriteMe
    const baseRequestData: BasePricingRequestData = {
      dateOfBirth: transformDobForPricing(dob),
      coverType,
      calculationType,
      monthlyPremium: monthlyPremium && Number(monthlyPremium),
      sumAssured: sumAssured && Number(sumAssured),
      coverTerm: String(coverTerm),
    }

    if (isJourneyUnAuth) {
      return {
        ...baseRequestData,
        enquiryId,
      } as UnAuthPricingRequestBody
    }

    const persistStoredQuoteDate = lastQuoteDate ? { persistStoredQuoteDate: true } : {}
    return isUnderwriteMe
      ? ({ ...baseRequestData, enquiryId, ...persistStoredQuoteDate } as PricingRequestBodyV2)
      : ({
          ...baseRequestData,
          uwOutcome: transformUwOutcomeForPricing(uwOutcome),
          smokerStatus: transformSmokerStatusForPricing(smokerStatus),
        } as PricingRequestBody)
  },
  buildIndicativePricingRequestBody: (args: BasePricingRequestParams): PricingRequestBody => {
    const { dob, coverType, calculationType, monthlyPremium, sumAssured, coverTerm, smokerStatus } = args
    return {
      dateOfBirth: transformDobForPricing(dob),
      coverType,
      calculationType,
      monthlyPremium: monthlyPremium && Number(monthlyPremium),
      sumAssured: sumAssured && Number(sumAssured),
      coverTerm: String(coverTerm),
      uwOutcome: 0,
      smokerStatus: transformSmokerStatusForPricing(smokerStatus),
    }
  },
  getPricingAnonymousQuote: async (args: PricingRequestParams): Promise<PricingFulfilledActionProps> => {
    const { underwriteMe, isJourneyUnAuth } = args
    const { isUnderwriteMe } = underwriteMe
    let pricingQuote: PricingResponseBodyData
    if (isJourneyUnAuth) {
      pricingQuote = await unAuthJourneyApi().getPricingAnonymousQuote(
        pricingApi.buildPricingRequestBody(args) as UnAuthPricingRequestBody,
      )
      return pricingApi.buildQuoteResponse(args, pricingQuote)
    }
    if (isUnderwriteMe) {
      pricingQuote = await authJourneyApi().getPricingAnonymousQuoteV2(
        pricingApi.buildPricingRequestBody(args) as PricingRequestBodyV2,
      )
    } else {
      pricingQuote = await authJourneyApi().getPricingAnonymousQuote(
        pricingApi.buildPricingRequestBody(args) as PricingRequestBody,
      )
    }
    return pricingApi.buildQuoteResponse(args, pricingQuote)
  },
  getPricingAnonymousIndicativeQuote: async (
    args: BasePricingRequestParams,
  ): Promise<PricingIndicativeQuoteFulfilledActionProps> => {
    if (args.isJourneyUnAuth) {
      const pricingQuote: PricingResponseBodyData = await unAuthJourneyApi().getPricingQuickQuote(
        pricingApi.buildIndicativePricingRequestBody(args) as PricingQuickQuoteRequestBody,
      )
      return pricingApi.buildQuoteResponse(args as BasePricingRequestParams, pricingQuote)
    }
    const pricingQuote: PricingResponseBodyData = await authJourneyApi().getPricingAnonymousQuote(
      pricingApi.buildIndicativePricingRequestBody(args) as PricingRequestBody,
    )
    return pricingApi.buildQuoteResponse(args as BasePricingRequestParams, pricingQuote)
  },
}
