import { Answer } from '../Shared/Questionaire/types'
import { MultipleChoiceAnswer } from '../Shared/Questionaire/questions/MultipleChoiceQuestion/types'
import { FinancesAnswer } from '../Shared/Questionaire/questions/FinancesQuestion/types'
import { IconCheckboxAnswer } from '../Shared/Questionaire/questions/IconCheckboxQuestion/types'
import { CoverSelectedEnum } from '../../journeys/purchase'

export const getQuestionSequence = (answers: Record<string, Answer>, selectedProducts: CoverSelectedEnum): string[] => {
  const showBody = selectedProducts === CoverSelectedEnum.Body || selectedProducts === CoverSelectedEnum.All
  const showLife = selectedProducts === CoverSelectedEnum.Life || selectedProducts === CoverSelectedEnum.All
  const showChildren = (answers.protectWhat as IconCheckboxAnswer)?.values.includes('children') && showLife
  const showHomeownerStatus = (answers.protectWhat as IconCheckboxAnswer)?.values.includes('home') && showLife
  const homeownerStatusValues = (answers.homeownerStatus as MultipleChoiceAnswer)?.values
  const showRent = homeownerStatusValues?.some((value) => value.id === 'rent') && showLife
  const showMortgage = homeownerStatusValues?.some((value) => value.id === 'mortgage') && showLife
  const showIncomeYears = (answers.income as FinancesAnswer)?.value !== 0 && showBody
  const showSomethingExtra = (answers.protectWhat as IconCheckboxAnswer)?.values.includes('extra') && showLife
  const showDebts = (answers.protectWhat as IconCheckboxAnswer)?.values.includes('debt') || (showBody && !showLife)

  return [
    ...(showLife ? ['protectWho'] : []),
    ...(showLife ? ['protectWhat'] : []),
    ...(showChildren ? ['children'] : []),
    ...(showHomeownerStatus ? ['homeownerStatus'] : []),
    ...(showRent ? ['rent'] : []),
    ...(showMortgage ? ['mortgage'] : []),
    ...(showDebts ? ['debts'] : []),
    'savings',
    ...(showBody ? ['income'] : []),
    ...(showIncomeYears ? ['incomeYears'] : []),
    ...(showBody ? ['additionalSupport'] : []),
    ...(showSomethingExtra ? ['somethingExtra'] : []),
    'term',
  ]
}
