import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import { CoverChoiceInterface } from '../../journeys/purchase/types'

export interface TaggingProps {
  numberOfLifeCoverPricingCalls: number
  numberOfBodyCoverPricingCalls: number
}

export type IndicativeQuoteState = {
  validationError?: string
  tagging: TaggingProps
  lifeCoverPricingCallStatus: PricingCallStatus
  bodyCoverPricingCallStatus: PricingCallStatus
  lifeCover: CoverSummaryInterface
  bodyCover: CoverSummaryInterface
}

export interface CoverSummaryInterface {
  coverType: PricingRequestBody.CoverTypeEnum
  title: string
  amount: number
  term: number
  monthlyCost: number
  adjustedAmount?: number
  adjustedMonthlyCost?: number
  calculationType?: PricingRequestBody.CalculationTypeEnum
}

export interface BasePricingRequestParams {
  dob: Date
  smokerStatus: boolean
  coverType: PricingRequestBody.CoverTypeEnum
  coverTerm: number
  calculationType: PricingRequestBody.CalculationTypeEnum
  monthlyPremium?: number
  sumAssured?: number
  isJourneyUnAuth?: boolean
}

export interface UpdateActionProps {
  coverType: PricingRequestBody.CoverTypeEnum
  title: string
  amount: number
  term: number
  monthlyCost: number
}

export interface SetValidationErrorActionProps {
  validationError: string
}

export interface PricingFulfilledActionProps {
  coverType: PricingRequestBody.CoverTypeEnum
  title: string
  amount: number
  term: number
  monthlyCost: number
}

export enum PricingCallStatus {
  Pending = 'Pending',
  Success = 'Success',
  Error = 'Error',
  Idle = 'Idle',
}

export interface IndicativeQuotePageProps {
  validationError?: string
  numberOfLifeCoverPricingCalls?: number
  numberOfBodyCoverPricingCalls?: number
  smokerStatus: boolean | null
  prePurchaseCoverChoice: CoverChoiceInterface
  lifeCoverPricingCallStatus: PricingCallStatus
  bodyCoverPricingCallStatus: PricingCallStatus
  dob: Date
  firstName: string
  lastName: string
  callPricingAPI?: (formData: BasePricingRequestParams) => void
  lifeCover: CoverSummaryInterface
  bodyCover: CoverSummaryInterface
  update?: (formData: CoverSummaryInterface) => void
  setValidationError?: (error: string) => void
  cavendishOnlineConsentDwellTime?: number
}

export interface UnderwriteOutcomeArgs {
  isUnderwriteMe: boolean
  underwritingCoverOutcome?: number | string
  underwriteMeCoverOutcome?: boolean
}

export interface BasePricing {
  dateOfBirth: string
  coverType: PricingRequestBody.CoverTypeEnum
  calculationType: PricingRequestBody.CalculationTypeEnum
  monthlyPremium?: number
  sumAssured?: number
  coverTerm: string
}
