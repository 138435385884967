import { VoucherIncentiveContentTiered } from './types'

export const VoucherDefaultDataTiered: VoucherIncentiveContentTiered = {
  policyDurationBulletTiered: `You purchase a new Plan & Protect Life or Critical Illness (Body Cover) policy online through Halifax from 5th December 2023.`,
  giftCardImageTiered: {
    alt: 'voucher',
    src: './assets/images/incentive/onecode-tiered-incentive.png',
    arialabel: 'Gift card image',
  },
  voucherDescriptionTiered: {
    title: 'Your {value} gift card',
    bodyText:
      'Get a {value} gift card if you buy this policy. The value of your gift card is based on the amount you’ll pay each month. If you buy more than one policy, we’ll use the higher monthly payment to work out the value.',
    bodyTextWithVoucherAmount: 'To receive your {value} gift card, you just need to make at least 3 monthly payments.',
  },
  voucherIncentivePeriodTiered: {
    endDate: undefined,
    startDate: undefined,
  },
}
