import { Typography, Box } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'
import { ChildrenAgeQ } from '../../../Shared/Questionaire/questions/ChildrenAgeQuestion/types'

export const childrenAge = (): ChildrenAgeQ => {
  return {
    id: 'children',
    category: 'lovedOnes',
    type: QuestionTypes.ChildrenAge,
    sectionHeader: 'Your Children',
    title: {
      icon: 'family-protector',
      text: 'Your children',
    },
    question: 'How old are they?',
    helpText: [
      `The money your policy pays out could help support your children if you couldn't be there.`,
      `We’ll help you decide how much cover you might need to support your children to age 18. `,
      `You can change this amount on your cover report.`,
    ],
    overlay: {
      id: 'information',
      link: 'How do you use this information?',
      content: (
        <>
          <Typography gutterBottom>
            According to the Child Poverty Action Group, it costs around £4,119.01 a year to raise a child to age 18.*{' '}
          </Typography>
          <Typography gutterBottom>
            So we&apos;ll help you decide how much cover you might need to support your children to 18, assuming
            they&apos;d need around £4,000 a year.
          </Typography>
          <Typography gutterBottom>You can change this amount on your cover report.</Typography>
          <Typography gutterBottom>
            To set aside an amount for something specific, you can use &apos;Leave something extra&apos;. We&apos;ll
            show you this option later, or you can do this on your cover report.
          </Typography>
          <Box paddingTop={3}>
            <Typography>*Child Poverty Action Group, 2019</Typography>
          </Box>
        </>
      ),
    },
    JourneyStepName: 'Your children',
    JourneyStep: '9',
  }
}
