import { Box, Typography } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'

export const Info = () => {
  return (
    <Box>
      <Box data-testid="marketing-preferences-info">
        <Typography variant="body1">
          We would like to keep you up to date on products and offers that may be of interest to you. Please select how
          you would like to hear from us below. These choices won’t affect any necessary information we need to send you
          such as statements and, don’t worry, you can change your mind and update your preferences at any time.
        </Typography>
      </Box>
    </Box>
  )
}
