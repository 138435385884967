import { Box, Typography } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { BulletPointWrapper } from '../../Shared/BulletPointWrapper'
import { withStyles } from './styles'

const amountRightForMe = [
  'Day-to-day costs and bills',
  'Rent or mortgage payments',
  'Costs to make health-related changes to your home.',
]

export const BodyCoverAmountRightForMe = () => {
  const classes = withStyles()

  return (
    <>
      <Typography className={classes.textContainer} variant="body1" data-testid="body-cover-amount-answer">
        Think about how much you&#39;d need to help you and your loved ones cope if you were too ill to work for an
        extended period. <br />
        <br /> Consider the help you might need to keep up with:
      </Typography>
      <Box data-testid="body-cover-amount-answer-bulletpoints">
        <BulletPointWrapper bulletPoints={amountRightForMe} />
      </Box>
    </>
  )
}
