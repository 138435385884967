import { Box, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@lbg-protection/lib-ui-monoceros'
import React, { useState } from 'react'
import { QuotesInBasket } from '../../QuotePage/QuotesInBasket'
import { calculateSum, countCoversInBasket } from '../../QuotePage/utils'
import { Basket } from '../Basket'
import { withStyles } from './styles'
import { StickyBasketProps } from './types'
import { PageFocus } from '../PageFocus'

export const StickyBasket = (props: StickyBasketProps) => {
  const [expandedPanel, setExpandedPanel] = useState<boolean>(false)

  const { lifeCover, bodyCover } = props

  const classes = withStyles()

  const handleChange = () => {
    setExpandedPanel(!expandedPanel)
  }

  return (
    <Box className={classes.stickyBasketWrapper}>
      {countCoversInBasket(lifeCover, bodyCover) ? (
        <ExpansionPanel
          className={classes.panelContainer}
          expanded={expandedPanel}
          onChange={() => handleChange()}
          data-testid="quotes-expansion-panel"
        >
          <PageFocus>
            <ExpansionPanelSummary
              className={classes.expansionPanelSummary}
              data-testid="quotes-expansion-panel-summary"
            >
              <QuotesInBasket
                quotesInBasket={countCoversInBasket(lifeCover, bodyCover)}
                total={calculateSum(lifeCover, bodyCover)}
              />
            </ExpansionPanelSummary>
          </PageFocus>
          <ExpansionPanelDetails className={classes.expansionPanelDetails} data-testid="quotes-expansion-panel-details">
            <Basket lifeCover={lifeCover} bodyCover={bodyCover} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ) : (
        <PageFocus>
          <Box className={classes.quotesInBasket}>
            <QuotesInBasket quotesInBasket={0} total={0} />
          </Box>
        </PageFocus>
      )}
    </Box>
  )
}
