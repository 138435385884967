import { UnderwritingEnquiryDecision } from '@lbg-protection/api-client-nmp-journey'
import { CoverSelectedEnum } from '../../../journeys/purchase/types'

export const isEligibleForProduct = (
  productName: UnderwritingEnquiryDecision.ProductNameEnum,
  decisions: UnderwritingEnquiryDecision[],
): boolean => {
  const ineligibleStatuses: Partial<UnderwritingEnquiryDecision['decisionStatus']>[] = [
    UnderwritingEnquiryDecision.DecisionStatusEnum.DECLINE,
    UnderwritingEnquiryDecision.DecisionStatusEnum.EVIDENCEREQUIRED,
    UnderwritingEnquiryDecision.DecisionStatusEnum.REFER,
  ]

  return !decisions.some(
    (decision) => decision.productName === productName && ineligibleStatuses.includes(decision.decisionStatus),
  )
}

export const isEligibleOverall = (
  decisions: UnderwritingEnquiryDecision[],
  selectedCovers: CoverSelectedEnum,
): boolean => {
  const onlyBodySelected = selectedCovers === CoverSelectedEnum.Body
  const { LIFE } = UnderwritingEnquiryDecision.ProductNameEnum
  const BODY = UnderwritingEnquiryDecision.ProductNameEnum.CI

  if (onlyBodySelected && (!isEligibleForProduct(BODY, decisions) || !isEligibleForProduct(LIFE, decisions))) {
    return false
  }
  if (!isEligibleForProduct(LIFE, decisions)) {
    return false
  }

  return true
}
