import { PersistenceSaveRequestBody } from '@lbg-protection/api-client-nmp-journey'
import { AddressInterface } from '../../journeys/purchase'
import { CoverSummaryInterface } from '../Shared/Basket/CoverSummary/types'

export { PolicyApplicationPricingRequestDetailsV2 as PolicyApplicationPricingRequestDetails } from '@lbg-protection/api-client-nmp-journey'

export enum SaveAndRetrieveLoadingStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export enum CallbackPage {
  LandingPage = 'LANDING_PAGE_CALL_BACK',
  AgeIneligiblePage = 'AGE_INELIGIBLE_PAGE_CALL_BACK',
  GeneralIneligiblePage = 'GENERAL_INELIGIBLE_PAGE_CALL_BACK',
}

export const JourneyExitPoint = {
  IndicativeQuote: 'INDICATIVE_QUOTE',
  PreQuote: 'PRE_QUOTE',
  Quote: 'QUOTE',
  Purchase: 'PURCHASE',
} as const

export type JourneyExitPoint = typeof JourneyExitPoint[keyof typeof JourneyExitPoint]

export interface SaveAndRetrieveState {
  saveStatus: SaveAndRetrieveLoadingStatus
  callbackRequestStatus: SaveAndRetrieveLoadingStatus
  isRetrievedJourney: boolean | null
  isValidSavedJourney: boolean
  journeyExitPoint?: JourneyExitPoint
  lastQuoteDate?: string
  recordCreatedOn?: string
  indicativeQuoteEmailSent?: boolean
  savedQuoteEmailSent?: boolean
  cavendishOnlineConsent?: boolean
}

export interface RetrieveData {
  journeyExitPoint?: SaveAndRetrieveState['journeyExitPoint']
  lastQuoteDate?: SaveAndRetrieveState['lastQuoteDate']
  recordCreatedOn?: SaveAndRetrieveState['recordCreatedOn']
  cavendishOnlineConsent?: SaveAndRetrieveState['cavendishOnlineConsent']
  isValidSavedJourney?: SaveAndRetrieveState['isValidSavedJourney']
  isRetrievedJourney?: SaveAndRetrieveState['isRetrievedJourney']
}

export interface SaveJourneyRequestParams {
  confirmedAddress?: AddressInterface
  lifeCover: CoverSummaryInterface
  bodyCover: CoverSummaryInterface
  enquiryId?: string
  lastQuoteDate?: SaveAndRetrieveState['lastQuoteDate']
  journeyExitPoint: PersistenceSaveRequestBody['applicationState']['journeyExitPoint']
  cavendishOnlineConsent?: boolean
}

export interface ProcessEmailArgs {
  isIndicativeQuoteEmail?: boolean
}

export interface SaveJourneyArgs {
  journeyExitPoint?: PersistenceSaveRequestBody['applicationState']['journeyExitPoint']
  isAutoSave?: boolean
}

export interface RetrieveJourneyArgs {
  shouldUseSavedAddress?: boolean
}

export interface ProcessSavedQuoteEmailParams {
  lifeCover: CoverSummaryInterface
  bodyCover: CoverSummaryInterface
  quoteValidUntil: string
}
