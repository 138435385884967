import {
  Box,
  CheckboxV2 as Checkbox,
  FormControlLabel,
  FormField,
  FormGroup,
  Typography,
} from '@lbg-protection/lib-ui-monoceros'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { DialogControl } from '../../../DialogControl/DialogControl'
import { IconTitle } from '../../../IconTitle'
import { SubmitAnswer } from '../../types'
import { Overlay } from '../types'
import { styles } from './styles'
import { TempCheckboxIcon } from './TempCheckboxIcon'
import { IconCheckboxAnswer, IconCheckboxOption, IconCheckboxQ } from './types'

type RemoveInvalidValues = (n: string[], h: IconCheckboxOption[]) => string[]
const removeInvalidValues: RemoveInvalidValues = (values, validOptions) => {
  const validValues = validOptions.map((option) => option.value)
  return values.reduce((acc: string[], previousValue: string) => {
    return validValues.indexOf(previousValue) > -1 ? [...acc, previousValue] : acc
  }, [])
}

interface QuestionProps {
  question: IconCheckboxQ
  answer: IconCheckboxAnswer | null
  submitAnswer: SubmitAnswer
  goToNextQuestion: () => void
}

export const Question = (props: QuestionProps) => {
  const { handleSubmit, register, errors, watch } = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' })
  const classes = styles(!!errors[props.question.id])({})

  useEffect(() => {
    // Remove possible invalid values from previous answer submissions
    if (props.answer !== null) {
      const validValues = removeInvalidValues(props.answer.values, props.question.options)
      if (validValues.length !== props.answer.values.length)
        props.submitAnswer({ ...props.answer, values: validValues })
    }
  }, [])

  const updateAnswer = () => {
    const values = watch(props.question.id)

    return props.submitAnswer({
      questionId: props.question.id,
      question: props.question.question,
      category: props.question.category,
      values,
    })
  }

  const onSubmit = () => {
    props.goToNextQuestion()
  }

  return (
    <Box className={classes.contentContainer}>
      <IconTitle dataTestId={`${props.question.id}-title`} title={props.question.title} />
      <Typography data-testid={`${props.question.id}-help-text`} variant="body1" gutterBottom>
        {props.question.helpText}
      </Typography>
      <form
        data-testid={`${props.question.id}-question-form`}
        id={`${props.question.id}-question-form`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormField
          id={props.question.id}
          fullWidth
          label={props.question.question}
          errorMessage={errors[props.question.id] ? props.question.errorMessage : undefined}
        >
          <FormGroup className={classes.checkboxGroup}>
            {props.question.options.map((option, index) => {
              return (
                <React.Fragment key={`${props.question.id}-${option.value}-checkbox`}>
                  <FormControlLabel
                    data-testid={`${props.question.id}-${option.value}-checkbox`}
                    label={option.label}
                    labelPlacement="bottom"
                    labelAlign="center"
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        name={props.question.id}
                        value={option.value}
                        checked={props.answer?.values.includes(option.value) || false}
                        onChange={() => updateAnswer()}
                        icon={
                          <TempCheckboxIcon checked={false} icon={option.icon} error={!!errors[props.question.id]} />
                        }
                        checkedIcon={<TempCheckboxIcon checked icon={option.icon} />}
                        inputRef={register({ required: true })}
                        inputProps={{ 'aria-required': !(props.answer !== null && props.answer.values.length) }}
                      />
                    }
                  />
                  {props.question.options.length % 2 === 0 && index % 2 !== 0 && <div style={{ flex: '0 0 100%' }} />}
                </React.Fragment>
              )
            })}
          </FormGroup>
        </FormField>
      </form>
      <Box className={classes.overlayLinks}>
        {props.question.overlays?.map((overlay: Overlay) => (
          <Box className={classes.overlayLink}>
            <DialogControl
              id={`${props.question.id}-${overlay.id}`}
              data-testid={`${props.question.id}-${overlay.id}`}
              title={overlay.link}
              controlText={overlay.link}
              transition="fade"
            >
              {overlay.content}
            </DialogControl>
          </Box>
        ))}
      </Box>
    </Box>
  )
}
