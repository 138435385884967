import { ButtonV2 as Button, Divider } from '@lbg-protection/lib-ui-monoceros'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { DialogControl } from '../../../DialogControl/DialogControl'
import { IconTitle } from '../../../IconTitle'
import { SubmitAnswer } from '../../types'
import { ExamplesDialogContent } from './components/ExamplesDialog/ExamplesDialogContent'
import { ItemDisplay } from './components/ItemDisplay/ItemDisplay'
import { ItemForm } from './components/ItemForm/ItemForm'
import { styles } from './styles'
import { Item, LeaveSomethingExtraAnswer, LeaveSomethingExtraQ } from './types'
import { addNewItem, updateItem } from './utils/helpers'

interface QuestionProps {
  question: LeaveSomethingExtraQ
  answer: LeaveSomethingExtraAnswer | null
  submitAnswer: SubmitAnswer
  goToNextQuestion: () => void
}

export const Question = (props: QuestionProps) => {
  const classes = styles({})
  const methods = useForm({ reValidateMode: 'onBlur' })

  const [items, setItems] = useState<Item[]>(props.answer ? props.answer.values : [])

  const anyOpenForm = items.some((item) => item.editable)
  useEffect(() => {
    if (!anyOpenForm) {
      props.submitAnswer({
        questionId: props.question.id,
        question: props.question.question,
        category: props.question.category,
        values: items,
      })
    }
  }, [anyOpenForm])

  const onSubmit = (data: Record<string, string>) => {
    if (Object.entries(data).length > 0) {
      // determine item id from field name
      const itemId = Number(Object.keys(data)[0].split('-')[2])
      const [description, amount] = Object.values(data).map((value: string) => value)
      const nextItems = updateItem(itemId, items, description, Number(amount))
      setItems(nextItems)
    } else {
      props.goToNextQuestion()
    }
  }

  return (
    <>
      <IconTitle dataTestId={`${props.question.id}-title`} title={props.question.title} />
      {props.question.description}

      <form
        noValidate
        data-testid={`${props.question.id}-question-form`}
        id={`${props.question.id}-question-form`}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {items.map((item: Item, index: number) => {
          return (
            <React.Fragment key={`extra-item-${index + 1}-container`}>
              {item.editable ? (
                <>
                  <FormProvider {...methods}>
                    <ItemForm item={item} items={items} setItems={setItems} />
                    {index !== items.length - 1 && <Divider className={classes.displayItemDivider} />}
                  </FormProvider>
                </>
              ) : (
                <>
                  {index === 0 && <Divider className={classes.displayItemDivider} />}
                  <ItemDisplay item={item} items={items} setItems={setItems} />
                  <Divider className={classes.displayItemDivider} />
                </>
              )}
            </React.Fragment>
          )
        })}
      </form>
      {!items.some((item: Item) => item.editable) && (
        <Button
          className={classes.addButton}
          data-testid="add-extra-item-button"
          type="button"
          variant="outlined"
          color="primary"
          onClick={() => {
            const nextItems = addNewItem(items)
            setItems(nextItems)
          }}
        >
          + Add an amount
        </Button>
      )}
      {props.question.overlayLink && (
        <DialogControl
          controlText={props.question.overlayLink}
          id="something-extra-examples"
          data-testid="something-extra-examples"
          title={props.question.overlayLink}
          transition="fade"
        >
          <ExamplesDialogContent />
        </DialogControl>
      )}
    </>
  )
}
