import { Box, Typography } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { HowLongShouldIGetCoverFor, LifeCoverAmountRightForMe, LifeCoverRightForYou } from '../Shared'
import { styles } from './styles'
import { PageFocus } from '../../Shared/PageFocus'

export const LifeCoverInfo = () => {
  const classes = styles()
  return (
    <>
      <Box data-testid="life-cover-question-container">
        <PageFocus>
          <Typography variant="h4" component="h1" className={classes.heading} data-testid="life-cover-question">
            What is Life Cover?
          </Typography>
        </PageFocus>
        <Typography variant="body1" data-testid="life-cover-question-answer">
          Life Cover is a life insurance policy. It pays out a cash lump sum if you die before your policy ends.
        </Typography>
      </Box>
      <Box className={classes.container} data-testid="life-cover-amount-question-container">
        <Typography variant="h4" component="h1" className={classes.heading} data-testid="life-cover-amount-question">
          What amount is right for me?
        </Typography>
        <LifeCoverAmountRightForMe />
      </Box>
      <Box className={classes.container} data-testid="life-cover-for-question-container">
        <Typography variant="h4" component="h1" className={classes.heading} data-testid="life-cover-for-question">
          How long should I get cover for?
        </Typography>
        <HowLongShouldIGetCoverFor />
      </Box>

      <Box className={classes.container} data-testid="need-life-cover-question-container">
        <Typography variant="h4" component="h1" className={classes.heading} data-testid="need-life-cover-question">
          Do I need Life Cover?
        </Typography>
        <LifeCoverRightForYou />
      </Box>
    </>
  )
}
