import React, { FC, useState } from 'react'
import { FeatureFlagContext } from './featureFlagContext'
import { FeatureFlagProviderProps } from './types'

const FeatureFlagProvider: FC<FeatureFlagProviderProps> = ({ featureFlagConfig, children }) => {
  const [featureFlags, setFeatureFlags] = useState(featureFlagConfig)

  const toggleFeature = (featureName: string, isEnabled: boolean) => {
    const next = { ...featureFlags, [featureName]: !isEnabled }
    setFeatureFlags(next)
  }

  return (
    <FeatureFlagContext.Provider
      value={{
        featureFlags,
        toggleFeature,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}

export { FeatureFlagProvider }
