import React from 'react'
import { ButtonV2 as Button, Brand, MonocerosTheme, useTheme } from '@lbg-protection/lib-ui-monoceros'
import { useJourneyNavMap } from '../../../../journeyNavMap'
import { useNavigateTo } from '../../../../utils/useNavigateTo'
import { useJourneyChecker } from '../../../../journeyCwaConfig'
import { useFeatureFlags } from '../../../../featureFlags'
import { useVoucherAmount } from '../../../Shared/VoucherIncentiveTiered/useVoucherAmount'
import { tagCover } from './tags'
import { GotoPaymentButtonProps } from './types'
import { isAnyCoverInBasket, isAnyCoverWithoutPriceNotInBasket, isAnyCoverWithPriceNotInBasket } from './utils'
import { tagValidationError } from '../../../../utils/tagUtils/tagsUtils'
import { isSW } from '../../../../utils/brandSpecificFormatting'

export const GoToPaymentButton = (props: GotoPaymentButtonProps) => {
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)
  const { lifeCover, bodyCover, setValidationError } = props
  const navigateTo = useNavigateTo()
  const targetNav = useJourneyNavMap().getTargetNav('quote', 'payment-details')
  const { isJourneyUnAuth } = useJourneyChecker()

  const { isFeatureEnabled } = useFeatureFlags()
  const isIncentiveTiered = isFeatureEnabled('incentiveTiered')
  const voucherAmount = useVoucherAmount()

  const tagCoversInBasket = () => {
    const voucherAmountForTag = isIncentiveTiered ? voucherAmount : undefined

    if (lifeCover.inBasket) tagCover(lifeCover, voucherAmountForTag)
    if (bodyCover.inBasket) tagCover(bodyCover, voucherAmountForTag)
  }

  const handleContinue = () => {
    if (isAnyCoverInBasket(lifeCover, bodyCover)) {
      tagCoversInBasket()
      navigateTo(targetNav)
      if (props.saveJourney && !isJourneyUnAuth) {
        props.saveJourney({ isAutoSave: true })
      }
    } else if (isAnyCoverWithPriceNotInBasket(lifeCover, bodyCover)) {
      window.scrollTo(0, 0)
      if (setValidationError) {
        tagValidationError('To continue to payment please add a cover option')
        setValidationError('To continue to payment, please add a cover option.')
      }
    } else if (isAnyCoverWithoutPriceNotInBasket(lifeCover, bodyCover)) {
      const errorMessage = isSWBrand
        ? 'To continue to payment, please add a cover option.'
        : 'To get a quote, please enter the cover amount and duration.'
      window.scrollTo(0, 0)
      if (setValidationError) setValidationError(errorMessage)
    }
  }

  return (
    <Button data-testid="goto-payment-details" variant="contained" color="primary" onClick={handleContinue}>
      Continue
    </Button>
  )
}
