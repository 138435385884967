import React from 'react'
import { Box, makeStyles } from '@lbg-protection/lib-ui-monoceros'

const styles = makeStyles((theme) => {
  return {
    image: {
      marginTop: theme.spacing(3),
      display: 'block',
      margin: '0 auto',
      maxWidth: '100%',
    },
    imageContainer: {
      display: 'flex',
      justifyItems: 'center',
      alignItems: 'center',
    },
  }
})

export interface VoucherImageTieredProps {
  alt: string
  src: string
  arialabel: string
}

export const VoucherImageTiered = ({ alt, src, arialabel }: VoucherImageTieredProps) => {
  const classes = styles({})

  return (
    <Box className={classes.imageContainer}>
      <img className={classes.image} alt={alt} src={src} data-testid="voucher-image" aria-label={arialabel} />
    </Box>
  )
}
