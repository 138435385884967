import React from 'react'
import { ButtonV2 as Button, Brand, makeStyles, useTheme, MonocerosTheme } from '@lbg-protection/lib-ui-monoceros'
import { isLloyds, isSW } from '../../../utils/brandSpecificFormatting'
import { BottomNavigation } from '../../Shared/BottomNavigation'
import ConnectedSaveJourneyWrapper from '../../SaveAndRetrieve/save/SaveJourneyWrapper'
import { ConnectedDynamicQuotePdfWrapper } from '../QuotePdfPage/DynamicQuotePdfWrapper'
import { hasMandatoryQuoteData } from '../utils'
import { GoToPaymentButton } from './GoToPaymentButton'
import { PreviousButton } from './PreviousButton'
import { QuotePageFooterProps } from './types'
import { useJourneyChecker } from '../../../journeyCwaConfig'
import { tagButtonClick } from '../../../utils/tagUtils/tagsUtils'

const styles = makeStyles(() => ({
  left: {
    justifyContent: 'left',
  },
}))

export const QuotePageFooter = (props: QuotePageFooterProps) => {
  const theme = useTheme<MonocerosTheme>()
  const transparentBackground = !!isLloyds(theme.name as Brand)

  return (
    <BottomNavigation
      userDefinedEndActions={<EndActions {...props} />}
      userDefinedStartActions={<StartActions removeFromBasket={props.removeFromBasket} />}
      transparentBackground={transparentBackground}
    />
  )
}

const EndActions = (props: QuotePageFooterProps) => {
  const { isJourneyUnAuth } = useJourneyChecker()
  const { name: brand = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isSwBrand = isSW(brand)

  const classes = styles()

  return (
    <>
      {!isJourneyUnAuth && !isSwBrand && (
        <ConnectedDynamicQuotePdfWrapper>
          <Button
            className={classes.left}
            variant="text"
            color="primary"
            disabled={!hasMandatoryQuoteData(props)}
            data-testid="view-full-quote-button"
            onClick={() => tagButtonClick('View full quote')}
          >
            View full quote
          </Button>
        </ConnectedDynamicQuotePdfWrapper>
      )}
      {!isJourneyUnAuth && isSwBrand && (
        <ConnectedDynamicQuotePdfWrapper>
          <Button
            variant="outlined"
            color="primary"
            disabled={!hasMandatoryQuoteData(props)}
            data-testid="view-full-quote-button"
            onClick={() => tagButtonClick('View full quote')}
          >
            View Full Quote
          </Button>
        </ConnectedDynamicQuotePdfWrapper>
      )}
      <GoToPaymentButton
        lifeCover={props.lifeCover}
        bodyCover={props.bodyCover}
        setValidationError={props.setValidationError}
        saveJourney={props.saveJourney}
      />
      {!isJourneyUnAuth && <ConnectedSaveJourneyWrapper currentPage="QUOTE" />}
    </>
  )
}

interface StartActionsProps {
  removeFromBasket: QuotePageFooterProps['removeFromBasket']
}

const StartActions: React.FC<StartActionsProps> = (props) => {
  return <PreviousButton removeFromBasket={props.removeFromBasket} />
}
