import { Brand, MonocerosTheme, makeStyles } from '@lbg-protection/lib-ui-monoceros'
import { isSW } from '../../../utils/brandSpecificFormatting'

export const styles = makeStyles((theme: MonocerosTheme) => {
  const isSWBrand = isSW(theme.name as Brand)
  return {
    container: {
      zIndex: 1200,
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(5),
      ...(isSWBrand && { backgroundColor: theme.palette.namedColours?.orionMellowGrey }),
      ...(!isSWBrand && { backgroundColor: theme.palette.common.white }),
    },
    swBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      ...(isSWBrand && { alignItems: 'start' }),
      ...(!isSWBrand && { alignItems: 'center' }),
    },
    icon: { marginBottom: theme.spacing(3), ...(isSWBrand && { alignSelf: 'start' }) },
    subHeader: {
      fontSize: theme.spacing(3),
      textAlign: isSWBrand ? 'start' : 'center',
      marginBottom: theme.spacing(3),
      ...(!isSWBrand && { fontWeight: theme.typography.fontWeightBold }),
    },
    text: {
      marginBottom: theme.spacing(3),
      textAlign: isSWBrand ? 'start' : 'center',
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      ...(isSWBrand && {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
        alignItems: 'start',
      }),
    },
  }
})
