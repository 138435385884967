import React from 'react'
import { Box, Typography } from '@lbg-protection/lib-ui-monoceros'
import { PhoneNumberLink } from '../../Shared/PhoneNumberLink/PhoneNumberLink'
import { tagButtonClick } from '../../../utils/tagUtils/tagsUtils'

export const NeedAdviceLBIS = () => {
  return (
    <Box>
      <Typography gutterBottom>
        If you&apos;d like advice, then contact our protection experts. They can help find cover that&apos;s right for
        you and your loved ones.
      </Typography>
      <Typography gutterBottom>
        Our protection experts can advise you on Scottish Widows protection policies. They won&apos;t charge you for
        their advice, and you&apos;re under no obligation when you speak to them.
      </Typography>
      <Typography gutterBottom>Call our protection experts on:</Typography>
      <PhoneNumberLink
        gutterBottom
        department="callback"
        data-testid="lbis-online-telephone"
        onClick={() => tagButtonClick('LBIS callback number')}
      />
      <Typography gutterBottom>Lines are open Monday to Thursday 9am to 7pm, Friday 9am to 6pm.</Typography>
      <Typography>
        Lloyds Bank Insurance Services Limited provides this service, which is also part of Lloyds Banking Group.
      </Typography>
    </Box>
  )
}
