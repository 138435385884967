/* eslint no-plusplus:0 */
import { CoverSummaryInterface, PricingCallStatus } from './types'

export const calculateSum = (lifeCover: CoverSummaryInterface, bodyCover: CoverSummaryInterface): number => {
  const coverSummaries = []
  if (lifeCover?.amount > 0) coverSummaries.push(lifeCover)
  if (bodyCover?.amount > 0) coverSummaries.push(bodyCover)
  return coverSummaries.reduce((accumulator, coverSummary) => accumulator + Number(coverSummary.monthlyCost), 0)
}

export const isPricingCallPending = (
  lifeCoverPricingCallStatus: PricingCallStatus,
  bodyCoverPricingCallStatus: PricingCallStatus,
): boolean => {
  return (
    lifeCoverPricingCallStatus === PricingCallStatus.Pending || bodyCoverPricingCallStatus === PricingCallStatus.Pending
  )
}

export const wasPricingCallRejected = (
  lifeCoverPricingCallStatus: PricingCallStatus,
  bodyCoverPricingCallStatus: PricingCallStatus,
): boolean => {
  return (
    lifeCoverPricingCallStatus === PricingCallStatus.Error || bodyCoverPricingCallStatus === PricingCallStatus.Error
  )
}
