/* eslint no-plusplus:0 */
import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import dayjs from 'dayjs'
import { CoverChoiceInterface, CoverSelectedEnum, PrePurchaseJourneyRouteEnum } from '../../journeys/purchase/types'
import { CoverSummaryInterface } from '../Shared/Basket/CoverSummary/types'
import { PricingCallStatus, UnderwriteOutcomeArgs } from './types'
import { QuotePageFooterProps } from './QuotePageFooter/types'
import { QuotePdfProps } from './QuotePdfPage/types'

export const isEligible = (eligibility: string | number | boolean): boolean => {
  if (typeof eligibility === 'boolean') {
    return eligibility
  }
  if (eligibility === 'ineligible') return false
  return true
}

export const countCoversInBasket = (lifeCover: CoverSummaryInterface, bodyCover: CoverSummaryInterface): number => {
  let quotesInBasket = 0
  if (lifeCover?.inBasket) quotesInBasket++
  if (bodyCover?.inBasket) quotesInBasket++
  return quotesInBasket
}

export const calculateSum = (lifeCover: CoverSummaryInterface, bodyCover: CoverSummaryInterface): number => {
  const coverSummaries = []
  if (lifeCover?.inBasket) coverSummaries.push(lifeCover)
  if (bodyCover?.inBasket) coverSummaries.push(bodyCover)
  return coverSummaries.reduce((accumulator, coverSummary) => accumulator + Number(coverSummary.monthlyCost), 0)
}

export const isPrePurchase = (userRoute: PrePurchaseJourneyRouteEnum | null): boolean => {
  return userRoute === PrePurchaseJourneyRouteEnum.PathFinder
}

type InitializeCover = (arg0: CoverSummaryInterface, arg1: CoverChoiceInterface | null) => CoverSummaryInterface
export const initializeCover: InitializeCover = (cover, prePurchaseCoverChoice) => {
  const coverTypeToUpdate =
    cover.coverType === PricingRequestBody.CoverTypeEnum.LIFE ? CoverSelectedEnum.Life : CoverSelectedEnum.Body
  const prePurchaseCover =
    cover.coverType === PricingRequestBody.CoverTypeEnum.LIFE
      ? prePurchaseCoverChoice?.life
      : prePurchaseCoverChoice?.body

  if (
    (prePurchaseCoverChoice?.coverSelected === CoverSelectedEnum.All ||
      prePurchaseCoverChoice?.coverSelected === coverTypeToUpdate) &&
    prePurchaseCover
  ) {
    return {
      ...cover,
      amount: !cover.amount ? prePurchaseCover?.sumAssured : cover.amount,
      term: !cover.term ? prePurchaseCover?.term : cover.term,
    }
  }
  return cover
}

export const transformSmokerStatusForPricing = (smokerStatus: boolean): string => {
  return smokerStatus ? '6' : '1'
}

export const transformUwOutcomeForPricing = (uwOutcome: string | number): number => {
  return typeof uwOutcome === 'number' ? uwOutcome : 0
}

export const transformDobForPricing = (dob: Date): string => {
  return dayjs(dob).format('YYYY-MM-DD')
}

export const isPricingCallPending = (
  lifeCoverPricingCallStatus: PricingCallStatus,
  bodyCoverPricingCallStatus: PricingCallStatus,
): boolean => {
  return (
    lifeCoverPricingCallStatus === PricingCallStatus.Pending || bodyCoverPricingCallStatus === PricingCallStatus.Pending
  )
}

export const wasPricingCallRejected = (
  lifeCoverPricingCallStatus: PricingCallStatus,
  bodyCoverPricingCallStatus: PricingCallStatus,
): boolean => {
  return (
    lifeCoverPricingCallStatus === PricingCallStatus.Error || bodyCoverPricingCallStatus === PricingCallStatus.Error
  )
}

const hasCoverData = (coverValue: CoverSummaryInterface): boolean =>
  coverValue.inBasket &&
  coverValue.coverType !== undefined &&
  coverValue.amount !== undefined &&
  coverValue.monthlyCost !== undefined &&
  coverValue.term !== undefined

export const hasMandatoryQuoteData = (propsValue: QuotePageFooterProps | QuotePdfProps): boolean =>
  propsValue !== null &&
  propsValue.firstName !== undefined &&
  propsValue.lastName !== undefined &&
  propsValue.dob !== undefined &&
  propsValue.total > 0 &&
  (hasCoverData(propsValue.lifeCover) || hasCoverData(propsValue.bodyCover))

export const getCoverOutcome = ({
  isUnderwriteMe,
  underwriteMeCoverOutcome,
  underwritingCoverOutcome,
}: UnderwriteOutcomeArgs) => {
  if (isUnderwriteMe) {
    return underwriteMeCoverOutcome !== undefined ? underwriteMeCoverOutcome : 'ineligible'
  }
  return underwritingCoverOutcome !== undefined ? underwritingCoverOutcome : 'ineligible'
}
