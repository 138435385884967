import { CoverSummaryInterface } from '../../../Shared/Basket/CoverSummary/types'

export const isAnyCoverInBasket = (lifeCover: CoverSummaryInterface, bodyCover: CoverSummaryInterface) => {
  if (lifeCover.inBasket || bodyCover.inBasket) return true
  return false
}

export const isAnyCoverWithPriceNotInBasket = (lifeCover: CoverSummaryInterface, bodyCover: CoverSummaryInterface) => {
  if (
    (lifeCover.amount && lifeCover.term && lifeCover.monthlyCost && !lifeCover.inBasket) ||
    (bodyCover.amount && bodyCover.term && bodyCover.monthlyCost && !bodyCover.inBasket)
  )
    return true
  return false
}

export const isAnyCoverWithoutPriceNotInBasket = (
  lifeCover: CoverSummaryInterface,
  bodyCover: CoverSummaryInterface,
) => {
  if (
    ((!lifeCover.amount || !lifeCover.term || !lifeCover.monthlyCost) && !lifeCover.inBasket) ||
    ((!bodyCover.amount || !bodyCover.term || !bodyCover.monthlyCost) && !bodyCover.inBasket)
  )
    return true
  return false
}
