import React from 'react'
import {
  Box,
  Card,
  makeStyles,
  Typography,
  MonocerosTheme,
  Brand,
  Accordion,
  ListWithBullets,
  ListItemWithBullet,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import clsx from 'clsx'
import { brandHasGutter, isBOS, isHalifax, isSW } from '../../../utils/brandSpecificFormatting'
import { PhoneNumberLink } from '../PhoneNumberLink/PhoneNumberLink'
import { ExternalLink } from '../ExternalLink/ExternalLink'
import { tagButtonClick, tagAccordionClick } from '../../../utils/tagUtils/tagsUtils'

const styles = makeStyles((theme: MonocerosTheme) => {
  const isScottish = isSW(theme.name as Brand)
  const spacingValue = brandHasGutter(theme.name)
  const defaultCardSpacing = isScottish
    ? `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(5)} ${theme.spacing(2)}`
    : `${theme.spacing(2)} ${spacingValue ? theme.spacing(spacingValue) : 0}`
  const extraCardSpacing = `${theme.spacing(4)} 0 ${theme.spacing(8)} 0`
  return {
    marginTop: {
      marginTop: isScottish ? theme.spacing(2) : 0,
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    numberContainer: {
      margin: `${theme.spacing(3)} 0`,
    },
    card: {
      backgroundColor: theme.palette.secondary.light,
      margin: isBOS(theme.name as Brand) ? extraCardSpacing : defaultCardSpacing,
      padding: isScottish ? `0 ${theme.spacing(2)} 1.25rem` : `${theme.spacing(4)} ${theme.spacing(3)}`,
    },
    panelBackgroud: {
      backgroundColor: theme.palette.secondary.light,
      margin: `0 -${theme.spacing(3)} !important`,
    },
    phoneNumber: {
      '& .MuiTypography-caption': {
        fontFamily: 'UnitRoundedW01-Medium',
        margin: '0.75rem 0',
      },
    },
  }
})

export const PhoneCard = () => {
  const { name: brand = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isScottish = isSW(brand as Brand)
  const classes = styles({})

  return (
    <Card className={classes.card}>
      <Typography className={clsx(classes.marginBottom, classes.marginTop)} variant="h4">
        Need some help?
      </Typography>
      <Typography>If you need more info, give us a call. We&apos;re happy to help.</Typography>
      <Box display="flex" alignItems="center" className={classes.numberContainer}>
        <PhoneNumberLink
          onClick={() => tagButtonClick('Model office phone number')}
          data-testid="phone-card-link"
          department="modelOffice"
        />
      </Box>
      <Typography gutterBottom>Lines are open 9am to 5pm, Monday to Friday{isScottish && `.`}</Typography>
      <Typography gutterBottom>
        Calls are recorded to help us improve our service. Call costs may vary depending on your provider.
      </Typography>
      <Accordion
        className={clsx(classes.panelBackgroud)}
        panelId="accessibility-and-support-accordion"
        summary="Accessibility and support"
        onChange={(_event: React.ChangeEvent<{}>, expanded: boolean) => {
          if (expanded) tagAccordionClick('Accessibility and Support')
        }}
      >
        <Box>
          <Typography gutterBottom>
            We&apos;re here to provide the support you need,{' '}
            {isScottish
              ? 'to make things easier for you. That can be how we speak to you on the phone, such as talking slowly and clearly.'
              : 'so let us know how we can make things easier for you. Here are some ways we can help:'}
          </Typography>
          {!isScottish && (
            <>
              <ListWithBullets className={classes.marginBottom}>
                <ListItemWithBullet>
                  {' '}
                  How we speak to you on the phone, such as talking slowly and clearly.
                </ListItemWithBullet>
                <ListItemWithBullet>
                  {' '}
                  How we support you in branch, such as better access or quieter appointment spaces.
                </ListItemWithBullet>
              </ListWithBullets>
              <Typography gutterBottom>
                You can update your support options at any time in your{' '}
                {isHalifax(brand) ? 'Online Banking' : 'Internet Banking'} settings. Or just talk to us in branch or
                over the phone. We&apos;re {isScottish ? 'here' : 'happy'} to help.
              </Typography>
              <Typography gutterBottom>
                For other types of help related to your financial wellbeing, mental health and more visit our{' '}
                <ExternalLink
                  externalLinkName="customerSupport"
                  externalLinkText="customer support page"
                  onClick={() => tagButtonClick('Customer Support Page')}
                />
                .
              </Typography>
            </>
          )}
          {isScottish && (
            <Typography gutterBottom>
              Let us know how we can help. Get in touch with us by visiting our{' '}
              <ExternalLink
                externalLinkName="customerSupport"
                externalLinkText="Accessibility page"
                onClick={() => tagButtonClick('Accessibility page')}
              />
              .
            </Typography>
          )}
        </Box>
      </Accordion>
      <Accordion
        className={clsx(classes.panelBackgroud)}
        panelId="help-with-money-worries"
        summary="Help with money worries"
        onChange={(_event: React.ChangeEvent<{}>, expanded: boolean) => {
          if (expanded) tagAccordionClick('Help with money worries')
        }}
      >
        <Box>
          <Typography gutterBottom>
            If you are struggling with your finances, or worried about the increased cost of living, you are not alone.
            We are here to help you get back on track.{' '}
            {!isScottish && (
              <>
                Visit our{' '}
                <ExternalLink
                  externalLinkName="moneyWorries"
                  externalLinkText="money worries page"
                  onClick={() => tagButtonClick('Money worries page')}
                />{' '}
                for further information.
              </>
            )}
          </Typography>
          {isScottish && (
            <Typography>
              Visit our{' '}
              <ExternalLink
                externalLinkName="moneyWorries"
                externalLinkText="Be Money Well pages"
                onClick={() => tagButtonClick('Be Money Well pages')}
              />{' '}
              for further information.
            </Typography>
          )}
        </Box>
      </Accordion>
    </Card>
  )
}
