import { createSelector, createSlice, PayloadAction, Slice } from '@reduxjs/toolkit'
import { Answer, QuestionaireState, SelectAnswer, SelectAnswers, SelectCurrentQuestion, SelectQuestion } from './types'

// Selectors
export const selectQuestion: SelectQuestion = (questions) =>
  createSelector(
    (state) => state.currentQuestion,
    (currentQuestion) => questions.find((q) => q.id === currentQuestion) || null,
  )

export const selectCurrentQuestion: SelectCurrentQuestion = createSelector(
  (state) => state.currentQuestion,
  (currentQuestion) => currentQuestion,
)

export const selectAnswers: SelectAnswers = createSelector(
  (state) => state.answers,
  (answers) => answers,
)

export const selectAnswer: SelectAnswer = createSelector(
  (state) => state.answers,
  (state) => selectCurrentQuestion(state),
  (answers: Record<string, Answer>, currentQuestion: string) => (currentQuestion && answers[currentQuestion]) || null,
)

// Reducer
const initialState: QuestionaireState = {
  answers: {},
  currentQuestion: '1',
}

export type Reducers = {
  submitAnswer: (state: QuestionaireState, action: PayloadAction<Answer>) => QuestionaireState
  gotoQuestion: (state: QuestionaireState, action: PayloadAction<string>) => QuestionaireState
  setFirstQuestion: (state: QuestionaireState, action: PayloadAction<string>) => QuestionaireState
}
const reducers: Reducers = {
  submitAnswer: (state, action) => {
    const newAnswer = action.payload
    const questionId = newAnswer ? newAnswer.questionId : state.currentQuestion
    // eslint-disable-next-line no-param-reassign
    state.answers[String(questionId)] = newAnswer
    return state
  },
  gotoQuestion: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.currentQuestion = action.payload
    return state
  },
  setFirstQuestion: (state, action) => {
    const firstQuestion = action.payload
    return {
      ...state,
      currentQuestion: firstQuestion,
    }
  },
}

type QSlice = Slice<QuestionaireState, Reducers, string>
export const createQuestionaireSlice = (name: string, firstQuestion: string): QSlice => {
  const init = { ...initialState, currentQuestion: firstQuestion }

  return createSlice({
    name,
    initialState: init,
    reducers,
  })
}
