import { Brand } from '@lbg-protection/lib-ui-monoceros'
import { isBOS, isLloyds, isSW } from '../../../utils/brandSpecificFormatting'
import { externalLinks } from './externalLinksData'
import { ExternalLinkNames } from './types'

export const getExternalLinkUrl = (brand: Brand, linkName: ExternalLinkNames): string => {
  if (isLloyds(brand)) return externalLinks.lloyds[linkName]
  if (isBOS(brand)) return externalLinks.bos[linkName]
  if (isSW(brand)) return externalLinks.sw[linkName]

  return externalLinks.halifax[linkName]
}
