import { useAppSelector } from '../../../app/hooks'
import {
  selectBodyCover as selectIndicativeBodyCover,
  selectLifeCover as selectIndicativeLifeCover,
} from '../../IndicativeQuotePage/indicativeQuote.slice'
import { selectBodyCover, selectLifeCover } from '../../QuotePage/quotes.slice'
import { calculateVoucherAmount, getHighestPremiumAmount } from './utils'

export const useVoucherAmount = (quoteType: 'indicative' | 'quote' = 'quote') => {
  const indicativeLifeCover = useAppSelector((state) => selectIndicativeLifeCover(state.indicativeQuotes))
  const indicativeBodyCover = useAppSelector((state) => selectIndicativeBodyCover(state.indicativeQuotes))
  const lifeCover = useAppSelector((state) => selectLifeCover(state.quotes))
  const bodyCover = useAppSelector((state) => selectBodyCover(state.quotes))
  const highestPremiumAmount =
    quoteType === 'indicative'
      ? getHighestPremiumAmount(indicativeLifeCover, indicativeBodyCover)
      : getHighestPremiumAmount(lifeCover, bodyCover)
  const giftAmount = calculateVoucherAmount(highestPremiumAmount ?? 0)

  return giftAmount
}
