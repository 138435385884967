import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import { createAsyncThunk, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import {
  CoverSummaryInterface,
  PricingCallStatus,
  PricingFulfilledActionProps,
  IndicativeQuoteState,
  UpdateActionProps,
} from './types'
import { pricingApi } from '../QuotePage/pricingApi'

export const initialState = {
  tagging: { numberOfLifeCoverPricingCalls: 0, numberOfBodyCoverPricingCalls: 0 },
  lifeCoverPricingCallStatus: PricingCallStatus.Idle,
  bodyCoverPricingCallStatus: PricingCallStatus.Idle,
  lifeCover: {
    coverType: PricingRequestBody.CoverTypeEnum.LIFE,
    title: 'Life Cover',
    amount: 0,
    term: 0,
    monthlyCost: 0,
  },
  bodyCover: {
    coverType: PricingRequestBody.CoverTypeEnum.BODY,
    title: 'Body Cover',
    amount: 0,
    term: 0,
    monthlyCost: 0,
  },
}

// Selectors
type SelectCover = Selector<IndicativeQuoteState, CoverSummaryInterface>
type SelectPricingCallStatus = Selector<IndicativeQuoteState, PricingCallStatus>
type SelectNumberOfPricingCalls = Selector<IndicativeQuoteState, number>
type SelectValidationError = Selector<IndicativeQuoteState, string | undefined>

export const selectLifeCover: SelectCover = (state) => state.lifeCover

export const selectBodyCover: SelectCover = (state) => state.bodyCover

export const selectLifeCoverPricingCallStatus: SelectPricingCallStatus = (state) => state.lifeCoverPricingCallStatus

export const selectBodyCoverPricingCallStatus: SelectPricingCallStatus = (state) => state.bodyCoverPricingCallStatus

export const selectNumberOfLifeCoverPricingCalls: SelectNumberOfPricingCalls = (state) =>
  state.tagging.numberOfLifeCoverPricingCalls

export const selectNumberOfBodyCoverPricingCalls: SelectNumberOfPricingCalls = (state) =>
  state.tagging.numberOfBodyCoverPricingCalls

export const selectValidationError: SelectValidationError = (state) => state.validationError

export const getPricingAnonymousIndicativeQuote = createAsyncThunk(
  'indicativeQuote/pricing',
  pricingApi.getPricingAnonymousIndicativeQuote,
)

export const indicativeQuoteSlice = createSlice({
  name: 'indicativeQuote',
  initialState,
  reducers: {
    update(state: IndicativeQuoteState, action: PayloadAction<UpdateActionProps>): IndicativeQuoteState {
      const { coverType, title, amount, term, monthlyCost } = action.payload
      const updatedCover = { coverType, title, amount, term, monthlyCost }
      if (coverType === PricingRequestBody.CoverTypeEnum.LIFE)
        return { ...state, lifeCover: updatedCover, validationError: '' }
      if (coverType === PricingRequestBody.CoverTypeEnum.BODY)
        return { ...state, bodyCover: updatedCover, validationError: '' }
      return { ...state }
    },
    setValidationError(state: IndicativeQuoteState, action: PayloadAction<string>): IndicativeQuoteState {
      const validationError = action.payload
      return { ...state, validationError }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getPricingAnonymousIndicativeQuote.pending,
      (state: IndicativeQuoteState, action): IndicativeQuoteState => {
        const { coverType } = action.meta.arg
        if (coverType === PricingRequestBody.CoverTypeEnum.LIFE)
          return {
            ...state,
            lifeCoverPricingCallStatus: PricingCallStatus.Pending,
          }
        if (coverType === PricingRequestBody.CoverTypeEnum.BODY)
          return {
            ...state,
            bodyCoverPricingCallStatus: PricingCallStatus.Pending,
          }
        return { ...state }
      },
    )
    builder.addCase(
      getPricingAnonymousIndicativeQuote.fulfilled,
      (state: IndicativeQuoteState, action: PayloadAction<PricingFulfilledActionProps>): IndicativeQuoteState => {
        const { coverType } = action.payload
        if (coverType === PricingRequestBody.CoverTypeEnum.LIFE)
          return {
            ...state,
            lifeCover: action.payload,
            lifeCoverPricingCallStatus: PricingCallStatus.Success,
            tagging: {
              ...state.tagging,
              numberOfLifeCoverPricingCalls: state.tagging.numberOfLifeCoverPricingCalls + 1,
            },
          }
        if (coverType === PricingRequestBody.CoverTypeEnum.BODY)
          return {
            ...state,
            bodyCover: action.payload,
            bodyCoverPricingCallStatus: PricingCallStatus.Success,
            tagging: {
              ...state.tagging,
              numberOfBodyCoverPricingCalls: state.tagging.numberOfBodyCoverPricingCalls + 1,
            },
          }
        return { ...state }
      },
    )
    builder.addCase(
      getPricingAnonymousIndicativeQuote.rejected,
      (state: IndicativeQuoteState, action): IndicativeQuoteState => {
        const { coverType } = action.meta.arg
        if (coverType === PricingRequestBody.CoverTypeEnum.LIFE)
          return { ...state, lifeCoverPricingCallStatus: PricingCallStatus.Error }
        if (coverType === PricingRequestBody.CoverTypeEnum.BODY)
          return { ...state, bodyCoverPricingCallStatus: PricingCallStatus.Error }
        return { ...state }
      },
    )
  },
})

export const { update, setValidationError } = indicativeQuoteSlice.actions
