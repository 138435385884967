export enum QuestionTypes {
  IconCheckbox = 'iconCheckbox',
  HomePayment = 'homePayment',
  Finances = 'financesQuestion',
  FamilyHistoryQuestion = 'FamilyHistoryQuestion',
  MultipleChoice = 'multipleChoice',
  ChildrenAge = 'childrenAge',
  SomethingExtra = 'somethingExtra',
  AwaitingInvestigationsQuestion = 'AwaitingInvestigationsQuestion',
  Treatment = 'treatment',
  Term = 'term',
}

export interface OptionInterface {
  id: string
  option: string
  outcome?: string | number
  exclusive?: boolean
  testId?: string
}

export interface Overlay {
  id: string
  link: string
  content: JSX.Element
}
