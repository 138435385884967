export const VoucherDefaultData = {
  policyDurationBullet: `You purchase a new Plan & Protect Life or Critical Illness (Body Cover) policy online through [Lloyds Bank, Halifax or Bank of Scotland] after the 28th April 2023.`,
  giftCardValue: '£100',
  giftCardImage: {
    alt: 'voucher',
    src: './assets/Onecode.png',
    arialabel: '£100 gift card voucher image',
  },
  voucherDescription: {
    title: 'Get a £100  gift card when you buy a policy online',
    bodyText1: "When you've done that, you'll be able to choose a gift card from over 140 retailers.",
  },
  voucherStartAlert: {
    title: 'The £100  gift card offer just started.',
    message: 'You will receive  gift card of your choice if you apply for this policy.',
  },

  voucherEndAlertTitle: 'The voucher offer that was available when you started your application has now ended.',
}
