import React from 'react'
import {
  Box,
  Card,
  Icon,
  IconProps,
  makeStyles,
  MonocerosTheme,
  ProgressBar,
  Typography,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import { getPadding } from '../../../styles'
import { ScreenTemplate } from '../ScreenTemplate'

const styles = makeStyles((theme: MonocerosTheme) => {
  const padding = getPadding(theme)
  return {
    cardContainer: {
      display: 'flex',
    },
    card: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: `${theme.spacing(2)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
      marginBottom: '0',
      border: 'none',
      flex: 1,
      backgroundColor: theme.palette.namedColours?.lloydsMellowGrey,
    },
    title: {
      ...padding.standard.vertical,
      width: '60%',
      textAlign: 'center',
      color: '#FFFFFF',
    },
    textContainer: { paddingRight: theme.spacing(1) },
    progressContainer: { paddingBottom: theme.spacing(2), paddingTop: theme.spacing(2) },
  }
})
interface Props {
  title?: string
  children?: React.ReactNode
  FooterNav?: React.ReactNode
  progress?: number
  showProgressBar?: boolean
  progressBarColor?: 'primary' | 'secondary'
  progressBarIconName?: IconProps['name']
  progressBarText?: string
}

export const UnderWritingTemplate = ({
  children,
  FooterNav,
  progress,
  showProgressBar,
  progressBarColor,
  progressBarIconName,
  progressBarText,
}: Props) => {
  const classes = styles()
  const theme = useTheme<MonocerosTheme>()
  const iconColor =
    theme.name === 'lloyds'
      ? theme.palette.namedColours?.lloydsAccessibleGrey
      : theme.palette.namedColours?.halifaxDarkGrey

  return (
    <ScreenTemplate dataTestId="uwTemplate" bottomNav={FooterNav}>
      <Box className={classes.cardContainer}>
        <Card variant="outlined" className={classes.card}>
          {showProgressBar && (
            <>
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                <Typography className={classes.textContainer} data-testid="your-progress">
                  {progressBarText}
                </Typography>
                <Icon name={progressBarIconName} size="1.5rem" htmlColor={iconColor} />
              </Box>
              <Box className={classes.progressContainer}>
                <ProgressBar
                  aria-label="Progress bar"
                  value={progress || 0}
                  color={progressBarColor}
                  data-testid="progress-bar"
                />
              </Box>
            </>
          )}
          <Box data-testid="question-component">{children}</Box>
        </Card>
      </Box>
    </ScreenTemplate>
  )
}

UnderWritingTemplate.defaultProps = {
  showProgressBar: true,
  progressBarColor: 'primary',
  progressBarIconName: 'apply',
  progressBarText: 'Your quote',
}
