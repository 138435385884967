import { MonocerosTheme } from '@lbg-protection/lib-ui-monoceros'

type Padding = {
  standard: {
    horizontal: Record<string, number>
    vertical: Record<string, number>
    top: Record<string, number>
    bottom: Record<string, number>
  }
}

export const getPadding = (theme: MonocerosTheme): Padding => ({
  standard: {
    horizontal: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    vertical: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    top: {
      paddingTop: theme.spacing(5),
    },
    bottom: {
      paddingBottom: theme.spacing(5),
    },
  },
})

type Margin = {
  standard: {
    divider: Record<string, number>
    bottom: Record<string, number>
  }
}
export const getMargin = (theme: MonocerosTheme): Margin => ({
  standard: {
    divider: {
      margin: theme.spacing(2),
    },
    bottom: {
      marginBottom: theme.spacing(2),
    },
  },
})

type Spacing = {
  card: Record<string, number>
}
export const getSpacing = (theme: MonocerosTheme): Spacing => ({
  card: {
    width: theme.spacing(75),
    height: theme.spacing(0),
    paddingLeft: theme.spacing(8),
  },
})
