import { tagButtonClick, tagExternalLink } from '../../utils/tagUtils/tagsUtils'

export const tagButtonEvent = (EventNarrative: string): void => {
  tagButtonClick(EventNarrative)
}

export const tagOverlayClick = (EventNarrative: string): void => {
  tagExternalLink(EventNarrative)
}

export const tagTsAndCsClick = () => {
  tagButtonClick('View terms and conditions')
}
