import React from 'react'
import { useHistory } from 'react-router-dom'
import { ButtonV2 as Button, Icon } from '@lbg-protection/lib-ui-monoceros'
import { tagButtonEvent } from '../../tags'

export const PreviousButton = () => {
  const history = useHistory()

  return (
    <Button
      variant="text"
      color="primary"
      data-testid="indicative-quote-page-bottom-navigation-previous-button"
      startIcon={<Icon name="chevron-left" />}
      onClick={() => {
        tagButtonEvent('Previous')
        history.go(-1)
      }}
    >
      Previous
    </Button>
  )
}
