import { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { JourneyNavConfig, JourneyNavState } from './journeyNavConfig'
import { JourneyNavContext } from './journeyNavContext'

export const useJourneyNavConfig = () => {
  const { journeyNavConfig, setJourneyNavConfig } = useContext(JourneyNavContext)
  const { pathname: path } = useLocation()

  const journeyNavState = useMemo(() => journeyNavConfig.find((route) => route.path === path)?.state, [
    journeyNavConfig,
    path,
  ])
  const setJourneyNavState = (pathname: string, newState: Partial<JourneyNavState>) => {
    const nextJourneyNavConfig = journeyNavConfig.map((route) => {
      if (route.path === pathname) {
        return { ...route, state: { ...route.state, ...newState } }
      }

      return route
    }) as JourneyNavConfig[]

    setJourneyNavConfig(nextJourneyNavConfig)
  }

  return {
    journeyNavState,
    setJourneyNavState,
  }
}
