import { Brand, MonocerosTheme, makeStyles } from '@lbg-protection/lib-ui-monoceros'
import { isSW } from '../../../../utils/brandSpecificFormatting'

export const withStyles = makeStyles((theme: MonocerosTheme) => {
  const isSWTheme = isSW(theme.name as Brand)
  return {
    wrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formWrapper: {
      width: isSWTheme ? '92%' : '90%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontSize: '1rem',
      '& input, span.currency': {
        fontSize: '1.125rem',
      },
    },
    gapBetweenFields: {
      marginBottom: 22,
    },
    input: {
      paddingBottom: theme.spacing(4),
    },
    button: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      background: theme.palette.common.white,
    },
    text: {
      marginTop: theme.spacing(3),
    },
    inputPlaceholder: {
      '&::placeholder': { color: '#414D66', fontSize: theme.spacing(3), fontWeight: 500, opacity: 1 },
    },
    monthlyPremiumText: {
      width: '100%',
      textAlign: 'center',
      ...(isSWTheme && { marginTop: theme.spacing(2) }),
    },
    marginBottom10: {
      marginBottom: 10,
    },
  }
})
