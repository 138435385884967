import { FinancesQ } from '../../../Shared/Questionaire/questions/FinancesQuestion/types'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'

export const savings = (): FinancesQ => {
  return {
    id: 'savings',
    category: 'finances',
    type: QuestionTypes.Finances,
    sectionHeader: 'Your finances',
    title: {
      text: 'Your finances',
      icon: 'coins',
    },
    question: 'How much do you have in your savings?',
    helpText: [
      `Your savings could help support you and your loved ones when your policy pays out. This could reduce the amount of cover you’d need, so you’d pay less for your policy each month.`,
    ],
    fieldConfig: {
      required: true,
      requiredErrorMessage: 'Please enter an amount',
      min: 0,
      max: 9999999.99,
      maxErrorMessage: 'Please enter a value of less than £10 million',
      decimals: 2,
    },
    JourneyStepName: 'Savings',
    JourneyStep: '14',
  }
}
