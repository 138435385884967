import { makeStyles } from '@lbg-protection/lib-ui-monoceros'

export const styles = makeStyles((theme) => {
  return {
    helpText: {
      margin: `${theme.spacing(5)} 0`,
    },
    milestoneHeading: {
      display: 'flex',
      alignItems: 'center',
    },
    divider: {
      margin: `${theme.spacing(2)} 0`,
    },
    milestoneBullet: {
      display: 'flex',
      alignItems: 'center',
    },
    milestoneTitle: {
      marginLeft: theme.spacing(1),
    },
    milestonesCard: {
      backgroundColor: theme.palette.secondary.light,
      padding: `${theme.spacing(4)} ${theme.spacing(2.5)} ${theme.spacing(3)} ${theme.spacing(2.5)}`,
      marginBottom: theme.spacing(6),
    },
    milestoneCopy: {
      marginLeft: theme.spacing(1),
    },
    selectField: {
      width: '6.25rem',
    },
    yearsLabel: {
      fontWeight: theme.typography.fontWeightBold,
    },
    listText: {
      margin: 0,
    },
  }
})
