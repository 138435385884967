class CacheUtil {
  private static cache: Map<string, Record<string, any>> = new Map<string, Record<string, any>>()

  private static instance: CacheUtil

  public static getInstance(): CacheUtil {
    if (!CacheUtil.instance) {
      CacheUtil.instance = new CacheUtil()
    }
    return CacheUtil.instance
  }

  public getCache(key: string) {
    return CacheUtil.cache.get(key)
  }

  public setCache(key: string, value: Record<string, any>) {
    return CacheUtil.cache.set(key, value)
  }

  public hasKey(key: string) {
    return CacheUtil.cache.has(key)
  }
}
export default CacheUtil
