import React, { useContext } from 'react'
import clsx from 'clsx'
import {
  Box,
  Card,
  Divider,
  Grid,
  Icon,
  Typography,
  useTheme,
  Brand,
  MonocerosTheme,
  ButtonV2 as Button,
} from '@lbg-protection/lib-ui-monoceros'
import { useHistory } from 'react-router-dom'
import { formatCurrencyWithZeroesAfterDecimal } from '../../../utils/formatCurrency'
import { CiLedContext } from '../../../app/Journey/ciLedContext'
import { isBOS, isSW } from '../../../utils/brandSpecificFormatting'
import { CoverSummary } from './CoverSummary/CoverSummary'
import { useStyles } from './styles'
import { BasketProps } from './types'
import { tagButtonClick } from '../../../utils/tagUtils/tagsUtils'

export const Basket = (props: BasketProps) => {
  const { lifeCover, bodyCover, source } = props
  const classes = useStyles()
  const theme = useTheme<MonocerosTheme>()
  const ciLed = useContext(CiLedContext)
  const history = useHistory()
  const isSWBrand = isSW(theme.name as Brand)

  const REVIEW_SUMMARY = 'reviewSummary'

  const coverSummaries = []
  if (ciLed) {
    if (bodyCover?.inBasket) coverSummaries.push(bodyCover)
    if (lifeCover?.inBasket) coverSummaries.push(lifeCover)
  } else {
    if (lifeCover?.inBasket) coverSummaries.push(lifeCover)
    if (bodyCover?.inBasket) coverSummaries.push(bodyCover)
  }

  const ReviewSummaryHeading = () => {
    const handleEdit = () => {
      tagButtonClick('Edit')
      history.go(-4)
    }
    return (
      <Grid container justify="space-between">
        <Grid item>
          <Typography
            variant={isSWBrand ? 'h2' : 'button'}
            data-testid="policy-summary-heading"
            className={classes.policiesLabel}
          >
            Your policies
          </Typography>
        </Grid>
        <Button
          variant="text"
          data-testid="pencil-edit-on-click"
          onClick={handleEdit}
          startIcon={<Icon name="pencil" size="1.1rem" color={isSWBrand ? 'inherit' : 'primary'} />}
          className={classes.buttonText}
        >
          <Typography
            variant="body2"
            color={isSWBrand ? 'inherit' : 'primary'}
            {...(!isSWBrand && { className: classes.pencilEdit })}
          >
            Edit
          </Typography>
        </Button>
      </Grid>
    )
  }

  const QuotePageHeading = () => {
    return (
      <Typography variant="h4" color="inherit" data-testid="policy-summary-heading" className={classes.basketHeading}>
        Your basket
      </Typography>
    )
  }

  const QuotePageNoCoverSummaries = () => {
    return (
      <Card className={classes.emptyBasket} data-testid="basket-component">
        <Typography variant="h4" data-testid="basket-header">
          Your basket
        </Typography>
        <Box className={classes.emptyBasketDivider}>
          <Icon className={classes.emptyBasketIcon} size="3rem" name="box" data-testid="basket-icon" />
          <Typography className={classes.emptyBasketTypography} align="center" variant="h5" data-testid="basket-empty">
            Empty
          </Typography>
          <Typography
            className={classes.emptyBasketTypography}
            align="center"
            variant="body1"
            data-testid="basket-text"
          >
            You haven&apos;t added a policy to your basket yet
          </Typography>
        </Box>
      </Card>
    )
  }

  const total = coverSummaries.reduce(
    (accumulator, coverSummaryData) => accumulator + Number(coverSummaryData.monthlyCost),
    0,
  )

  const formattedTotal = formatCurrencyWithZeroesAfterDecimal('en-GB', 'GBP', total)

  return coverSummaries.length ? (
    <Card
      className={source === REVIEW_SUMMARY ? classes.reviewSummaryCard : classes.basketCard}
      data-testid="policy-summary-container"
    >
      {source === REVIEW_SUMMARY ? <ReviewSummaryHeading /> : <QuotePageHeading />}
      <Divider
        className={source === REVIEW_SUMMARY ? classes.reviewSummaryHeadingDivider : classes.basketHeadingDivider}
      />
      {coverSummaries.map((coverSummaryData) => {
        const testId = `${coverSummaryData.coverType.toLowerCase()}-cover-summary-container`
        return (
          <Box key={JSON.stringify(coverSummaryData)} data-testid={testId}>
            <CoverSummary coverSummary={coverSummaryData} />
            <Divider className={source === REVIEW_SUMMARY ? classes.reviewSummaryDivider : classes.basketDivider} />
          </Box>
        )
      })}
      <Box className={classes.summaryRow} data-testid="policy-summary-total-container">
        <Typography variant="subtitle2" component="p" color="inherit" data-testid="basket-total-label">
          Monthly total
        </Typography>
        <Typography
          component="p"
          className={clsx(isBOS(theme.name as Brand) ? classes.totalAmount : '')}
          variant={isBOS(theme.name as Brand) ? 'caption' : 'h4'}
          color="inherit"
          data-testid="basket-total-amount"
        >
          {formattedTotal}
        </Typography>
      </Box>
    </Card>
  ) : (
    <QuotePageNoCoverSummaries />
  )
}
