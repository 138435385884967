import { ButtonV2 as Button, Icon } from '@lbg-protection/lib-ui-monoceros'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { BottomNavigation } from '../BottomNavigation/BottomNavigation'
import { GotoQuestion, Question } from './types'

type PreviousProps = {
  prevQuestion: string
  gotoQuestion: GotoQuestion
}
const Previous = ({ prevQuestion, gotoQuestion }: PreviousProps) => {
  const history = useHistory()

  const whereToGo = () => {
    if (prevQuestion === '__none') {
      history.go(-1)
    } else {
      gotoQuestion(prevQuestion)
    }
  }

  return (
    <Button
      variant="text"
      color="primary"
      data-testid="questionaire-nav-prev"
      onClick={whereToGo}
      startIcon={<Icon name="chevron-left" />}
    >
      Previous
    </Button>
  )
}

type NextProps = {
  nextQuestion: string
  question: Question | null
}
const Next = ({ question, nextQuestion }: NextProps) => (
  <Button
    type="submit"
    form={`${question?.id}-question-form`}
    variant="contained"
    color="primary"
    data-testid={`questionaire-nav-${nextQuestion !== '__none' ? 'next' : 'finish'}`}
  >
    Continue
  </Button>
)

interface Props {
  gotoQuestion: GotoQuestion
  prevQuestion: string
  nextQuestion: string
  question: Question | null
}
export const FooterNav = ({ question, gotoQuestion, prevQuestion, nextQuestion }: Props) => {
  useEffect(() => {
    // Scrolls to top:
    return function cleanup() {
      window.scrollTo(0, 0)
    }
  }, [nextQuestion])

  return (
    <BottomNavigation
      data-testid="button-navigation-controls"
      userDefinedStartActions={<Previous prevQuestion={prevQuestion} gotoQuestion={gotoQuestion} />}
      userDefinedEndActions={<Next question={question} nextQuestion={nextQuestion} />}
    />
  )
}
