import React from 'react'
import { ButtonV2 as Button } from '@lbg-protection/lib-ui-monoceros'
import { useNavigateTo } from '../../../utils/useNavigateTo'
import { LoadingSpinner } from '../../Shared/LoadingSpinner/LoadingSpinner'
import { saveJourney, selectSaveStatus, saveAndRetrieveSlice } from '../saveAndRetrieve.slice'
import { SaveAndRetrieveLoadingStatus, SaveJourneyArgs } from '../types'
import { tagButtonClick } from '../../../utils/tagUtils/tagsUtils'
import { SaveError } from './Screens/SaveError'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'

type SaveJourneyWrapperProps = {
  journeyExitPoint?: SaveJourneyArgs['journeyExitPoint']
  currentPage: string
}

const SaveJourneyWrapper: React.FC<SaveJourneyWrapperProps> = (props) => {
  const navigateTo = useNavigateTo()
  const dispatch = useAppDispatch()
  const saveStatus = useAppSelector(selectSaveStatus)

  if (saveStatus === SaveAndRetrieveLoadingStatus.Success) {
    navigateTo('/saved')
  }

  return (
    <>
      {saveStatus === SaveAndRetrieveLoadingStatus.Loading && <LoadingSpinner />}
      {saveStatus === SaveAndRetrieveLoadingStatus.Error && (
        <SaveError resetSaveStatus={() => dispatch(saveAndRetrieveSlice.actions.resetSaveStatus())} />
      )}
      <Button
        data-testid="save-exit-button"
        variant="outlined"
        color="primary"
        onClick={() => {
          dispatch(
            saveJourney({
              journeyExitPoint: props.journeyExitPoint,
            }),
          )
          tagButtonClick('Save and exit')
        }}
      >
        Save and exit
      </Button>
    </>
  )
}

export default SaveJourneyWrapper
