import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@lbg-protection/lib-ui-monoceros'
import clsx from 'clsx'
import React from 'react'
import { PdfViewerProps } from './PdfViewerProps'
import { extractFileNameFromFile } from './utils/extractFileNameFromFile'
import { PageFocus } from '../PageFocus'

export interface ConfirmDownloadDialogProps extends DialogProps, Pick<PdfViewerProps, 'filename' | 'file'> {
  onConfirm: (event: React.SyntheticEvent) => void
  onClose: () => void
  'data-testid'?: string
}

export const ConfirmDownloadDialog = ({ filename, file, onConfirm, onClose, ...props }: ConfirmDownloadDialogProps) => {
  const resolvedFilename = filename || extractFileNameFromFile(file)
  const dataTestId = `${props['data-testid']}-dialog`

  return (
    <>
      <Dialog fullScreen={false} maxWidth="sm" onClose={onClose} {...props}>
        <PageFocus>
          <DialogTitle data-testid={`${dataTestId}-title`} withCloseButton onClose={onClose}>
            Before you continue
          </DialogTitle>
        </PageFocus>

        <DialogContent data-testid={`${dataTestId}-content`}>
          <Typography variant="body1">
            This will save a copy of this document to your device. Depending on your settings, if you open or view this
            document right now then your Online Banking session might time out and your progress won&lsquo;t be saved.
          </Typography>
        </DialogContent>
        <DialogActions>
          <a
            className={clsx(
              'MuiButtonBase-root',
              'MuiButton-root',
              'MuiButton-contained',
              'MuiButton-containedPrimary',
            )}
            onClick={(event: React.SyntheticEvent) => {
              onConfirm(event)
            }}
            href={file}
            download={resolvedFilename}
            data-testid="pdf-dialog-button-ok"
          >
            Download
          </a>
        </DialogActions>
      </Dialog>
    </>
  )
}
