import { Item } from '../types'

export const addNewItem = (items: Item[]): Item[] => {
  let nextId = 1
  if (items.length > 0) {
    const lastId = items.map((item) => item.id).reduce((a, b) => Math.max(a, b))
    nextId = lastId + 1
  }

  return [...items, { id: nextId, description: '', amount: '', editable: true }]
}

export const updateItem = (id: number, items: Item[], description: string, amount: number): Item[] => {
  return items.map((item) => {
    if (item.id === id) return { ...item, description, amount, editable: false }
    return { ...item }
  })
}

export const toggleItemEditable = (id: number, items: Item[], editable: boolean): Item[] => {
  return items.map((item) => {
    if (item.id === id) return { ...item, editable }
    return { ...item }
  })
}

export const removeItem = (id: number, items: Item[]): Item[] => {
  return items.filter((item: Item) => item.id !== id)
}
