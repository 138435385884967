import { createContext, Dispatch, SetStateAction } from 'react'
import { JourneyNavConfig } from './journeyNavConfig'

export interface JourneyNavContext {
  journeyNavConfig: JourneyNavConfig[]
  setJourneyNavConfig: Dispatch<SetStateAction<JourneyNavConfig[]>>
}

export const JourneyNavContext = createContext<JourneyNavContext>({
  journeyNavConfig: [],
  setJourneyNavConfig: () => null,
})
