import dayjs from 'dayjs'

export const calculateAge = (dob: Date): number => {
  return dayjs().diff(dob, 'year')
}

export const getFixedDobForTesting = (): Date => {
  const dob = new Date()
  dob.setDate(1)
  dob.setMonth(0)
  dob.setFullYear(dob.getFullYear() - 28)
  return dob
}
