import { CoverSummaryInterface } from '../../../Shared/Basket/CoverSummary/types'
import { tagPricingEvent } from '../../../../utils/tagUtils/tagsUtils'

export const tagCover = (coverSummary: CoverSummaryInterface, voucherAmount?: number) => {
  const voucherDetails = voucherAmount ? voucherAmount.toString() : ''
  const calculationType = coverSummary.calculationType ? coverSummary.calculationType.toString() : ''
  tagPricingEvent(
    coverSummary.coverType,
    coverSummary.amount.toString(),
    coverSummary.monthlyCost.toString(),
    coverSummary.term.toString(),
    voucherDetails,
    `chosen - ${calculationType}`,
  )
}
