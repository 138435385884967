import { NumberFieldConfig } from '@lbg-protection/lib-ui-monoceros'
import { formatCurrencyWithZeroesAfterDecimal } from '../../../../utils/formatCurrency'

export const amountConfig = new NumberFieldConfig(1, 250000)
export const amountLabel = 'Cover amount'
export const amountName = 'amount'

export const costLabel = 'Monthly payment'
export const costName = 'monthlyCost'

export const termConfig = (maxTerm: number): NumberFieldConfig => {
  return new NumberFieldConfig(5, maxTerm)
}
export const termLabel = 'Cover duration'
export const termName = 'term'

export const adjustedAmountMsg = (monthlyCost: number): string => {
  const maxCover = '£500,000'

  return `Maximum cover is ${maxCover}, which would cost ${formatCurrencyWithZeroesAfterDecimal(
    'en-GB',
    'GBP',
    monthlyCost,
  )} a month. We'll change it to ${maxCover} now.`
}

export const adjustedMonthlyCostMsg =
  "Minimum monthly cost is £5. We'll change it to the £5 then you can adjust the cover amount and term."

export const debounceDelay = 1000
