import {
  Accordion,
  AccordionGroup,
  Box,
  Brand,
  MonocerosTheme,
  Typography,
  makeStyles,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import React, { useContext } from 'react'
import { CiLedContext } from '../../../app/Journey/ciLedContext'
import { WhatIsCovered } from '../../Shared/WhatIsCovered/WhatIsCovered'
import {
  BodyCoverAmountRightForMe,
  BodyCoverRightForYou,
  HowLongShouldIGetCoverFor,
  LifeCoverAmountRightForMe,
  LifeCoverRightForYou,
  NeedAdviceCavendishOnline,
  NeedAdviceLBIS,
} from '../Shared'
import { tagAccordionClick } from '../../../utils/tagUtils/tagsUtils'
import { isSW } from '../../../utils/brandSpecificFormatting'

const styles = makeStyles({
  hideBorderTop: {
    '&.MuiExpansionPanel-root:before': {
      display: 'none',
    },
  },
  hideBorderBottom: {
    '&.MuiExpansionPanel-root:last-child::after': {
      display: 'none',
    },
  },
})

const LifeCover = () => (
  <Typography>
    Life Cover is a life insurance policy. It pays out a cash lump sum if you die before your policy ends.
    <br />
    <br />
    It can act like a safety net, and could help your loved ones cope financially when you can&apos;t be there.
  </Typography>
)

const LifeCoverAccordion = () => {
  const classes = styles()
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)
  const ciLed = useContext(CiLedContext)
  return (
    <Accordion
      {...(isSWBrand && !ciLed && { className: classes.hideBorderTop })}
      panelId="quote-accordion-what-is-life-cover"
      summary="What is Life Cover?"
      onChange={() => tagAccordionClick('What is Life Cover?')}
    >
      <LifeCover />
    </Accordion>
  )
}

const BodyCover = () => (
  <Typography variant="body1">
    Body Cover is a critical illness insurance policy. It pays out a cash lump sum if you are diagnosed with an illness
    covered by your policy before it ends.
    <br />
    <br />
    The money could help you and your loved ones meet day-to-day costs and let you focus on your own health and
    wellbeing.
  </Typography>
)

const BodyCoverAccordion = () => (
  <Accordion
    panelId="quote-accordion-what-is-body-cover"
    summary="What is Body Cover?"
    onChange={() => tagAccordionClick('What is Body Cover?')}
  >
    <BodyCover />
  </Accordion>
)

export const QuoteAccordions = () => {
  const classes = styles()
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)
  const ciLed = useContext(CiLedContext)
  return (
    <AccordionGroup>
      {ciLed ? (
        <>
          <BodyCoverAccordion />
          <LifeCoverAccordion />
        </>
      ) : (
        <>
          <LifeCoverAccordion />
          <BodyCoverAccordion />
        </>
      )}
      <Accordion
        panelId="quote-accordion-what-cover-is-right-for-me"
        summary="Which cover is right for me?"
        onChange={() => tagAccordionClick('Which cover is right for me?')}
      >
        {ciLed ? (
          <Box>
            <BodyCoverRightForYou />
            <br />
            <LifeCoverRightForYou />
          </Box>
        ) : (
          <Box>
            <LifeCoverRightForYou />
            <br />
            <BodyCoverRightForYou />
          </Box>
        )}
      </Accordion>
      <Accordion
        panelId="quote-accordion-what-is-covered"
        summary="What is covered?"
        onChange={() => tagAccordionClick('What is covered?')}
      >
        <WhatIsCovered ciLed={ciLed} />
      </Accordion>
      <Accordion
        panelId="quote-accordion-what-amount-is-right-for-me"
        summary="What amount is right for me?"
        onChange={() => tagAccordionClick('What amount is right for me?')}
      >
        {ciLed ? (
          <Box>
            <Typography variant="h4" data-testid="body-cover-amount-question">
              Body Cover
            </Typography>
            <br />
            <BodyCoverAmountRightForMe />
            <br />
            <Typography variant="h4" data-testid="life-cover-amount-question">
              Life Cover
            </Typography>
            <br />
            <LifeCoverAmountRightForMe />
          </Box>
        ) : (
          <Box>
            <Typography variant="h4" data-testid="life-cover-amount-question">
              Life Cover
            </Typography>
            <br />
            <LifeCoverAmountRightForMe />
            <br />
            <Typography variant="h4" data-testid="body-cover-amount-question">
              Body Cover
            </Typography>
            <br />
            <BodyCoverAmountRightForMe />
          </Box>
        )}
      </Accordion>
      <Accordion
        panelId="quote-accordion-how-long-should-i-get-cover-for"
        summary="How long should I get cover for?"
        onChange={() => tagAccordionClick('How long should I get cover for?')}
      >
        <HowLongShouldIGetCoverFor />
      </Accordion>
      <Accordion
        {...(isSWBrand && { className: classes.hideBorderBottom })}
        panelId="quote-accordion-need-advice"
        summary="Need advice?"
        onChange={() => tagAccordionClick('Need advice?')}
      >
        {isSWBrand ? <NeedAdviceCavendishOnline /> : <NeedAdviceLBIS />}
      </Accordion>
    </AccordionGroup>
  )
}
