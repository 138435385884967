export default (locale: string, currency: string, amount: number): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
    .format(amount)
    .replace(/\D00$/, '')
}

export const formatCurrencyWithZeroesAfterDecimal = (locale: string, currency: string, amount: number): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(amount)
}
