import React from 'react'
import {
  Box,
  ListWithBullets,
  ListItemWithBullet,
  makeStyles,
  MonocerosTheme,
  Typography,
  Brand,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import clsx from 'clsx'
import { DialogControl } from '../../Shared/DialogControl/DialogControl'
import { tagOverlayClick } from '../tags'
import { isSW } from '../../../utils/brandSpecificFormatting'

const styles = makeStyles((theme: MonocerosTheme) => {
  const isSWBrand = isSW(theme.name as Brand)
  return {
    link: {
      marginTop: theme.spacing(isSWBrand ? 2 : 3),
    },
    bulletList: {
      marginBottom: theme.spacing(5),
      ...(isSWBrand && { marginTop: theme.spacing(5), padding: 0 }),
    },
    linkTitle: {
      fontFamily: theme.typography.body1.fontFamily,
    },
  }
})

export const HowDoIChooseLifeOverlay = () => {
  const classes = styles()
  const overlaySummary = `How do I choose what's right for me?`
  const { name: themeName = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(themeName)
  return (
    <Box className={classes.link}>
      <DialogControl
        id="how-do-i-choose-life"
        data-testid="how-do-i-choose-life"
        title={overlaySummary}
        controlText={overlaySummary}
        customOnClick={() => tagOverlayClick(`${overlaySummary} - Life`)}
        linkClass={clsx(isSWBrand && classes.linkTitle)}
      >
        <Typography gutterBottom variant="subtitle2" data-testid="indicative-life-choose-right-answer">
          Life Cover amount
        </Typography>
        <Typography>
          It might help to think about the kind of support your loved ones would need if you couldn&apos;t be there.
          Things like:
        </Typography>
        <ListWithBullets className={classes.bulletList}>
          <ListItemWithBullet>Helping with day-to-day costs</ListItemWithBullet>
          <ListItemWithBullet>Paying off any debts you may have, like a mortgage</ListItemWithBullet>
          <ListItemWithBullet>Leaving money for the future.</ListItemWithBullet>
        </ListWithBullets>
        <Typography gutterBottom variant="subtitle2">
          Life Cover duration
        </Typography>
        <Typography>It might help to think about life&apos;s milestones, like the number of years until:</Typography>
        <ListWithBullets className={clsx(isSWBrand && classes.bulletList)}>
          <ListItemWithBullet>Your children turn 18</ListItemWithBullet>
          <ListItemWithBullet>Your mortgage is paid off</ListItemWithBullet>
          <ListItemWithBullet>You reach state pension age.</ListItemWithBullet>
        </ListWithBullets>
      </DialogControl>
    </Box>
  )
}
