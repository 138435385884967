import {
  ButtonV2 as Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  makeStyles,
  NamedTheme,
  Typography,
} from '@lbg-protection/lib-ui-monoceros'
import React, { useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { PdfViewerLoading } from './PdfViewerLoading'
import { PdfViewerProps } from './PdfViewerProps'
import { usePreventUserZoom } from './useApplyPreventUserZoom'
import { useWindowSize } from './utils/useWindowResize'
import { PageFocus } from '../PageFocus'

pdfjs.GlobalWorkerOptions.workerSrc = './workers/pdf.worker.min.js'

export const useStyles = makeStyles((theme: NamedTheme) => {
  return {
    loading: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      boxSizing: 'border-box',
      textAlign: 'center',
    },
    error: { margin: theme.spacing(3) },
    documentWrapper: {
      display: 'flex',
      userSelect: 'none',
      flexGrow: 1,
      '& .react-pdf__Document': {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
      },
      '& .react-pdf__message': {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  }
})

export const PdfViewer = (props: PdfViewerProps) => {
  const [numPages, setNumPages] = useState(0)
  const [scale, setScale] = useState(1)
  const [pages, setPages] = useState<number[]>([])
  const [numberOfPagesLoaded, setNumberOfPagesRendered] = useState(0)
  const [openErrorDialog, setErrorDialog] = useState(true)
  const [width, setWidth] = useState(0)
  const [successfullyLoaded, setSuccessfullyLoaded] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  useEffect(() => {
    const nextPages: number[] = []
    for (let pageNumber = 1; pageNumber <= numPages; pageNumber += 1) {
      nextPages.push(pageNumber)
    }
    setPages(nextPages)
  }, [numPages])

  useEffect(() => {
    const div: HTMLDivElement = ref.current as HTMLDivElement
    setWidth(ref.current !== null ? div.offsetWidth - (div.clientWidth - div.offsetWidth) : windowWidth ?? 0)
  }, [ref.current, windowWidth, windowHeight])

  usePreventUserZoom(props.containerRef ?? ref)

  const [resolvedFile, setResolvedFile] = useState<PdfViewerProps['file']>()

  useEffect(() => {
    if (typeof props?.file === 'function') {
      const result = props.file()
      if (typeof result === 'object' && result.then) {
        result
          .then((asyncResult: PdfViewerProps['file']) => {
            setResolvedFile(asyncResult)
          })
          .catch(() => {
            setResolvedFile(null)
          })
      } else {
        setResolvedFile(result)
      }
    } else {
      setResolvedFile(props?.file ?? null)
    }
  }, [])

  const classes = useStyles()

  const DialogOnError = () => {
    return (
      <Dialog
        open={openErrorDialog}
        className={classes.error}
        fullScreen={false}
        maxWidth="sm"
        onClose={() => setErrorDialog(!openErrorDialog)}
      >
        <PageFocus>
          <DialogTitle variant="h4">Unable to download</DialogTitle>
        </PageFocus>

        <DialogContent>
          <Typography variant="body1">
            There was a problem downloading this document. Please try again later.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialog(!openErrorDialog)} variant="contained" color="primary" fullWidth>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <PageFocus>
      <>
        <div
          style={{
            position: 'absolute',
            top: '18px',
            right: '18px',
            zIndex: 10,
            display: 'flex',
            flexDirection: 'column',
            background: 'rgba(255,255,255,.8)',
            borderRadius: '32px',
            alignItems: 'center',
            opacity: successfullyLoaded ? 1 : 0,
            transition: 'opacity .5s, height .5s ease-in-out',
            overflow: 'hidden',
            touchAction: 'none',
            userSelect: 'none',
          }}
        >
          <IconButton
            style={{ borderRadius: '50%', background: 'transparent' }}
            title="Zoom In"
            onClick={() => {
              setScale(Math.min(scale + 0.25, 5))
            }}
            color="primary"
            disabled={scale >= 5}
          >
            <Icon name="plus" />
          </IconButton>
          <Typography style={{ padding: '6px' }}>{Number(scale * 100).toFixed(0)}%</Typography>
          <IconButton
            style={{ borderRadius: '50%', background: 'transparent' }}
            color="primary"
            title="Zoom Out"
            onClick={() => {
              setScale(Math.max(scale - 0.25, 1))
            }}
            disabled={scale <= 1}
          >
            <Icon name="minus" />
          </IconButton>
        </div>
        <div ref={ref} className={classes.documentWrapper}>
          {resolvedFile !== undefined && (
            <Document
              options={{}}
              renderMode="svg"
              loading={<PdfViewerLoading />}
              error={DialogOnError}
              onLoadSuccess={(pdf) => {
                if (props.onLoadSuccess) {
                  props.onLoadSuccess(pdf)
                }
                setNumPages(pdf.numPages)
                setSuccessfullyLoaded(true)
              }}
              {...props}
              file={resolvedFile}
            >
              {pages.map((pageNumber) => (
                <Page
                  key={pageNumber}
                  width={width}
                  loading=""
                  onRenderSuccess={() => {
                    setNumberOfPagesRendered(numberOfPagesLoaded + 1)
                  }}
                  scale={scale}
                  pageNumber={pageNumber}
                />
              ))}
            </Document>
          )}
        </div>
      </>
    </PageFocus>
  )
}
