import { createSelector, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import {
  CoverAmountInterface,
  CoverSelectedEnum,
  PrePurchaseInterface,
  PrePurchaseJourneyRouteEnum,
  PrePurchaseState,
  SelectCoverChoice,
  SelectPrePurchase,
  SelectUserRoute,
} from './types'
import { isPPO } from './utils'

// Selectors
export const selectPrePurchase: SelectPrePurchase = createSelector(
  (state) => state as PrePurchaseInterface,
  (ppo) => ppo,
)

export const selectUserRoute: SelectUserRoute = createSelector(selectPrePurchase, (ppo) => ppo.userRoute)

export const selectCoverChoice: SelectCoverChoice = createSelector(selectPrePurchase, (ppo) => {
  return {
    coverSelected: ppo.coverSelected,
    ...(ppo.life && { life: ppo.life }),
    ...(ppo.body && { body: ppo.body }),
  }
})

const initialState: PrePurchaseState = {
  coverSelected: CoverSelectedEnum.None,
}

type Reducers = {
  injectPrePurchase: Reducer<PrePurchaseState, PayloadAction<PrePurchaseInterface>>
  setCoverSelected: Reducer<PrePurchaseState, PayloadAction<CoverSelectedEnum>>
  setSelectedCovers: Reducer<PrePurchaseState, PayloadAction<Record<string, CoverAmountInterface>>>
  setUserRoute: Reducer<PrePurchaseState, PayloadAction<PrePurchaseJourneyRouteEnum>>
}

const reducers: Reducers = {
  injectPrePurchase: (_state, action) => {
    // validate
    const prePurchase = action.payload
    if (!isPPO(prePurchase)) {
      return initialState
    }
    return prePurchase
  },
  setCoverSelected: (state, action) => {
    return { ...state, coverSelected: action.payload }
  },
  setSelectedCovers: (state, action) => {
    const selectedCovers = action.payload
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { life, body, ...stateWithNoCovers } = { ...state }

    return { ...stateWithNoCovers, ...selectedCovers }
  },
  setUserRoute: (state, action) => {
    return { ...state, userRoute: action.payload }
  },
}

export const slice = createSlice({
  name: 'prePurchase',
  initialState,
  reducers,
})
