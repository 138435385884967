import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Box, ButtonV2 as Button, makeStyles, CurrencyInput, FormField, Input } from '@lbg-protection/lib-ui-monoceros'
import { Item } from '../../types'
import { removeItem } from '../../utils/helpers'

export const styles = makeStyles((theme) => {
  return {
    extraItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(4.5),
      '& > div:nth-child(2)': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(4),
      },
    },
    saveButton: {
      marginRight: theme.spacing(4),
    },
  }
})

interface ItemFormProps {
  item: Item
  items: Item[]
  setItems: Dispatch<SetStateAction<Item[]>>
}

export const ItemForm = (props: ItemFormProps) => {
  const classes = styles({})
  const { control, errors } = useFormContext()
  const descriptionInputRef = useRef<HTMLInputElement>(null)
  const amountInputRef = useRef<HTMLInputElement>(null)
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (errors[`extra-item-${props.item.id}-description`]) {
      return descriptionInputRef.current?.focus()
    }
    if (errors[`extra-item-${props.item.id}-amount`]) {
      return amountInputRef.current?.focus()
    }
  }, [errors])

  return (
    <Box
      key={`extra-item-${props.item.id}-form`}
      data-testid={`extra-item-${props.item.id}-form`}
      className={classes.extraItemContainer}
    >
      <Controller
        name={`extra-item-${props.item.id}-description`}
        control={control}
        defaultValue={props.item.description}
        rules={{
          required: { value: true, message: 'Please enter a description' },
        }}
        render={({ name, onBlur, onChange, value }) => (
          <FormField
            id={name}
            key={name}
            name={name}
            label="Description"
            inputWidth="17.75rem"
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const validCharacters = /^([\w]+[\s|-]?)+$/
              const inputValue = e.target.value
              if (validCharacters.test(inputValue) || inputValue === '') {
                onChange({ target: { value: inputValue }, type: 'change' })
              }
            }}
            onBlur={onBlur}
            errorMessage={errors[name]?.message}
            inputRef={descriptionInputRef}
          >
            <Input
              inputProps={{
                'data-testid': `extra-item-${props.item.id}-description-input`,
                type: 'text',
                maxLength: 15,
                'aria-required': true,
              }}
            />
          </FormField>
        )}
      />
      <Controller
        name={`extra-item-${props.item.id}-amount`}
        id={`extra-item-${props.item.id}-amount`}
        key={`extra-item-${props.item.id}-amount`}
        control={control}
        defaultValue={props.item.amount}
        rules={{
          required: { value: true, message: 'Please enter an amount' },
          max: { value: 9999999.99, message: 'Please enter a value of less than £10 million' },
        }}
        as={
          <FormField
            inputProps={{
              'data-testid': `extra-item-${props.item.id}-amount-input`,
              'aria-required': true,
            }}
            inputWidth="9.375rem"
            label="Amount"
            errorMessage={errors[`extra-item-${props.item.id}-amount`]?.message}
            inputRef={amountInputRef}
          >
            <CurrencyInput allowNegative={false} />
          </FormField>
        }
      />
      <Box>
        <Button
          className={classes.saveButton}
          data-testid={`save-extra-item-${props.item.id}-button`}
          type="submit"
          variant="outlined"
          color="primary"
        >
          + Save
        </Button>
        <Button
          data-testid={`remove-extra-item-${props.item.id}-button`}
          type="button"
          variant="outlined"
          color="primary"
          onClick={() => {
            const nextItems = removeItem(props.item.id, props.items)
            props.setItems(nextItems)
          }}
        >
          Remove
        </Button>
      </Box>
    </Box>
  )
}
