import React, { useEffect, ReactElement } from 'react'
import { makeStyles } from '@lbg-protection/lib-ui-monoceros'

interface PageFocusType {
  children: React.ReactNode
}
export const PageFocus = ({ children }: PageFocusType): ReactElement => {
  const classes = makeStyles(() => {
    return {
      titleFocus: {
        '&:focus': {
          outline: 'none',
        },
      },
    }
  })()
  const titleRef = React.createRef<HTMLParagraphElement>()

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus()
    }
  }, [])

  return (
    <div className={classes.titleFocus} ref={titleRef} tabIndex={-1}>
      {children}
    </div>
  )
}
