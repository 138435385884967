import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import { CoverChoiceInterface, PrePurchaseJourneyRouteEnum } from '../../journeys/purchase/types'
import { SaveJourneyArgs } from '../SaveAndRetrieve/types'
import { CoverSummaryInterface } from '../Shared/Basket/CoverSummary/types'

export interface TaggingProps {
  numberOfLifeCoverPricingCalls: number
  numberOfBodyCoverPricingCalls: number
}

export type QuotesState = {
  validationError?: string
  tagging: TaggingProps
  lifeCoverPricingCallStatus: PricingCallStatus
  bodyCoverPricingCallStatus: PricingCallStatus
  lifeCover: CoverSummaryInterface
  bodyCover: CoverSummaryInterface
}

export interface AddToBasketActionProps {
  coverType: PricingRequestBody.CoverTypeEnum
}

export type RemoveFromBasketActionProps = {
  coverType: PricingRequestBody.CoverTypeEnum
}

export interface UnderwriteMe {
  isUnderwriteMe: boolean
  enquiryId: string
}
export interface PricingRequestParams extends BasePricingRequestParams {
  underwriteMe: UnderwriteMe
  uwOutcome: string | number
  lastQuoteDate?: string
  isJourneyUnAuth?: boolean
}
export interface BasePricingRequestParams {
  dob: Date
  smokerStatus: boolean
  coverType: PricingRequestBody.CoverTypeEnum
  coverTerm: number
  calculationType: PricingRequestBody.CalculationTypeEnum
  monthlyPremium?: number
  sumAssured?: number
  isJourneyUnAuth?: boolean
}

export interface UpdateActionProps {
  coverType: PricingRequestBody.CoverTypeEnum
  title: string
  amount: number
  term: number
  monthlyCost: number
}

export interface SetValidationErrorActionProps {
  validationError: string
}

export interface PricingFulfilledActionProps {
  coverType: PricingRequestBody.CoverTypeEnum
  title: string
  amount: number
  term: number
  monthlyCost: number
  inBasket: boolean
}

export interface PricingIndicativeQuoteFulfilledActionProps {
  coverType: PricingRequestBody.CoverTypeEnum
  title: string
  amount: number
  term: number
  monthlyCost: number
}

export enum PricingCallStatus {
  Pending = 'Pending',
  Success = 'Success',
  Error = 'Error',
  Idle = 'Idle',
}

export interface QuotePageProps {
  validationError?: string
  numberOfLifeCoverPricingCalls?: number
  numberOfBodyCoverPricingCalls?: number
  smokerStatus: boolean
  lifeOutcome?: number | string
  ciOutcome?: number | string
  userRoute: PrePurchaseJourneyRouteEnum
  prePurchaseCoverChoice: CoverChoiceInterface
  lifeCoverPricingCallStatus: PricingCallStatus
  bodyCoverPricingCallStatus: PricingCallStatus
  dob: Date
  firstName: string
  lastName: string
  enquiryId?: string
  callPricingAPI?: (formData: PricingRequestParams) => void
  lifeCover: CoverSummaryInterface
  bodyCover: CoverSummaryInterface
  update?: (formData: Omit<CoverSummaryInterface, 'inBasket'>) => void
  addToBasket?: (data: Pick<CoverSummaryInterface, 'coverType'>) => void
  removeFromBasket?: (data: Pick<CoverSummaryInterface, 'coverType'>) => void
  setValidationError?: (error: string) => void
  uwMeLifeOutcome?: boolean
  uwMeCiOutcome?: boolean
  saveJourney?: (params: SaveJourneyArgs) => void
  hasIndicativeQuote?: boolean
}

export interface UnderwriteOutcomeArgs {
  isUnderwriteMe: boolean
  underwritingCoverOutcome?: number | string
  underwriteMeCoverOutcome?: boolean
}

export interface BasePricingRequestData {
  dateOfBirth: string
  coverType: PricingRequestBody.CoverTypeEnum
  calculationType: PricingRequestBody.CalculationTypeEnum
  monthlyPremium?: number
  sumAssured?: number
  coverTerm: string
}
