import React from 'react'
import { Typography } from '@lbg-protection/lib-ui-monoceros'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'
import { MultipleChoiceQ } from '../../../Shared/Questionaire/questions/MultipleChoiceQuestion/types'
import { Answer } from '../../../Shared/Questionaire/types'
import { FinancesAnswer } from '../../../Shared/Questionaire/questions/FinancesQuestion/types'

export const incomeYears = (answers: Record<string, Answer>): MultipleChoiceQ => {
  const monthlyIncome = (answers.income as FinancesAnswer)?.value
  const income1Year = monthlyIncome * 12
  const income2Years = monthlyIncome * 24
  const income3Years = monthlyIncome * 36

  const numberWithCommas = (value: number): string => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return {
    id: 'incomeYears',
    type: QuestionTypes.MultipleChoice,
    category: 'incomeYears',
    title: {
      text: 'Cover for critical illness',
      icon: 'jar',
    },
    description: (
      <>
        <Typography gutterBottom>
          Based on what you&apos;ve told us, we&apos;ve worked out how much you earn after tax each year. We&apos;ve
          used this to suggest how much you might want to be paid out as a cash lump sum if you were diagnosed with a
          critical illness.
        </Typography>
        <Typography gutterBottom>You can change this on your cover report.</Typography>
      </>
    ),
    question: "Choose how much you'd like to be paid out",
    options: [
      {
        id: '1',
        option: `1 year's income (£${numberWithCommas(income1Year)})`,
        outcome: income1Year,
        exclusive: true,
      },
      {
        id: '2',
        option: `2 year's income (£${numberWithCommas(income2Years)})`,
        outcome: income2Years,
        exclusive: true,
      },
      {
        id: '3',
        option: `3 year's income (£${numberWithCommas(income3Years)})`,
        outcome: income3Years,
        exclusive: true,
      },
    ],
    spaced: true,
    centreOptions: true,
    errorMessage: 'Please select an option',
    JourneyStepName: 'Income years',
    JourneyStep: '16',
    QAQuestion: 'Income years',
  }
}
