import React, { useMemo } from 'react'
import {
  BottomNavigation as MonocerosBottomNavigation,
  ButtonV2 as Button,
  Icon,
  makeStyles,
  useTheme,
  MonocerosTheme,
  Brand,
} from '@lbg-protection/lib-ui-monoceros'
import clsx from 'clsx'
import { isSW } from '../../../utils/brandSpecificFormatting'

interface BottomNavigationProps {
  continueDisabled?: boolean
  displayAtBottom?: boolean
  userDefinedEndActions?: React.ReactNode
  userDefinedStartActions?: React.ReactNode
  transparentBackground?: boolean
  onContinue?: () => void
  onPrevious?: () => void
  showDivider?: boolean
}

export const BottomNavigation = (props: BottomNavigationProps) => {
  const { name } = useTheme<MonocerosTheme>()
  const isOrionTheme = useMemo(() => isSW(name as Brand), [name])

  const {
    showDivider = true,
    userDefinedEndActions,
    continueDisabled,
    onContinue,
    userDefinedStartActions,
    onPrevious,
    transparentBackground,
  } = props

  const classes = makeStyles((theme) => ({
    paddingX: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    orionPreviousbtn: {
      '& .MuiButton-textPrimary': {
        background: 'none',
      },
      '& .MuiButton-textPrimary:hover': {
        background: 'none !important',
        textDecoration: 'none !important',
      },
      '& .MuiButton-iconSizeMedium > *:first-child': {
        fontSize: '0.875rem',
        marginTop: '2px',
      },
      '& span': {
        margin: 0,
      },
    },
  }))()

  return (
    <MonocerosBottomNavigation
      showDivider={showDivider}
      className={clsx(isOrionTheme && classes.paddingX, isOrionTheme && classes.orionPreviousbtn)}
      endActions={
        userDefinedEndActions || (
          <Button
            variant="contained"
            color="primary"
            disabled={continueDisabled}
            data-testid="bottom-navigation-continue-button"
            onClick={onContinue}
          >
            Continue
          </Button>
        )
      }
      startActions={
        userDefinedStartActions || (
          <Button variant="text" color="primary" startIcon={<Icon name="chevron-left" />} onClick={onPrevious}>
            Previous
          </Button>
        )
      }
      data-label="bottom-navigation"
      transparentBackground={transparentBackground || isOrionTheme}
    />
  )
}
