import React from 'react'
import { JourneyCwaConfigContext } from './journeyCwaConfigContext'
import { JourneyCheckerResult } from './types'

const useJourneyChecker = (): JourneyCheckerResult => {
  const cwaConfig = React.useContext(JourneyCwaConfigContext)

  return {
    isJourneyWeb: cwaConfig.cwa === 'web',
    isJourneyUnAuth: cwaConfig.cwa === 'unauth',
  }
}

export { useJourneyChecker }
