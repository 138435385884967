import { Box, Icon, makeStyles, Typography } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { IconTitleType } from './types'
import { PageFocus } from '../PageFocus'

const styles = makeStyles((theme) => ({
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(3)} 0`,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}))

interface IconTitleProps {
  title: IconTitleType
  dataTestId?: string
}

export const IconTitle = (props: IconTitleProps) => {
  const classes = styles({})
  const { text, icon } = props.title

  return (
    <Box data-testid={props.dataTestId} className={classes.headingContainer}>
      {icon && <Icon className={classes.icon} size="2.25rem" name={icon} color="primary" />}
      <PageFocus>
        <Typography variant="h4">{text}</Typography>
      </PageFocus>
    </Box>
  )
}
