import { IconProps } from '@lbg-protection/lib-ui-monoceros'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'
import { Answer } from '../../../Shared/Questionaire/types'
import { IconCheckboxAnswer, IconCheckboxQ } from '../../../Shared/Questionaire/questions/IconCheckboxQuestion/types'

export const protectWhat = (answers: Record<string, Answer>): IconCheckboxQ => {
  const showChildrenOption = (answers.protectWho as IconCheckboxAnswer)?.values.includes('children')

  return {
    id: 'protectWhat',
    category: 'lovedOnes',
    type: QuestionTypes.IconCheckbox,
    sectionHeader: 'Your plan',
    title: {
      text: 'Your plan',
      icon: 'book',
    },
    helpText: 'Life Cover could pay out a cash lump sum if you die before your policy ends.',
    question: 'How would you want the money to help your loved ones?',
    options: [
      ...(showChildrenOption
        ? [
            {
              icon: 'family-protector' as IconProps['name'],
              label: 'Raise my children',
              value: 'children',
            },
          ]
        : []),
      {
        icon: 'home',
        label: 'Secure my home',
        value: 'home',
      },
      {
        icon: '2-cards',
        label: 'Pay off my debts',
        value: 'debt',
      },
      {
        icon: 'present',
        label: 'Leave something extra',
        value: 'extra',
      },
    ],
    errorMessage: `Please select the ways you'd like to help your loved ones`,
    JourneyStepName: 'Your plan',
    JourneyStep: '8',
  }
}
