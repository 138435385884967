import { CoverSummaryInterface } from '../../IndicativeQuotePage/types'

export const calculateVoucherAmount = (amount: number): number => {
  if (amount) {
    if (amount >= 5.0 && amount <= 9.99) {
      return 20
    }
    if (amount >= 10.0 && amount <= 19.99) {
      return 40
    }
    if (amount >= 20.0 && amount <= 29.99) {
      return 80
    }
    if (amount >= 30.0 && amount <= 39.99) {
      return 120
    }
    if (amount >= 40.0 && amount <= 49.99) {
      return 160
    }
    if (amount >= 50.0) {
      return 200
    }
  }
  return 0
}

export type Cover = CoverSummaryInterface & { inBasket?: boolean }

export const getHighestPremiumAmount = (lifeCover: Cover, bodyCover: Cover) => {
  if (lifeCover.inBasket && !bodyCover.inBasket) {
    return lifeCover.monthlyCost
  }
  if (!lifeCover.inBasket && bodyCover.inBasket) {
    return bodyCover.monthlyCost
  }
  if (lifeCover.monthlyCost >= 0 && bodyCover.monthlyCost >= 0) {
    return Math.max(lifeCover.monthlyCost, bodyCover.monthlyCost)
  }
  return 0
}
