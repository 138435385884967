import { makeStyles, MonocerosTheme } from '@lbg-protection/lib-ui-monoceros'
import { getMargin } from '../../../../../styles'

export const styles = makeStyles((theme: MonocerosTheme) => {
  const margin = getMargin(theme)

  return {
    helpText: {
      ...margin.standard.bottom,
    },
    input: {
      paddingLeft: theme.spacing(2),
    },
    overlayLink: {
      marginTop: theme.spacing(4),
    },
  }
})
