import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { NgaBridgeWrapper } from '@lbg-cwa/jsbridge'
import { RealNgaBridge } from '@lbg-cwa/jsbridge/dist/NgaBridgeWrapper'
import { getJSBridge } from './nga/getJSBridge'
import { config, EnvironmentConfigInterface } from './config'
import { setStore } from './reduxStore'

const renderApp = async () => {
  const ConnectedAuthApp = React.lazy(() => import(/* webpackChunkName: "authApp" */ './app/auth'))
  const UnAuthApp = React.lazy(() => import(/* webpackChunkName: "unAuthApp" */ './app/unAuth'))
  const configSettings: EnvironmentConfigInterface = await config.get()
  const { store } = setStore()
  const { cwaEnvironmentName } = configSettings
  const realjsBridge = cwaEnvironmentName === 'nga' || cwaEnvironmentName === 'web' ? await getJSBridge() : null
  ReactDOM.render(
    <Suspense fallback="">
      {cwaEnvironmentName === 'nga' || cwaEnvironmentName === 'web' ? (
        <ConnectedAuthApp
          configSettings={configSettings}
          store={store}
          ngaBridgeWrapper={new NgaBridgeWrapper(realjsBridge as RealNgaBridge)}
        />
      ) : (
        <UnAuthApp configSettings={configSettings} store={store} />
      )}
    </Suspense>,
    document.getElementById('root'),
  )
}

renderApp()
