import { FinancesQ } from '../../../Shared/Questionaire/questions/FinancesQuestion/types'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'

export const income = (): FinancesQ => {
  return {
    id: 'income',
    category: 'finances',
    type: QuestionTypes.Finances,
    sectionHeader: 'Your finances',
    title: {
      text: 'Your finances',
      icon: 'coins',
    },
    question: 'How much do you earn each month, after tax?',
    helpText: [
      'If you were diagnosed with a critical illness, your cover could help you and your loved ones keep up with day-to-day costs.',
    ],
    fieldConfig: {
      required: true,
      requiredErrorMessage: 'Please enter your monthly income after tax',
      min: 0,
      max: 9999999.99,
      maxErrorMessage: 'Please enter a value of less than £10 million',
      decimals: 2,
    },
    JourneyStepName: 'Income',
    JourneyStep: '15',
  }
}
