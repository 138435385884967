import {
  Box,
  ButtonV2 as Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinkV2 as Link,
  ListItemWithBullet,
  ListWithBullets,
  makeStyles,
  MonocerosTheme,
  Typography,
} from '@lbg-protection/lib-ui-monoceros'
import React, { useState } from 'react'
import { PageFocus } from '../../../../PageFocus'

interface AwaitingInvestigationsDialogProps {
  id: string
}

const useStyles = makeStyles((theme: MonocerosTheme) => {
  return {
    linkIndent: {
      margin: `${theme.spacing(1.5)} ${theme.spacing(6)}`,
      display: 'block',
    },
    investigationBox: {
      marginTop: '1.8rem',
      '&:first-child': {
        marginTop: 0,
      },
    },
  }
})

export const AwaitingInvestigationsDialog = (props: AwaitingInvestigationsDialogProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const toggleOpen = () => {
    setIsOpen((prevState) => !prevState)
  }

  const investigations = [
    {
      id: 0,
      text: 'Scans',
      testId: 'scans-bullpoint-text-container',
      bullet: ['MRI', 'CT', 'Mammogram', 'Ultrasound'],
    },
    {
      id: 1,
      text: 'Heart investigations',
      testId: 'heart-investigations-bullpoint-text-container',
      bullet: ['Angiogram', 'Echocardiogram', 'Electrocardiogram', 'Heart rate monitor'],
    },
    {
      id: 2,
      text: 'Internal camera investigations',
      testId: 'internal-investigations-bullpoint-text-container',
      bullet: ['Colonoscopy', 'Endoscopy'],
    },
  ]

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        href={`#${props.id}-dialog-link`}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault()
          toggleOpen()
        }}
        className={classes.linkIndent}
        variant="body1"
        data-testid="helper-text-dialog-typography"
      >
        Examples of tests you need to tell us about
      </Link>
      <Dialog data-testid="helper-text-dialog" onClose={toggleOpen} open={isOpen}>
        <PageFocus>
          <DialogTitle data-testid="helper-text-dialog-heading" onClose={toggleOpen}>
            Example of tests you need to tell us about
          </DialogTitle>
        </PageFocus>
        <DialogContent>
          {investigations.map((investigation) => (
            <Box
              key={`${investigation.id}-${investigation.testId}`}
              data-testid={`helper-text-dialog-${investigation.id}`}
              className={classes.investigationBox}
            >
              <Typography variant="subtitle2" gutterBottom key={`${investigation.id}-${investigation.testId}`}>
                {investigation.text}
              </Typography>
              <ListWithBullets disablePadding>
                {investigation.bullet.map((bullet) => {
                  return <ListItemWithBullet>{bullet}</ListItemWithBullet>
                })}
              </ListWithBullets>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={toggleOpen} data-testid="dd-overlay-close">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
