import { connect } from 'react-redux'
import * as Types from '../../../app/types'
import { SetPreferenceActionProps } from '../types'
import { MarketingPreferences } from './MarketingPreferences'
import { marketingPreferencesSlice } from './marketingpreferences.slice'
import { MarketingPreferencesDispatchProps, MarketingPreferencesStateProps } from './types'

const mapStateToProps = (state: Types.RootState): MarketingPreferencesStateProps => ({
  marketingPreferences: state.marketingPreferences,
})

const mapDispatchToProps = (dispatch: Types.AppDispatch): MarketingPreferencesDispatchProps => ({
  setMarketingPreference: (marketingPreferences: SetPreferenceActionProps): void =>
    dispatch(marketingPreferencesSlice.actions.setMarketingPreference(marketingPreferences)),
})

const ConnectedMarketingPreferences = connect(mapStateToProps, mapDispatchToProps)(MarketingPreferences)

export { ConnectedMarketingPreferences }
