import { useContext } from 'react'
import { FeatureFlagContext } from './featureFlagContext'
import { FeatureFlags } from './types'

const useFeatureFlags = () => {
  const { featureFlags } = useContext(FeatureFlagContext)

  const isFeatureEnabled = (key: keyof FeatureFlags): boolean => {
    const val = featureFlags && featureFlags[key]
    return typeof val === 'boolean' ? val : false
  }

  return {
    featureFlags,
    isFeatureEnabled,
  }
}

export { useFeatureFlags }
