import {
  AboutYouAddressResultSetResults as AboutYouAddressResult,
  AboutYouResponseData as AboutYouData,
  AboutYouProductHolding,
} from '@lbg-protection/api-client-nmp-journey'
import { createAsyncThunk, createSelector, createSlice, PayloadAction, Reducer, Selector } from '@reduxjs/toolkit'
import { authJourneyApi } from '../../apis/authJourneyApi'
import { JourneyNames } from '../../config'
import { AddressInterface, AddressResult } from './types'
import { transformAboutYouProducts } from './utils'

export enum AboutYouLoadingStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export interface AboutYouState {
  aboutYouData?: AboutYouData
  confirmedAddress?: AddressInterface
  loadingStatus: AboutYouLoadingStatus
  showAddressFinder: boolean
}

const initialState: AboutYouState = {
  loadingStatus: AboutYouLoadingStatus.Idle,
  showAddressFinder: true,
}

const aboutYouInitialState: AboutYouData = {
  title: '',
  lastName: '',
  dateOfBirth: '',
  addressesMatchingPostCode: { total: 0, results: [] },
}
export interface AboutYouApplicantDetails {
  title: string
  firstName: string
  lastName: string
  dateOfBirth: Date
  telephone?: string
  email?: string
  postCode: string
}

type Reducers = {
  submitConfirmedAddress: Reducer<AboutYouState, PayloadAction<AddressInterface>>
  clearConfirmedAddress: Reducer<AboutYouState>
  setDateOfBirth: Reducer<AboutYouState, PayloadAction<string>>
  setFirstName: Reducer<AboutYouState, PayloadAction<string>>
  setLastName: Reducer<AboutYouState, PayloadAction<string>>
  setTitle: Reducer<AboutYouState, PayloadAction<string>>
  setEmailAddress: Reducer<AboutYouState, PayloadAction<string>>
  setTelePhone: Reducer<AboutYouState, PayloadAction<string>>
  setShowAddressFinder: Reducer<AboutYouState, PayloadAction<boolean>>
}

const reducers: Reducers = {
  submitConfirmedAddress: (state = initialState, action) => {
    return { ...state, confirmedAddress: action.payload }
  },
  clearConfirmedAddress: (state = initialState) => {
    return { ...state, confirmedAddress: undefined }
  },
  setShowAddressFinder: (state = initialState, action) => {
    return { ...state, showAddressFinder: action.payload }
  },
  setDateOfBirth: (state = initialState, action) => {
    const dateOfBirth = action.payload
    const aboutYouDetails = state.aboutYouData ?? aboutYouInitialState
    return { ...state, aboutYouData: { ...aboutYouDetails, dateOfBirth } }
  },
  setFirstName: (state = initialState, action) => {
    const firstName = action.payload
    const aboutYouDetails = state.aboutYouData ?? aboutYouInitialState
    return { ...state, aboutYouData: { ...aboutYouDetails, firstName } }
  },
  setLastName: (state = initialState, action) => {
    const lastName = action.payload
    const aboutYouDetails = state.aboutYouData ?? aboutYouInitialState
    return { ...state, aboutYouData: { ...aboutYouDetails, lastName } }
  },
  setTitle: (state = initialState, action) => {
    const title = action.payload
    const aboutYouDetails = state.aboutYouData ?? aboutYouInitialState
    return { ...state, aboutYouData: { ...aboutYouDetails, title } }
  },
  setEmailAddress: (state = initialState, action) => {
    const primaryEmailAddressMasked = action.payload
    const aboutYouDetails = state.aboutYouData ?? aboutYouInitialState
    return { ...state, aboutYouData: { ...aboutYouDetails, primaryEmailAddressMasked } }
  },
  setTelePhone: (state = initialState, action) => {
    const primaryTelephoneMasked = action.payload
    const aboutYouDetails = state.aboutYouData ?? aboutYouInitialState
    return { ...state, aboutYouData: { ...aboutYouDetails, primaryTelephoneMasked } }
  },
}

export const getAboutYouThunk = createAsyncThunk(
  'aboutYou/get',
  async (journeyName: JourneyNames): Promise<AboutYouData> => {
    return authJourneyApi().getAboutYou(journeyName)
  },
)
export const aboutYouSlice = createSlice({
  name: 'aboutYou',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addCase(getAboutYouThunk.pending, (state, _action) => {
      return { ...state, loadingStatus: AboutYouLoadingStatus.Loading }
    })
    builder.addCase(getAboutYouThunk.fulfilled, (state, action) => {
      return { ...state, loadingStatus: AboutYouLoadingStatus.Success, aboutYouData: action.payload }
    })
    builder.addCase(getAboutYouThunk.rejected, (state, _action) => {
      return { ...state, loadingStatus: AboutYouLoadingStatus.Error }
    })
  },
})

export const selectAboutYouData: Selector<AboutYouState, AboutYouData> = (state) => state.aboutYouData as AboutYouData
export const selectApplicantDetails: Selector<AboutYouState, AboutYouApplicantDetails> = createSelector(
  selectAboutYouData,
  (aboutYouData) =>
    ({
      title: aboutYouData?.title,
      firstName: aboutYouData?.firstName as string,
      lastName: aboutYouData?.lastName,
      dateOfBirth: aboutYouData?.dateOfBirth ? new Date(aboutYouData.dateOfBirth) : undefined,
      telephone: aboutYouData?.primaryTelephoneMasked,
      email: aboutYouData?.primaryEmailAddressMasked,
      postCode: aboutYouData?.postCode as string,
    } as AboutYouApplicantDetails),
)
const selectAboutYouAddresses: Selector<AboutYouState, Array<AboutYouAddressResult>> = createSelector(
  selectAboutYouData,
  (aboutYouData) => aboutYouData.addressesMatchingPostCode.results,
)
// Filter for full structured data presence, sort descending by relevance, then map to full address interface
export const selectFilteredAddresses: Selector<AboutYouState, Array<AddressResult>> = createSelector(
  selectAboutYouAddresses,
  (results) =>
    results
      .filter(
        (result) =>
          result.result.subBuildingName !== undefined &&
          result.result.buildingName !== undefined &&
          result.result.buildingNumber !== undefined &&
          result.result.district !== undefined &&
          result.result.postTown !== undefined &&
          result.result.county !== undefined,
      )
      .sort((a, b) => b.relevancy - a.relevancy)
      .map(
        (result): AddressResult => ({
          address: {
            postCode: result.result.postcode,
            subBuildingName: result.result.subBuildingName as string,
            buildingName: result.result.buildingName as string,
            buildingNumber: result.result.buildingNumber as string,
            addressLines: result.result.addressLines,
            district: result.result.district as string,
            postTown: result.result.postTown as string,
            county: result.result.county as string,
          },
          summary: result.summary,
        }),
      ),
)
export const selectConfirmedAddress: Selector<AboutYouState, AboutYouState['confirmedAddress']> = (state) =>
  state.confirmedAddress
export const selectPostTown: Selector<AboutYouState, string> = createSelector(
  selectFilteredAddresses,
  (addresses) => addresses[0].address.postTown,
)
export const selectAboutYouLoadingStatus: Selector<AboutYouState, AboutYouLoadingStatus> = (state) =>
  state.loadingStatus
export const selectValidDataLoaded: Selector<AboutYouState, boolean> = (state) =>
  !!state.aboutYouData?.postCode && !!state.aboutYouData?.addressesMatchingPostCode?.results?.[0]?.result?.postTown
export const selectProductHoldings: Selector<AboutYouState, AboutYouProductHolding[]> = (state) =>
  transformAboutYouProducts(state.aboutYouData?.productHoldings ?? [])

export const selectDateOfBirth: Selector<AboutYouState, string> = (state) => state.aboutYouData?.dateOfBirth ?? ''
export const selectShowAddressFinder: Selector<AboutYouState, boolean> = (state) => state.showAddressFinder
