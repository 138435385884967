import React from 'react'
import { Box, Icon, IconProps, makeStyles } from '@lbg-protection/lib-ui-monoceros'

interface TempCheckboxIconProps {
  checked: boolean
  icon: IconProps['name']
  error?: boolean
}

const styles = (props: TempCheckboxIconProps) =>
  makeStyles((theme) => ({
    uncheckedRect: {
      height: '70px',
      width: '60px',
      border: `2px solid ${!props.error ? '#838EA4' : theme.palette.error.main}`,
      borderRadius: '6px',
    },
    checkedRect: {
      height: '70px',
      width: '60px',
      border: `2px solid ${theme.palette.primary.dark}`,
      borderRadius: '6px',
      backgroundColor: theme.palette.primary.dark,
    },
    uncheckedIcon: {
      position: 'relative',
      top: '15%',
    },
    checkedIcon: {
      position: 'relative',
      top: '15%',
      fill: `${theme.palette.common.white} !important`,
    },
    checkedTickContainer: {
      position: 'absolute',
      top: '-0.35rem',
      right: '-0.35rem',
      height: '22px',
      width: '22px',
      border: `1.5px solid ${theme.palette.primary.main}`,
      borderRadius: '50%',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
    },
    tick: {
      position: 'absolute',
      top: '0.15rem',
      right: '0.15rem',
      color: theme.palette.primary.main,
    },
  }))

export const TempCheckboxIcon = (props: TempCheckboxIconProps) => {
  const classes = styles(props)({})

  return (
    <>
      <Box className={props.checked ? classes.checkedRect : classes.uncheckedRect}>
        {props.checked && (
          <Box className={classes.checkedTickContainer}>
            <Icon className={classes.tick} name="tick" size="0.9rem" />
          </Box>
        )}
        <Icon
          className={props.checked ? classes.checkedIcon : classes.uncheckedIcon}
          name={props.icon}
          color="primary"
          size="2.5rem"
        />
      </Box>
    </>
  )
}
