import { Box, Typography } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { BulletPointWrapper } from '../../Shared/BulletPointWrapper'
import { withStyles } from './styles'

const amountsRightForMe = [
  'Helping with the day-to-day costs',
  'Paying off any debts you may have, like a mortgage',
  'Leaving money for the future.',
]

export const LifeCoverAmountRightForMe = () => {
  const classes = withStyles()

  return (
    <Box>
      <Typography className={classes.textContainer} variant="body1" data-testid="life-cover-amount-question-answer">
        It might help to think about the kind of support your loved ones would need if you couldn&#39;t be there. Things
        like:
      </Typography>
      <Box data-testid="life-cover-amount-question-answer-bulletpoints">
        <BulletPointWrapper bulletPoints={amountsRightForMe} />
      </Box>
    </Box>
  )
}
