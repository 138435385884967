import React, { useEffect } from 'react'
import {
  BottomNavigation,
  Dialog,
  DialogActions,
  DialogContent,
  Brand,
  makeStyles,
  ButtonV2 as Button,
  Icon,
  IconProps,
  MonocerosTheme,
  useTheme,
  Typography,
} from '@lbg-protection/lib-ui-monoceros'
import { getIllustrationSize, isLloyds, isSW } from '../../../../utils/brandSpecificFormatting'
import { useContent } from '../../../Content/useContent'
import { SaveAndRetrieveContent } from '../../SaveAndRetrieveContent'
import { tagSaveResultPageLoad } from '../../tags'
import { PageFocus } from '../../../Shared/PageFocus'
import { tagButtonClick } from '../../../../utils/tagUtils/tagsUtils'

const styles = makeStyles((theme) => ({
  icon: {
    marginTop: theme.spacing(1),
  },
  heading: {
    margin: theme.spacing(4, 0, 3, 0),
  },
  bottomActions: {
    marginBottom: theme.spacing(2),
    borderTop: 'none',
    padding: 0,
  },
}))

interface SaveErrorProps {
  resetSaveStatus: () => void
}

export const SaveError: React.FC<SaveErrorProps> = (props) => {
  const classes = styles({})
  const { name: brand = Brand.HALIFAX2020, palette } = useTheme<MonocerosTheme>()
  const content = useContent<SaveAndRetrieveContent>()
  const iconName = content('saveErrorIcon', 'Tools-illustration') as IconProps['name']
  const iconColor = isLloyds(brand) ? palette.namedColours?.lloydsBrightGreen : palette.namedColours?.halifaxCoreBlue
  const isSwBrand = isSW(brand)
  const buttonText = isSwBrand ? 'Go back to my application' : 'Back to application'

  useEffect(() => {
    tagSaveResultPageLoad('ERROR')
  }, [])

  const backToApplication = () => {
    props.resetSaveStatus()
    tagButtonClick(buttonText)
  }

  return (
    <Dialog data-testid="save-error-container" open onClose={backToApplication}>
      <DialogContent role="main">
        <Icon className={classes.icon} name={iconName} htmlColor={iconColor} size={getIllustrationSize(brand)} />
        <PageFocus>
          <Typography className={classes.heading} variant="h4">
            Sorry, we haven&apos;t been able to save
          </Typography>
        </PageFocus>
        <Typography>
          Please try saving again. If you see this error again, you can still carry on with your application, we just
          won&apos;t be able to save your progress for now.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.bottomActions}>
        <BottomNavigation
          startActions={
            <Button
              data-testid="save-error-go-back"
              aria-label={buttonText}
              variant="contained"
              color="primary"
              onClick={backToApplication}
            >
              {buttonText}
            </Button>
          }
        />
      </DialogActions>
    </Dialog>
  )
}
