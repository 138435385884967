import { ButtonV2 as Button, Icon } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { BottomNavigation } from '../Shared/BottomNavigation'
import { tagButtonClick } from '../../utils/tagUtils/tagsUtils'

type NavProps = {
  goToSpecialRequirements: () => void
}

export const MarketingPreferencesFooter = (props: NavProps) => (
  <BottomNavigation userDefinedStartActions={<StartActions {...props} />} userDefinedEndActions={<EndActions />} />
)

const EndActions = () => {
  return (
    <>
      <Button
        type="submit"
        form="marketing-prefs-form"
        variant="contained"
        color="primary"
        data-testid="marketing-preferences-continue"
        onClick={() => tagButtonClick('Continue')}
      >
        Continue
      </Button>
    </>
  )
}

const StartActions = ({ goToSpecialRequirements }: NavProps) => {
  const handlePrevious = () => {
    tagButtonClick('Previous')
    goToSpecialRequirements()
  }

  return (
    <>
      <Button
        variant="text"
        color="primary"
        startIcon={<Icon name="chevron-left" />}
        onClick={handlePrevious}
        data-testid="marketing-preferences-previous"
      >
        Previous
      </Button>
    </>
  )
}
