import { OptionInterface } from '../types'

export const answerOptions: OptionInterface[] = [
  {
    id: '1',
    option: 'Yes',
    outcome: 'ineligible',
    exclusive: true,
    testId: 'treatment-yes-select',
  },
  {
    id: '2',
    option: 'No',
    outcome: 'eligible',
    exclusive: true,
    testId: 'treatment-no-select',
  },
]
