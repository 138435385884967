import {
  Box,
  Brand,
  FormField,
  Icon,
  ListItemWithBullet,
  ListWithBullets,
  makeStyles,
  MonocerosTheme,
  SelectItem,
  SelectItemGroup,
  Typography,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import clsx from 'clsx'
import { useNavigateTo } from '../../../../../utils/useNavigateTo'
import { IconTitle } from '../../../IconTitle'
import { SubmitAnswer } from '../../types'
import { AwaitingInvestigationsDialog } from './AwaitingInvestigationsDialog/AwaitingInvestigationsDialog'
import { MultipleChoiceAnswer, MultipleChoiceQ } from './types'
import { brandHasBackground, isSW } from '../../../../../utils/brandSpecificFormatting'
import { CheckBeforeContinue, CheckBeforeContinueProps } from './CheckBeforeContinue'

const useStyles = makeStyles((theme: MonocerosTheme) => {
  return {
    container: {
      marginTop: theme.spacing(3),
    },
    sectionHeading: {
      marginBottom: theme.spacing(3),
    },
    questionPart2: {
      display: 'block',
      marginTop: theme.spacing(3),
    },
    formBackground: {
      backgroundColor: 'white',
      margin: `0 ${theme.spacing(-3)}`,
      padding: `${theme.spacing(4)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`,
    },
    form: {
      paddingBottom: theme.spacing(0.5),
    },
    selectItem: {
      '&:last-child': { marginBottom: 0 },
    },
    checkboxText: {
      lineHeight: theme.spacing(4),
    },
  }
})

interface QuestionProps {
  question: MultipleChoiceQ
  answer: MultipleChoiceAnswer | null
  submitAnswer: SubmitAnswer
  goToNextQuestion: () => void
  customNextRoute?: string
  checkBeforeContinueProps?: CheckBeforeContinueProps
}

export const Question = ({
  question,
  answer,
  submitAnswer,
  goToNextQuestion,
  customNextRoute,
  checkBeforeContinueProps,
}: QuestionProps) => {
  const classes = useStyles()
  const theme = useTheme<MonocerosTheme>()
  const navigateTo = useNavigateTo()
  const { errors, control, handleSubmit, watch } = useForm({ mode: 'onSubmit', reValidateMode: 'onChange' })
  const iconColor =
    theme.name === 'lloyds' ? theme.palette.namedColours?.lloydsInfo : theme.palette.namedColours?.halifaxCoreBlue

  const valuesAreSame = (valueA: any, valueB: any): boolean => {
    let isSame = false

    if (valueA === valueB) {
      isSame = true
    } else if (Array.isArray(valueA) && Array.isArray(valueB) && valueA.length === valueB.length) {
      const valueAClone = [...valueA]
      const valueBClone = [...valueB]

      valueAClone.sort()
      valueBClone.sort()

      isSame = valueAClone.every((valueAItem, index) => valueAItem === valueBClone[index])
    }

    return isSame
  }

  const formValues = watch()

  useEffect(() => {
    const nextAnswerValuesIds = answer?.values.map((value) => value.id) ?? []
    const selectedOptionsIds = formValues[question.id] ?? []
    if (!valuesAreSame(selectedOptionsIds, nextAnswerValuesIds)) {
      const values = selectedOptionsIds.map((id: string) => question.options.find((option) => option.id === id))
      submitAnswer({
        questionId: question.id,
        question: question.question,
        category: question.category,
        values,
      })
    }
  }, [formValues[question.id], answer])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [errors[question.id]])

  const onSubmit = () => {
    customNextRoute ? navigateTo(customNextRoute) : goToNextQuestion() // eslint-disable-line no-unused-expressions
  }

  return (
    <Box className={question.sectionHeader ? classes.container : ''} data-testid={`${question.id}-question-container`}>
      {question.title && <IconTitle title={question.title} />}
      {question.sectionHeader && (
        <Typography
          className={classes.sectionHeading}
          data-testid={`${question.category}-section-header`}
          component="h1"
          variant="h4"
        >
          {question.sectionHeader}
        </Typography>
      )}
      {question.description}
      <Box className={clsx(brandHasBackground(theme.name) && classes.formBackground, classes.form)}>
        <form
          id={`${question.id}-question-form`}
          data-testid={`${question.id}-question-form`}
          onSubmit={handleSubmit(onSubmit)}
          action=""
        >
          <Controller
            name={question.id}
            control={control}
            defaultValue={answer?.values.map((value) => value.id) ?? []}
            rules={{
              validate: (value) => {
                return value && value.length > 0
              },
            }}
            render={({ onChange, onBlur, value }) => (
              <FormField
                id={question.id}
                label={
                  <>
                    {question.question && <>{question.question}</>}{' '}
                    {question.question2 && (
                      <>
                        <span className={classes.questionPart2}>{question.question2}</span>
                      </>
                    )}
                  </>
                }
                name={question.id}
                onChange={(_event: unknown, values: string[]) => {
                  onChange({ target: { value: values, name: question.id }, type: 'change' })
                }}
                onBlur={onBlur}
                errorMessage={errors[question.id] ? question.errorMessage : undefined}
                fullWidth
                gutterBottom={false}
                value={value}
                helperText={
                  question.helpText && (
                    <>
                      <ListWithBullets>
                        <ListItemWithBullet bullet={<Icon name="information" size="24px" htmlColor={iconColor} />}>
                          {question.helpText}
                          {question.reasons && (
                            <ListWithBullets>
                              {question.reasons?.map((reason) => {
                                return <ListItemWithBullet>{reason}</ListItemWithBullet>
                              })}
                            </ListWithBullets>
                          )}
                        </ListItemWithBullet>
                      </ListWithBullets>
                      {question.id === 'AwaitingInvestigations' && <AwaitingInvestigationsDialog id={question.id} />}
                    </>
                  )
                }
              >
                <SelectItemGroup spaced={question.spaced}>
                  {question.options.map((option, index) => (
                    <SelectItem
                      className={classes.selectItem}
                      textAlign={question.centreOptions ? 'center' : 'left'}
                      value={option.id}
                      exclusive={option.exclusive}
                      key={option.id}
                      data-testid={`${question.category}-option-${option.id}`}
                      checkbox={option.checkbox}
                      aria-labelledby={`option${index}`}
                      selected={answer?.values.some((answerValue) => answerValue.id === option.id)}
                      showTickOnSelect={isSW(theme.name as Brand)}
                    >
                      <Box component="span" id={`option${index}`}>
                        {option.option}
                      </Box>
                    </SelectItem>
                  ))}
                </SelectItemGroup>
              </FormField>
            )}
          />
          {checkBeforeContinueProps &&
            answer?.values[0]?.id !== undefined &&
            answer?.values[0]?.id !== checkBeforeContinueProps?.doNotDisplayWhenAnswer && (
              <CheckBeforeContinue
                control={control}
                answer={answer}
                errors={errors}
                checkBeforeContinueProps={checkBeforeContinueProps}
              />
            )}
        </form>
      </Box>
    </Box>
  )
}
