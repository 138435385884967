import {
  EmailSavedQuoteRequestBody,
  PersistenceRetrieveResponseBodyData,
  PersistenceSaveRequestBody,
} from '@lbg-protection/api-client-nmp-journey'
import { authJourneyApi } from '../../apis/authJourneyApi'
import { AddressInterface } from '../../journeys/purchase'
import { getPolicies } from '../PaymentDetailsPage/utils'
import { JourneyExitPoint, ProcessSavedQuoteEmailParams, SaveJourneyRequestParams } from './types'
import { getJourneyExitPoint } from './utils/getJourneyExitPoint'

export const saveAndRetrieveApi = {
  convertAddressForSaveRequest: (
    address: AddressInterface,
  ): PersistenceSaveRequestBody['applicationState']['address'] => {
    const { postCode, ...restOfAddress } = address

    return { ...restOfAddress, postcode: postCode }
  },
  buildSaveJourneyRequestBody: ({
    confirmedAddress,
    lifeCover,
    bodyCover,
    enquiryId,
    lastQuoteDate,
    journeyExitPoint,
    cavendishOnlineConsent,
  }: SaveJourneyRequestParams): PersistenceSaveRequestBody => {
    const policies = getPolicies(lifeCover, bodyCover)
    const exitPoint = journeyExitPoint ?? getJourneyExitPoint(policies, lastQuoteDate)
    const address = confirmedAddress
      ? { address: saveAndRetrieveApi.convertAddressForSaveRequest(confirmedAddress) }
      : {}
    const cavendishConsent = cavendishOnlineConsent !== undefined ? { cavendishOnlineConsent } : {}

    const baseRequest = {
      applicationState: {
        ...address,
        journeyExitPoint: exitPoint,
        uwMeEnquiryId: enquiryId,
        policies,
      },
      ...cavendishConsent,
    }

    return lastQuoteDate ? { ...baseRequest, persistStoredQuoteDate: true } : baseRequest
  },
  saveJourney: async ({
    confirmedAddress,
    lifeCover,
    bodyCover,
    enquiryId,
    lastQuoteDate,
    journeyExitPoint,
    cavendishOnlineConsent,
  }: SaveJourneyRequestParams) => {
    await authJourneyApi().saveJourney(
      saveAndRetrieveApi.buildSaveJourneyRequestBody({
        confirmedAddress,
        lifeCover,
        bodyCover,
        enquiryId,
        lastQuoteDate,
        journeyExitPoint,
        cavendishOnlineConsent,
      }),
    )
  },
  getQuoteType: (
    lifeCover: ProcessSavedQuoteEmailParams['lifeCover'],
    bodyCover: ProcessSavedQuoteEmailParams['bodyCover'],
  ): EmailSavedQuoteRequestBody['quoteType'] => {
    if (lifeCover.inBasket && !bodyCover.inBasket) {
      return 'LIFE'
    }
    if (bodyCover.inBasket && !lifeCover.inBasket) {
      return 'BODY'
    }

    return 'LIFE_AND_BODY'
  },
  processSavedQuoteEmail: async ({ lifeCover, bodyCover, quoteValidUntil }: ProcessSavedQuoteEmailParams) => {
    await authJourneyApi().processSavedQuoteEmail({
      quoteType: saveAndRetrieveApi.getQuoteType(lifeCover, bodyCover),
      quoteValidUntil,
    })
  },
  retrieveJourney: async (): Promise<PersistenceRetrieveResponseBodyData> => {
    const response = await authJourneyApi().retrieveJourney()

    return response
  },
  markSavedJourneyAsPurchased: async () => {
    await authJourneyApi().saveJourney({ applicationState: { journeyExitPoint: JourneyExitPoint.Purchase } })
  },
  saveCavendishOnlineConsent: async (consent: boolean) => {
    await authJourneyApi().saveJourney({
      applicationState: { journeyExitPoint: 'CAVENDISH_CONSENT' },
      cavendishOnlineConsent: consent,
    })
  },
  requestCallback: async (page: string) => {
    await authJourneyApi().saveJourney({
      applicationState: { journeyExitPoint: page },
      cavendishOnlineConsent: true,
    })
  },
}
