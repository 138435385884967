import {
  Box,
  Brand,
  ListItemWithBullet,
  ListWithBullets,
  MonocerosTheme,
  Typography,
  makeStyles,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'

import { InfoDialog } from '../InfoDialog/InfoDialog'
import {
  eligibilityBulletList,
  generalConditionsBulletList,
  howToClaimBulletList,
  termsandConditionsOrderList,
} from './voucherIncentiveData'
import { isSW } from '../../../utils/brandSpecificFormatting'

const withStyles = makeStyles((theme: MonocerosTheme) => {
  const isOrionTheme = isSW(theme.name as Brand)
  return {
    section: {
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(4),
    },
    sectionTextPadding: {
      paddingBottom: theme.spacing(isOrionTheme ? 4 : 3),
    },
    title: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(5),
    },
    voucherTerms: {
      paddingBottom: theme.spacing(4),
    },
  }
})

interface VoucherTermsAndConditionsOverlayProps {
  open: boolean
  toggleOpen: () => void
  policyDurationBullet: string
  giftCardValue: string
}

export const VoucherTermsAndConditionsOverlay = ({
  open,
  toggleOpen,
  policyDurationBullet,
  giftCardValue,
}: VoucherTermsAndConditionsOverlayProps) => {
  const classes = withStyles({})
  const eligibilitySecondParagraph = `You make a minimum of six monthly premium payments and the total premiums paid across all of your new policies exceed ${giftCardValue}.`
  const thankYouText = '‘Simply Thank You’'
  const { name: themeName = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(themeName)
  const boldString = (str: string, subStr: string) => {
    const splitTextArr = str.split(subStr)
    return (
      <>
        {splitTextArr[0]}
        {splitTextArr.length > 1 ? (
          <>
            <Typography variant="body2" display="inline">
              {subStr}
            </Typography>
            {splitTextArr[1]}
          </>
        ) : (
          undefined
        )}
      </>
    )
  }
  const textVariant = isSWBrand ? 'h5' : 'subtitle2'

  return (
    <InfoDialog open={open} data-testid="terms-and-conditions" title="Terms and conditions" toggleOpen={toggleOpen}>
      <Typography
        variant={textVariant}
        className={classes.sectionTextPadding}
        data-testid="terms-and-conditions-eligibility"
        component="h2"
        tabIndex={0}
      >
        Eligibility
      </Typography>
      <Typography variant="body1" className={classes.sectionTextPadding} tabIndex={0}>
        You will receive this offer if:
      </Typography>
      <Box tabIndex={0}>
        <ListWithBullets data-testid="terms-and-conditions-eligibility-bullets">
          <ListItemWithBullet> {policyDurationBullet} </ListItemWithBullet>
          <ListItemWithBullet>{eligibilitySecondParagraph}</ListItemWithBullet>
          {eligibilityBulletList.map((text) => (
            <ListItemWithBullet> {text} </ListItemWithBullet>
          ))}
        </ListWithBullets>
      </Box>

      <Typography
        variant={textVariant}
        component="h2"
        className={classes.section}
        data-testid="terms-and-conditions-how-to-claim"
        tabIndex={0}
      >
        How to claim
      </Typography>
      <Box tabIndex={0}>
        <ListWithBullets data-testid="terms-and-conditions-how-to-claim-bullets">
          {howToClaimBulletList.map((text) => (
            <ListItemWithBullet> {text} </ListItemWithBullet>
          ))}
        </ListWithBullets>
      </Box>

      <Typography
        variant={textVariant}
        className={classes.section}
        data-testid="terms-and-conditions-general-conditions"
        tabIndex={0}
        component="h2"
      >
        General Conditions
      </Typography>
      <Box tabIndex={0}>
        <ListWithBullets data-testid="terms-and-conditions-general-conditions-bullets">
          {generalConditionsBulletList.map((text, index) => (
            <ListItemWithBullet key={`terms-and-conditions-general-conditions-bullet-${index}`}>
              {index === 1 ? boldString(text, thankYouText) : text}
            </ListItemWithBullet>
          ))}
        </ListWithBullets>
      </Box>

      {termsandConditionsOrderList.map((item) => {
        if (item.style === 'subtitle2') {
          return (
            <Typography
              variant={textVariant}
              className={classes.section}
              data-testid={`voucher-terms-and-conditions-${item.dataTestID}`}
              tabIndex={0}
              component="h2"
            >
              {item.text}
            </Typography>
          )
        }
        if (item.style === 'subtitle1') {
          return (
            <Typography variant="subtitle1" className={classes.section} tabIndex={0} component="h2">
              {item.text}
            </Typography>
          )
        }
        if (item.style === 'body') {
          return (
            <Typography className={classes.voucherTerms} variant="body1" tabIndex={0}>
              {item.text}
            </Typography>
          )
        }
        if (item.style === 'bulletpoints') {
          return (
            <Box tabIndex={0}>
              <ListWithBullets>
                {(item.text as string[]).map((point) => (
                  <ListItemWithBullet>
                    <Typography variant="body1">{point}</Typography>
                  </ListItemWithBullet>
                ))}
              </ListWithBullets>
            </Box>
          )
        }
        if (item.style === 'textlist') {
          return (
            <Box tabIndex={0}>
              {(item.text as string[]).map((point) => (
                <Typography variant="body1" className={classes.sectionTextPadding}>
                  {point}
                </Typography>
              ))}
            </Box>
          )
        }
        return <></>
      })}
    </InfoDialog>
  )
}
