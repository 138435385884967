import React from 'react'
import { makeStyles, Box, Typography, MonocerosTheme, Brand, useTheme } from '@lbg-protection/lib-ui-monoceros'
import { isBOS, isHalifax, isLloyds, isSW } from '../../../utils/brandSpecificFormatting'

const getBackgroundColor = (theme: MonocerosTheme) => {
  if (isBOS(theme.name as Brand)) return theme.palette.namedColours?.bosDarkBlue
  if (isHalifax(theme.name as Brand)) return theme.palette.namedColours?.halifaxDarkBlue
  if (isLloyds(theme.name as Brand)) return theme.palette.namedColours?.lloydsBottleGreen
  if (isSW(theme.name as Brand)) return theme.palette.namedColours?.orionStrongCopyGrey

  return theme.palette.primary.dark
}

const styles = makeStyles((theme: MonocerosTheme) => {
  return {
    footerTextContainer: {
      backgroundColor: getBackgroundColor(theme),
      paddingBlock: theme.spacing(4),
    },

    footerText: {
      color: theme.palette.common.white,
      fontSize: '14px',
      margin: '0 14%',
      [theme.breakpoints.down('xs')]: {
        margin: `0 ${theme.spacing(3)}`,
      },
    },
  }
})

export const FooterTextBox = () => {
  const { name: themeName = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(themeName)

  const classes = styles()

  return (
    <Box className={classes.footerTextContainer} data-testid="footer-text-box">
      <Typography className={classes.footerText} variant="body1">
        {!isSWBrand &&
          'Life and Critical Illness policies are arranged by Scottish Widows Limited. This website is operated by '}
        Scottish Widows Limited. Registered Office: 25 Gresham Street, London EC2V 7HN. Registered in England and Wales,
        Company number 3196171. Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct
        Authority and the Prudential Regulation Authority under registration number 181655. Your calls may be recorded
        for accuracy of information.
      </Typography>
    </Box>
  )
}
