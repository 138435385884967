import {
  Box,
  Icon,
  List,
  ListItem,
  makeStyles,
  MonocerosTheme,
  SelectItem,
  SelectItemGroup,
  Typography,
} from '@lbg-protection/lib-ui-monoceros'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { SubmitAnswer } from '../../types'
import { OptionInterface, TreatmentAnswer, TreatmentQ } from './types'
import { answerOptions } from './utils/answerOptions'
import { canSubmitAnswer } from './utils/canSubmitAnswer'

const useStyles = makeStyles((theme: MonocerosTheme) => {
  return {
    subtext: {
      marginTop: theme.spacing(1),
    },
    betweenQuestionSpacing: {
      marginBottom: theme.spacing(5),
    },
    spacingBetweenTitleAndField: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    iconSpacing: {
      marginRight: theme.spacing(3),
    },
    question: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  }
})

interface QuestionProps {
  question: TreatmentQ
  answer?: TreatmentAnswer
  submitAnswer: SubmitAnswer
  goToNextQuestion: () => void
}

export const Question = ({ question, answer, submitAnswer, goToNextQuestion }: QuestionProps) => {
  const classes = useStyles({})
  const { handleSubmit } = useForm()

  const [values, setValues] = useState<OptionInterface[]>(answer?.values ?? [])

  useEffect(() => {
    setValues(answer?.values || [])
  }, [question])

  useEffect(() => {
    if (canSubmitAnswer(answerOptions, values)) {
      submitAnswer({
        questionId: question.id,
        question: question.question,
        category: question.category,
        values,
      })
    } else {
      submitAnswer(null)
    }
  }, [values])

  const handleSubmission = (value: OptionInterface[]) => {
    setValues(value)
  }

  const onSubmit = () => {
    goToNextQuestion()
  }

  return (
    <Box data-testid={`${question.id}-container`}>
      <Typography
        className={classes.spacingBetweenTitleAndField}
        variant="h4"
        component="h1"
        data-testid="treatment-heading"
      >
        {question.sectionHeader}
      </Typography>
      <Typography variant="button" component="h2">
        {question.question}
      </Typography>
      <Box component="span" role="note" aria-label="information point">
        <Box display="flex" className={classes.spacingBetweenTitleAndField}>
          <Icon className={classes.iconSpacing} color="primary" size="1.5rem" name="information" />
          <Typography>{question.helpText}</Typography>
        </Box>
        <List>
          {question.reasons.map((text) => (
            <ListItem key={text}>
              <Icon className={classes.iconSpacing} name="bullet-point" size="1.5rem" />
              <Typography aria-label={text}>{text}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>
      <form
        data-testid={`${question.id}-question-form`}
        id={`${question.id}-question-form`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <SelectItemGroup
          className={classes.spacingBetweenTitleAndField}
          value={values}
          onChange={(_event, value) => {
            return handleSubmission((value as unknown) as OptionInterface[])
          }}
        >
          {question.options.map((value, index) => (
            <SelectItem
              data-testid={value.testId}
              key={value.testId}
              value={value}
              exclusive={value.exclusive}
              aria-labelledby={`option${index}`}
            >
              <Box component="span" id={`option${index}`}>
                {value.option}
              </Box>
            </SelectItem>
          ))}
        </SelectItemGroup>
      </form>
    </Box>
  )
}
