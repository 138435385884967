import {
  Box,
  Brand,
  FormLabel,
  Icon,
  makeStyles,
  MonocerosTheme,
  Typography,
  useTheme,
  ButtonV2 as Button,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import isNumber from '../../../../utils/isNumber'
import { tagButtonClick } from '../../../../utils/tagUtils/tagsUtils'
import { isSW } from '../../../../utils/brandSpecificFormatting'

export interface EditableNumberFieldProps {
  value: number
  formattedValue: string
  component: JSX.Element
  label: string
  onEdit: (e: React.MouseEvent<HTMLButtonElement>) => void
  showFixed: boolean
  fieldType?: string
}

const withStyles = makeStyles((theme) => {
  return {
    editable: {
      paddingBottom: theme.spacing(2),
    },
    hidden: {
      display: 'none',
    },
    visible: {
      marginBottom: theme.spacing(1),
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    editWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    editText: {
      textDecoration: 'underline',
    },
    label: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      color: theme.palette.primary.dark,
      fontSize: '18px',
    },
  }
})

export const EditableNumberField = (props: EditableNumberFieldProps) => {
  const classes = withStyles()
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)
  const { label, showFixed, value, formattedValue, onEdit, component } = props
  const showEditable = showFixed && isNumber(value)
  const numberFieldId = props.fieldType ?? 'number-field'

  return (
    <>
      <Box width="100%" className={showEditable ? classes.editable : classes.hidden}>
        <FormLabel data-testid={`${numberFieldId}-${label}-editable-label`}>
          <Typography variant="subtitle2" aria-label={label}>
            {label}
          </Typography>
        </FormLabel>
        <Box className={classes.content}>
          <Typography
            variant="h2"
            data-testid={`${numberFieldId}-${label}-editable-value`}
            id={`cache-bust-${props.formattedValue}-${numberFieldId}-id`}
            aria-label={formattedValue}
            role="status"
          >
            {formattedValue}
          </Typography>
          <Button
            variant="text"
            className={classes.editWrapper}
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault()
              tagButtonClick(`Edit-${numberFieldId}`)
              onEdit(e)
            }}
            data-testid={`${numberFieldId}-${label}-editable-button`}
            startIcon={
              <Icon name="pencil" color={isSWBrand ? 'inherit' : 'primary'} size={isSWBrand ? '24px' : '1.1rem'} />
            }
          >
            <Typography
              className={!isSWBrand ? classes.editText : ''}
              variant="body2"
              color={isSWBrand ? 'inherit' : 'primary'}
              data-testid={`${numberFieldId}-${label}-edit`}
            >
              Edit
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box width="100%" className={showEditable ? classes.hidden : classes.visible}>
        {component}
      </Box>
    </>
  )
}
