import {
  FormField,
  Checkbox,
  FormControlLabel,
  makeStyles,
  MonocerosTheme,
  Box,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { Controller, Control, FieldValues, FieldError, DeepMap } from 'react-hook-form'
import { MultipleChoiceAnswer } from './types'

interface Props {
  control: Control<FieldValues>
  answer: MultipleChoiceAnswer | null
  errors: DeepMap<FieldValues, FieldError>
  checkBeforeContinueProps: CheckBeforeContinueProps
}
export interface CheckBeforeContinueProps {
  id: string
  errorMessage: string
  label: string
  doNotDisplayWhenAnswer: string
}

const useStyles = makeStyles((theme: MonocerosTheme) => {
  return {
    checkboxContainer: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(-4),
    },
  }
})

export const CheckBeforeContinue: React.FC<Props> = ({ control, answer, errors, checkBeforeContinueProps }) => {
  const { id, label, errorMessage } = checkBeforeContinueProps
  const classes = useStyles()
  const consentId = `checkBeforeContinue-${id}`
  return (
    <Box className={classes.checkboxContainer}>
      <Controller
        name={consentId}
        defaultValue={false}
        control={control}
        rules={{
          validate: (value) => {
            return value || answer?.values[0] === undefined
          },
        }}
        render={({ onChange, value, ref, onBlur }) => (
          <FormField id={consentId} errorMessage={errors[consentId] ? errorMessage : undefined}>
            <FormControlLabel
              labelAlign="start"
              control={
                <Checkbox
                  data-testid={consentId}
                  onBlur={onBlur}
                  checked={value}
                  inputRef={ref}
                  onChange={(e) => onChange(e.target.checked)}
                  inputProps={{
                    'aria-required': 'true',
                  }}
                />
              }
              label={label}
            />
          </FormField>
        )}
      />
    </Box>
  )
}
