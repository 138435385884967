import React, { useContext } from 'react'
import { Box, Brand, Container, Grid, makeStyles, MonocerosTheme, useTheme } from '@lbg-protection/lib-ui-monoceros'
import { CiLedContext } from '../../../app/Journey/ciLedContext'
import { PrePurchaseJourneyRouteEnum } from '../../../journeys/purchase'
import { isBOS, isSW } from '../../../utils/brandSpecificFormatting'
import { Basket } from '../../Shared/Basket/Basket'
import { QuoteCard } from '../QuoteCard'
import { QuotesHeader } from '../QuotesHeader'
import { WhyPriceMightBeDifferentDialog } from '../Shared/WhyPriceMightBeDifferentDialog'
import { QuotePageProps } from '../types'
import { getCoverOutcome } from '../utils'

const withStyles = makeStyles((theme: MonocerosTheme) => {
  const isSWBrand = isSW(theme.name as Brand)
  return {
    quotesWrapper: {
      paddingTop: isSWBrand ? 0 : theme.spacing(1),
      flexDirection: 'column',
      flex: 1,
    },
    coverContainer: {
      marginTop: theme.spacing(4),
    },
    coverCard: {
      marginBottom: theme.spacing(4),
    },
    basketContainer: {
      display: 'none',
    },
    quoteContainer: {
      paddingLeft: isSWBrand ? '0.75rem' : '18px',
      paddingRight: isSWBrand ? '0.75rem' : '18px',
      background: isBOS(theme.name as Brand) ? theme.palette.secondary.light : 'transparent',
    },
    quotesHeaderWrapper: {
      flexDirection: 'column',
      flex: 1,
    },
    quoteHeaderContainer: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  }
})

const LifeCover = (props: QuotePageProps) => (
  <>
    <QuoteCard
      userRoute={PrePurchaseJourneyRouteEnum.StraightToQuote}
      numberOfPricingCalls={props.numberOfLifeCoverPricingCalls}
      smokerStatus={props.smokerStatus}
      uwOutcome={getCoverOutcome({
        isUnderwriteMe: true,
        underwritingCoverOutcome: props.lifeOutcome,
        underwriteMeCoverOutcome: props.uwMeLifeOutcome,
      })}
      pricingCallStatus={props.lifeCoverPricingCallStatus}
      onUpdate={props.update}
      callPricingAPI={props.callPricingAPI}
      onSubmit={props.addToBasket}
      coverSummary={props.lifeCover}
      dob={props.dob ?? new Date('1995/01/01')}
      removeFromBasket={props.removeFromBasket}
      enquiryId={props.enquiryId}
    />
    {!props.lifeCover.inBasket && <WhyPriceMightBeDifferentDialog coverType="life" />}
  </>
)

const BodyCover = (props: QuotePageProps) => (
  <>
    <QuoteCard
      userRoute={PrePurchaseJourneyRouteEnum.StraightToQuote}
      numberOfPricingCalls={props.numberOfBodyCoverPricingCalls}
      smokerStatus={props.smokerStatus}
      uwOutcome={getCoverOutcome({
        isUnderwriteMe: true,
        underwritingCoverOutcome: props.ciOutcome,
        underwriteMeCoverOutcome: props.uwMeCiOutcome,
      })}
      pricingCallStatus={props.bodyCoverPricingCallStatus}
      onUpdate={props.update}
      callPricingAPI={props.callPricingAPI}
      onSubmit={props.addToBasket}
      coverSummary={props.bodyCover}
      dob={props.dob ?? new Date('1995/01/01')}
      removeFromBasket={props.removeFromBasket}
      enquiryId={props.enquiryId}
    />
    {!props.bodyCover.inBasket && <WhyPriceMightBeDifferentDialog coverType="body" />}
  </>
)

export const Quotes = (props: QuotePageProps) => {
  const classes = withStyles()
  const theme = useTheme<MonocerosTheme>()
  const spacing = 0
  const ciLed = useContext(CiLedContext)

  return (
    <>
      {isBOS(theme.name as Brand) && (
        <Box component={Container} className={classes.quoteHeaderContainer}>
          <Grid container className={classes.quotesHeaderWrapper} spacing={spacing}>
            <QuotesHeader validationError={props.validationError} hasIndicativeQuote={props.hasIndicativeQuote} />
          </Grid>
        </Box>
      )}
      <Box component={Container} className={classes.quoteContainer}>
        <Grid container className={classes.quotesWrapper} spacing={spacing}>
          {!isBOS(theme.name as Brand) && (
            <QuotesHeader validationError={props.validationError} hasIndicativeQuote={props.hasIndicativeQuote} />
          )}
          <Box display="flex" flexDirection="column">
            <Grid item className={classes.coverContainer}>
              {ciLed ? (
                <>
                  <Box className={classes.coverCard}>
                    <BodyCover {...props} />
                  </Box>
                  <LifeCover {...props} />
                </>
              ) : (
                <>
                  <Box className={classes.coverCard}>
                    <LifeCover {...props} />
                  </Box>
                  <BodyCover {...props} />
                </>
              )}
            </Grid>
          </Box>
          <Grid item className={classes.basketContainer}>
            <Basket lifeCover={props.lifeCover} bodyCover={props.bodyCover} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
