import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  useTheme,
  Brand,
  MonocerosTheme,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { IndicativeQuoteCardContent } from './IndicativeQuoteCardContent'
import { IndicativeQuoteCardHeader } from './IndicativeQuoteCardHeader'
import { IndicativeQuoteCardProps } from './types'
import { isHalifax } from '../../../utils/brandSpecificFormatting'

const cardStyles = (theme: MonocerosTheme) => {
  const { name: brand = Brand.HALIFAX2020, palette } = theme
  const noBottomMargin = {
    marginBottom: 0,
  }
  const baseContent = {
    width: '90%',
    margin: 0,
  }

  switch (brand) {
    case Brand.BOS:
      return { card: { bg: palette.namedColours?.bosAliceBlue, noBottomMargin }, content: baseContent }
    case Brand.LLOYDS:
      return { card: { bg: palette.common.white, noBottomMargin }, content: baseContent }
    case Brand.ORION2024:
      return {
        card: { bg: palette.common.white, noBottomMargin },
        content: { ...baseContent, width: '100%', padding: 0, marginTop: 32 },
      }
    case Brand.HALIFAX2020:
    default:
      return { card: { bg: '#EBF6FF' }, content: baseContent }
  }
}

const withStyles = makeStyles((theme: MonocerosTheme) => {
  const { card, content } = cardStyles(theme)
  const { bg, noBottomMargin } = card

  return {
    card: {
      minWidth: '100%',
      padding: 0,
      backgroundColor: bg,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      ...(noBottomMargin && noBottomMargin),
    },
    header: { padding: 0, width: '100%' },
    divider: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        margin: `${theme.spacing(2)} 0`,
      },
    },
    content,
    subDivider: {
      margin: `0 ${theme.spacing(3)}`,
    },
  }
})

export const IndicativeQuoteCard = (props: IndicativeQuoteCardProps) => {
  const classes = withStyles()
  const theme = useTheme<MonocerosTheme>()

  const dividerTag = !isHalifax(theme.name as Brand) && <Divider variant="middle" className={classes.subDivider} />

  return (
    <Card className={classes.card} data-testid={`${props.coverSummary.title}-component`}>
      <CardHeader
        className={classes.header}
        title={<IndicativeQuoteCardHeader coverSummary={props.coverSummary} />}
        subheader={dividerTag}
      />
      <Divider className={classes.divider} />
      <CardContent className={classes.content}>
        <IndicativeQuoteCardContent {...props} />
      </CardContent>
    </Card>
  )
}
