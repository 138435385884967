import { baseUrlPath } from '../config'

export const getCookie = (cookieName: string) => {
  const name = `${cookieName}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function setCookie(cookieName: string, cookieValue: string, expiresInDays?: number) {
  let expires = ''

  if (expiresInDays) {
    const currentDate = new Date()
    currentDate.setTime(currentDate.getTime() + expiresInDays * 24 * 60 * 60 * 1000)
    expires = `expires=${currentDate.toUTCString()};`
  }

  const path = baseUrlPath() || '/'

  document.cookie = `${cookieName}=${cookieValue};${expires}path=${path}`
}

export const deleteCookie = (cookieName: string) => {
  setCookie(cookieName, '', -1)
}
