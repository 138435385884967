import React from 'react'
import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import { Box, Brand, MonocerosTheme, Typography, useTheme } from '@lbg-protection/lib-ui-monoceros'
import { CavendishOnlineContent } from './CavendishOnlineContent'
import { isSW } from '../../../../utils/brandSpecificFormatting'
import { LbisOnlineContent } from './LbisOnlineContent'

interface IneligibleProps {
  coverType: PricingRequestBody.CoverTypeEnum
}

// TODO: I don't think we can reach this screen when SW, but added check just in case. Should clean up later.
export const Ineligible = (props: IneligibleProps) => {
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)

  return (
    <Box data-testid={`ineligible-for-${props.coverType.toLowerCase()}-wrapper`}>
      <Typography gutterBottom variant="h4" data-testid={`ineligible-for-${props.coverType.toLowerCase()}-header`}>
        It looks like we can&apos;t offer you this cover
      </Typography>
      <Typography gutterBottom>
        Based on what you&apos;ve told us, we can&apos;t offer you this kind of cover. We want to make sure you get the
        help you need, so here are some options:
      </Typography>
      {isSWBrand ? <CavendishOnlineContent /> : <LbisOnlineContent />}
    </Box>
  )
}
