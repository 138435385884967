import React from 'react'
import clsx from 'clsx'
import { Alert, Box, Brand, MonocerosTheme, Typography, useTheme } from '@lbg-protection/lib-ui-monoceros'
import { isBOS, isHalifax, isSW } from '../../../utils/brandSpecificFormatting'
import { PageFocus } from '../../Shared/PageFocus'
import { withStyles } from './styles'
import { QuotesHeaderProps } from './types'

const getHelpTextClassName = (theme: MonocerosTheme): 'quoteHelpText' | 'quoteHelpTextWider' | undefined => {
  if (theme.name === Brand.LLOYDS) {
    return 'quoteHelpText'
  }
  if (theme.name === Brand.BOS) {
    return 'quoteHelpTextWider'
  }
  return undefined
}

export const QuotesHeader = (props: QuotesHeaderProps) => {
  const classes = withStyles()
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)
  const extraClass = getHelpTextClassName(theme)
  const helpTextClass = clsx(classes.quoteHelp, extraClass && classes[extraClass])
  const colorClass = !isHalifax(theme.name as Brand) ? 'initial' : 'inherit'
  const { validationError, hasIndicativeQuote } = props

  return (
    <Box>
      {validationError && (
        <Box className={isBOS(theme.name as Brand) ? classes.validationErrorBoxWider : undefined}>
          <Alert
            severity="error"
            data-testid="quote-page-validation-errors-alert"
            className={classes.validationErrorBox}
            role="presentation"
          >
            <PageFocus>
              <Typography>{validationError}</Typography>
            </PageFocus>
          </Alert>
        </Box>
      )}
      <Box className={classes.title} data-testid="mobile-quote-header-container">
        <Typography
          align={isSWBrand ? 'left' : 'center'}
          variant={isSWBrand ? 'h1' : 'h4'}
          component="h1"
          color={colorClass}
          data-testid="mobile-quote-header-title"
        >
          Thank you, here&apos;s your quote
        </Typography>
      </Box>
      <Box>
        <Typography
          align="left"
          variant="body1"
          color="primary"
          className={helpTextClass}
          data-testid="mobile-quote-header-description"
        >
          {hasIndicativeQuote
            ? `We've used the amount and duration you entered in your quick quote. You can change them here to make sure
            they're right for you.`
            : 'You should choose the amount and duration that are right for you.'}
        </Typography>
      </Box>
    </Box>
  )
}
