import { UwMePlatformSpecificEnquiryConfig } from '../types'
import { UwMeConfig } from '../../../config'

export const getPlatformSpecificEnquiryConfig = (
  underwriteMeConfig: UwMeConfig,
  isMobile: boolean,
): UwMePlatformSpecificEnquiryConfig => {
  const {
    enquiry: { salesChannel, localeDesktop, localeMobile },
  } = underwriteMeConfig
  return { salesChannel, locale: isMobile ? localeMobile : localeDesktop }
}
