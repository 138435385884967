import { Typography, makeStyles, Brand, MonocerosTheme, useTheme } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { formatCurrencyWithZeroesAfterDecimal } from '../../../utils/formatCurrency'
import { formatPolicyString } from './utils'
import { isLloyds, isBOS } from '../../../utils/brandSpecificFormatting'

const styles = makeStyles((theme: MonocerosTheme) => {
  return {
    titleContainer: {
      display: 'inline-flex',
    },
    basketTotal: {
      marginTop: `-${theme.spacing(0.5)}`,
      marginLeft: theme.spacing(0.5),
    },
  }
})
interface QuotesInBasketProps {
  quotesInBasket: number
  total: number
}

const getMonthlyTotalBold = (theme: MonocerosTheme, total: number, totalClass: string) => {
  switch (theme.name) {
    case Brand.LLOYDS:
      return (
        <Typography variant="subtitle2">
          Monthly total: {total > 0 ? formatCurrencyWithZeroesAfterDecimal('en-GB', 'GBP', total) : '£'}
        </Typography>
      )
    case Brand.BOS:
      return (
        <Typography variant="subtitle2" className={totalClass}>
          Monthly total: {total > 0 ? formatCurrencyWithZeroesAfterDecimal('en-GB', 'GBP', total) : '£'}
        </Typography>
      )
    case Brand.ORION2024:
      return <b>Monthly total: {total > 0 ? formatCurrencyWithZeroesAfterDecimal('en-GB', 'GBP', total) : ''}</b>
    default:
      return <b>Monthly total: {total > 0 ? formatCurrencyWithZeroesAfterDecimal('en-GB', 'GBP', total) : '£'}</b>
  }
}

export const QuotesInBasket = (props: QuotesInBasketProps) => {
  const classes = styles({})
  const theme = useTheme<MonocerosTheme>()
  const { quotesInBasket, total } = props
  const monthlyTotalBold = getMonthlyTotalBold(theme, total, classes.basketTotal)
  return (
    <Typography
      className={isLloyds(theme.name as Brand) || isBOS(theme.name as Brand) ? classes.titleContainer : ''}
      data-testid="mobile-basket-quotes-in-basket"
    >
      {quotesInBasket} {formatPolicyString(quotesInBasket)}/{monthlyTotalBold}
    </Typography>
  )
}
