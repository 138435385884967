import React from 'react'
import { journeyNavMapContext } from './journeyMapNavContext'

const useJourneyNavMap = () => {
  const navMap = React.useContext(journeyNavMapContext)

  const getTargetNav = (key: string, defaultTarget: string): string => {
    return navMap && navMap.pageTargets[key] ? navMap.pageTargets[key] : defaultTarget
  }

  return {
    navMap,
    entryPoints: navMap.entryPoints,
    getTargetNav,
  }
}

export { useJourneyNavMap }
