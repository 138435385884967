import { FinancesQ } from '../../../Shared/Questionaire/questions/FinancesQuestion/types'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'

export const additionalSupport = (): FinancesQ => {
  return {
    id: 'additionalSupport',
    category: 'finances',
    type: QuestionTypes.Finances,
    sectionHeader: 'Extra support',
    title: {
      text: 'Extra support',
      icon: 'account-bank',
    },
    question: `Add some extra support`,
    helpText: [
      'A critical illness can lead to unplanned costs. So you might want to add an extra amount of cover to help out.',
      `It’s hard to plan for these costs, but here are some examples of costs that you might want to think about:`,
    ],
    fieldConfig: {
      required: true,
      requiredErrorMessage: 'Please enter an amount',
      min: 0,
      max: 9999999.99,
      maxErrorMessage: 'Please enter a value of less than £10 million',
      decimals: 2,
    },
    JourneyStepName: 'Extra support',
    JourneyStep: '17',
    list: [
      'Full or part-time home care',
      'Travel to and from hospital for you and your loved ones',
      'Changes to your home to help you cope with your illness.',
    ],
  }
}
