import { ValidationRules } from 'react-hook-form'
import formatCurrency from '../../../../utils/formatCurrency'

export const minAmount = 1
export const maxAmount = 500000

export const amountValidation: ValidationRules = {
  required: { value: true, message: 'Please enter a cover amount.' },
  min: {
    value: minAmount,
    message: `Minimum cover is ${formatCurrency('en-GB', 'GBP', minAmount)}.`,
  },
  max: {
    value: maxAmount,
    message: `We can cover up to ${formatCurrency('en-GB', 'GBP', maxAmount)}.`,
  },
}

export const minTerm = 5
export const termValidator = (maxTerm: number): ValidationRules => ({
  required: { value: true, message: 'Please enter a cover duration.' },
  min: {
    value: minTerm,
    message: 'Cover term must be at least 5 years.',
  },
  max: {
    value: maxTerm,
    message: 'This is more that the maximum term we can cover you.',
  },
})

export const minMonthlyCost = 5
export const maxMonthlyCost = 999.99
export const monthlyCostValidator: ValidationRules = {
  required: { value: true, message: 'Please enter a monthly payment.' },
  min: {
    value: minMonthlyCost,
    message: 'Monthly cost must be at least £5.',
  },
  max: {
    value: maxMonthlyCost,
    message: 'Monthly cost can be at most £999.99.',
  },
}
