import { Box, Icon, IconProps, useTheme, Brand, Typography, MonocerosTheme } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import clsx from 'clsx'
import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import { withStyles } from './styles'
import { QuoteCardHeaderProps } from './types'
import { useContent } from '../../../Content/useContent'
import { QuotePageContent } from '../../QuotePageContent'
import { isHalifax, isSW } from '../../../../utils/brandSpecificFormatting'

export const QuoteCardHeader = (props: QuoteCardHeaderProps) => {
  const classes = withStyles()
  const theme = useTheme<MonocerosTheme>()
  const titleVariant = isHalifax(theme.name as Brand) ? 'h5' : 'h4'
  const boxClass = isHalifax(theme.name as Brand)
    ? classes.wrapper
    : clsx(
        classes.wrapper,
        props.coverSummary.coverType === PricingRequestBody.CoverTypeEnum.BODY
          ? classes.boxBackgroundBody
          : classes.boxBackgroundLife,
      )

  // eslint-disable-next-line no-nested-ternary
  const colorClass = isHalifax(theme.name as Brand) ? 'inherit' : isSW(theme.name as Brand) ? 'textPrimary' : 'initial'

  return (
    <Box className={boxClass} data-testid={`${props.coverSummary.coverType}-header`}>
      <Box className={classes.title}>
        <RenderCoverIcon {...props} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography
            className={isHalifax(theme.name as Brand) ? classes.emphasised : ''}
            variant={titleVariant}
            component="h2"
            color={colorClass}
          >
            {props.coverSummary?.title}
          </Typography>
          {props.coverSummary.coverType === PricingRequestBody.CoverTypeEnum.BODY && (
            <Typography className={classes.coverDescription} variant="body2" color={colorClass}>
              (Critical Illness Cover)
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

interface QuoteCardIconProps {
  iconName: string
  iconColor: string
}

const LifeCoverIcon = (props: QuoteCardIconProps) => {
  const classes = withStyles()
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)
  return (
    <Box className={classes.coverIcon}>
      <Icon
        size={isSWBrand ? '24px' : '27px'}
        name={props.iconName as IconProps['name']}
        color={props.iconColor as IconProps['color']}
        data-testid="life-cover-icon"
      />
    </Box>
  )
}
const BodyCoverIcon = (props: QuoteCardIconProps) => {
  const classes = withStyles()
  return (
    <Box className={classes.coverIcon}>
      <Icon
        size="27px"
        name={props.iconName as IconProps['name']}
        color={props.iconColor as IconProps['color']}
        data-testid="body-cover-icon"
      />
    </Box>
  )
}
const RenderCoverIcon = (props: QuoteCardHeaderProps) => {
  const content = useContent<QuotePageContent>()
  const theme = useTheme<MonocerosTheme>()
  const bodyCoverIcon = content('bodyCoverHeadingIcon', 'heart-outline')
  const lifeCoverIcon = content('lifeCoverHeadingIcon', 'umbrella-outline')
  const iconColor = isHalifax(theme.name as Brand) ? 'inherit' : 'primary'
  if (props.coverSummary.title === 'Life Cover') return <LifeCoverIcon iconName={lifeCoverIcon} iconColor={iconColor} />
  return <BodyCoverIcon iconName={bodyCoverIcon} iconColor={iconColor} />
}
