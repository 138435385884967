import { Alert, Box, Container, Grid, Step, StepLabel, Stepper, Typography } from '@lbg-protection/lib-ui-monoceros'
import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import React, { useContext } from 'react'
import { useFeatureFlags } from '../../../featureFlags'
import { CiLedContext } from '../../../app/Journey/ciLedContext'
import { CavendishOnlineConsentDialog } from '../../Shared/CavendishOnlineConsentDialog'
import { IndicativeQuoteCard } from '../IndicativeQuoteCard'
import { IndicativeQuotePageHeader } from '../IndicativeQuotePageHeader'
import { IndicativeQuotePageProps } from '../types'
import withStyles from './styles'
import { BodyCoverCostOverlay } from './BodyCoverCostOverlay'
import { HowDoIChooseLifeOverlay } from './HowDoIChooseLifeOverlay'
import { HowDoIChooseBodyOverlay } from './HowDoIChooseBodyOverlay'

interface CoverCardQuoteProps extends IndicativeQuotePageProps {
  coverType: PricingRequestBody.CoverTypeEnum
}

const noop = () => null

const CoverCardQuote = ({
  numberOfLifeCoverPricingCalls,
  numberOfBodyCoverPricingCalls,
  smokerStatus,
  lifeCoverPricingCallStatus,
  bodyCoverPricingCallStatus,
  lifeCover,
  bodyCover,
  dob,
  callPricingAPI = noop,
  update: updateCover = noop,
  coverType,
}: CoverCardQuoteProps) => {
  const classes = withStyles()

  return (
    <Grid item className={classes.coverContainer}>
      <IndicativeQuoteCard
        numberOfPricingCalls={
          coverType === PricingRequestBody.CoverTypeEnum.BODY
            ? numberOfBodyCoverPricingCalls
            : numberOfLifeCoverPricingCalls
        }
        smokerStatus={smokerStatus}
        pricingCallStatus={
          coverType === PricingRequestBody.CoverTypeEnum.BODY ? bodyCoverPricingCallStatus : lifeCoverPricingCallStatus
        }
        onUpdate={updateCover}
        callPricingAPI={callPricingAPI}
        coverSummary={coverType === PricingRequestBody.CoverTypeEnum.BODY ? bodyCover : lifeCover}
        dob={dob}
      />
    </Grid>
  )
}

export const IndicativeQuote = (props: IndicativeQuotePageProps) => {
  const classes = withStyles()
  const spacing = 0
  const ciLed = useContext(CiLedContext)
  const { isFeatureEnabled } = useFeatureFlags()
  const isCavendishOnlineConsent = isFeatureEnabled('cavendishOnlineConsent')
  const isMonthlyCostAdded = props.lifeCover.monthlyCost > 0 || props.bodyCover.monthlyCost > 0

  return (
    <Box component={Container} className={classes.quoteContainer}>
      <Grid container className={classes.quotesWrapper} spacing={spacing}>
        <IndicativeQuotePageHeader validationError={props.validationError} isMonthlyCostAdded={isMonthlyCostAdded} />

        {isCavendishOnlineConsent && <CavendishOnlineConsentDialog dwellTime={props.cavendishOnlineConsentDwellTime} />}
        {ciLed ? (
          <Box display="flex" flexDirection="column">
            <CoverCardQuote {...props} coverType={PricingRequestBody.CoverTypeEnum.BODY} />
            <HowDoIChooseBodyOverlay />
            <CoverCardQuote {...props} coverType={PricingRequestBody.CoverTypeEnum.LIFE} />
            <HowDoIChooseLifeOverlay />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column">
            <CoverCardQuote {...props} coverType={PricingRequestBody.CoverTypeEnum.LIFE} />
            <HowDoIChooseLifeOverlay />
            <CoverCardQuote {...props} coverType={PricingRequestBody.CoverTypeEnum.BODY} />
            <HowDoIChooseBodyOverlay />
          </Box>
        )}

        <BodyCoverCostOverlay />
        <Alert className={classes.alert} severity="info" data-testid="indicative-quote-alert" role="presentation">
          <Typography>
            This is the price a healthy person the same age as you might pay. The price may change based on your health
            and the amount and duration of cover you choose when you apply.
          </Typography>
        </Alert>
        <div className={classes.stepperCard}>
          <Typography component="h2" variant="h4" align="left" className={classes.stepperHeading}>
            Apply today in just 10 minutes
          </Typography>
          <Stepper orientation="vertical" data-testid="ready-to-apply-stepper" className={classes.stepper}>
            <Step active>
              <StepLabel aria-label="1">
                <Typography>Answer a few questions about your health</Typography>
              </StepLabel>
            </Step>
            <Step active>
              <StepLabel aria-label="2">
                <Typography>We&apos;ll give you an instant decision</Typography>
              </StepLabel>
            </Step>
            <Step active>
              <StepLabel aria-label="3">
                <Typography>Get your full quote</Typography>
              </StepLabel>
            </Step>
          </Stepper>
        </div>
      </Grid>
    </Box>
  )
}
