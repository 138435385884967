import { useNgaBridge } from '@lbg-cwa/jsbridge-react'
import { LinkV2 as Link } from '@lbg-protection/lib-ui-monoceros'
import React, { useCallback, useState } from 'react'
import { baseUrlPath } from '../../../config'
import { PdfViewerDialog } from './PdfViewerDialog'
import { PdfViewerLinkProps } from './PdfViewerLinkProps'
import { useJourneyChecker } from '../../../journeyCwaConfig'

export const PdfViewerLink = ({ href, customOnClick, ...props }: PdfViewerLinkProps) => {
  const [open, setOpen] = useState(false)

  const doClose = () => {
    setOpen(false)
  }

  const ngaBridge = useNgaBridge()
  const ngaBridgeAvailable = ngaBridge?.isNgaBridgeAvailable() ?? false
  const openInPdfViewerDialog = !ngaBridgeAvailable || !ngaBridge.isIos()
  const fullHref = ngaBridge?.isAndroid() ? `${baseUrlPath()}/${href}` : href
  const { isJourneyWeb, isJourneyUnAuth } = useJourneyChecker()

  const onClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()

      if (openInPdfViewerDialog) {
        setOpen(true)
      }

      if (customOnClick) {
        customOnClick()
      }
    },
    [openInPdfViewerDialog],
  )

  const ngaAndroidDownload = useCallback(
    (event: React.SyntheticEvent) => {
      if (event) {
        event.preventDefault()
      }
      if (fullHref) {
        fetch(fullHref, {
          method: 'GET',
          credentials: 'same-origin',
          headers: {
            accept: '*/*',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          },
        })
          .then((res): void => {
            try {
              res.blob()
            } catch (_error) {
              console.log('Error downloading static pdf')
            }
          })
          .finally()
      }
    },
    [fullHref],
  )

  let patchedHref = fullHref

  if (ngaBridgeAvailable) {
    if (patchedHref && !patchedHref.startsWith('blob://') && !patchedHref.includes('interceptURL=')) {
      patchedHref += !patchedHref?.includes('?') ? '?' : '&'
      patchedHref += 'interceptURL=viewDownloadPDF'
    }
  }

  return (
    <>
      {isJourneyWeb || isJourneyUnAuth ? (
        <Link
          {...props}
          rel="noopener noreferrer"
          href={`${fullHref}?interceptURL=openPDF`}
          target="_blank"
          variant="body2"
          onClick={customOnClick}
        >
          {props.children}
        </Link>
      ) : (
        <Link {...props} href={patchedHref} onClick={openInPdfViewerDialog ? onClick : undefined} variant="body2">
          {props.children}
        </Link>
      )}
      {openInPdfViewerDialog && (
        <PdfViewerDialog
          open={open}
          onClose={doClose}
          onCloseButtonClick={doClose}
          onDownload={openInPdfViewerDialog && ngaBridge.isAndroid() ? ngaAndroidDownload : undefined}
          pdfViewerProps={{
            file: props.file || patchedHref,
            filename: props.filename || props.download,
          }}
          downloadUrl={fullHref}
        />
      )}
    </>
  )
}
