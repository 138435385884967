import React, { useState } from 'react'
import { Box, Brand, LinkV2 as Link, MonocerosTheme, Typography, useTheme } from '@lbg-protection/lib-ui-monoceros'
import clsx from 'clsx'
import { InfoDialog } from '../../Shared/InfoDialog/InfoDialog'
import withStyles from './styles'
import { tagOverlayClick } from '../tags'
import { isSW } from '../../../utils/brandSpecificFormatting'

export const BodyCoverCostOverlay = () => {
  const [toggleInfo, setToggleInfo] = useState<boolean>(false)
  const classes = withStyles({})
  const { name: themeName = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(themeName)
  return (
    <Box className={classes.overlayLink}>
      <Link
        href="#indicative-body-cover-cost-link"
        onClick={(event: React.SyntheticEvent) => {
          event.preventDefault()
          tagOverlayClick('Why does body cover cost more?')
          setToggleInfo(!toggleInfo)
        }}
        data-testid="indicative-body-cover-cost-link"
        variant="body2"
        className={clsx(isSWBrand && classes.linkTitle)}
      >
        Why does Body Cover cost more?
      </Link>
      <InfoDialog
        open={toggleInfo}
        toggleOpen={() => setToggleInfo(!toggleInfo)}
        data-testid="indicative-body-cover-cost"
        title="Why does Body Cover cost more?"
      >
        <Typography variant="body1" data-testid="indicative-body-cover-cost-answer">
          {`Body Cover, or critical illness cover, often costs more than life insurance. If you had both kinds of cover
          over the same time period, you'd be more likely to fall seriously ill rather than die unexpectedly. This
          means it's more likely an insurer would need to pay out for Body Cover. So, they might charge a bit more
          to make up for the added risk.`}
        </Typography>
      </InfoDialog>
    </Box>
  )
}
