import { ContactDetails } from '@lbg-protection/api-client-nmp-journey'
import { AboutYouApplicantDetails } from '../../../journeys/purchase/aboutYou.slice'

export const getContactDetails = (customerDetails?: AboutYouApplicantDetails): Array<ContactDetails> => {
  const details: Array<ContactDetails> = []
  if (customerDetails?.email) details.push({ type: 'EMAIL', value: customerDetails?.email })
  if (customerDetails?.telephone) details.push({ type: 'MOBILE', value: customerDetails?.telephone })

  return details
}
