import React, { Dispatch, SetStateAction, SyntheticEvent } from 'react'
import { Box, Typography, Icon, makeStyles, LinkV2 as Link } from '@lbg-protection/lib-ui-monoceros'
import { Item } from '../../types'
import { toggleItemEditable } from '../../utils/helpers'

export const styles = makeStyles((theme) => {
  return {
    itemContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    description: {
      marginRight: theme.spacing(1),
      wordBreak: 'break-word',
    },
    editAmount: {
      display: 'flex',
      alignItems: 'center',
    },
    editButton: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(3),
      '& > svg': {
        marginRight: theme.spacing(1),
      },
    },
  }
})

interface ItemDisplayProps {
  item: Item
  items: Item[]
  setItems: Dispatch<SetStateAction<Item[]>>
  included?: boolean
}

export const ItemDisplay = (props: ItemDisplayProps) => {
  const classes = styles({})

  return (
    <Box className={classes.itemContainer} data-testid="leaveSomethingExtra-item">
      <Typography className={classes.description} variant="h5" data-testid="leaveSomethingExtra-item-desc">
        {props.item.description}
      </Typography>
      <Box className={classes.editAmount}>
        <Typography variant="h5" data-testid="leaveSomethingExtra-item-amount">
          {`£${props.item.amount.toLocaleString()}`}
        </Typography>
        <Link
          data-testid={`edit-extra-item-${props.item.id}-button`}
          href={`#edit-extra-item-${props.item.id}-button`}
          variant="body2"
          className={classes.editButton}
          onClick={(event: SyntheticEvent) => {
            event.preventDefault()
            if (
              !props.items.some((item: Item) => item.editable) &&
              (props.included === undefined || props.included === true)
            ) {
              const nextItems = toggleItemEditable(props.item.id, props.items, true)
              props.setItems(nextItems)
            }
          }}
        >
          <Icon name="pencil" size="1rem" />
          Edit
        </Link>
      </Box>
    </Box>
  )
}
