import { AboutYouProductHolding } from '@lbg-protection/api-client-nmp-journey'
import { CoverSelectedEnum, PrePurchaseInterface } from './types'

export const isPPO = (input: PrePurchaseInterface): input is PrePurchaseInterface => {
  const ppo = input
  const mandatoryFields = ppo.userRoute !== undefined && ppo.coverSelected !== undefined
  if (!mandatoryFields) return false
  if (ppo.coverSelected === CoverSelectedEnum.All && (ppo.body === undefined || ppo.life === undefined)) {
    return false
  }
  if (ppo.coverSelected === CoverSelectedEnum.Body && ppo.body === undefined) {
    return false
  }
  if (ppo.coverSelected === CoverSelectedEnum.Life && ppo.life === undefined) {
    return false
  }
  if (ppo.coverSelected === CoverSelectedEnum.None && (ppo.life !== undefined || ppo.body !== undefined)) {
    return false
  }
  return true
}

export const transformAboutYouProducts = (productHoldings: AboutYouProductHolding[]): AboutYouProductHolding[] => {
  const updatedAccounts = productHoldings?.map((product) => {
    const sortCode = product.sortCode.toString()
    const accountNumber = product.accountNumber.toString()

    const newSortCode = sortCode.padStart(6, '0')
    const newAccountNumber = accountNumber.padStart(8, '0')

    return { ...product, sortCode: newSortCode, accountNumber: newAccountNumber }
  })
  return updatedAccounts ?? []
}
