import {
  Box,
  Icon,
  makeStyles,
  NamedTheme,
  Typography,
  useTheme,
  MonocerosTheme,
  Brand,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { BulletPointWrapper } from '../../Shared/BulletPointWrapper'
import { isSW } from '../../../utils/brandSpecificFormatting'

const useStyles = makeStyles((theme: NamedTheme) => {
  return {
    heading: {
      marginBottom: theme.spacing(4),
    },
  }
})

interface CoverRightForYouProps {
  title: string
  content: string[]
}
const getBulletIcon = (theme: MonocerosTheme): React.ReactNode => {
  switch (theme.name) {
    case Brand.LLOYDS:
      return <Icon name="tick" size="1.5rem" htmlColor={theme.palette.namedColours?.lloydsPositive} />
    case Brand.BOS:
      return <Icon name="tick" size="1.5rem" htmlColor={theme.palette.namedColours?.bosCoreBlue} />
    default:
      return <Icon name="positive" color="secondary" size="1.5rem" />
  }
}

export const CoverRightForYou = ({ title, content }: CoverRightForYouProps) => {
  const theme = useTheme<MonocerosTheme>()
  const isWidowsBrand = isSW(theme.name as Brand)
  const bulletIcon = getBulletIcon(theme)
  const classes = useStyles()
  return (
    <Box>
      <Box className={classes.heading}>
        <Typography variant="caption" data-testid="need-life-cover-question-answer">
          {title}
        </Typography>
      </Box>
      <Box data-testid="need-life-cover-question-answer-bulletpoints">
        <BulletPointWrapper {...(!isWidowsBrand && { bulletPointIcon: bulletIcon })} bulletPoints={content} />
      </Box>
    </Box>
  )
}
