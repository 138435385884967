import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { authJourneyApi } from '../apis/authJourneyApi'
import { RootState } from './types'

export interface UserInfoState {
  extptyId: string
  extsysId: string
  ocisId: string
  userId: string
}

export type AppState = UserInfoState

const initialState: AppState = {
  extptyId: '',
  extsysId: '',
  ocisId: '',
  userId: '',
}

export const getUserInfoThunk = createAsyncThunk('app/getUserInfo', async () => {
  return authJourneyApi().getUserInfo()
})

export const appSlice = createSlice({
  name: 'protectionApp',
  initialState,
  reducers: {
    setExtPtyId(state, action: PayloadAction<string>) {
      return { ...state, extptyId: action.payload }
    },
    setExtSysId(state, action: PayloadAction<string>) {
      return { ...state, extsysId: action.payload }
    },
    setOcisId(state, action: PayloadAction<string>) {
      const ocisId = action.payload
      return { ...state, ocisId }
    },
    setUserId(state, action: PayloadAction<string>) {
      return { ...state, userId: action.payload }
    },
    setUserInfo(state, action: PayloadAction<UserInfoState>) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfoThunk.pending, (state, _action) => {
      return { ...state }
    })
    builder.addCase(getUserInfoThunk.fulfilled, (state, action: PayloadAction<any>) => {
      const { user } = action.payload
      const userInfo = {
        extptyId: user.externalPartyId,
        extsysId: user.externalSystemId,
        ocisId: user.ocisId,
        userId: user.id,
      }
      return { ...state, ...userInfo }
    })
    builder.addCase(getUserInfoThunk.rejected, (state, _action) => {
      return { ...state }
    })
  },
})

export const getOcisId = (state: RootState) => state.app.ocisId

export const { setExtPtyId, setExtSysId, setOcisId, setUserId, setUserInfo } = appSlice.actions
export default appSlice.reducer
