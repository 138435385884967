import {
  UnderwritingEnquiryAnswer,
  UnderwritingEnquiryQuestion,
  UnderwritingEnquiryResponseBodyData,
  UnderwritingEnquiryValidationError,
} from '@lbg-protection/api-client-nmp-journey'
import { CoverAmountInterface } from '../../journeys/purchase'

export enum UwMeLoadingStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}
export interface UwMeState {
  loadingStatus: UwMeLoadingStatus
  uwMeData: UnderwritingEnquiryResponseBodyData | null
  currentPageIndex: number
  tag?: string
}

export interface TransformedEnquiryLineQuestion {
  preamble?: string
  name?: string
  path?: string
  locale?: string
  hasAnswer?: boolean
  isSatisfied?: boolean
  answers?: string[]
  validationErrors?: UnderwritingEnquiryValidationError[]
  definition?: UnderwritingEnquiryQuestion
}

export interface LBGOption {
  id: string
  text: string
  exclusive: boolean
}
export enum UwMeInputType {
  OPTION_GROUP = 'OPTION_GROUP',
  INTEGER = 'INTEGER',
  PAST_DATE = 'PAST_DATE',
  DATE = 'DATE',
  FUTURE_DATE = 'FUTURE_DATE',
  NUMBER = 'NUMBER',
  OPTION_BACKED = 'OPTION_BACKED',
}
export enum UwMeTags {
  STARTER = 'STARTER',
  DO_NOT_DISPLAY = 'DO_NOT_DISPLAY',
  EXCLUSIVE = 'EXCLUSIVE',
  GROUP_QUESTIONS = 'GROUP_QUESTIONS',
  DISPLAY_FOR_UNAUTH = 'DISPLAY_FOR_UNAUTH',
}
export type UwMeAnswer = UnderwritingEnquiryAnswer
export interface LBGQuestion {
  name: string
  path?: string
  text: string
  helpText?: string
  helpOverlay?: string
  inputType: string
  options?: LBGOption[] | string[]
  min?: number
  max?: number
  previousAnswer?: UwMeAnswer['answerValue']
  isMultiValued?: boolean
}
export interface UwMeAnswerEnquiryQuestionRequestData {
  enquiryId: string
  answers: UwMeAnswer[]
}

export enum IneligibleDecision {
  DECLINE = 'DECLINE',
  POSTPONE = 'POSTPONE',
}

export interface UwMePlatformSpecificEnquiryConfig {
  salesChannel: string
  locale: string
}
export interface UwMeStartEnquiryRequestArgs {
  isMobile: boolean
  tag?: string
}

export type EnquiryId = string

export type UwMeStartEnquiryParams = UwMeStartEnquiryRequestArgs & {
  dateOfBirth: string
  prePurchaseLifeCover?: CoverAmountInterface
  prePurchaseBodyCover?: CoverAmountInterface
}

export interface UwMeStartEnquiryRequestQueryParams {
  locale: string
  tag?: string
}

type Gender = 'Male' | 'Female'

interface HeightInterface {
  ft?: number | string
  in?: number | string
  cm?: number | string
}

interface WeightInterface {
  st?: number | string
  lb?: number | string
  kg?: number | string
}

export interface BMIAnswer {
  questionId: string
  question: string
  category: string
  values: {
    gender?: Gender
    weight: WeightInterface
    height: HeightInterface
  }
}
