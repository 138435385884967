import {
  UnderwritingEnquiryOption,
  UnderwritingEnquiryResponseBodyData,
  UnderwritingEnquiryQuestion,
} from '@lbg-protection/api-client-nmp-journey'
import { LBGOption, LBGQuestion, TransformedEnquiryLineQuestion, UwMeTags } from '../types'
import { ConfigState } from '../../../app/config.slice'

const isStarterQuestion = (question: TransformedEnquiryLineQuestion): boolean => {
  return !!question.definition?.tags?.includes(UwMeTags.STARTER)
}

const shouldDisplayQuestion = (question: TransformedEnquiryLineQuestion, isUnAuth: boolean | undefined): boolean => {
  if (isUnAuth && question.definition?.tags?.includes(UwMeTags.DISPLAY_FOR_UNAUTH)) {
    return true
  }
  return !question.definition?.tags?.includes(UwMeTags.DO_NOT_DISPLAY)
}

const getQuestionText = (question: TransformedEnquiryLineQuestion): string => {
  return (isStarterQuestion(question) ? question.definition?.text : question.preamble) as string
}

const getQuestionHelpText = (question: TransformedEnquiryLineQuestion): string | undefined => {
  return isStarterQuestion(question) ? undefined : question.definition?.text
}

const transformOption = (
  option: UnderwritingEnquiryOption,
  isMultiValued: UnderwritingEnquiryQuestion['isMultiValued'],
): LBGOption => {
  return {
    id: option.name as string,
    text: option.text as string,
    exclusive: !!option.tags?.includes(UwMeTags.EXCLUSIVE) || !isMultiValued,
  }
}

const uwMeToStandardQuestion = (uwMeQuestion: TransformedEnquiryLineQuestion): LBGQuestion => {
  const uwMeOptions = uwMeQuestion.definition?.options?.map((option) =>
    transformOption(option, uwMeQuestion.definition?.isMultiValued),
  )
  const transformedQuestion: LBGQuestion = {
    inputType: uwMeQuestion.definition?.type as string,
    name: uwMeQuestion.definition?.name as string,
    path: uwMeQuestion.path,
    text: getQuestionText(uwMeQuestion),
    helpText: getQuestionHelpText(uwMeQuestion),
    helpOverlay: uwMeQuestion.definition?.helpText,
    options: uwMeOptions,
    min: uwMeQuestion.definition?.lowerBound,
    max: uwMeQuestion.definition?.upperBound,
    previousAnswer: uwMeQuestion.answers,
    isMultiValued: uwMeQuestion.definition?.isMultiValued,
  }
  Object.keys(transformedQuestion).forEach((key) => {
    if (transformedQuestion[key as keyof LBGQuestion] === undefined) {
      delete transformedQuestion[key as keyof LBGQuestion]
    }
  })

  return transformedQuestion
}

export const getGroupedQuestions = (
  uwMeResponse: UnderwritingEnquiryResponseBodyData,
  configState: ConfigState,
): LBGQuestion[][] => {
  const questions: LBGQuestion[][] = []
  const { isUnAuth } = configState
  if (uwMeResponse.sections) {
    uwMeResponse.sections.forEach((section) => {
      if (section.enquiryLines) {
        section.enquiryLines.forEach((enquiryLine) => {
          if (enquiryLine.tags?.includes(UwMeTags.GROUP_QUESTIONS)) {
            const transformedEnquiryLineQuestions = enquiryLine.questions
              ?.filter((enquiryQuestion) => shouldDisplayQuestion(enquiryQuestion, isUnAuth))
              .map((question) => uwMeToStandardQuestion({ ...question, preamble: enquiryLine.preamble }))
            if (transformedEnquiryLineQuestions && transformedEnquiryLineQuestions?.length > 0) {
              questions.push(transformedEnquiryLineQuestions)
            }
          } else if (enquiryLine.questions) {
            enquiryLine.questions
              .filter((enquiryQuestion) => shouldDisplayQuestion(enquiryQuestion, isUnAuth))
              .forEach((question) => {
                questions.push([uwMeToStandardQuestion({ ...question, preamble: enquiryLine.preamble })])
              })
          }
        })
      }
    })
  }
  return questions
}
