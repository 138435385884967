import { Brand, MonocerosTheme, makeStyles } from '@lbg-protection/lib-ui-monoceros'
import { isSW } from '../../../utils/brandSpecificFormatting'

export const withStyles = makeStyles((theme: MonocerosTheme) => {
  const isOrionTheme = isSW(theme.name as Brand)
  return {
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    quoteHelp: {
      marginTop: isOrionTheme ? 0 : '1.5rem',
      width: '80%',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#333333',
    },
    validationErrorBox: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(5),
    },
    quoteHelpText: {
      width: 'auto',
      color: '#505050',
    },
    titleFocus: {
      '&:focus': {
        outline: 'none',
      },
    },
  }
})
