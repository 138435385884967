import { ListItemWithBullet, ListWithBullets, Typography } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { FinancesQ } from '../../../Shared/Questionaire/questions/FinancesQuestion/types'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'

export const debtsToInclude = [
  'Credit or store cards',
  'Personal loans',
  'Car repayments',
  `Any ‘credit free’ arrangements`,
]

export const debtsNotToInclude = ['Mortgages', 'Student loans']

export const debts = (): FinancesQ => {
  return {
    id: 'debts',
    category: 'finances',
    type: QuestionTypes.Finances,
    sectionHeader: 'Your finances',
    title: {
      text: 'Your finances',
      icon: 'coins',
    },
    question: 'How much debt do you have?',
    helpText: [`When your policy pays out, the money could be used to help pay off your debts.`],
    fieldHelperText: `Don't include your mortgage, if you have one.`,
    fieldConfig: {
      required: true,
      requiredErrorMessage: 'Please enter an amount',
      min: 0,
      max: 9999999.99,
      maxErrorMessage: 'Please enter a value of less than £10 million',
      decimals: 2,
    },
    overlay: {
      id: 'work out',
      link: 'How do I work this out?',
      content: (
        <>
          <Typography gutterBottom>Think about any debts you might have, like:</Typography>
          <ListWithBullets>
            {debtsToInclude.map((item, index) => (
              <ListItemWithBullet key={`to-include-${index}`}>{item}</ListItemWithBullet>
            ))}
          </ListWithBullets>
          <Typography style={{ paddingTop: '1rem' }} gutterBottom>
            You don&apos;t need to include:
          </Typography>
          <ListWithBullets>
            {debtsNotToInclude.map((item, index) => (
              <ListItemWithBullet key={`not-to-include-${index}`}>{item}</ListItemWithBullet>
            ))}
          </ListWithBullets>
        </>
      ),
    },
    JourneyStepName: 'Debts',
    JourneyStep: '13',
  }
}
