import { makeStyles, NamedTheme } from '@lbg-protection/lib-ui-monoceros'

export const styles = makeStyles((theme: NamedTheme) => {
  return {
    container: {
      marginTop: theme.spacing(5),
    },
    heading: {
      marginBottom: theme.spacing(4),
    },
    bulletPointContainer: {
      marginTop: theme.spacing(3),
    },
  }
})
