import { useNgaBridge } from '@lbg-cwa/jsbridge-react'
import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import * as Types from '../../../app/types'
import { baseUrlPath } from '../../../config'
import { selectApplicantDetails } from '../../../journeys/purchase/aboutYou.slice'
import { deleteCookie, setCookie } from '../../../utils/cookieUtils'
import { PdfViewerDialog } from '../../Shared/PdfViewer/PdfViewerDialog'
import { selectBodyCover, selectLifeCover } from '../quotes.slice'
import { calculateSum } from '../utils'
import { QuotePdfProps } from './types'
import { useJourneyChecker } from '../../../journeyCwaConfig'

const PDF_PAYLOAD_COOKIE_NAME = 'NmpPdfPayload'

interface PdfWrapperProps {
  children?: React.ReactNode
}

const pickQuotePdfProps = (props: React.Props<unknown> & QuotePdfProps): QuotePdfProps => {
  const { bodyCover, lifeCover, firstName, lastName, dob, total } = props
  return { bodyCover, lifeCover, firstName, lastName, dob, total }
}

export const DynamicQuotePdf = (props: React.Props<unknown> & Partial<QuotePdfProps>) => {
  const [open, setOpen] = useState(false)

  const [payload, setPayload] = useState<QuotePdfProps>()

  const nextPayload = pickQuotePdfProps(props as QuotePdfProps)

  useDeepCompareEffect(() => {
    setPayload(nextPayload)
  }, [nextPayload])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [])

  const ngaBridge = useNgaBridge()
  const { isJourneyWeb, isJourneyUnAuth } = useJourneyChecker()
  const pdfUrl = `${baseUrlPath()}/assets/pdf/gen/quote.pdf`

  const cleanUpPdfPayloadCookie = () => {
    deleteCookie(PDF_PAYLOAD_COOKIE_NAME)
  }

  const openPDFWeb = useCallback(() => {
    setCookie(PDF_PAYLOAD_COOKIE_NAME, JSON.stringify(payload))
    const a = document.createElement('a')
    a.rel = 'noopener noreferrer'
    a.target = '_blank'
    a.href = `${pdfUrl}?interceptURL=openPDF`
    document.body.appendChild(a)
    a.click()
    a.remove()
    setTimeout(cleanUpPdfPayloadCookie, 1000)
  }, [payload])

  const viewDownloadPDF = useCallback(() => {
    return fetch(`${pdfUrl}?interceptURL=viewDownloadPDF`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(payload),
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      return response.arrayBuffer()
    })
  }, [payload])

  const viewDownloadPDFIOS = useCallback(() => {
    setCookie(PDF_PAYLOAD_COOKIE_NAME, JSON.stringify(payload))
    const a = document.createElement('a')
    a.download = 'quote.pdf'
    a.href = `${pdfUrl}?interceptURL=viewDownloadPDF`
    document.body.appendChild(a)
    a.click()
    a.remove()
    setTimeout(cleanUpPdfPayloadCookie, 1000)
  }, [payload])

  const onClick = useCallback(
    (event: React.SyntheticEvent) => {
      if (isJourneyWeb || isJourneyUnAuth) {
        openPDFWeb()
      } else if (!ngaBridge.isIos()) {
        event.preventDefault()
        setOpen(true)
      } else {
        viewDownloadPDFIOS()
      }
    },
    [payload],
  )

  return (
    <>
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...child.props,
            onClick: (event: React.SyntheticEvent) => {
              onClick(event)
              if (child.props.onClick) child.props.onClick()
            },
          })
        }
        return child
      })}

      {!isJourneyWeb && !isJourneyUnAuth && !ngaBridge.isIos() && (
        <PdfViewerDialog
          onClose={onClose}
          onCloseButtonClick={onClose}
          open={open}
          onDownload={(event: React.SyntheticEvent) => {
            event.preventDefault()

            fetch(`${pdfUrl}`, {
              method: 'POST',
              mode: 'cors',
              body: JSON.stringify(payload),
              credentials: 'same-origin',
              headers: {
                accept: '*/*',
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json',
                Pragma: 'no-cache',
              },
            })
              .then((response) => response?.blob())
              .then((blob) => {
                if (blob && !ngaBridge.isNgaBridgeAvailable()) {
                  const url = window.URL.createObjectURL(blob)
                  const a = document.createElement('a')
                  a.href = url
                  a.download = 'quote.pdf'
                  document.body.appendChild(a)
                  a.click()
                  a.remove()
                }
              })
          }}
          downloadUrl={pdfUrl}
          pdfViewerProps={{
            filename: 'quote.pdf',
            file: viewDownloadPDF,
          }}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: Types.RootState) => ({
  dob: selectApplicantDetails(state.aboutYou).dateOfBirth,
  firstName: selectApplicantDetails(state.aboutYou).firstName,
  lastName: selectApplicantDetails(state.aboutYou).lastName,
  lifeCover: selectLifeCover(state.quotes),
  bodyCover: selectBodyCover(state.quotes),
  total: calculateSum(selectLifeCover(state.quotes), selectBodyCover(state.quotes)),
})

const ConnectedDynamicQuotePdfWrapper = connect(mapStateToProps, null)(DynamicQuotePdf)

export { ConnectedDynamicQuotePdfWrapper }
