import { JourneyNames } from '../config'
import { StandardViewTags } from './tags.interface'
import { window } from './window'
import { onErrorThirdPartyLogger } from '../errorReporter/ErrorReporter/ErrorReporter'

declare global {
  interface Window {
    runAnalytics: any
    LBGAnalytics: any
  }
}

window.runAnalytics = window.runAnalytics || {}
window.LBGAnalytics = window.LBGAnalytics || {}

export class Analytics {
  private applicationState: string | undefined

  public initialDataLayer(brand: JourneyNames, mandatePartyID: string, isAuth: boolean): void {
    try {
      window.runAnalytics((LBGAnalytics: any) => {
        LBGAnalytics.data
          .setJourney('Life Insurance - Digital', '2')
          .setBrand(brand)
          .setDivision('Insurance and Wealth')
          .setPresentation('Responsive')
          .setProduct('Insurance', 'LifeInsurance')
          .setChannel('Online', undefined, undefined, isAuth)
          .setPageRole('Sales')
          .setCustomerID(mandatePartyID, 'Retail')
          .setCustomerAttribute('Segment', 'Unknown')
      })
    } catch (e) {
      onErrorThirdPartyLogger(e)
    }
  }

  private sendScreenToTarget(screenName: string) {
    try {
      window.LBGAnalytics.triggerView.invoke(screenName, true)
    } catch (e) {
      onErrorThirdPartyLogger(e)
    }
  }

  public initialPageLoad(tags: StandardViewTags): void {
    try {
      if (window.LBGAnalytics && window.LBGAnalytics.data) {
        if (tags.ApplicationState) this.applicationState = tags.ApplicationState
        window.LBGAnalytics.data
          .setJourney('Life Insurance - Digital', '2')
          .setJourneyStep(tags.JourneyStep, tags.JourneyStepName)
          .setJourneyStatus('', tags.JourneyAmount || '', this.applicationState)
          .done()
          .events.pageView()
          .send()
      }
      const targetPageName = tags.TargetPageName ? tags.TargetPageName : tags.JourneyStepName
      this.sendScreenToTarget(targetPageName)
    } catch (e) {
      onErrorThirdPartyLogger(e)
    }
  }

  public tagAccordionEvent(accordionName: string): void {
    try {
      window.LBGAnalytics.events.accordion('Supporting Material', accordionName).send()
    } catch (e) {
      onErrorThirdPartyLogger(e)
    }
  }

  public tagButtonEvent(buttonName: string): void {
    try {
      window.LBGAnalytics.events.send({ JourneyEvent: 'Button Click', LinkValue: buttonName })
    } catch (e) {
      onErrorThirdPartyLogger(e)
    }
  }

  public tagFieldUpdate(fieldName: string, fieldValue: string, fieldDescription = ''): void {
    try {
      window.LBGAnalytics.events
        .fieldUpdate(fieldName)
        .captureInput(fieldDescription, fieldValue)
        .send()
    } catch (e) {
      onErrorThirdPartyLogger(e)
    }
  }

  public tagToolTip(toolTipName: string): void {
    try {
      window.LBGAnalytics.events.tooltip(toolTipName).send()
    } catch (e) {
      onErrorThirdPartyLogger(e)
    }
  }

  public tagExternalLinkEvent(linkName: string): void {
    try {
      window.LBGAnalytics.events.send({
        JourneyEvent: 'External Click',
        EventAction: 'Supporting Material',
        LinkValue: linkName,
      })
    } catch (e) {
      onErrorThirdPartyLogger(e)
    }
  }

  public tagChosenSumAssured(
    JourneyProduct: string,
    JourneyAmount: string,
    JourneyTariff: string,
    JourneyTerm: string,
    voucherAmount: string,
  ): void {
    try {
      window.LBGAnalytics.data
        .setJourneyStatus('Supporting Material', voucherAmount, '')
        .setJourneyProduct(JourneyProduct, '', '')
        .setJourneyAmount(JourneyAmount)
        .setJourneyRate(JourneyTariff)
        .setJourneyTerm(JourneyTerm)
        .done()
        .events.send({ JourneyEvent: 'Button Click', LinkValue: 'Chosen - sum assured led' })
    } catch (e) {
      onErrorThirdPartyLogger(e)
    }
  }

  public tagPricingEvent(
    JourneyProduct: string,
    JourneyAmount: string,
    JourneyTariff: string,
    JourneyTerm: string,
    voucherAmount: string,
    calculationType: string,
  ): void {
    try {
      window.LBGAnalytics.data
        .setJourneyStatus('Supporting Material', voucherAmount, '')
        .setJourneyProduct(JourneyProduct, '', '')
        .setJourneyAmount(JourneyAmount)
        .setJourneyRate(JourneyTariff)
        .setJourneyTerm(JourneyTerm)
        .done()
        .events.fieldUpdate(`${calculationType} led`)
        .send()
    } catch (e) {
      onErrorThirdPartyLogger(e)
    }
  }

  public tagValidationError(errorValue: string): void {
    window.LBGAnalytics.events
      .error(errorValue)
      .apiResponse()
      .send()
  }

  public tagError(tags: StandardViewTags): void {
    try {
      window.LBGAnalytics.data
        .setJourney('Life Insurance - Digital', '2')
        .setJourneyStep(undefined, tags.JourneyStepName)
        .setJourneyStatus('', 'Error', tags.ApplicationState)
        .done()
        .events.pageView()
        .send()
    } catch (e) {
      onErrorThirdPartyLogger(e)
    }
  }
}

export const AnalyticsService = new Analytics()
