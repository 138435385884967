import React from 'react'
import { useHistory } from 'react-router-dom'
import { ButtonV2 as Button, Icon, makeStyles, MonocerosTheme, useTheme, Brand } from '@lbg-protection/lib-ui-monoceros'
import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import { isBOS } from '../../../../utils/brandSpecificFormatting'
import { QuotePageFooterProps } from '../types'

const styles = makeStyles(() => {
  return {
    previousContainer: {
      marginBottom: '-10px !important',
    },
  }
})

interface PreviousButtonProps {
  removeFromBasket: QuotePageFooterProps['removeFromBasket']
}

export const PreviousButton: React.FC<PreviousButtonProps> = (props) => {
  const classes = styles({})
  const theme = useTheme<MonocerosTheme>()
  const history = useHistory()

  const handlePrevious = () => {
    history.goBack()

    if (props.removeFromBasket) {
      props.removeFromBasket({ coverType: PricingRequestBody.CoverTypeEnum.LIFE })
      props.removeFromBasket({ coverType: PricingRequestBody.CoverTypeEnum.BODY })
    }
  }

  return (
    <Button
      variant="text"
      color="primary"
      data-testid="quote-page-bottom-navigation-previous-button"
      startIcon={<Icon name="chevron-left" />}
      onClick={handlePrevious}
      className={isBOS(theme.name as Brand) ? undefined : classes.previousContainer}
    >
      Previous
    </Button>
  )
}
