import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  useTheme,
  Brand,
  MonocerosTheme,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import clsx from 'clsx'
import { isEligible } from '../utils'
import { Eligible } from './Eligible'
import { Ineligible } from './Ineligible'
import { QuoteCardHeader } from './QuoteCardHeader'
import { QuoteCardProps } from './types'
import { isBOS, isHalifax, isSW } from '../../../utils/brandSpecificFormatting'

const withStyles = makeStyles((theme: MonocerosTheme) => {
  const isOrionTheme = isSW(theme.name as Brand)
  return {
    card: {
      minWidth: '100%',
      padding: 0,
      backgroundColor: '#EBF6FF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
    },
    header: { padding: 0, width: '100%' },
    divider: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
        margin: `${theme.spacing(2)} 0`,
      },
    },
    content: {
      width: isOrionTheme ? '100%' : '90%',
      margin: 0,
      padding: `16px ${isOrionTheme && '0'}`,
    },
    cardBackground: {
      backgroundColor: isBOS(theme.name as Brand)
        ? theme.palette.namedColours?.bosAliceBlue
        : theme.palette.common.white,
      marginBottom: '0px',
    },
    subDivider: {
      margin: `0 ${theme.spacing(3)}`,
    },
  }
})

export const QuoteCard = (props: QuoteCardProps) => {
  const classes = withStyles()
  const theme = useTheme<MonocerosTheme>()
  const isHalifaxBrand = isHalifax(theme.name as Brand)
  const boxClass = !isHalifaxBrand ? clsx(classes.card, classes.cardBackground) : classes.card
  const dividerTag = !isHalifaxBrand ? <Divider variant="middle" className={classes.subDivider} /> : ''

  return (
    <Card className={boxClass} data-testid={`${props.coverSummary.title}-component`}>
      <CardHeader
        className={classes.header}
        title={<QuoteCardHeader coverSummary={props.coverSummary} />}
        subheader={dividerTag}
      />
      <Divider className={classes.divider} />
      <CardContent className={classes.content}>
        {isEligible(props.uwOutcome) ? (
          <Eligible {...props} />
        ) : (
          <Ineligible coverType={props.coverSummary.coverType} />
        )}
      </CardContent>
    </Card>
  )
}
