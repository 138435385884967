import React from 'react'
import { Typography, ListWithBullets, ListItemWithBullet } from '@lbg-protection/lib-ui-monoceros'

export const ExamplesDialogContent = () => {
  const listItems = ['University fees', 'House deposits', 'Weddings']

  return (
    <>
      <Typography gutterBottom>You might want to leave a bit of money to help with things like:</Typography>
      <ListWithBullets>
        {listItems.map((item, index) => (
          <ListItemWithBullet key={`something-extra-example-${index}`}>{item}</ListItemWithBullet>
        ))}
      </ListWithBullets>
    </>
  )
}
