import { ExternalLinkNames } from './types'

export const externalLinks: Record<'halifax' | 'lloyds' | 'bos' | 'sw', Record<ExternalLinkNames, string>> = {
  halifax: {
    customerSupport: `https://www.halifax.co.uk/helpcentre/support-and-wellbeing.html`,
    moneyWorries: `https://www.halifax.co.uk/managingyourmoney/moneyworries.html`,
  },
  lloyds: {
    customerSupport: `https://www.lloydsbank.com/help-guidance/supportandwellbeing.html`,
    moneyWorries: `https://www.lloydsbank.com/help-guidance/managing-your-money/moneyworries.html`,
  },
  bos: {
    customerSupport: `https://www.bankofscotland.co.uk/helpcentre/support-and-wellbeing.html`,
    moneyWorries: `https://www.bankofscotland.co.uk/helpcentre/money-worries.html`,
  },
  sw: {
    customerSupport: `https://www.scottishwidows.co.uk/accessibility.html`,
    moneyWorries: `https://www.scottishwidowsbemoneywell.co.uk/`,
  },
}
