import { createAsyncThunk, createSlice, Selector, createAction } from '@reduxjs/toolkit'
import { NotFoundException } from '@lbg-protection/api-client-nmp-journey'
import { RootState } from '../../app/types'
import { policyApi } from './policyApi'
import { CreatePolicyApplicationParams } from './types'

export enum CreatePolicyApplicationCallStatus {
  Pending = 'Pending',
  Success = 'Success',
  Idle = 'Idle',
  TechnicalError = 'TechnicalError',
  BankValidationError = 'BankValidationError',
}

export interface PolicyApplicationState {
  validationErrorCount: number
  createPolicyApplicationCallStatus: CreatePolicyApplicationCallStatus
}

export const policyApplicationInitialState: PolicyApplicationState = {
  validationErrorCount: 0,
  createPolicyApplicationCallStatus: CreatePolicyApplicationCallStatus.Idle,
}

export const incrementValidationErrorCount = createAction('incrementValidationErrorCount')

const isCallStatus = (payload: any): payload is CreatePolicyApplicationCallStatus => {
  return Object.values(CreatePolicyApplicationCallStatus).includes(payload)
}

export const createPolicyApplication = createAsyncThunk(
  'policy/create',
  async (
    {
      args,
      navigateTo,
      targetNav,
      artificialLoadingTimer,
    }: {
      args: CreatePolicyApplicationParams
      navigateTo: (page: string) => void
      targetNav: string
      artificialLoadingTimer: Promise<unknown>
    },
    { getState, rejectWithValue, dispatch },
  ) => {
    const {
      saveAndRetrieve: { lastQuoteDate },
    } = getState() as RootState
    try {
      await policyApi.createPolicyApplication({ ...args, lastQuoteDate })
      await artificialLoadingTimer
      navigateTo(targetNav)
    } catch (e) {
      await artificialLoadingTimer
      if (e instanceof NotFoundException) {
        dispatch(incrementValidationErrorCount())
        navigateTo('bank-validation-error')
        return rejectWithValue(CreatePolicyApplicationCallStatus.BankValidationError)
      }
      throw e
    }
  },
)

export const policyApplicationSlice = createSlice({
  name: 'policyApplication',
  initialState: policyApplicationInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(incrementValidationErrorCount, (state) => {
      state.validationErrorCount++
    })
    builder.addCase(createPolicyApplication.fulfilled, (state, _) => {
      state.createPolicyApplicationCallStatus = CreatePolicyApplicationCallStatus.Success
    })
    builder.addCase(createPolicyApplication.pending, (state, _) => {
      state.createPolicyApplicationCallStatus = CreatePolicyApplicationCallStatus.Pending
    })
    builder.addCase(createPolicyApplication.rejected, (state, { payload }) => {
      if (payload && isCallStatus(payload)) {
        state.createPolicyApplicationCallStatus = payload
      } else {
        state.createPolicyApplicationCallStatus = CreatePolicyApplicationCallStatus.TechnicalError
      }
    })
  },
})

export const selectPolicyApplicationCallStatus: Selector<
  RootState,
  PolicyApplicationState['createPolicyApplicationCallStatus']
> = (state) => state.policyApplication.createPolicyApplicationCallStatus

export const selectValidationErrorCounts: Selector<RootState, PolicyApplicationState['validationErrorCount']> = (
  state,
) => state.policyApplication.validationErrorCount
