import { Box, LoadingSpinner as Spinner } from '@lbg-protection/lib-ui-monoceros'
import React, { useEffect } from 'react'
import { styles } from './styles'

export const LoadingSpinner = () => {
  const classes = styles()

  const blockKeyboard = (e: KeyboardEvent): boolean => {
    e.preventDefault()
    return false
  }

  useEffect(() => {
    window.addEventListener('keydown', blockKeyboard)
    return () => window.removeEventListener('keydown', blockKeyboard)
  }, [])

  return (
    <div className={classes.pageContainerNoBackground}>
      <Box data-testid="loading-spinner-container" className={classes.container} aria-label="loading">
        <Spinner color="primary" size="48px" />
      </Box>
    </div>
  )
}
