import dayjs from 'dayjs'
import {
  AnswerUnderwritingQuestionRequestBody,
  AnswerUnderwritingQuestionRequestPathParams,
  CloseUnderwritingEnquiryRequestPathParams,
  StartUnderwritingEnquiryRequestBody,
  StartUnderwritingEnquiryRequestQuery,
  UnderwritingEnquiryAnswer,
  UnderwritingEnquiryResponseBodyData,
  OptionListResponseBodyData,
  RetrieveUnderwritingQuestionOptionListRequestPathParams,
  RetrieveUnderwritingQuestionOptionListRequestQuery,
} from '@lbg-protection/api-client-public-site-journey'
import { CoverAmountInterface } from '../../journeys/purchase'
import { memoize } from '../../utils/memoize'
import { getPlatformSpecificEnquiryConfig } from './utils/getPlatformSpecificEnquiryConfig'
import { getUwMeConfig } from './utils/getUwMeConfig'
import {
  UwMeAnswerEnquiryQuestionRequestData,
  UwMeStartEnquiryRequestQueryParams,
  UwMeInputType,
  UwMeStartEnquiryParams,
} from './types'
import { unAuthJourneyApi } from '../../apis/unAuthJourneyApi'

export const uwMeUnAuthApi = {
  buildStartEnquiryRequestBody: (
    {
      dateOfBirth,
      prePurchaseLifeCover,
      prePurchaseBodyCover,
    }: {
      dateOfBirth: string
      prePurchaseLifeCover?: CoverAmountInterface
      prePurchaseBodyCover?: CoverAmountInterface
    },
    salesChannel: string,
  ): StartUnderwritingEnquiryRequestBody => {
    const dob = dayjs(dateOfBirth).format('YYYY-MM-DD')

    return {
      salesChannel,
      applicant: { dob },
      cover: {
        underwriteLife: true,
        underwriteBody: true,
        lifeCoverAmount: prePurchaseLifeCover?.sumAssured || 0,
        bodyCoverAmount: prePurchaseBodyCover?.sumAssured || 0,
      },
    }
  },

  buildStartEnquiryRequestQuery: (params: UwMeStartEnquiryRequestQueryParams): StartUnderwritingEnquiryRequestQuery => {
    return params.tag !== undefined ? { locale: params.locale, tag: params.tag } : { locale: params.locale }
  },
  buildOptionBackedQuestionOptions: async (
    uwMeResponse: UnderwritingEnquiryResponseBodyData,
    query: RetrieveUnderwritingQuestionOptionListRequestQuery,
  ): Promise<UnderwritingEnquiryResponseBodyData> => {
    const uwMeOptionBackedQuestionOptionsMemoize = memoize(uwMeUnAuthApi.uwMeOptionBackedQuestionOptions)
    if (uwMeResponse.sections) {
      // eslint-disable-next-line no-restricted-syntax
      for (const section of uwMeResponse.sections) {
        if (section.enquiryLines) {
          // eslint-disable-next-line no-restricted-syntax
          for (const enquiry of section.enquiryLines) {
            if (enquiry.questions) {
              // eslint-disable-next-line no-restricted-syntax
              for (const question of enquiry.questions) {
                if (
                  question.definition &&
                  question.definition.type === UwMeInputType.OPTION_BACKED &&
                  question.definition.options === undefined
                ) {
                  /* eslint-disable no-await-in-loop */
                  const response = await uwMeOptionBackedQuestionOptionsMemoize(
                    { questionName: question.name || '' },
                    { ...query },
                  )
                  /* eslint-enable no-await-in-loop */
                  question.definition.options = response.options
                }
              }
            }
          }
        }
      }
    }
    return uwMeResponse
  },
  getEnquiryResponseWithOptionBackedOptions: async (
    uwMeResponse: UnderwritingEnquiryResponseBodyData,
  ): Promise<UnderwritingEnquiryResponseBodyData> => {
    const startEnquiryResponseWithOptionBackedOptions = await uwMeUnAuthApi.buildOptionBackedQuestionOptions(
      uwMeResponse,
      {
        locale: uwMeResponse.locale || '',
        tag: uwMeResponse.tag || '',
        branch: uwMeResponse.branch || '',
      },
    )
    return startEnquiryResponseWithOptionBackedOptions
  },
  uwMeStartEnquiry: async ({
    dateOfBirth,
    prePurchaseLifeCover,
    prePurchaseBodyCover,
    isMobile,
  }: UwMeStartEnquiryParams): Promise<UnderwritingEnquiryResponseBodyData> => {
    const uwMeConfig = await getUwMeConfig()
    const { salesChannel, locale } = getPlatformSpecificEnquiryConfig(uwMeConfig, isMobile)
    const startEnquiryResponse = await unAuthJourneyApi().uwMeStartEnquiry(
      uwMeUnAuthApi.buildStartEnquiryRequestBody(
        {
          dateOfBirth,
          prePurchaseLifeCover,
          prePurchaseBodyCover,
        },
        salesChannel,
      ),
      uwMeUnAuthApi.buildStartEnquiryRequestQuery({ locale }),
    )
    const startEnquiryResponseWithOptionBackedOptions = await uwMeUnAuthApi.getEnquiryResponseWithOptionBackedOptions(
      startEnquiryResponse,
    )
    return startEnquiryResponseWithOptionBackedOptions
  },
  buildAnswerEnquiryQuestionRequestBody: (
    answers: UnderwritingEnquiryAnswer[],
  ): AnswerUnderwritingQuestionRequestBody => {
    return { answers }
  },
  buildAnswerEnquiryQuestionRequestPathParams: (enquiryId: string): AnswerUnderwritingQuestionRequestPathParams => {
    return { enquiryId }
  },
  uwMeAnswerEnquiryQuestion: async (
    requestData: UwMeAnswerEnquiryQuestionRequestData,
  ): Promise<UnderwritingEnquiryResponseBodyData> => {
    const answerQuestionResponse = await unAuthJourneyApi().uwMeAnswerEnquiryQuestion(
      uwMeUnAuthApi.buildAnswerEnquiryQuestionRequestBody(requestData.answers),
      uwMeUnAuthApi.buildAnswerEnquiryQuestionRequestPathParams(requestData.enquiryId),
    )
    const answerQuestionResponseWithOptionBackedOptions = await uwMeUnAuthApi.getEnquiryResponseWithOptionBackedOptions(
      answerQuestionResponse,
    )
    return answerQuestionResponseWithOptionBackedOptions
  },

  uwMeOptionBackedQuestionOptions: async (
    path: RetrieveUnderwritingQuestionOptionListRequestPathParams,
    query: RetrieveUnderwritingQuestionOptionListRequestQuery,
  ): Promise<OptionListResponseBodyData> => {
    const optionBackedQuestionOptions = await unAuthJourneyApi().getOptionBackedQuestionOptions(path, query)
    return optionBackedQuestionOptions
  },
  buildCloseUnderwritingEnquiryRequestPathParams: (enquiryId: string): CloseUnderwritingEnquiryRequestPathParams => {
    return { enquiryId }
  },
  uwMeCloseEnquiry: async (enquiryId: string): Promise<UnderwritingEnquiryResponseBodyData> => {
    const closeEnquiryResponse = await unAuthJourneyApi().uwMeCloseEnquiry(
      uwMeUnAuthApi.buildCloseUnderwritingEnquiryRequestPathParams(enquiryId),
    )
    const closeEnquiryResponseWithOptionBackedOptions = await uwMeUnAuthApi.getEnquiryResponseWithOptionBackedOptions(
      closeEnquiryResponse,
    )

    return closeEnquiryResponseWithOptionBackedOptions
  },
}
