import {
  Box,
  Brand,
  ListItemWithBullet,
  ListWithBullets,
  MonocerosTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'

import { InfoDialog } from '../InfoDialog/InfoDialog'
import {
  eligibilityBulletList1,
  premiumForVoucher,
  eligibilityBulletList2,
  generalConditionsBulletList,
  howToClaimBulletList,
} from './voucherTermsAndConditionsDataTiered'
import { isBOS, isLloyds, isSW } from '../../../utils/brandSpecificFormatting'

const withStyles = makeStyles((theme: MonocerosTheme) => {
  const isOrionTheme = isSW(theme.name as Brand)
  const tableHeadingBGColor = (brand: Brand) => {
    if (isLloyds(brand)) {
      return theme.palette.namedColours?.lloydsWoolGrey
    }
    if (isBOS(brand)) {
      return theme.palette.namedColours?.bosBabyBlue
    }
    if (isOrionTheme) {
      return theme.palette.namedColours?.orionMellowGrey
    }
    return theme.palette.namedColours?.halifaxLightGrey
  }

  return {
    section: {
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(4),
    },
    sectionTextPadding: {
      paddingBottom: theme.spacing(isOrionTheme ? 4 : 3),
    },
    tableHeading: {
      backgroundColor: tableHeadingBGColor(theme.name as Brand),
      fontSize: theme.spacing(3),
    },
    tableCell: {
      fontWeight: isOrionTheme ? 350 : 'normal',
      fontSize: theme.spacing(3),
    },
  }
})

interface VoucherTermsAndConditionsOverlayTieredProps {
  open: boolean
  toggleOpen: () => void
  policyDurationBullet: string
}

export const VoucherTermsAndConditionsOverlayTiered = ({
  open,
  toggleOpen,
  policyDurationBullet,
}: VoucherTermsAndConditionsOverlayTieredProps) => {
  const classes = withStyles()
  const { name: themeName = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(themeName)
  const textVariant = isSWBrand ? 'h5' : 'subtitle2'
  return (
    <InfoDialog open={open} data-testid="terms-and-conditions" title="Terms and conditions" toggleOpen={toggleOpen}>
      <Typography
        className={classes.sectionTextPadding}
        variant={textVariant}
        component="h2"
        data-testid="terms-and-conditions-eligibility"
        tabIndex={0}
      >
        Eligibility
      </Typography>
      <Typography variant="body1" className={classes.sectionTextPadding} tabIndex={0}>
        You will receive this offer if:
      </Typography>
      <Box tabIndex={0}>
        <ListWithBullets data-testid="terms-and-conditions-eligibility-bullets1">
          <ListItemWithBullet> {policyDurationBullet} </ListItemWithBullet>
          {Object.entries(eligibilityBulletList1).map(([key, textValue]) => (
            <ListItemWithBullet key={key}> {textValue} </ListItemWithBullet>
          ))}
        </ListWithBullets>
        <TableContainer className={classes.section}>
          <Table>
            <TableHead>
              <TableRow>
                {Object.getOwnPropertyNames(premiumForVoucher[0]).map((key) => (
                  <TableCell className={classes.tableHeading}>{`${key[0].toUpperCase()}${key.substring(1)}`}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {premiumForVoucher.map((item) => (
                <TableRow>
                  <TableCell className={classes.tableCell}>{item.premium}</TableCell>
                  <TableCell className={classes.tableCell}>{item.voucher}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ListWithBullets className={classes.section} data-testid="terms-and-conditions-eligibility-bullets2">
          {Object.entries(eligibilityBulletList2).map(([key, textValue]) => (
            <ListItemWithBullet key={key}> {textValue} </ListItemWithBullet>
          ))}
        </ListWithBullets>
      </Box>

      <Typography
        className={classes.section}
        variant={textVariant}
        component="h2"
        data-testid="terms-and-conditions-how-to-claim"
        tabIndex={0}
      >
        How to claim
      </Typography>
      <Box tabIndex={0}>
        <ListWithBullets data-testid="terms-and-conditions-how-to-claim-bullets">
          {Object.entries(howToClaimBulletList).map(([key, textValue]) => (
            <ListItemWithBullet key={key}> {textValue} </ListItemWithBullet>
          ))}
        </ListWithBullets>
      </Box>

      <Typography
        className={classes.section}
        variant={textVariant}
        component="h2"
        data-testid="terms-and-conditions-general-conditions"
        tabIndex={0}
      >
        General Conditions
      </Typography>
      <Box tabIndex={0}>
        <ListWithBullets data-testid="terms-and-conditions-general-conditions-bullets">
          {Object.entries(generalConditionsBulletList).map(([key, textValue]) => (
            <ListItemWithBullet key={key}> {textValue} </ListItemWithBullet>
          ))}
        </ListWithBullets>
      </Box>
    </InfoDialog>
  )
}
