import {
  ButtonV2 as Button,
  makeStyles,
  Typography,
  Alert,
  Brand,
  MonocerosTheme,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { isHalifax } from '../../../../utils/brandSpecificFormatting'

interface AdjustedValueConfirmationProps {
  message: string
  onSubmit?: () => void
}

const getBackgroundColour = (theme: MonocerosTheme) => {
  switch (theme.name) {
    case Brand.LLOYDS:
      return theme.palette.namedColours?.lloydsNegativeTint
    case Brand.BOS:
      return theme.palette.namedColours?.bosNegativeTint
    default:
      return theme.palette.common.white
  }
}

const withStyles = makeStyles((theme: MonocerosTheme) => {
  return {
    wrapper: {
      marginBottom: theme.spacing(5),
      border: isHalifax(theme.name as Brand) ? `${theme.spacing(0.2)} solid ${theme.palette.error.main}` : 'none',
      width: theme.spacing(48),
      backgroundColor: getBackgroundColour(theme),
    },
  }
})

export const AdjustedValueConfirmation = (props: AdjustedValueConfirmationProps) => {
  const { message, onSubmit = () => null } = props
  const classes = withStyles({})
  return (
    <Alert className={classes.wrapper} severity="error" aria-label="information point" role="note">
      <Typography variant="body1" data-testid="adjusted-value-error-message">
        {message}
      </Typography>
      <br />
      <Button
        color="primary"
        variant="outlined"
        onClick={onSubmit}
        data-testid="adjusted-value-submit-button"
        fullWidth
      >
        Change
      </Button>
    </Alert>
  )
}
