import { createAsyncThunk, createSlice, PayloadAction, Reducer, Selector } from '@reduxjs/toolkit'
import {
  AboutYouResponseData,
  UnderwritingEnquiryDecision,
  UnderwritingEnquiryResponseBodyData,
} from '@lbg-protection/api-client-nmp-journey'
import { RootState } from '../../app/types'
import { PrePurchaseInterface } from '../../journeys/purchase'
import { getGroupedQuestions } from './transformers/transformUwMeResponse'
import {
  IneligibleDecision,
  LBGQuestion,
  UwMeLoadingStatus,
  UwMeStartEnquiryRequestArgs,
  UwMeState,
  UwMeAnswerEnquiryQuestionRequestData,
  EnquiryId,
} from './types'
import { uwMeAuthApi } from './underwriteMeAuthApi'
import { uwMeUnAuthApi } from './underwriteMeUnAuthApi'
import { aboutYouSlice } from '../../journeys/purchase/aboutYou.slice'

const initialState: UwMeState = {
  loadingStatus: UwMeLoadingStatus.Idle,
  uwMeData: null,
  currentPageIndex: 0,
  tag: '',
}

type Reducers = {
  setCurrentPageIndex: Reducer<UwMeState, PayloadAction<number>>
  setTag: Reducer<UwMeState, PayloadAction<string>>
  setEnquiry: Reducer<UwMeState, PayloadAction<UwMeState['uwMeData']>>
}

const reducers: Reducers = {
  setCurrentPageIndex: (state = initialState, action) => {
    const nextPageIndex = action.payload

    return { ...state, currentPageIndex: nextPageIndex }
  },
  setTag(state = initialState, action) {
    return { ...state, tag: action.payload }
  },
  setEnquiry: (state = initialState, action) => {
    return { ...state, uwMeData: action.payload, loadingStatus: UwMeLoadingStatus.Success }
  },
}

export const uwMeStartEnquiry = createAsyncThunk(
  'uwMe/startEnquiry',
  async (args: UwMeStartEnquiryRequestArgs, { getState }): Promise<UnderwritingEnquiryResponseBodyData> => {
    const {
      prePurchase,
      aboutYou: { aboutYouData },
      config: { isUnAuth },
    } = getState() as RootState
    const { dateOfBirth } = aboutYouData as AboutYouResponseData
    const { life, body } = prePurchase as PrePurchaseInterface
    const uwMeApi = isUnAuth ? uwMeUnAuthApi : uwMeAuthApi
    const uwMeData = await uwMeApi.uwMeStartEnquiry({
      dateOfBirth,
      prePurchaseLifeCover: life,
      prePurchaseBodyCover: body,
      isMobile: args.isMobile,
      tag: args.tag,
    })
    return uwMeData
  },
)

const getBirthDateFromUWAnswer = (reqData: UwMeAnswerEnquiryQuestionRequestData) =>
  reqData.answers[0]?.questionPath === 'BIRTHDATE' ? reqData.answers[0]?.answerValue[0] : undefined

export const uwMeAnswerQuestion = createAsyncThunk(
  'uwMe/answerQuestion',
  async (arg: UwMeAnswerEnquiryQuestionRequestData, { getState, dispatch }) => {
    const {
      config: { isUnAuth },
    } = getState() as RootState
    const uwMeApi = isUnAuth ? uwMeUnAuthApi : uwMeAuthApi
    const dobAnswer = getBirthDateFromUWAnswer(arg)
    if (dobAnswer) {
      dispatch(aboutYouSlice.actions.setDateOfBirth(dobAnswer))
    }
    return uwMeApi.uwMeAnswerEnquiryQuestion(arg)
  },
)

export const uwMeCloseEnquiry = createAsyncThunk('uwMe/closeEnquiry', async (arg: EnquiryId, { getState }) => {
  const {
    config: { isUnAuth },
  } = getState() as RootState
  const uwMeApi = isUnAuth ? uwMeUnAuthApi : uwMeAuthApi
  return uwMeApi.uwMeCloseEnquiry(arg)
})

export const uwMeSlice = createSlice({
  name: 'underwriteMe',
  initialState,
  reducers,
  extraReducers: (builder) => {
    builder.addCase(uwMeStartEnquiry.pending, (state, _action) => {
      return { ...state, loadingStatus: UwMeLoadingStatus.Loading }
    })
    builder.addCase(uwMeStartEnquiry.fulfilled, (state, action) => {
      return { ...state, loadingStatus: UwMeLoadingStatus.Success, uwMeData: action.payload }
    })
    builder.addCase(uwMeStartEnquiry.rejected, (state, _action) => {
      return { ...state, loadingStatus: UwMeLoadingStatus.Error }
    })
    builder.addCase(uwMeAnswerQuestion.pending, (state, _action) => {
      return { ...state, loadingStatus: UwMeLoadingStatus.Loading }
    })
    builder.addCase(uwMeAnswerQuestion.fulfilled, (state, action) => {
      return { ...state, loadingStatus: UwMeLoadingStatus.Success, uwMeData: action.payload }
    })
    builder.addCase(uwMeAnswerQuestion.rejected, (state, _action) => {
      return { ...state, loadingStatus: UwMeLoadingStatus.Error }
    })
    builder.addCase(uwMeCloseEnquiry.pending, (state, _action) => {
      return { ...state, loadingStatus: UwMeLoadingStatus.Loading }
    })
    builder.addCase(uwMeCloseEnquiry.fulfilled, (state, action) => {
      return { ...state, loadingStatus: UwMeLoadingStatus.Success, uwMeData: action.payload }
    })
    builder.addCase(uwMeCloseEnquiry.rejected, (state, _action) => {
      return { ...state, loadingStatus: UwMeLoadingStatus.Error }
    })
  },
})

export const selectUwMeLoadingStatus: Selector<UwMeState, UwMeState['loadingStatus']> = (state) => state.loadingStatus
export const selectUwMeQuestions: Selector<RootState, LBGQuestion[][]> = (state) =>
  state.underwriteMe?.uwMeData ? getGroupedQuestions(state.underwriteMe?.uwMeData, state.config) : []
export const selectUwMeQuestionsLoaded: Selector<RootState, boolean> = (state) => selectUwMeQuestions(state).length > 0

export const selectUwMeEnquiryId: Selector<UwMeState, string> = (state) => state.uwMeData?.enquiryId || ''
export const selectUwMeCurrentPageIndex: Selector<UwMeState, number> = (state) => state.currentPageIndex
export const selectUwMeDecisions: Selector<UwMeState, UnderwritingEnquiryDecision[]> = (state) =>
  state.uwMeData?.decisions || []
export const selectIsEnquiryOpen: Selector<UwMeState, boolean> = (state) => !!state.uwMeData?.isOpen
export const selectIsEnquiryCloseable: Selector<UwMeState, boolean> = (state) =>
  selectIsEnquiryOpen(state) && !!state.uwMeData?.isSatisfied && !!state.uwMeData.isCloseable
export const selectIsCovidPostpone: Selector<UwMeState, boolean> = (state) =>
  !!state.uwMeData?.decisions?.some((decision) => decision.decisionStatus === IneligibleDecision.POSTPONE)
export const selectUwMeTag: Selector<UwMeState, string> = (state) => state.tag || ''
