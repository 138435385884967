import React from 'react'
import clsx from 'clsx'
import { Alert, Box, Typography, useTheme, MonocerosTheme, Brand } from '@lbg-protection/lib-ui-monoceros'
import { isLloyds, isBOS, isSW } from '../../../utils/brandSpecificFormatting'
import { PageFocus } from '../../Shared/PageFocus'
import { withStyles } from './styles'
import { IndicativeQuotesHeaderProps } from './types'

export const IndicativeQuotePageHeader = (props: IndicativeQuotesHeaderProps) => {
  const classes = withStyles()
  const { name: brand = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isBosTheme = isBOS(brand)
  const isSwBrand = isSW(brand)
  const colorClass = isLloyds(brand) || isBosTheme ? 'initial' : 'inherit'

  const { validationError } = props

  return (
    <Box>
      {validationError && (
        <Alert
          severity="error"
          data-testid="indicative-quote-page-validation-errors-alert"
          className={classes.validationErrorBox}
          role="presentation"
        >
          <PageFocus>
            <Typography>{validationError}</Typography>
          </PageFocus>
        </Alert>
      )}
      {!isSwBrand && !isBosTheme && (
        <Box className={classes.title} data-testid="indicative-quote-header-container">
          <PageFocus>
            <Typography
              align="center"
              variant="h4"
              component="h1"
              color={colorClass}
              data-testid="indicative-quote-header-title"
            >
              Get quick quote
            </Typography>
          </PageFocus>
        </Box>
      )}
      <Box>
        <Typography
          align="left"
          variant="body1"
          color="primary"
          className={clsx(classes.quoteHelp, classes.quoteHelpText)}
          data-testid="indicative-quote-header-description"
        >
          {!props.isMonthlyCostAdded
            ? 'Just enter a cover amount and a duration to find out how much this cover could cost for you.'
            : 'Based on what you told us this is how much this cover could cost for you.'}
        </Typography>
      </Box>
    </Box>
  )
}
