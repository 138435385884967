import React from 'react'
import { CoverRightForYou } from './CoverRightForYou'

const rightBodyCover = [
  `You'd struggle to keep up with the day-to-day costs if you were too ill to work for some time`,
  `You don’t have savings or workplace benefits that could cover your costs if you were too ill to work`,
  "You'd like to have a cash lump sum pay-out if you became seriously ill, to let you focus on your health and wellbeing.",
]

export const BodyCoverRightForYou = () => {
  return <CoverRightForYou title="Body Cover might be right for you if:" content={rightBodyCover} />
}
