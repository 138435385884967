import { createAction, createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { SmokerDeclarationState, SetStatusActionProps, SelectSmokerDeclarationStatus } from '../types'

// Action Creators
export const setSmokerStatus = createAction<SetStatusActionProps>('setSmokerStatus')

const initialState: SmokerDeclarationState = {
  smokerStatus: null,
}

export const smokerDeclarationSlice = createSlice({
  name: 'smokerDeclaration',
  initialState,
  reducers: {
    setSmokerStatus: (
      state: SmokerDeclarationState,
      action: PayloadAction<SetStatusActionProps>,
    ): SmokerDeclarationState => {
      const { smokerStatus } = action.payload
      state.smokerStatus = smokerStatus // eslint-disable-line no-param-reassign
      return state
    },
  },
})

export const selectSmokerDeclarationStatus: SelectSmokerDeclarationStatus = createSelector(
  (state) => state.smokerStatus,
  (smokerStatus) => smokerStatus,
)
