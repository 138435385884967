import { useContext } from 'react'

import { TokenisedStringData, TokenisedString } from './Content'
import { ContentContext } from './ContentContext'
import { KnownKeys } from '../../utils/typeHelpers'
import { bindContent, BindContentData } from './bindContent'

type TypeWithGeneric<T> = T[]
type extractGeneric<Type> = Type extends TypeWithGeneric<infer X> ? X : never

type Content = {}

type Nullable<T> = { [P in keyof T]: T[P] | null }

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useContent = <T extends Content>() => {
  const journeyContent: T = useContext(ContentContext) as T

  return <
    K extends keyof T & KnownKeys<T>,
    V extends T[K],
    RV extends V extends TokenisedString<TokenisedStringData> ? string : V,
    D = V extends TokenisedString<TokenisedStringData> ? Nullable<extractGeneric<V>> : undefined
  >(
    key: K,
    defaultValue: V,
    data?: D,
  ): RV => {
    let originalContentValue: V
    let contentValue: RV
    if (journeyContent && journeyContent[key as keyof T] !== undefined) {
      originalContentValue = journeyContent[key as keyof T] as V
    } else {
      originalContentValue = defaultValue
    }

    if (originalContentValue && typeof originalContentValue === 'string' && data) {
      contentValue = bindContent(originalContentValue, (data as unknown) as BindContentData) as RV
    } else {
      contentValue = originalContentValue as RV
    }

    return contentValue
  }
}
