import { Typography, ListItemWithBullet, ListWithBullets, makeStyles } from '@lbg-protection/lib-ui-monoceros'
import React, { ReactElement, ReactNode } from 'react'

interface BulletPointWrapperProps {
  bulletPoints: Array<string | Array<string> | ReactElement>
  bulletPointIcon?: ReactNode
  dataTestId?: string
}

const withStyles = makeStyles(() => {
  return {
    root: {
      padding: 0,
    },
  }
})

const getListText = (pointsData: string | Array<string> | ReactElement) => {
  if (typeof pointsData === 'string') {
    return <Typography variant="body1">{pointsData}</Typography>
  }
  if (Array.isArray(pointsData)) {
    return pointsData.map((text) => <Typography variant="body1">{text}</Typography>)
  }
  return pointsData
}

export const BulletPointWrapper = (props: BulletPointWrapperProps) => {
  const classes = withStyles()
  const bulletPointIcon: React.ReactElement | undefined = props.bulletPointIcon
    ? (props.bulletPointIcon as React.ReactElement)
    : undefined
  return (
    <ListWithBullets data-testid={props.dataTestId} className={classes.root} bullet={bulletPointIcon}>
      {props.bulletPoints.map((bulletPoint) => (
        <ListItemWithBullet>{getListText(bulletPoint)}</ListItemWithBullet>
      ))}
    </ListWithBullets>
  )
}
