import { FormField, Select, Typography } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { IconTitle } from '../../../IconTitle/IconTitle'
import { SubmitAnswer } from '../../types'
import { Milestones } from './Milestones'
import { styles } from './styles'
import { Term, TermAnswer, TermQ } from './types'

interface QuestionProps {
  question: TermQ
  answer: TermAnswer | null
  submitAnswer: SubmitAnswer
  goToNextQuestion: () => void
}

export const Question = (props: QuestionProps) => {
  const classes = styles({})

  const { handleSubmit, errors, control, watch } = useForm({
    mode: 'onChange',
  })

  const formValues = watch()

  useDeepCompareEffect(() => {
    props.submitAnswer({
      questionId: 'term',
      question: 'Term',
      category: 'term',
      values: formValues,
    })
  }, [formValues])

  const onSubmit = () => {
    props.goToNextQuestion()
  }

  return (
    <>
      <IconTitle dataTestId={`${props.question.id}-title`} title={props.question.title} />
      <Typography className={classes.helpText} variant="body1" data-testid={`${props.question.id}-help-text`}>
        {props.question.helpText}
      </Typography>
      <Milestones milestones={props.question.milestones} />
      <form
        data-testid={`${props.question.id}-question-form`}
        id={`${props.question.id}-question-form`}
        onSubmit={handleSubmit(onSubmit)}
      >
        {props.question.terms.map((term: Term) => {
          return (
            <React.Fragment key={`${term.id}-question}`}>
              <Controller
                name={term.id}
                defaultValue={props.answer?.values[term.id] || ''}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: term.errorMessage,
                  },
                }}
                render={({ value, name, onChange, ref }) => (
                  <FormField
                    id={props.question.id}
                    name={name}
                    endAdornment="years"
                    label={term.label}
                    errorMessage={errors[name]?.message}
                    helperText={term.helpText}
                    value={value}
                  >
                    <Select
                      native
                      displayEmpty
                      className={classes.selectField}
                      key={term.id}
                      id={term.id}
                      onChange={onChange}
                      value={value}
                      inputProps={{
                        'data-testid': term.id,
                        'aria-label': `${term.id} select term length`,
                        'aria-required': true,
                      }}
                      ref={ref}
                      inputRef={ref}
                    >
                      <option hidden value="">
                        Select
                      </option>
                      {props.question.termRange.map((termYear) => (
                        <option
                          data-testid={`${term.id}-dropdown-value-${termYear}`}
                          key={`${term.id}-dropdown-value-${termYear}`}
                          value={termYear}
                        >
                          {termYear}
                        </option>
                      ))}
                    </Select>
                  </FormField>
                )}
              />
            </React.Fragment>
          )
        })}
      </form>
    </>
  )
}
