import { Box, Typography } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { BulletPointWrapper } from '../../Shared/BulletPointWrapper'
import { withStyles } from './styles'

const coverFor = ['Your children turn 18', 'Your mortgage is paid off', 'You reach state pension age.']

export const HowLongShouldIGetCoverFor = () => {
  const classes = withStyles()

  return (
    <Box data-testid="life-cover-for-question-container">
      <Typography className={classes.textContainer} variant="body1" data-testid="life-cover-for-question-answer">
        It might help to think about some of life&#39;s milestones, like the number of years until:
      </Typography>
      <Box data-testid="life-cover-for-question-answer-bulletpoints">
        <BulletPointWrapper bulletPoints={coverFor} />
      </Box>
    </Box>
  )
}
