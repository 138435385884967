import { connect } from 'react-redux'
import * as Types from '../../app/types'
import { CoverSelectedEnum } from '../../journeys/purchase'
import { selectCoverChoice } from '../../journeys/purchase/prePurchase.slice'
import { selectApplicantDetails } from '../../journeys/purchase/aboutYou.slice'
import { Questionaire, QuestionaireDispatchProps, QuestionaireStateProps } from '../Shared/Questionaire'
import {
  createQuestionaireSlice,
  selectAnswer,
  selectAnswers,
  selectCurrentQuestion,
  selectQuestion,
} from '../Shared/Questionaire/questionaire.slice'
import { Answer, Question } from '../Shared/Questionaire/types'
import { getQuestions } from './questions/getQuestions'
import { getQuestionSequence } from './questionSequence'

const coverCalculatorSlice = createQuestionaireSlice('coverCalculator', 'protectWho')

const mapStateToProps = (state: Types.RootState): QuestionaireStateProps => {
  const selectedProducts = selectCoverChoice(state.prePurchase)?.coverSelected || CoverSelectedEnum.None
  const { dateOfBirth } = selectApplicantDetails(state.aboutYou)
  const questions = getQuestions(selectAnswers(state.coverCalculator), selectedProducts, dateOfBirth)

  return {
    question: selectQuestion(questions as Question[])(state.coverCalculator),
    answer: selectAnswer(state.coverCalculator),
    questionSequence: getQuestionSequence(selectAnswers(state.coverCalculator), selectedProducts),
    currentQuestion: selectCurrentQuestion(state.coverCalculator),
    progressBarColor: 'secondary',
    progressBarIconName: 'calculator',
    progressBarText: 'Your calculation',
  }
}

const mapDispatchToProps = (dispatch: Types.AppDispatch): QuestionaireDispatchProps => ({
  submitAnswer: (answer: Answer): void => dispatch(coverCalculatorSlice.actions.submitAnswer(answer)),
  goto: (question: string): void => dispatch(coverCalculatorSlice.actions.gotoQuestion(question)),
  finish: (navigateTo: Function): void => navigateTo('/cover-summary'),
})

const ConnectedQuestionnaire = connect(mapStateToProps, mapDispatchToProps)(Questionaire)
export { ConnectedQuestionnaire, coverCalculatorSlice }
