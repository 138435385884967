import { Brand, makeStyles, MonocerosTheme, useTheme } from '@lbg-protection/lib-ui-monoceros'
import { isLloyds, isBOS, isSW } from '../../../utils/brandSpecificFormatting'

export default makeStyles((theme: MonocerosTheme) => {
  const { name = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(name)
  const isLLoydsBrand = isLloyds(theme.name as Brand)
  return {
    quotesWrapper: {
      paddingTop: isSWBrand ? 0 : theme.spacing(1),
      flexDirection: 'column',
      flex: 1,
    },
    coverContainer: {
      marginTop: theme.spacing(isSWBrand ? 6 : 4),
    },
    quoteContainer: {
      paddingLeft: theme.spacing(isSWBrand ? 2 : 3),
      paddingRight: theme.spacing(isSWBrand ? 2 : 3),
      background: isBOS(theme.name as Brand) ? theme.palette.secondary.light : 'transparent',
    },
    alert: {
      marginTop: isSWBrand ? 40 : theme.spacing(4),
    },
    overlayHeading: {
      marginBottom: theme.spacing(4),
    },
    overlayLink: {
      marginTop: isSWBrand ? 0 : theme.spacing(2),
    },
    stepperCard: {
      marginTop: isLLoydsBrand ? theme.spacing(4) : theme.spacing(5),
      background: theme.palette.common.white,
      ...(isSWBrand && { padding: theme.spacing(2), marginTop: theme.spacing(8) }),
    },
    stepperHeading: {
      marginTop: isLLoydsBrand ? theme.spacing(3) : 0,
      marginLeft: isLLoydsBrand ? theme.spacing(3) : 0,
      marginBottom: isLLoydsBrand ? 0 : theme.spacing(4),
      ...(isSWBrand && { marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }),
    },
    stepper: {
      paddingLeft: isLLoydsBrand ? theme.spacing(3) : 0,
      paddingRight: isLLoydsBrand ? theme.spacing(3) : 0,
    },
    linkTitle: {
      fontFamily: theme.typography.body1.fontFamily,
    },
  }
})
