import { makeStyles } from '@lbg-protection/lib-ui-monoceros'

export const withStyles = makeStyles((theme) => {
  return {
    wrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formWrapper: {
      width: '90%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontSize: '1rem',
      '& input, span.currency': {
        fontSize: '1.125rem',
      },
    },
    addedToBasketWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    input: {
      paddingBottom: theme.spacing(4),
    },
    button: {
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      background: theme.palette.common.white,
      '&:hover:not(:disabled)': {
        backgroundColor: theme.palette.common.white,
      },
    },
    text: {
      marginTop: theme.spacing(3),
    },
    inputPlaceholder: {
      '&::placeholder': { color: '#414D66', fontSize: theme.spacing(3), fontWeight: 500, opacity: 1 },
    },
  }
})
