import { AboutYouProductHolding } from '@lbg-protection/api-client-nmp-journey'
import { PaymentDetails } from '../types'
import { formatSortCode } from './formatSortCode'

export const getSelectedAccountDetailsFromProductHoldings = (
  productHoldings: AboutYouProductHolding[],
  selectedAccountId: string | undefined,
): PaymentDetails | undefined => {
  const selectedAccountDetails = productHoldings.find(
    (account) => `${account.sortCode}${account.accountNumber}` === selectedAccountId,
  )

  if (selectedAccountDetails) {
    const { sortCode } = selectedAccountDetails
    const formattedSortCode = formatSortCode(sortCode)

    return {
      accountNumber: selectedAccountDetails.accountNumber.toString(),
      sortCode: formattedSortCode,
      isNamedAccountHolder: true,
      selectedAccountId,
    }
  }
  return undefined
}
