import { Box, Icon, IconProps, useTheme, Brand, Typography, MonocerosTheme } from '@lbg-protection/lib-ui-monoceros'
import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import React, { useMemo } from 'react'
import clsx from 'clsx'
import { withStyles } from './styles'
import { IndicativeQuoteCardHeaderProps } from './types'
import { useContent } from '../../../Content/useContent'
import { IndicativeQuotePageContent } from '../../IndicativeQuotePageContent'
import { isHalifax, isSW } from '../../../../utils/brandSpecificFormatting'

export const IndicativeQuoteCardHeader = (props: IndicativeQuoteCardHeaderProps) => {
  const classes = withStyles()
  const { name: themeName = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isHalifaxBrand = useMemo(() => isHalifax(themeName), [themeName])
  const isSWBrand = useMemo(() => isSW(themeName), [themeName])
  const titleVariant = isHalifaxBrand || isSWBrand ? 'h5' : 'h4'

  const isBodyCard = useMemo(() => props.coverSummary.coverType === PricingRequestBody.CoverTypeEnum.BODY, [
    props.coverSummary.coverType,
  ])
  const boxClass = useMemo(
    () =>
      !isHalifaxBrand
        ? clsx(
            classes.wrapper,
            isBodyCard
              ? clsx(classes.boxBackgroundBody, isSWBrand && classes.marginBottom20)
              : classes.boxBackgroundLife,
          )
        : classes.wrapper,
    [isHalifaxBrand, isBodyCard, isSWBrand],
  )
  const colorClass = isHalifaxBrand ? 'inherit' : 'initial'

  return (
    <Box className={boxClass} data-testid={`${props.coverSummary.coverType}-header`}>
      <Box className={classes.title}>
        <RenderCoverIcon {...props} />
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Typography
            className={isHalifaxBrand ? classes.emphasised : ''}
            variant={titleVariant}
            component="h2"
            color={colorClass}
          >
            {props.coverSummary?.title}
          </Typography>
          {isBodyCard && (
            <Typography
              className={classes.coverDescription}
              variant={isSWBrand ? 'subtitle2' : 'body2'}
              color={colorClass}
            >
              (Critical Illness Cover)
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

interface IndicativeQuoteCardIconProps {
  iconName: string
  iconColor: string
}

const LifeCoverIcon = (props: IndicativeQuoteCardIconProps) => {
  const classes = withStyles()
  return (
    <Box className={classes.coverIcon}>
      <Icon
        size="27px"
        name={props.iconName as IconProps['name']}
        color={props.iconColor as IconProps['color']}
        data-testid="life-cover-icon"
      />
    </Box>
  )
}
const BodyCoverIcon = (props: IndicativeQuoteCardIconProps) => {
  const classes = withStyles()
  return (
    <Box className={classes.coverIcon}>
      <Icon
        size="27px"
        name={props.iconName as IconProps['name']}
        color={props.iconColor as IconProps['color']}
        data-testid="body-cover-icon"
      />
    </Box>
  )
}
const RenderCoverIcon = (props: IndicativeQuoteCardHeaderProps) => {
  const content = useContent<IndicativeQuotePageContent>()
  const theme = useTheme<MonocerosTheme>()
  const bodyCoverIcon = content('bodyCoverHeadingIcon', 'heart-outline')
  const lifeCoverIcon = content('lifeCoverHeadingIcon', 'umbrella-outline')
  const iconColor = isHalifax(theme.name as Brand) ? 'inherit' : 'primary'
  if (props.coverSummary.title === 'Life Cover') return <LifeCoverIcon iconName={lifeCoverIcon} iconColor={iconColor} />
  return <BodyCoverIcon iconName={bodyCoverIcon} iconColor={iconColor} />
}
