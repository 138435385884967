import { CreatePolicyApplicationRequestBody } from '../types'

export const getPrintEnum = (
  specialRequirement: string | null | undefined,
): CreatePolicyApplicationRequestBody.PrintRequirementsEnum | null => {
  const PrintEnum = CreatePolicyApplicationRequestBody.PrintRequirementsEnum

  if (specialRequirement === 'Large print') return PrintEnum.LARGEPRINT
  if (specialRequirement === 'Braille') return PrintEnum.BRAILLE
  if (specialRequirement === 'Audio CD') return PrintEnum.AUDIOCD
  if (specialRequirement === 'Coloured paper') return PrintEnum.COLOUREDPAPER

  return null
}
