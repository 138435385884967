import React, { useEffect, useState } from 'react'
import {
  ButtonV2 as Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
  Brand,
  useTheme,
  MonocerosTheme,
} from '@lbg-protection/lib-ui-monoceros'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { saveCavendishOnlineConsent, selectCavendishOnlineConsent } from '../../SaveAndRetrieve/saveAndRetrieve.slice'
import { IndicativeQuotePageContent } from '../../IndicativeQuotePage/IndicativeQuotePageContent'
import { useContent } from '../../Content/useContent'
import { tagToolTip, tagButtonClick } from '../../../utils/tagUtils/tagsUtils'
import { useJourneyChecker } from '../../../journeyCwaConfig'
import { isLloyds } from '../../../utils/brandSpecificFormatting'

interface CavendishOnlineConsentDialogProps {
  dwellTime?: number
}

const useStyles = makeStyles((theme) => {
  return {
    typography: {
      paddingBottom: theme.spacing(3),
    },
    dialogActions: {
      display: 'flex',
      flexDirection: 'column',
      borderTop: `1px solid #d7d7d7`,
      '& > button:not(:first-child)': {
        marginTop: theme.spacing(2),
        marginLeft: 0,
      },
    },
  }
})

export const CavendishOnlineConsentDialog: React.FC<CavendishOnlineConsentDialogProps> = (props) => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const content = useContent<IndicativeQuotePageContent>()
  const overlayText = content('cavendishOverlayText', '')
  const cavendishOnlineConsent = useAppSelector(selectCavendishOnlineConsent)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { isJourneyUnAuth } = useJourneyChecker()
  const theme = useTheme<MonocerosTheme>()
  const isLloydsBrand = isLloyds(theme.name as Brand)
  useEffect(() => {
    if (cavendishOnlineConsent === undefined) {
      if (props.dwellTime) {
        setTimeout(() => {
          setIsDialogOpen(true)
          tagToolTip('Cavendish consent viewed')
        }, props.dwellTime)
      } else {
        setIsDialogOpen(true)
        tagToolTip('Cavendish consent viewed')
      }
    }
  }, [])

  const handleClick = (consent: boolean) => {
    dispatch(saveCavendishOnlineConsent(consent))
    setIsDialogOpen(false)
    tagButtonClick(`Cavendish consent - ${consent ? 'yes' : 'no'}`)
  }

  return (
    <Dialog open={isDialogOpen} fullScreen={false} fullWidth data-testid="cavendish-online-consent-dialog">
      <DialogTitle data-testid="cavendish-online-consent-dialog-title">
        {isLloydsBrand && !isJourneyUnAuth ? 'Need help? Speak to an expert' : 'Need help finding a policy?'}
      </DialogTitle>
      <DialogContent data-testid="cavendish-online-consent-dialog-content">
        <Typography className={classes.typography}>{overlayText}</Typography>
        <Typography variant="h6">
          Are you happy for us to share your details with them so they can contact you? Your details won&apos;t be
          shared with anyone else.
        </Typography>
      </DialogContent>
      <DialogActions data-testid="cavendish-online-consent-dialog-actions" className={classes.dialogActions}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          data-testid="cavendish-online-consent-yes-button"
          onClick={() => handleClick(true)}
        >
          Yes
        </Button>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          data-testid="cavendish-online-consent-no-button"
          onClick={() => handleClick(false)}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}
