import { PricingCallStatus } from '../../types'

const pricingCallWasSuccessful = (pricingCallStatus: PricingCallStatus): boolean => {
  return pricingCallStatus === PricingCallStatus.Success
}

const premiumLeadPricingCallIsPending = (pricingCallStatus: PricingCallStatus, coverCost: number): boolean => {
  if (pricingCallStatus === PricingCallStatus.Pending && coverCost) return true
  return false
}

export const shouldShowMonthlyCost = (pricingCallStatus: PricingCallStatus, coverCost: number): boolean => {
  return pricingCallWasSuccessful(pricingCallStatus) || premiumLeadPricingCallIsPending(pricingCallStatus, coverCost)
}
