import React, { useState } from 'react'
import {
  ButtonV2 as Button,
  PromoBox,
  Typography,
  makeStyles,
  MonocerosTheme,
  Card,
  IconProps,
  Brand,
  useTheme,
  Box,
} from '@lbg-protection/lib-ui-monoceros'
import clsx from 'clsx'
import { VoucherImage, VoucherImageProps } from './VoucherImage'
import { VoucherTermsAndConditionsOverlay } from './VoucherTermsAndConditionsOverlay'
import { isLloyds, isBOS, isSW } from '../../../utils/brandSpecificFormatting'
import { VoucherDescription } from './types'
import { tagButtonClick } from '../../../utils/tagUtils/tagsUtils'

const styles = makeStyles((theme: MonocerosTheme) => {
  const isOrionTheme = isSW(theme.name as Brand)
  const orionDarkRed = theme.palette.namedColours?.orionDarkBackgroundRed
  const { white } = theme.palette.common
  return {
    findOutButton: {
      width: '100%',
      marginTop: theme.spacing(4),
      color: theme.palette.primary.main,
      '&:hover:not(:disabled)': {
        backgroundColor: white,
        textDecoration: 'underline',
        ...(isOrionTheme && { color: orionDarkRed }),
      },
      '&:focus': {
        backgroundColor: isOrionTheme ? orionDarkRed : '#FFC764',
        textDecoration: 'underline',
        border: `1px solid ${white}`,
        outline: `2px solid ${isOrionTheme ? orionDarkRed : '#05286A'}`,
        color: isOrionTheme ? white : '#05286A',
      },
    },
    cardWithDefaultBackgroundAndSpacing: {
      padding: `${theme.spacing(isOrionTheme ? 6 : 4)} ${theme.spacing(isOrionTheme ? 2 : 3)} 0px ${theme.spacing(
        isOrionTheme ? 2 : 3,
      )}`,
      backgroundColor: 'transparent',
      marginBottom: isOrionTheme ? 0 : theme.spacing(4),
    },
    cardBorder: {
      borderColor: '#BFBFBF',
      borderWidth: 1,
      borderStyle: 'solid',
      position: 'relative',
    },
    titleContainer: {
      display: 'block',
    },
    cardExtraPadding: {
      padding: `0px ${theme.spacing(3)} ${theme.spacing(isOrionTheme ? 5 : 4)} ${theme.spacing(3)}`,
      backgroundColor: 'transparent',
    },
    boxPadding: {
      padding: `0 ${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    withinAlert: {
      padding: 0,
    },
    cardWithNoBackGroundAndSpacing: {
      backgroundColor: 'transparent',
      padding: `${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(3)}`,
    },
    incentive: {
      paddingTop: theme.spacing(3),
    },
    cardText: {
      fontSize: theme.spacing(3),
      lineHeight: theme.spacing(4),
    },
  }
})

export interface VoucherIncentiveProps {
  withBorder?: boolean
  withPadding?: boolean
  policyDurationBullet: string
  voucherImageProps: VoucherImageProps
  giftCardValue: string
  voucherDescription: VoucherDescription
  withinAlert?: boolean
}

export const VoucherIncentiveCard = ({
  policyDurationBullet,
  voucherImageProps,
  withBorder,
  withPadding,
  giftCardValue,
  voucherDescription,
  withinAlert,
}: VoucherIncentiveProps) => {
  const classes = styles({})
  const theme = useTheme<MonocerosTheme>()
  const isLloydsBrand = isLloyds(theme.name as Brand)
  const isBOSBrand = isBOS(theme.name as Brand)
  const isSWBrand = isSW(theme.name as Brand)
  const promoBoxVariant = isLloydsBrand || isBOSBrand ? 'compact' : 'large'
  const voucherIcon = isLloydsBrand || isBOSBrand ? 'present' : 'Present-illustration'
  const [showTermsAndCondtionsOverlay, setShowTermsAndCondtionsOverlay] = useState(false)

  const boxPositioning = !withinAlert && withBorder ? classes.boxPadding : ''

  let cardPositioning = withBorder ? classes.cardBorder : ''

  const cardWithBackgroundAndSpacing = isBOS(theme.name as Brand)
    ? classes.cardWithNoBackGroundAndSpacing
    : classes.cardWithDefaultBackgroundAndSpacing

  const promoBoxBorder = withinAlert && isLloydsBrand ? classes.cardBorder : ''

  if (withinAlert) {
    cardPositioning = classes.withinAlert
  } else {
    cardPositioning = `${cardPositioning} ${withPadding ? classes.cardExtraPadding : cardWithBackgroundAndSpacing}`
  }

  const titleContent = <div className={classes.titleContainer}>{voucherDescription.title}</div>

  return (
    <Box className={boxPositioning}>
      <Card className={cardPositioning} data-testid="voucher-card">
        <PromoBox
          variant={promoBoxVariant}
          iconName={voucherIcon as IconProps['name']}
          title={titleContent}
          titleComponent="h2"
          className={promoBoxBorder}
        >
          <Typography variant="body1" className={clsx(isSWBrand && classes.cardText)}>
            To receive your gift card, you must make
            <Typography display="inline" variant="body2">
              {' '}
              at least 6 monthly payments
            </Typography>{' '}
            and
            <Typography display="inline" variant="body2">
              {' '}
              pay at least £100
            </Typography>{' '}
            in total.
          </Typography>

          <Typography variant="body1" className={clsx(isSWBrand && classes.cardText, classes.incentive)}>
            {voucherDescription.bodyText1}
          </Typography>

          <Typography variant="body1" className={clsx(isSWBrand && classes.cardText, classes.incentive)}>
            Terms and conditions apply.
          </Typography>
          <VoucherImage {...voucherImageProps} />
          <Button
            className={classes.findOutButton}
            variant="outlined"
            data-testid="view-terms-and-conditions-voucher"
            onClick={() => {
              tagButtonClick('View terms and conditions')
              setShowTermsAndCondtionsOverlay(true)
            }}
          >
            View terms and conditions
          </Button>
          {showTermsAndCondtionsOverlay && (
            <VoucherTermsAndConditionsOverlay
              open={showTermsAndCondtionsOverlay}
              toggleOpen={() => setShowTermsAndCondtionsOverlay(!showTermsAndCondtionsOverlay)}
              policyDurationBullet={policyDurationBullet}
              giftCardValue={giftCardValue}
            />
          )}
        </PromoBox>
      </Card>
    </Box>
  )
}
