import React from 'react'
import {
  Box,
  ListItemWithBullet,
  ListWithBullets,
  MonocerosTheme,
  Typography,
  makeStyles,
} from '@lbg-protection/lib-ui-monoceros'
import { DialogControl } from '../../Shared/DialogControl/DialogControl'
import { tagExternalLink } from '../../../utils/tagUtils/tagsUtils'

const withStyles = makeStyles((theme: MonocerosTheme) => {
  return {
    link: {
      marginTop: theme.spacing(2),
    },
  }
})

export const WhyPriceMightBeDifferentDialog = ({ coverType }: { coverType: 'life' | 'body' }) => {
  const classes = withStyles()
  const dataTestId = `why-price-might-be-different-${coverType}`
  const overlaySummary = 'Why this price might be different to your quick quote'

  return (
    <Box className={classes.link}>
      <DialogControl
        controlText={overlaySummary}
        id={dataTestId}
        title={overlaySummary}
        transition="fade"
        data-testid={dataTestId}
        customOnClick={() => tagExternalLink(`${overlaySummary} - ${coverType}`)}
      >
        <Typography gutterBottom>
          Now that we understand a bit more about your health and lifestyle, we&apos;re able to give you a more accurate
          quote. There are many factors that can affect the price, like:
        </Typography>
        <ListWithBullets>
          <ListItemWithBullet>Your health, lifestyle or job</ListItemWithBullet>
          <ListItemWithBullet>If you smoke or vape</ListItemWithBullet>
          <ListItemWithBullet>If your family has a history of certain illnesses.</ListItemWithBullet>
        </ListWithBullets>
      </DialogControl>
    </Box>
  )
}
