import { Brand } from '@lbg-protection/lib-ui-monoceros'
import { isBOS, isHalifax, isLloyds } from '../../../utils/brandSpecificFormatting'

export const exitSurveyUrls = (brand: Brand, partyId: string): string => {
  const baseExitSurveryUrl = `https://lloydsbankinggroup.eu.qualtrics.com/jfe/form/SV_0feqe2pHz3pT4Tc?brand=`
  const partyIdParams = `&PARTYID=${partyId}`
  if (isHalifax(brand)) return `${baseExitSurveryUrl}HAL${partyIdParams}`

  if (isLloyds(brand)) return `${baseExitSurveryUrl}NLB${partyIdParams}`

  if (isBOS(brand)) return `${baseExitSurveryUrl}BOS${partyIdParams}`

  return ''
}
