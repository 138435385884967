import { additionalSupport } from './additionalSupport/additionalSupport'
import { homeownerStatus } from './homeownerStatus/homeownerStatus'
import { income } from './income/income'
import { leaveSomethingExtra } from './leaveSomethingExtra/leaveSomethingExtra'
import { mortgage } from './homePayment/mortgage'
import { protectWho } from './protectWho/protectWho'
import { protectWhat } from './protectWhat/protectWhat'
import { rent } from './homePayment/rent'
import { savings } from './savings/savings'
import { debts } from './debts/debts'
import { incomeYears } from './incomeYears/incomeYears'
import { childrenAge } from './childrenAge/childrenAge'
import { term } from './term/term'
import { CoverSelectedEnum } from '../../../journeys/purchase'
import { Question, Answer } from '../../Shared/Questionaire/types'

export const getQuestions = (
  answers: Record<string, Answer>,
  selectedProducts: CoverSelectedEnum,
  dateOfBirth: Date,
): Question[] => {
  return [
    protectWho(),
    protectWhat(answers),
    childrenAge(),
    homeownerStatus(),
    rent(),
    mortgage(),
    income(),
    incomeYears(answers),
    additionalSupport(),
    leaveSomethingExtra(),
    debts(),
    savings(),
    leaveSomethingExtra(),
    term(answers, dateOfBirth, selectedProducts),
  ]
}
