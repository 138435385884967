import { UpdateActionProps } from '../../QuotePage/types'
import { PolicyApplicationPricingRequestDetails } from '../types'

export const buildPolicy = (policy: PolicyApplicationPricingRequestDetails): UpdateActionProps => {
  return {
    coverType: policy.coverType,
    amount: Number(policy.sumAssured),
    term: Number(policy.coverTerm),
    monthlyCost: Number(policy.monthlyPremium),
    title: '',
  }
}
