import { JourneyNames } from '../config'
import { BOS_DEFAULT_SORT_CODE, HALIFAX_DEFAULT_SORT_CODE, LLOYDS_DEFAULT_SORT_CODE } from './constants'

export type DefaultOutletDetails = {
  outletId: number
  outletIdType: 'SORT_CODE' | 'OU_ID'
  brand: 'HFX' | 'BOS' | 'LYDS'
}

export const getDefaultOutletHeadersByBrand = (brand: JourneyNames): DefaultOutletDetails | undefined => {
  switch (brand) {
    case 'halifax':
      return { outletId: HALIFAX_DEFAULT_SORT_CODE, outletIdType: 'SORT_CODE', brand: 'HFX' }
    case 'lloyds':
      return { outletId: LLOYDS_DEFAULT_SORT_CODE, outletIdType: 'SORT_CODE', brand: 'LYDS' }
    case 'bos':
      return { outletId: BOS_DEFAULT_SORT_CODE, outletIdType: 'SORT_CODE', brand: 'BOS' }
    default:
      return undefined
  }
}
