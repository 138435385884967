import CacheUtil from './cacheUtil'

const prepareCacheKeyFromArgs = (args: any) => {
  let cacheKey = ''
  if (typeof args === 'object') {
    if (args.length > 0) {
      /* eslint-disable no-shadow */
      Object.entries(args).forEach(([key, value]) => {
        if (key !== undefined) {
          Object.entries(value as any).forEach(([key, value]) => {
            if (key !== undefined) cacheKey = `${cacheKey}_${value}`
          })
        }
      })
    } else
      Object.entries(args).forEach(([key, value]) => {
        if (key !== undefined) cacheKey = `${cacheKey}_${value}`
      })
  } else return args
  return cacheKey.substr(1)
}

export const memoize = (callback: any) => {
  const cacheUtil = CacheUtil.getInstance()
  return (...args: any) => {
    const key = prepareCacheKeyFromArgs(args)
    if (cacheUtil.hasKey(key)) {
      return cacheUtil.getCache(key)
    }

    const value = callback(...args)
    cacheUtil.setCache(key, value)
    return value
  }
}
