import { HomePaymentQ } from '../../../Shared/Questionaire/questions/HomePaymentQuestion/types'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'

export const rent = (): HomePaymentQ => {
  return {
    id: 'rent',
    category: 'homePayment',
    type: QuestionTypes.HomePayment,
    question: 'Rent details',
    sectionHeader: 'Your home',
    title: {
      text: 'Your home',
      icon: 'home',
    },
    amount: {
      id: 'amount',
      label: 'How much rent do you pay each month?',
      fieldConfig: {
        required: true,
        requiredErrorMessage: 'Please enter the amount you pay in rent each month',
        min: 0,
        max: 9999999.99,
        maxErrorMessage: 'Please enter a value of less than £10 million',
        decimals: 2,
      },
    },
    term: {
      id: 'term',
      label: 'How long would you want to cover your rent after you die?',
      fieldConfig: {
        required: true,
        requiredErrorMessage: "Please enter the number of years you'd like your rent to be covered",
        min: 0,
        max: 100,
        maxErrorMessage: 'Please enter a number up to 100 years',
        decimals: 0,
      },
    },
    helpText: 'If you were to die while covered, this policy could help secure your home for your loved ones.',
    JourneyStepName: 'Home payment - renting',
    JourneyStep: '12',
  }
}
