import { CoverSummaryInterface } from '../../Shared/Basket/CoverSummary/types'
import { PolicyApplicationPricingRequestDetails, PricingRequestBody } from '../types'

export const getPolicies = (
  lifeCover: CoverSummaryInterface | undefined,
  bodyCover: CoverSummaryInterface | undefined,
): Array<PolicyApplicationPricingRequestDetails> => {
  const policies: Array<PolicyApplicationPricingRequestDetails> = []
  const buildPolicy = (policy: CoverSummaryInterface): PolicyApplicationPricingRequestDetails => {
    return {
      coverType:
        policy.coverType === 'LIFE' ? PricingRequestBody.CoverTypeEnum.LIFE : PricingRequestBody.CoverTypeEnum.BODY,
      calculationType: PricingRequestBody.CalculationTypeEnum.SUMASSURED,
      monthlyPremium: policy.monthlyCost,
      sumAssured: policy.amount,
      coverTerm: policy.term.toString(),
    }
  }
  if (lifeCover?.inBasket) {
    policies.push(buildPolicy(lifeCover))
  }
  if (bodyCover?.inBasket) {
    policies.push(buildPolicy(bodyCover))
  }
  return policies
}
