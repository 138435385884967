import { createAction, createSelector, createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { Answer } from '../../Shared/Questionaire/types'

export interface OptionInterface {
  id: string
  option: string
  outcome: string
  exclusive?: boolean
}

export interface MultipleChoiceAnswer {
  questionId: string
  question: string
  category: string
  values: OptionInterface[]
}

const initialState: MultipleChoiceAnswer = {
  questionId: '1',
  question: 'Do you have any special print requirements?',
  category: 'special-requirement',
  values: [],
}

export type SelectSpecialRequirement = (state: MultipleChoiceAnswer | null) => string | null
export type SelectSpecialRequirementAnswer = (state: MultipleChoiceAnswer | null) => MultipleChoiceAnswer | null

export const setSpecialRequirement = createAction<Answer | null>('setSpecialRequirement')

export const selectSpecialRequirement: SelectSpecialRequirement = createSelector(
  (state) => (state as MultipleChoiceAnswer).values,
  (values) => (values?.length > 0 ? values[0].outcome : null),
)

export const selectSpecialRequirementAnswer: SelectSpecialRequirementAnswer = createSelector(
  (state) => state,
  (state) => state,
)

type Reducers = {
  setSpecialRequirement: Reducer<MultipleChoiceAnswer, PayloadAction<Answer>>
}

const reducers: Reducers = {
  setSpecialRequirement: (_state, action) => {
    return action.payload as MultipleChoiceAnswer
  },
}

export const specialRequirementsSlice = createSlice({
  name: 'specialRequirements',
  initialState,
  reducers,
})
