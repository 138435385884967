import React from 'react'
import { Typography } from '@lbg-protection/lib-ui-monoceros'
import { QuestionTypes } from '../../../Shared/Questionaire/questions/types'
import { MultipleChoiceQ } from '../../../Shared/Questionaire/questions/MultipleChoiceQuestion/types'

export const homeownerStatus = (): MultipleChoiceQ => {
  return {
    id: 'homeownerStatus',
    type: QuestionTypes.MultipleChoice,
    category: 'home',
    title: {
      text: 'Your home',
      icon: 'home',
    },
    description: (
      <Typography gutterBottom>
        If you were to die while covered, this policy could help secure your home for your loved ones.
      </Typography>
    ),
    question: `Pick the option that's right for you:`,
    options: [
      {
        id: 'mortgage',
        option: 'I pay a mortgage',
        exclusive: true,
      },
      {
        id: 'rent',
        option: 'I pay rent',
        exclusive: true,
      },
      {
        id: 'own',
        option: 'I own my home with no mortgage',
        exclusive: true,
      },
      {
        id: 'nota',
        option: 'None of the above',
        exclusive: true,
      },
    ],
    spaced: true,
    centreOptions: true,
    errorMessage: 'Please select an option',
    JourneyStepName: 'Homeowner status',
    JourneyStep: '10',
    QAQuestion: 'Homeowner status',
  }
}
