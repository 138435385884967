import React from 'react'
import { PhoneLink, useTheme, MonocerosTheme, Brand } from '@lbg-protection/lib-ui-monoceros'
import { Departments, getBrandDepartmentNumber } from './phoneNumbers'
import { addSpaces } from './utils'
import { selectIsUnauth } from '../../../app/config.slice'
import { useAppSelector } from '../../../app/hooks'

export interface PhoneNumberLinkProps {
  department: Departments
  number?: string
  displayTel?: string
  'data-testid'?: string
  onClick?: () => void
  gutterBottom?: boolean
}

export const PhoneNumberLink = (props: PhoneNumberLinkProps) => {
  const { name: brand } = useTheme<MonocerosTheme>() as { name: Brand }
  const isUnauth = useAppSelector(selectIsUnauth)
  const phoneNumber =
    props.number !== undefined && props.number !== ''
      ? props.number
      : getBrandDepartmentNumber(brand, props.department, isUnauth)

  return (
    <PhoneLink
      tel={phoneNumber}
      displayTel={props.displayTel ? props.displayTel : addSpaces(phoneNumber)}
      data-testid={props['data-testid']}
      onClick={props.onClick}
      gutterBottom={props.gutterBottom}
    />
  )
}
