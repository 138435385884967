import { createSlice, PayloadAction, Selector } from '@reduxjs/toolkit'
import { RootState } from './types'

export interface ConfigState {
  isUnAuth: boolean | undefined
}

const initialState: ConfigState = {
  isUnAuth: undefined,
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setIsUnAuth(state, action: PayloadAction<boolean>) {
      return { ...state, isUnAuth: action.payload }
    },
  },
})

export const selectIsUnauth: Selector<RootState, ConfigState['isUnAuth']> = (state) => state.config.isUnAuth

export const { setIsUnAuth } = configSlice.actions
export default configSlice.reducer
