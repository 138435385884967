import { RealNgaBridge } from '@lbg-cwa/jsbridge/dist/NgaBridgeWrapper'

declare global {
  interface Window {
    JSBridge: RealNgaBridge
  }
}

const isDevelopmentBuild = process.env.NODE_ENV !== 'production'

export const getJSBridge = (): Promise<RealNgaBridge> =>
  new Promise<RealNgaBridge>((resolve, reject) => {
    // inject a mockjs
    if (isDevelopmentBuild && !window.JSBridge) {
      import(/* webpackChunkName: "development" */ './MockJSBridge')
        .then((module) => {
          console.info(module)
          window.JSBridge = module.default as RealNgaBridge
          resolve(module.default as RealNgaBridge)
        })
        .catch(() => reject())
    } else {
      resolve(window.JSBridge)
    }
  })
