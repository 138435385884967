import { ChildrenAgeAnswer } from '../../../../Shared/Questionaire/questions/ChildrenAgeQuestion/types'
import { HomePaymentAnswer } from '../../../../Shared/Questionaire/questions/HomePaymentQuestion/types'
import { Milestone } from '../../../../Shared/Questionaire/questions/TermQuestion/types'

export const getTermRange = (minTerm: number, maxTerm: number): number[] =>
  Array.from(Array(maxTerm - minTerm + 1), (_, i) => i + minTerm)

export const getMilestones = (
  children: ChildrenAgeAnswer | undefined,
  mortgage: HomePaymentAnswer | undefined,
  customerAge: number,
  termRange: number[],
): Milestone[] => {
  const childrenMilestone = children ? 18 - Math.min(...children.values.map((value) => Number(value.age))) : undefined
  const mortgageMilestone = mortgage ? Number(mortgage.values.term) : undefined
  const ageMilestone = 69 - customerAge

  return [
    ...(childrenMilestone && childrenMilestone > 0
      ? [
          {
            id: 'children',
            copy: 'until your children are 18',
            years: termRange.includes(childrenMilestone) ? `${childrenMilestone} years` : '',
          },
        ]
      : []),
    ...(mortgageMilestone
      ? [
          {
            id: 'mortgage',
            copy: 'until your mortgage is paid off',
            years: termRange.includes(mortgageMilestone) ? `${mortgageMilestone} years` : '',
          },
        ]
      : []),
    {
      id: 'max',
      copy: `until you're 69, the longest you can have this cover`,
      years: `${ageMilestone} years`,
    },
  ]
}
