import { PrePurchaseJourneyRouteEnum } from '../../../../journeys/purchase'
import { QuoteCardProps } from '../types'
import { tagPricingEvent } from '../../../../utils/tagUtils/tagsUtils'

export const tagPricing = (props: QuoteCardProps): void => {
  const { numberOfPricingCalls = 0, userRoute } = props
  if (numberOfPricingCalls === 1) {
    if (userRoute === PrePurchaseJourneyRouteEnum.PathFinder) {
      tagPricingEvent(
        props.coverSummary.coverType,
        props.coverSummary.amount.toString(),
        props.coverSummary.monthlyCost.toString(),
        props.coverSummary.term.toString(),
        '',
        `Initial - Pathfinder - ${props.coverSummary.calculationType}`,
      )
    } else
      tagPricingEvent(
        props.coverSummary.coverType,
        props.coverSummary.amount.toString(),
        props.coverSummary.monthlyCost.toString(),
        props.coverSummary.term.toString(),
        '',
        `Initial - Non Pathfinder - ${props.coverSummary.calculationType}`,
      )
  }
  if (numberOfPricingCalls > 1) {
    tagPricingEvent(
      props.coverSummary.coverType,
      props.coverSummary.amount.toString(),
      props.coverSummary.monthlyCost.toString(),
      props.coverSummary.term.toString(),
      '',
      `${props.coverSummary.calculationType}`,
    )
  }
}
