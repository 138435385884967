import { makeStyles, MonocerosTheme, Brand } from '@lbg-protection/lib-ui-monoceros'
import { isSW } from '../../../utils/brandSpecificFormatting'

const getBackgroundColour = (theme: MonocerosTheme): string | undefined => {
  switch (theme.name) {
    case Brand.LLOYDS:
      return theme.palette.namedColours?.lloydsTeal
    case Brand.BOS:
      return theme.palette.namedColours?.bosMidBlue
    case Brand.ORION2024:
      return theme.palette.namedColours?.orionInfo
    default:
      return theme.palette.primary.main
  }
}

export const useStyles = makeStyles((theme: MonocerosTheme) => ({
  basketCard: {
    backgroundColor: getBackgroundColour(theme),
    color: 'white',
    padding: theme.spacing(3),
    width: '100%',
  },
  reviewSummaryCard: {
    paddingRight: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.25),
    paddingBottom: theme.spacing(2),
  },
  basketHeading: {
    display: 'none',
  },
  basketHeadingDivider: {
    display: 'none',
  },
  reviewSummaryHeadingDivider: {
    marginTop: theme.spacing(0.4),
    marginBottom: theme.spacing(2.66),
  },
  basketDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  reviewSummaryDivider: {
    marginTop: theme.spacing(2.33),
    marginBottom: theme.spacing(2),
  },
  totalAmount: {
    fontSize: '1.5rem',
  },
  emptyBasket: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    paddingTop: '1.5rem',
    paddingRight: '1.5rem',
    paddingLeft: '1.5rem',
    paddingBottom: '1.5rem',
  },
  emptyBasketDivider: {
    borderTop: '1px solid white',
    marginTop: '1rem',
    paddingTop: '2rem',
    paddingRight: '3rem',
    paddingLeft: '3rem',
  },
  emptyBasketIcon: {
    display: 'block',
    margin: '0 auto',
    paddingBottom: '1rem',
  },
  emptyBasketTypography: {
    paddingBottom: '1rem',
  },
  pencilEdit: {
    textDecoration: 'underline',
  },
  policiesLabel: {
    fontSize: '1.125rem',
    display: 'inline-block',
    margin: '10px 0 0 0',
    letterSpacing: '0',
  },
  buttonText: {
    padding: '6px 8px',
    ...(isSW(theme.name as Brand) && {
      '& .MuiButton-startIcon': {
        marginRight: '5px',
      },
    }),
  },
  summaryRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  summaryCol: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    justifyContent: 'space-between',
  },
  alightRight: {
    alignItems: 'flex-end',
  },
}))
