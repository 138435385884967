import React, { FC, useState } from 'react'
import { JourneyNavContext } from './journeyNavContext'
import { JourneyNavConfig } from './journeyNavConfig'

interface JourneyNavConfigProviderProps {
  journeyNavConfig: JourneyNavConfig[]
}

export const JourneyNavConfigProvider: FC<JourneyNavConfigProviderProps> = (props) => {
  const [journeyNavConfig, setJourneyNavConfig] = useState<JourneyNavConfig[]>(props.journeyNavConfig)

  return (
    <JourneyNavContext.Provider
      value={{
        journeyNavConfig,
        setJourneyNavConfig,
      }}
    >
      {props.children}
    </JourneyNavContext.Provider>
  )
}
