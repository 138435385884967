import dayjs from 'dayjs'
import { UnderwritingEnquiryDecision } from '@lbg-protection/api-client-nmp-journey'
import { CoverSelectedEnum } from '../../../journeys/purchase/types'
import { isEligibleOverall } from '../../UnderwriteMe/utils/isEligible'

const MAX_DAYS_ALLOWED_FROM_QUOTE_DATE = 30
const isSavedAddressValid = (savedPostcode: string | undefined, ocisPostcode: string | undefined) =>
  !!savedPostcode && savedPostcode === ocisPostcode

const isSavedEnquiryValid = (enquiryDecisions: UnderwritingEnquiryDecision[] = []) => {
  if (!isEligibleOverall(enquiryDecisions, CoverSelectedEnum.None)) {
    return false
  }
  return true
}

const isQuoteDateWithInMaxDaysAllowed = (quoteDate?: string) => {
  if (quoteDate) {
    const currentDate = dayjs()
    const daysSinceQuote = currentDate.diff(quoteDate, 'days')

    if (daysSinceQuote > MAX_DAYS_ALLOWED_FROM_QUOTE_DATE) {
      return false
    }
  }
  return true
}

export const isSavedJourneyValid = (
  savedPostcode: string | undefined,
  ocisPostcode: string | undefined,
  enquiryDecisions: UnderwritingEnquiryDecision[] | undefined,
  quoteDate: string | undefined,
  shouldValidateAddress?: boolean,
) =>
  shouldValidateAddress
    ? isSavedAddressValid(savedPostcode, ocisPostcode) &&
      isSavedEnquiryValid(enquiryDecisions) &&
      isQuoteDateWithInMaxDaysAllowed(quoteDate)
    : isSavedEnquiryValid(enquiryDecisions) && isQuoteDateWithInMaxDaysAllowed(quoteDate)
