import { useCallback, useEffect, useRef } from 'react'

const makeRemoveEventListener = <T,>(
  eventName: 'wheel' | 'touchstart' | 'touchmove' | 'touchend',
  el: HTMLElement,
  handler: (event: T) => void,
) => {
  return () => {
    if (el) {
      el.removeEventListener(eventName, handler as () => void)
    }
  }
}

export const usePreventUserZoom = (elRef?: React.RefObject<HTMLElement | null | undefined>) => {
  const el = elRef?.current

  const wheelHandler = useCallback((event: WheelEvent) => {
    if (event.ctrlKey) {
      event.preventDefault()
      event.stopPropagation()
    }
  }, [])

  const gestureScaling = useRef(false)

  const onTouchStart = useCallback((event: TouchEvent) => {
    if (event.touches.length === 2) {
      event.stopPropagation()
      event.preventDefault()
      gestureScaling.current = true
    }
  }, [])

  const onTouchMove = useCallback((event: TouchEvent) => {
    if (gestureScaling.current) {
      event.stopPropagation()
      event.preventDefault()
    }
  }, [])

  const onTouchEnd = useCallback((event: TouchEvent) => {
    if (gestureScaling.current) {
      event.stopPropagation()
      event.preventDefault()
      gestureScaling.current = false
    }
  }, [])

  useEffect(() => {
    const removeListeners: Function[] = []

    if (el) {
      // wheel
      removeListeners.push(makeRemoveEventListener<WheelEvent>('wheel', el, wheelHandler))
      el.addEventListener('wheel', wheelHandler)

      // touchstart
      removeListeners.push(makeRemoveEventListener<TouchEvent>('touchstart', el, onTouchStart))
      el.addEventListener('touchstart', onTouchStart)

      // touchmove
      removeListeners.push(makeRemoveEventListener<TouchEvent>('touchmove', el, onTouchMove))
      el.addEventListener('touchmove', onTouchMove)

      // touchend
      removeListeners.push(makeRemoveEventListener<TouchEvent>('touchend', el, onTouchEnd))
      el.addEventListener('touchend', onTouchEnd)
    }

    return () => {
      removeListeners.forEach((fn) => fn())
    }
  }, [el])
}
