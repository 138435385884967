import {
  Typography,
  Icon,
  Box,
  Divider,
  Card,
  ListItemText,
  ListWithBullets,
  ListItemWithBullet,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { Milestone } from './types'
import { styles } from './styles'

interface MilestonesProps {
  milestones: Milestone[]
}

export const Milestones = (props: MilestonesProps) => {
  const classes = styles({})
  const getHyphenatedString = (string: string): string => {
    const spaces = /\s/g
    return string
      .replace(spaces, '-')
      .replace(':', '')
      .toLowerCase()
  }

  return (
    <Card className={classes.milestonesCard}>
      <Box className={classes.milestoneHeading}>
        <Icon name="bulb" size="1.5rem" color="primary" />
        <Typography className={classes.milestoneTitle} variant="h4" data-testid="term_page_milestone_heading">
          {props.milestones.length > 1 ? `Milestones to think about` : `A milestone to think about`}
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      <ListWithBullets>
        {props.milestones.map((milestone, index) => (
          <ListItemWithBullet key={`milestone-${index + 1}`} data-testid="term_page_milestone_list_item">
            <ListItemText className={classes.listText} data-testid="term-page-milestone-list-item">
              <span
                className={classes.yearsLabel}
                data-testid={`term-page-milestone-list-item-${getHyphenatedString(milestone.years)}`}
              >
                {`${milestone.years} `}
              </span>
              {milestone.copy}
            </ListItemText>
          </ListItemWithBullet>
        ))}
      </ListWithBullets>
    </Card>
  )
}
