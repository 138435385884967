import {
  Box,
  CurrencyInput,
  FormField,
  ListItemWithBullet,
  ListWithBullets,
  Typography,
} from '@lbg-protection/lib-ui-monoceros'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { DialogControl } from '../../../DialogControl/DialogControl'
import { IconTitle } from '../../../IconTitle'
import { SubmitAnswer } from '../../types'
import { styles } from './styles'
import { FinancesAnswer, FinancesQ } from './types'

interface FinancesQuestionProps {
  question: FinancesQ
  answer: FinancesAnswer | null
  submitAnswer: SubmitAnswer
  goToNextQuestion: () => void
}

export const Question = (props: FinancesQuestionProps) => {
  const classes = styles()
  const { errors, handleSubmit, control, watch } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
  })

  const inputValue = watch(props.question.id, props.answer?.value.toString() || '0')

  useEffect(() => {
    if (Number(inputValue) !== props.answer?.value) {
      props.submitAnswer({
        questionId: props.question.id,
        question: props.question.question,
        category: props.question.category,
        value: Number(inputValue),
      })
    }
  }, [inputValue])

  const onSubmit = () => {
    props.goToNextQuestion()
  }

  return (
    <>
      <IconTitle dataTestId={`${props.question.id}-title`} title={props.question.title} />
      {props.question.helpText?.map((paragraph: string, index: number) => (
        <Typography
          key={`${props.question.id}-help-text-p${index + 1}`}
          data-testid={`${props.question.id}-help-text-p${index + 1}`}
          className={classes.helpText}
          variant="body1"
        >
          {paragraph}
        </Typography>
      ))}
      {props.question.list && (
        <ListWithBullets>
          {props.question.list.map((item, index) => (
            <ListItemWithBullet key={`extra-support-example-${index + 1}`}>{item}</ListItemWithBullet>
          ))}
        </ListWithBullets>
      )}
      <form
        data-testid={`${props.question.id}-question-form`}
        id={`${props.question.id}-question-form`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          id={props.question.id}
          name={props.question.id}
          key={props.question.id}
          defaultValue={props.answer?.value || 0}
          control={control}
          rules={{
            required: {
              value: props.question.fieldConfig.required,
              message: props.question.fieldConfig.requiredErrorMessage ?? '',
            },
            min: {
              value: props.question.fieldConfig.min,
              message: props.question.fieldConfig.minErrorMessage ?? '',
            },
            max: {
              value: props.question.fieldConfig.max,
              message: props.question.fieldConfig.maxErrorMessage ?? '',
            },
          }}
          render={({ onChange, onBlur, ref, value }) => (
            <FormField
              id={props.question.id}
              key={props.question.id}
              fullWidth
              value={value}
              label={props.question.question}
              errorMessage={errors?.[props.question.id]?.message}
              helperText={props.question.fieldHelperText}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
            >
              <CurrencyInput
                min={props.question.fieldConfig.min}
                max={props.question.fieldConfig.max}
                data-testid={`${props.question.id}-field`}
                decimalScale={props.question.fieldConfig.decimals}
                inputProps={{ 'aria-required': true }}
              />
            </FormField>
          )}
        />
      </form>
      {props.question.overlay && (
        <Box className={classes.overlayLink}>
          <DialogControl
            controlText={props.question.overlay.link}
            id={props.question.id}
            data-testid={props.question.id}
            title={props.question.overlay.link}
            transition="fade"
          >
            {props.question.overlay.content}
          </DialogControl>
        </Box>
      )}
    </>
  )
}
