export const eligibilityBulletList = [
  `This offer is limited to one ‘Onecode’ reward code per customer.`,
  `This offer is limited to new customers who have not received or are due to receive a voucher when purchasing a Scottish Widows Plan & Protect policy via Lloyds Bank, Halifax or Bank of Scotland within the last 12 months.`,
]

export const howToClaimBulletList = [
  `After you have made at least six successful monthly premium payments and the total premiums paid across all of your policies exceeds £100, an email or letter will be sent to you within 30 days containing a 'Onecode' reward code for the value of £100 and details on how to redeem it for a gift card using the online portal.`,
  `If you do not use the 'Onecode' reward code and select a gift card from the online portal within six months of the reward code being sent, you will no longer be able to claim the £100 gift card.`,
]

export const generalConditionsBulletList = [
  `The promoter of this offer is Scottish Widows, 25 Gresham Street, London, EC2V 7HN, part of Lloyds Banking Group. The administrator is Simply Thank You, Richmond Court, Morton Road, Darlington, DL1 4PT.`,
  `Your Onecode will be emailed/ supplied to you by ‘Simply Thank You’ who also host the online portal where you make your gift card selection.`,
  `‘Simple Thank You’ are separate from Scottish Widows and not part of Lloyds Banking Group.`,
  `We will need to share some of your details with Simply Thank You but this will be limited to details needed for them to supply the ‘Onecode’ reward code to you. Simply Thank You will not use or share your details with other parties for marketing purposes.`,
  `There is no cash alternative.`,
  `In the event of circumstances outside our control where a ‘Onecode’ reward code is not available, a similar alternative of equal value will be provided.`,
  `After you pay for your sixth month of cover, and once the total premiums paid across all your polices exceed £100 you'll receive your ‘Onecode’ reward code to redeem for £100 gift card. We will send the code within 30 days via email or letter. The code is then valid for 6 months.`,
  `We reserve the right to withdraw or amend this offer at any time.`,
  `Scottish Widows reserves the right to withhold this offer should we discover or have a reasonable suspicion that the terms and conditions of this offer are being abused.`,
]

export const termsandConditionsOrderList = [
  {
    style: 'subtitle2',
    text: 'Terms and Conditions',
    dataTestID: 'terms-and-conditions',
  },
  {
    style: 'textlist',
    text: [
      `This website is owned and operated by Simply Thank You Limited. If you have any queries about this site, please email us at clientservices@simplythankyou.co.uk.`,
    ],
  },
  {
    style: 'subtitle1',
    text: 'General',
    dataTestID: 'General',
  },
  {
    style: 'textlist',
    text: [
      `Simply Thank You is owned and operated by Simply Thank You Limited.`,
      `We reserve the right to supplement and amend these Terms and Conditions. We will post any changes on this website and it is your responsibility as a customer to review the Terms and Conditions on each occasion you access this website or our service. Changes will be effective 2 hours after the posting of any such change and all subsequent dealings between you and us shall be on the amended terms and conditions.`,
      `Additionally, we reserve the right to suspend, restrict or terminate access to this website and/or our Services for any reason at any time.`,
      `These Terms and Conditions shall be deemed to include all other notices, policies, disclaimers and other terms contained in this website, provided that in the event of a conflict between any such other notices, policies, disclaimers and other terms, these Terms and Conditions shall prevail. If any of these Terms and Conditions is held to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.`,
      `We shall ensure that we comply with the requirement of all current data protection legislation including, without limitation, the Data Protection Act 1998 (as replaced, modified or re-enacted from time to time). We shall only use personal data received from you for the purpose of fulfilling our obligations under these Terms and Conditions and as stated in our Privacy Policy.`,
      `A person who is not party to the agreement between you and us has no rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any of the Terms and Conditions but this does not affect any right or remedy which exists apart from that Act.`,
      `Your purchase will be deemed to have occurred in the UK. These Terms and Conditions shall be governed by and construed in accordance with English Law and the parties agree to submit to the exclusive jurisdiction of the English courts.`,
      `No part of any Simply Thank You Limited publication may be reproduced, stored or transmitted in any form or by any means without the written approval of Simply Thank You Limited. It is acknowledged that all trademarks and trade names mentioned on the Simply Thank You Limited Web site are the property of their respective companies/owners. Simply Thank You Quality Pledge.`,
    ],
  },
  {
    style: 'subtitle1',
    text: 'Simply Thank You Quality Pledge',
    dataTestID: 'Simply Thank You Quality Pledge',
  },
  {
    style: 'textlist',
    text: [
      `We know that our customers want to have complete confidence when they shop with Simply Thank You, so we back each of our products with the Simply Thank You Quality Pledge. Our products are thoroughly tested before they make it to the pages of our site. We source the best products, food, drink and flowers so we can be confident in our range and pass that confidence to our customers. We only stock items that we would be delighted to receive ourselves. So, if you are not happy with a purchase or a gift received from Simply Thank You, please get in touch to let us put it right.`,
    ],
  },
  {
    style: 'subtitle1',
    text: 'Products',
    dataTestID: 'Products',
  },
  {
    style: 'textlist',
    text: [
      `All our product descriptions are accurate at the time of publishing, and we make every effort to supply accurate images for each. Please note that our flower and plant products may be delivered in bud to ensure longer life. In some cases, we may substitute a product without prior notice. The alternative product will be of equal or higher quality and value. In the unlikely event that we are unable to supply a product or its substitute, we will inform you as soon as possible and reimburse payment in full within 30 days of your intended delivery date.`,
    ],
  },
  {
    style: 'subtitle1',
    text: 'Ordering',
    dataTestID: 'Ordering',
  },
  {
    style: 'subtitle1',
    text: 'Making an Order',
    dataTestID: 'Making an Order',
  },
  {
    style: 'textlist',
    text: [
      `To make an order you must supply us with full and accurate details of you and the gift recipient including name, address and full postcode and where possible a contact telephone number.`,
      `Once full payment details have been processed, your order is complete. Payments are made through 'Worldpay,' a secure online real-time payment service that supports major credit and debit cards including Visa, MasterCard, Switch, Solo, Delta, JCB and American Express. Unfortunately we can't accept payment with a Diners Club or department store cards.`,
    ],
  },
  {
    style: 'subtitle1',
    text: 'Points Rewards',
    dataTestID: 'Points Rewards',
  },
  {
    style: 'textlist',
    text: [
      `The Points are issued to the Purchaser. We may and will assume unless and until we are notified to the contrary, that the person who uses the points at any time is the rightful user of the points issued.`,
    ],
  },

  {
    style: 'subtitle1',
    text: 'Points-',
    dataTestID: 'Points-',
  },
  {
    style: 'textlist',
    text: [
      `cannot be exchanged for cash and no exchange can be given.`,
      `can only be used to purchase Goods online at Simply Thank You Limited client facing websites, to which the user will be given relevant access.`,
      `Points may be denominated in GB £s.`,
      `You will not earn any interest on any funds loaded into a points account nor receive any other benefit whether related to the length of time you hold the points or otherwise.`,
      `Points already issued may be removed or cancelled if it is decided that the points were awarded in error. For complete clarification, any actions or advice from our staff that is contrary to these terms and conditions will not have the effect of altering these terms and conditions.`,
      `We will not issue a statement in respect of your points.`,
      `Unused balances are not transferable.`,
      `Points will be valid for 2 years from the date of issuance.`,
    ],
  },

  {
    style: 'subtitle1',
    text: 'Buying Alcohol',
    dataTestID: 'Buying Alchol',
  },

  {
    style: 'textlist',
    text: [
      `If you're ordering alcoholic gifts, you and your recipient must be over the age of 18.`,
      `Under the Licensing Acts of 1964 (UK) and 1976 (Scotland) it is an offence for any person under 18 years to buy, or attempt to buy, alcoholic liquor. It is an offence under Sections 32 and 33 of the Intoxicating Liquor Act 1988 (Ireland) for any person under 18 years to buy, or attempt to buy, intoxicating liquor. It is an offence under the Licensing (Northern Ireland) Order 1996 for any person under 18 years to buy, or attempt to buy, intoxicating liquor.`,
    ],
  },
  {
    style: 'subtitle1',
    text: 'Confirmation and Dispatch',
    dataTestID: 'Confirmation and Dispatch',
  },
  {
    style: 'textlist',
    text: [
      `Orders received and accepted before 15.00 will be dispatched on the same day if a week day, next available working day, or on the day preceding the specified delivery date subject to stock availability.`,
      `Orders received after 15.00 will be dispatched on the following working day or on the day preceding the specified delivery date, subject to stock availability.`,
      `By clicking the 'Checkout' button you are consenting to be bound by our Terms and Conditions.`,
      `Once your order is accepted, you'll receive an automatic email confirming your order details and supplying a reference number to be used in any further correspondence.`,
    ],
  },
  {
    style: 'subtitle1',
    text: 'Delivery',
    dataTestID: 'Delivery',
  },
  {
    style: 'textlist',
    text: [
      `Except where stated, Standard Delivery is next working day via courier.`,
      `We do not make deliveries on Sundays or on Public Holidays.`,
      `The courier will deliver to your chosen address in good faith, and we cannot accept responsibility if the recipient is no longer at the given address, refuses to accept or fails to return the item(s).`,
      `For delivery to particular organisations where a third party is involved, for example an office building, hospital or airport, the signature of any person authorised to accept delivery on behalf of the organisation is accepted as proof of delivery to your chosen recipient.`,
      `For delivery to some locations, surcharges are applicable and you will be advised of this prior to placing your order.`,
      `Vouchers/Gift cards will be sent via Royal Mail post. We recommend that you choose the “Insured Handling Fee” as this will allow your order to be replaced should it be lost or damaged in the post.`,
      `If you choose the “Non Insured” option then it is your risk/cost should the order be lost or damaged.`,
    ],
  },
  {
    style: 'subtitle1',
    text: 'Returns, Exchanges and Refunds',
    dataTestID: 'Returns, Exchanges and Refunds',
  },
  {
    style: 'subtitle1',
    text: 'Cancelling or Amending an Order',
    dataTestID: 'Cancelling or Amending an Order',
  },
  {
    style: 'textlist',
    text: [
      `In the case of perishable items, for example flowers, it is not always possible to cancel an order once it has been processed.`,
      `In most other cases however, we will attempt to fulfil cancel requests up to 2 hours before delivery.`,
      `You can find out about order cancellation by contacting our customer service team on 0800 136545 between 9am and 6pm Monday to Friday, or by emailing orders@simplythankyou.co.uk`,
      `If your order has already arrived, please follow our Returns procedure.`,
    ],
  },

  {
    style: 'subtitle1',
    text: 'Return and Refund',
    dataTestID: 'Return and Refund',
  },
  {
    style: 'textlist',
    text: [
      `Returns are minimal and are therefore dealt with on a case by case basis. If you are unhappy with an item you've received, we will refund the price of the gift item within 30 days of the date of cancellation. In some cases, we will arrange for a courier to collect and return the parcel.`,
      `If you are unsure about requesting a refund, contact our customer service team for help on 0800 136545.`,
      `Simply Thank You trades in line with the new European Directive on distance selling, which is incorporated into UK law by the Consumer Contracts Regulations 2013.`,
    ],
  },

  {
    style: 'subtitle1',
    text: 'Missing, Incomplete or Damaged Orders',
    dataTestID: 'Missing, Incomplete or Damaged Orders',
  },
  {
    style: 'textlist',
    text: [
      `If your order hasn't arrived, arrives incomplete or arrives damaged you can contact our customer service team on 0800 136545.`,
      `Vouchers: If an order of vouchers is lost or stolen, and was placed using the insured Royal Mail Special Delivery service, a replacement order will be sent promptly, once a claim has been processed with Royal Mail. For this reason, we recommend that voucher orders should be sent by Special Delivery and that customers should choose the Special Delivery option when placing their order. We regret that we are unable to supply replacement vouchers unless the original order is sent by Royal Mail Special Delivery.`,
      `If a non-delivered order of vouchers was placed using first or second class Royal Mail post, we will investigate the non-delivery with Royal Mail but unless the original order can be located, we will be unable to supply a replacement order.`,
      `In the case of an incomplete or damaged delivery, please don't destroy or throw away the products until you have spoken with a member of Simply Thank You staff, who will advise how to proceed.`,
      `In the case of gift items which contain multiple products, for example hampers, damaged contents will be replaced as specified.`,
      `If your product is found to be damaged when unpacked, you must notify us within 48 hours of receipt of the goods. All original packaging must be retained for a minimum of 30 days from date of receipt in case the product needs to be returned for any reason (excluding large kitchen appliances). When you receive your goods please open the box and examine them before signing to ensure they are in good condition, if the packaging or item is damaged please do not accept the goods and notify us as soon as possible. If you are unable to check the item at the time of delivery, please sign as unchecked. Once items are delivered we allow 48 hours to check your purchase for damage and report any issues, we cannot accept claims or returns for damage after 48 hours of delivery. Regrettably if items are signed for in good condition that later prove to be damaged, we have no recourse with the carriers so please check items thoroughly to avoid any disappointment.`,
    ],
  },

  {
    style: 'subtitle1',
    text: 'Disclaimer',
    dataTestID: 'Disclaimer',
  },
  {
    style: 'textlist',
    text: [
      `Whilst we agree to use all reasonable endeavours to ensure that this website and/or our service is fully operational and error-free we cannot guarantee this and therefore accept no responsibility for any defects and/or interruption of this website or our service.`,
      `In the event of any cause beyond our reasonable control which renders the provision of this website or our service impossible or impractical, we shall be released from our obligations under these Terms and Conditions.`,
      `We exclude all liability for any claims, losses, demands and damages, including without limitation any costs, loss of profits, loss of contracts or business opportunity, loss of data and any other consequential, incidental, special or punitive damages, even if we have been advised of the possibility of such damages, arising directly or indirectly out of or in any way connected with your use or inability to access this website and/or our service, whether arising in contract, tort (including negligence), under statute or otherwise provided that nothing contained in these Terms and Conditions affects or will affect you or the recipient's statutory rights in relation to the quality, fitness or description of the products supplied.`,
    ],
  },
]
