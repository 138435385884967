import { PricingRequestBody } from '@lbg-protection/api-client-nmp-journey'
import {
  Box,
  Icon,
  makeStyles,
  Typography,
  ButtonV2 as Button,
  Brand,
  useTheme,
  MonocerosTheme,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { CoverSummaryInterface } from '../../../Shared/Basket/CoverSummary/types'
import { isBOS, isSW } from '../../../../utils/brandSpecificFormatting'
import { tagButtonClick } from '../../../../utils/tagUtils/tagsUtils'

const withStyles = makeStyles((theme: MonocerosTheme) => {
  return {
    desktopWrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center' },
    text: { marginTop: '1rem' },
    mobileWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      height: theme.spacing(7.3),
    },
    addedText: {
      marginLeft: theme.spacing(2),
      fontWeight: isBOS(theme.name as Brand) ? 'bold' : undefined,
    },
    addEditWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    editText: {
      textDecoration: 'underline',
    },
    widowsWrapper: {
      width: '90%',
    },
  }
})

interface AddedToBasketProps {
  coverType: PricingRequestBody.CoverTypeEnum
  removeFromBasket?: (data: Pick<CoverSummaryInterface, 'coverType'>) => void
}

export const AddedToBasket = (props: AddedToBasketProps) => {
  const isMobile = true
  return isMobile ? <AddedToBasketMobile {...props} /> : <AddedToBasketDesktop />
}

const getIconColor = (theme: MonocerosTheme) => {
  switch (theme.name) {
    case Brand.LLOYDS:
      return theme.palette.namedColours?.lloydsAppleGreen
    case Brand.BOS:
      return theme.palette.namedColours?.bosPositive
    default:
      return theme.palette.primary.main
  }
}

export const AddedToBasketMobile = (props: AddedToBasketProps) => {
  const { coverType, removeFromBasket } = props
  const handleEdit = async () => {
    if (removeFromBasket) {
      tagButtonClick(`Edit - ${coverType}`)
      removeFromBasket({ coverType })
    }
  }
  const classes = withStyles()
  const theme = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(theme.name as Brand)
  const iconColor = getIconColor(theme)
  return (
    <Box className={`${classes.mobileWrapper} ${isSWBrand && classes.widowsWrapper}`}>
      <Box className={classes.addEditWrapper}>
        <Icon name="positive" htmlColor={iconColor} size={isSWBrand ? '24px' : '34px'} />
        <Typography className={classes.addedText} variant="h5" color="inherit" data-testid={`${coverType}-added`}>
          Added
        </Typography>
      </Box>
      <Button
        variant="text"
        data-testid={`${coverType}-edit`}
        onClick={handleEdit}
        startIcon={
          <Icon name="pencil" color={isSWBrand ? 'inherit' : 'primary'} size={isSWBrand ? '24px' : '1.1rem'} />
        }
      >
        <Typography
          className={!isSWBrand ? classes.editText : ''}
          variant="body2"
          color={isSWBrand ? 'inherit' : 'primary'}
        >
          Edit
        </Typography>
      </Button>
    </Box>
  )
}

export const AddedToBasketDesktop = () => {
  const classes = withStyles()
  return (
    <Box className={classes.desktopWrapper}>
      <Icon name="tick" shape="circle" htmlColor="#048161" size="24px" data-testid="tick-icon" />
      <Icon name="shopping-cart" color="primary" size="60px" data-testid="shopping-cart-icon" />
      <Typography className={classes.text} variant="overline" data-testid="added-to-basket">
        Added to basket
      </Typography>
    </Box>
  )
}
