import { OptionInterface } from '../types'

export const canSubmitAnswer = (answerOptions: OptionInterface[], values: OptionInterface[] | undefined): boolean => {
  if (!values || values.length <= 0) {
    return false
  }
  const badValues = []
  values.forEach((value): null => {
    const { option, outcome } = value
    if (
      !option ||
      !outcome ||
      answerOptions.filter((e) => option.includes(e.option)).length === 0 ||
      (outcome !== 'eligible' && outcome !== 'ineligible')
    ) {
      badValues.push(value)
    }
    return null
  })
  if (badValues.length > 0) {
    return false
  }
  return true
}
