import { MarketingPreferencesState } from '../types'

export const initialState: MarketingPreferencesState = [
  {
    id: 1,
    name: 'scottishWidowsWebsites',
    title: 'Scottish Widows Websites',
    subtext: `If you choose "No", you may still see offer and product messages when you log into our websites. But we won't use your data to tailor them to you. `,
    preference: null,
  },
  {
    id: 2,
    name: 'email',
    title: 'Email',
    preference: null,
  },
  {
    id: 3,
    name: 'post',
    title: 'Post',
    preference: null,
  },
  {
    id: 4,
    name: 'deviceNotifications',
    title: 'Device notifications',
    subtext: `These are messages you may receive through our mobile apps`,
    preference: null,
  },
  {
    id: 5,
    name: 'textMessages',
    title: 'Text messages',
    preference: null,
  },
  {
    id: 6,
    name: 'phone',
    title: 'Phone',
    preference: null,
  },
]
