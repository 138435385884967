import { Button, Dialog, DialogActions, DialogContent, makeStyles } from '@lbg-protection/lib-ui-monoceros'
import clsx from 'clsx'
import React, { useRef, useState } from 'react'
import { ConfirmDownloadDialog } from './ConfirmDownloadDialog'
import { PdfViewer } from './PdfViewer'
import { PdfViewerLoading } from './PdfViewerLoading'
import { PdfViewerDialogProps } from './types'
import { extractFileNameFromFile } from './utils/extractFileNameFromFile'

const withStyles = makeStyles(() => {
  return {
    root: {
      '& .MuiDialog-paper': {
        padding: 0,
      },
      '& .react-pdf__message': {},
    },
    content: { display: 'flex', flexDirection: 'column', padding: 0, '&:first-child': { paddingTop: 0 } },
    actions: { padding: '1rem 0.75rem', margin: 0, borderTop: '1px solid #ddd' },
    close: {
      flex: 1,
    },
    download: {
      flex: 1,
      marginLeft: '1rem',
    },
  }
})

export const PdfViewerDialog = ({
  downloadUrl,
  onDownload,
  pdfViewerProps,
  open,
  onCloseButtonClick,
  ...props
}: PdfViewerDialogProps) => {
  const classes = withStyles()
  const [showDownloadDialog, setShowDownloadDialog] = useState<boolean>(false)

  const pdfFilePath = downloadUrl ?? pdfViewerProps?.file ?? ''

  const resolvedFilename = pdfViewerProps?.filename ? pdfViewerProps.filename : extractFileNameFromFile(pdfFilePath)

  const onDismissConfirm = () => {
    setShowDownloadDialog(false)
  }

  const onConfirm = (event: React.SyntheticEvent) => {
    if (onDownload) {
      onDownload(event)
    }

    setShowDownloadDialog(false)
  }

  const paperRef = useRef<HTMLElement | null>()

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={onCloseButtonClick}
        {...props}
        className={classes.root}
        PaperProps={{
          ref: paperRef,
        }}
      >
        <DialogContent className={classes.content} data-testid="pdf-dialog-view-container">
          <main>
            {pdfViewerProps?.file ? <PdfViewer {...pdfViewerProps} containerRef={paperRef} /> : <PdfViewerLoading />}
          </main>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={onCloseButtonClick}
            className={classes.close}
            data-testid="pdf-dialog-button-close"
          >
            Close
          </Button>
          <a
            className={clsx(
              classes.download,
              'MuiButtonBase-root',
              'MuiButton-root',
              'MuiButton-contained',
              'MuiButton-containedPrimary',
            )}
            onClick={(event: React.SyntheticEvent) => {
              event.preventDefault()
              setShowDownloadDialog(true)
            }}
            href={pdfFilePath}
            download={resolvedFilename}
            data-testid="pdf-dialog-button-download"
          >
            Download
          </a>
        </DialogActions>
      </Dialog>
      {pdfViewerProps && (
        <ConfirmDownloadDialog
          file={pdfFilePath}
          filename={resolvedFilename}
          open={showDownloadDialog}
          onClose={onDismissConfirm}
          onConfirm={onConfirm}
        />
      )}
    </>
  )
}
