import { Brand, ButtonV2 as Button } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { exitSurveyUrls } from '../../../SaveAndRetrieve/utils/exitSurveyUrls'
import { ExitSurveyGiveFeedBackBtnProps } from '../types'
import { tagButtonClick } from '../../../../utils/tagUtils/tagsUtils'

export const ExitSurveyGiveFeedBackBtn = (props: ExitSurveyGiveFeedBackBtnProps) => {
  const { ocisId, showContainedButton, brand } = props

  const onGiveFeedback = () => {
    tagButtonClick('Give feedback')
    const url = exitSurveyUrls(brand as Brand, ocisId)
    if (url) window.open(url)
  }

  return (
    <Button
      variant={showContainedButton ? 'contained' : 'outlined'}
      color="primary"
      fullWidth
      data-testid="exit-survey-give-feedback"
      onClick={onGiveFeedback}
    >
      Give feedback
    </Button>
  )
}

ExitSurveyGiveFeedBackBtn.defaultProps = {
  showContainedButton: false,
}
