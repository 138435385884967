import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MarketingPreferencesState, SetPreferenceActionProps } from '../types'
import { initialState } from './initialState'

// Action Creators
export const setMarketingPreference = createAction<SetPreferenceActionProps>('setMarketingPreference')

export const marketingPreferencesSlice = createSlice({
  name: 'marketingPreferences',
  initialState,
  reducers: {
    setMarketingPreference: (
      state: MarketingPreferencesState,
      action: PayloadAction<SetPreferenceActionProps>,
    ): MarketingPreferencesState => {
      const { id, preference } = action.payload
      const index = state.findIndex((marketingPreference) => marketingPreference.id === id)
      // eslint-disable-next-line no-param-reassign
      state[index].preference = preference
      return state
    },
  },
})
