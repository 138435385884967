import { Box, makeStyles, Typography, Link } from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { BulletPointWrapper } from '../../Shared/BulletPointWrapper/BulletPointWrapper'
import { tagExternalLink } from '../../../utils/tagUtils/tagsUtils'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingTop: theme.spacing(1),
  },
  bulletPointsContainer: {
    paddingTop: theme.spacing(3),
  },
}))

const legalEntities = [
  'Scottish Widows Limited',
  'Scottish Widows Unit Trust Managers Limited',
  'Scottish Widows Administration Services',
  'HBOS Investment Fund Managers Limited',
]
const fullHref = 'https://www.lloydsbankinggroup.com/who-we-are/our-brands.html'
export const LegalEntities = () => {
  const classes = useStyles({})
  return (
    <Box data-testid="legal-entities-info" className={classes.wrapper}>
      <Typography variant="body1">
        By saying yes you are giving consent for Scottish Widows to use your personal information to send relevant
        offers and products. Scottish Widows includes the following legal entities:
      </Typography>
      <Box className={classes.bulletPointsContainer}>
        <BulletPointWrapper bulletPoints={legalEntities} />
      </Box>
      <br />
      <Typography variant="body1">
        Occasionally we will send you selected offers from{' '}
        <Link
          rel="noopener noreferrer"
          href={fullHref}
          onClick={() => tagExternalLink('Other companies within Lloyds Banking Group')}
          target="_blank"
          variant="body2"
          data-testid="other-companies-link"
        >
          other companies within Lloyds Banking Group
        </Link>{' '}
        that may be relevant to you.
      </Typography>
    </Box>
  )
}
