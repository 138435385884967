import { useCallback, useMemo } from 'react'
import { useJourneyNavConfig } from '../components/JourneyNavProvider'
import { AnalyticsService } from './analytics.service'
import { JourneyStep as JourneyStepType } from '../components/JourneyNavProvider/journeyNavConfig'
import { StandardViewTags } from './tags.interface'

export const useTagging = () => {
  const { journeyNavState } = useJourneyNavConfig()

  const standardTagInfo: StandardViewTags = useMemo(() => {
    const { JourneyStep, JourneyStepName, ApplicationState } = journeyNavState?.journeyStep || ({} as JourneyStepType)
    return {
      JourneyStep: JourneyStep || '',
      JourneyStepName: JourneyStepName || '',
      ApplicationState,
    }
  }, [journeyNavState])

  const tagRoutePageLoad = useCallback(
    (JourneyAmount = '') => {
      AnalyticsService.initialPageLoad({ ...standardTagInfo, JourneyAmount })
    },
    [standardTagInfo],
  )

  const tagTechnicalErrorPageLoad = useCallback(() => {
    AnalyticsService.initialPageLoad({
      JourneyStep: '1',
      JourneyStepName: 'Technical Error',
      ApplicationState: standardTagInfo.ApplicationState,
    })
  }, [standardTagInfo.ApplicationState])

  const tagErrorScreen = useCallback(() => {
    AnalyticsService.tagError(standardTagInfo)
  }, [standardTagInfo])

  return {
    tagRoutePageLoad,
    tagTechnicalErrorPageLoad,
    tagErrorScreen,
  }
}
