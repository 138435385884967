import { makeStyles } from '@lbg-protection/lib-ui-monoceros'

export const styles = makeStyles(() => {
  return {
    '@global': {
      html: { height: '100%' },
      body: { height: '100%' },
      '#root': { display: 'flex', flexDirection: 'column', flex: 1, height: '100%', alignItems: 'center' },
    },
    container: {
      zIndex: 1200,
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: `rgba(225,228,233, 0.7)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pageContainerNoBackground: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'start',
    },
  }
})
