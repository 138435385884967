import React from 'react'
import { Brand, LinkV2 as Link, MonocerosTheme, useTheme } from '@lbg-protection/lib-ui-monoceros'
import { LinkV2Props } from '@lbg-protection/lib-ui-monoceros/dist/components/Link'
import { getExternalLinkUrl } from './index'
import { ExternalLinkNames } from './types'

export interface ExternalLinkProps extends LinkV2Props {
  externalLinkName: ExternalLinkNames
  externalLinkText: string
  dataTestId?: string
  onClick?: () => void
}

export const ExternalLink = (props: ExternalLinkProps) => {
  const { name: brand } = useTheme<MonocerosTheme>() as { name: Brand }
  const externalLinkUrl = getExternalLinkUrl(brand, props.externalLinkName)

  return (
    <Link
      href={externalLinkUrl}
      target="_blank"
      data-testid={props.dataTestId ?? `external-link-${props.externalLinkName}`}
      onClick={props.onClick}
    >
      {props.externalLinkText}
    </Link>
  )
}
