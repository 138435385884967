import { Box, makeStyles, Typography, useTheme, MonocerosTheme, Brand } from '@lbg-protection/lib-ui-monoceros'
import clsx from 'clsx'
import React from 'react'
import { PdfViewerLink } from '../PdfViewer'
import { tagExternalLink } from '../../../utils/tagUtils/tagsUtils'
import { isSW } from '../../../utils/brandSpecificFormatting'

const withStyles = makeStyles((theme) => {
  return {
    text: {
      marginTop: theme.spacing(4),
    },
    link: { textDecoration: 'underline' },
    impInfoLink: {
      paddingLeft: theme.spacing(5.5),
    },
  }
})

export const WhatIsCovered = (props: { ciLed: boolean }) => {
  const classes = withStyles()
  const theme = useTheme<MonocerosTheme>()
  const isOrionTheme = isSW(theme.name as Brand)
  const bodyCoverSummaryPdf = './assets/pdf/body-cover-policy-summary-500K.pdf'
  const lifeCoverSummaryPdf = './assets/pdf/life-cover-policy-summary-500K.pdf'
  const bodyCoverTitle = isOrionTheme ? 'Body Cover policy summary' : 'Body Cover Policy Summary'
  const lifeCoverTitle = isOrionTheme ? 'Life Cover policy summary' : 'Life Cover Policy Summary'

  return (
    <Box data-testid="what-is-covered-container">
      <Typography variant="body1" data-testid="life-cover-for-question-answer">
        Find out more in the policy summaries:
      </Typography>
      {props.ciLed ? (
        <Box>
          <Typography variant="body2" className={clsx(classes.text, classes.link)} color="primary">
            <PdfViewerLink
              href={bodyCoverSummaryPdf}
              data-testid="body-summary-pdf-link"
              customOnClick={() => tagExternalLink('Body Cover Policy Summary')}
            >
              {bodyCoverTitle}
            </PdfViewerLink>
          </Typography>
          <Typography variant="body2" className={clsx(classes.text, classes.link)} color="primary">
            <PdfViewerLink
              href={lifeCoverSummaryPdf}
              data-testid="life-summary-pdf-link"
              customOnClick={() => tagExternalLink('Life Cover Policy Summary')}
            >
              {lifeCoverTitle}
            </PdfViewerLink>
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography variant="body2" className={clsx(classes.text, classes.link)} color="primary">
            <PdfViewerLink
              href={lifeCoverSummaryPdf}
              data-testid="life-summary-pdf-link"
              customOnClick={() => tagExternalLink('Life Cover Policy Summary')}
            >
              {lifeCoverTitle}
            </PdfViewerLink>
          </Typography>
          <Typography variant="body2" className={clsx(classes.text, classes.link)} color="primary">
            <PdfViewerLink
              href={bodyCoverSummaryPdf}
              data-testid="body-summary-pdf-link"
              customOnClick={() => tagExternalLink('Body Cover Policy Summary')}
            >
              {bodyCoverTitle}
            </PdfViewerLink>
          </Typography>
        </Box>
      )}
    </Box>
  )
}
