import { createAction, createSelector, createSlice, Selector } from '@reduxjs/toolkit'
import { Item } from '../Shared/Questionaire/questions/LeaveSomethingExtraQuestion/types'
import { CoverItem, CoverItemIDEnum, CoverItems, CoverProductsEnum, CoverSummaryPageState, Reducers } from './types'

const initialState: CoverSummaryPageState = {
  loading: true,
  coverItems: {},
  currentSomethingExtraItems: [],
  savedSomethingExtraItems: null,
}

// action creators
export const setLoading = createAction<{ loading: boolean }>('setLoading')
export const injectCoverItems = createAction<{ coverItems: CoverItems }>('injectCoverItems')
export const setShowEditScreen = createAction<{ product: CoverProductsEnum; itemId: CoverItemIDEnum; show: boolean }>(
  'setShowEditScreen',
)
export const setItemIncluded = createAction<{ product: CoverProductsEnum; itemId: CoverItemIDEnum; included: boolean }>(
  'setItemIncluded',
)
export const updateAmount = createAction<{ product: CoverProductsEnum; itemId: CoverItemIDEnum; amount: number }>(
  'updateAmount',
)
export const setCurrentSomethingExtraItems = createAction<{ somethingExtraItems: Item[] }>(
  'setCurrentSomethingExtraItems',
)
export const saveSomethingExtraItems = createAction<{ somethingExtraItems: Item[] }>('saveSomethingExtraItems')
export const updateAndCloseItem = createAction<{
  product: CoverProductsEnum
  itemId: CoverItemIDEnum
  amount: number
  included: boolean
}>('updateAndCloseItem')

// selectors
export const selectLoading: Selector<CoverSummaryPageState, boolean> = createSelector(
  (state) => state.loading,
  (loading) => loading,
)

export const selectCoveritems: Selector<CoverSummaryPageState, CoverItems> = createSelector(
  (state) => state.coverItems,
  (items) => items,
)

export const selectCurrentSomethingExtraItems: Selector<CoverSummaryPageState, Item[]> = createSelector(
  (state) => state.currentSomethingExtraItems,
  (currentSomethingExtraItems) => currentSomethingExtraItems,
)
export const selectSavedSomethingExtraItems: Selector<CoverSummaryPageState, Item[] | null> = createSelector(
  (state) => state.savedSomethingExtraItems,
  (savedSomethingExtraItems) => savedSomethingExtraItems,
)

// reducers
const reducers: Reducers = {
  setLoading: (state = initialState, action) => {
    const { loading } = action.payload
    return {
      ...state,
      loading,
    }
  },
  injectCoverItems: (state = initialState, action) => {
    const { coverItems } = action.payload
    return {
      ...state,
      coverItems,
    }
  },
  setShowEditScreen: (state = initialState, action) => {
    const { product, itemId, show } = action.payload
    return {
      ...state,
      coverItems: {
        ...state.coverItems,
        [product]: state.coverItems[product]?.map((item: CoverItem) => {
          if (item.id === itemId) return { ...item, showEditScreen: show }
          return { ...item }
        }),
      },
    }
  },
  setItemIncluded: (state = initialState, action) => {
    const { product, itemId, included } = action.payload
    return {
      ...state,
      coverItems: {
        ...state.coverItems,
        [product]: state.coverItems[product]?.map((item: CoverItem) => {
          if (item.id === itemId) return { ...item, included }
          return { ...item }
        }),
      },
    }
  },
  updateAmount: (state = initialState, action) => {
    const { product, itemId, amount } = action.payload
    return {
      ...state,
      coverItems: {
        ...state.coverItems,
        [product]: state.coverItems[product]?.map((item: CoverItem) => {
          if (item.id === itemId) return { ...item, amount }
          return { ...item }
        }),
      },
    }
  },
  setCurrentSomethingExtraItems: (state = initialState, action) => {
    const { somethingExtraItems } = action.payload
    return {
      ...state,
      currentSomethingExtraItems: somethingExtraItems,
    }
  },
  saveSomethingExtraItems: (state = initialState, action) => {
    const { somethingExtraItems } = action.payload
    return {
      ...state,
      savedSomethingExtraItems: somethingExtraItems,
    }
  },
  updateAndCloseItem: (state = initialState, action) => {
    const { product, itemId, amount, included } = action.payload
    return {
      ...state,
      coverItems: {
        ...state.coverItems,
        [product]: state.coverItems[product]?.map((item: CoverItem) => {
          if (item.id === itemId) {
            return {
              ...item,
              amount,
              included,
              showEditScreen: false,
            }
          }
          return { ...item }
        }),
      },
    }
  },
}

export const coverSummarySlice = createSlice({
  name: 'coverSummary',
  initialState,
  reducers,
})
