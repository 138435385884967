import '@lbg-protection/lib-ui-monoceros/dist/assets/fonts.css'
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { appSlice } from './app/app.slice'
import { configSlice } from './app/config.slice'
import { coverCalculatorSlice } from './components/CoverCalculatorPage'
import { coverSummarySlice } from './components/CoverSummary/coverSummary.slice'
import { indicativeQuoteSlice } from './components/IndicativeQuotePage'
import { marketingPreferencesSlice } from './components/MarketingPreferencesPage'
import { paymentDetailsSlice } from './components/PaymentDetailsPage/paymentDetails.slice'
import { policyApplicationSlice } from './components/PaymentDetailsPage/policyApplication.slice'
import { quotesSlice } from './components/QuotePage'
import { saveAndRetrieveSlice } from './components/SaveAndRetrieve/saveAndRetrieve.slice'
import { smokerDeclarationSlice } from './components/SmokerDeclarationPage/SmokerDeclaration/smokerDeclaration.slice'
import { specialRequirementsSlice } from './components/SpecialRequirementsPage/special-requirement-slice'
import { uwMeSlice } from './components/UnderwriteMe/underwriteMe.slice'
import { prePurchaseSlice } from './journeys/purchase'
import { aboutYouSlice } from './journeys/purchase/aboutYou.slice'

export const rootReducer = combineReducers({
  config: configSlice.reducer,
  app: appSlice.reducer,
  paymentDetails: paymentDetailsSlice.reducer,
  underwriteMe: uwMeSlice.reducer,
  quotes: quotesSlice.reducer,
  indicativeQuotes: indicativeQuoteSlice.reducer,
  coverCalculator: coverCalculatorSlice.reducer,
  prePurchase: prePurchaseSlice.reducer,
  marketingPreferences: marketingPreferencesSlice.reducer,
  specialRequirements: specialRequirementsSlice.reducer,
  coverSummary: coverSummarySlice.reducer,
  policyApplication: policyApplicationSlice.reducer,
  aboutYou: aboutYouSlice.reducer,
  saveAndRetrieve: saveAndRetrieveSlice.reducer,
  smokerDeclaration: smokerDeclarationSlice.reducer,
})

export const setStore = () => {
  const prodMiddlewares = [...getDefaultMiddleware()]
  const nonProdMiddlwares = [...getDefaultMiddleware().concat(logger)]

  const middleware = process.env.NODE_ENV !== 'production' ? nonProdMiddlwares : prodMiddlewares

  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware,
  })

  return { store }
}
