import { AnalyticsService } from '../../analytics/analytics.service'

export const tagButtonClick = (LinkValue: string) => AnalyticsService.tagButtonEvent(LinkValue)
export const tagAccordionClick = (accordionName: string) => AnalyticsService.tagAccordionEvent(accordionName)
export const tagExternalLink = (linkName: string) => AnalyticsService.tagExternalLinkEvent(linkName)
export const tagFieldUpdate = (fieldName: string, fieldValue = '', fieldDescription = '') =>
  AnalyticsService.tagFieldUpdate(fieldName, fieldValue, fieldDescription)
export const tagToolTip = (toolTipName: string) => AnalyticsService.tagToolTip(toolTipName)
export const tagChosenSumAssured = (
  JourneyProduct: string,
  JourneyAmount: string,
  JourneyTariff: string,
  JourneyTerm: string,
  voucherAmount: string,
) => AnalyticsService.tagChosenSumAssured(JourneyProduct, JourneyAmount, JourneyTariff, JourneyTerm, voucherAmount)
export const tagPricingEvent = (
  JourneyProduct: string,
  JourneyAmount: string,
  JourneyTariff: string,
  JourneyTerm: string,
  voucherAmount: string,
  calculationType: string,
) =>
  AnalyticsService.tagPricingEvent(
    JourneyProduct,
    JourneyAmount,
    JourneyTariff,
    JourneyTerm,
    voucherAmount,
    calculationType,
  )
export const tagValidationError = (errorValue: string) => AnalyticsService.tagValidationError(errorValue)
